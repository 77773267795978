import { prints } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Prints';
import { triplex } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Triplex';
import { calendar } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Calendar';
import { photobox } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Photobox';
import { leporello } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Leporello';
import { wallart } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart';
import { greeingCards } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/GreeingsCards';
import { stickers } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Stickers';
import { decor } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Decor';
import { gifts } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Gifts';

export const productsPreviewMap = {
  ...prints,
  ...leporello,
  ...triplex,
  ...calendar,
  ...photobox,
  ...wallart,
  ...greeingCards,
  ...stickers,
  ...gifts,
  ...decor
};
