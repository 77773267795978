import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

export const Triplex20x30_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 3, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 4, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 608 327 L 596 981 L 731 975 L 724 320 M 596 981 L 187 1004 L 187 970 C 325 921 398 896 598 828 L 596 981 C 611 950 607 891 599 828 L 598 828" />
              <path d="M 799 312 L 817 958 L 1371 862 L 1283 201" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={21} left={12} width={40} height={46} zIndex={1}>
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 1880" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={17}
        left={49}
        width={47}
        height={48}
        zIndex={1}
        transform="rotate(-6deg)"
      >
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>
    </>
  );
};
