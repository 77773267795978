import { useEffect, useState } from 'react';
import { Modal } from 'modules/ScrShop/components/Modal';
import styled from 'styled-components';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useDispatch } from 'react-redux';
import {
  getCollectionStore,
  getEndCustomerStore,
  getModalDataState,
  getModalState
} from 'modules/ScrShop/store/selectors';
import {
  revokeTermsAction,
  setModalStateAction,
  setShippingValueAction
} from 'modules/ScrShop/store/actions';
import { partial } from 'lodash';
import { IDictionary } from 'modules/ScrShop/store/types';
import { Button } from 'modules/ScrShop/components/Buttons';
import { getShippingStore } from 'modules/ScrShop/store/selectors/shipping';
import { getTranslationKey } from 'helpers/texting';
import { useAppSelector } from 'hooks/redux';

const ModalContentWrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;
const Tab = styled(NavItem)`
  cursor: pointer;
`;
const TabsWrapper = styled.div`
  margin-bottom: 10px;
`;
const Revoke = styled.a`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const IFrame = styled.iframe`
  width: 100%;
  border: none;
  min-height: 400px;
`;

const titles: IDictionary<string> = {
  imprint: 'shop.footer.imprint',
  terms: 'shop.footer.terms',
  privacy: 'shop.footer.privacy'
};

// tslint:disable-next-line:max-func-body-length
export function TermsModal() {
  const dispatch = useDispatch();
  const { _user } = useAppSelector(getCollectionStore);
  const isShowModal = useAppSelector(getModalState)('isShowTermsModal');
  const modalData = useAppSelector(getModalDataState);
  const { authed } = useAppSelector(getEndCustomerStore);
  const formValues = useAppSelector(getShippingStore);
  const { collectionCreator, customDomainUrl } = useAppSelector((state) => state.collection);
  const [activeTab, setActiveTab] = useState('imprint');
  const { customImprint = '', customPrivacy = '' } = collectionCreator;

  const { gtcAndPrivacyCheckbox } = formValues;
  const isShowRevoke = gtcAndPrivacyCheckbox && authed;

  const getProxyUrl = (url: string, fallbackUrl?: string) =>
    url && !customDomainUrl ? `/proxy/${url}` : fallbackUrl;

  const imprintURL = getProxyUrl(customImprint, `/imprint/${window.SITE_ID}`);
  const privacyURL = getProxyUrl(customPrivacy, `/privacy/${window.SITE_ID}`);

  useEffect(() => {
    if (modalData) {
      setActiveTab(modalData as string);
    }
  }, [modalData]);

  const getActiveClass = (tab: string) => (tab === activeTab ? 'active' : '');
  const setActiveTabHandler = (tab: string) => setActiveTab(tab);
  const modalTitle = titles[activeTab];

  const onCloseClick = () =>
    dispatch(setModalStateAction({ modal: 'isShowTermsModal', state: false, customData: {} }));

  const confirmHandler = () => {
    dispatch(setShippingValueAction({ gtcAndPrivacyCheckbox: { value: true, error: '' } }));
    onCloseClick();
  };

  const removeHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(revokeTermsAction());
    onCloseClick();
  };

  const iFrameAllowRestrictions =
    'allow-top-navigation allow-modals allow-same-origin allow-scripts';

  return isShowModal ? (
    // @ts-ignore
    <Modal onClose={onCloseClick} width="50%" zIndex={999999}>
      <ModalContentWrapper>
        <Title>{getTranslationKey(modalTitle)}</Title>
        <TabsWrapper>
          <Nav tabs>
            <Tab>
              <NavLink
                className={getActiveClass('imprint')}
                onClick={partial(setActiveTabHandler, 'imprint')}
              >
                {getTranslationKey('shop.footer.imprint')}
              </NavLink>
            </Tab>
            <Tab>
              <NavLink
                className={getActiveClass('privacy')}
                onClick={partial(setActiveTabHandler, 'privacy')}
              >
                {getTranslationKey('shop.footer.privacy')}
              </NavLink>
            </Tab>
            <Tab>
              <NavLink
                className={getActiveClass('terms')}
                onClick={partial(setActiveTabHandler, 'terms')}
              >
                {getTranslationKey('shop.footer.terms')}
              </NavLink>
            </Tab>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="imprint">
              <IFrame id="imprint-iframe" src={imprintURL} sandbox={iFrameAllowRestrictions} />
            </TabPane>
            <TabPane tabId="privacy">
              <IFrame id="privacy-iframe" src={privacyURL} sandbox={iFrameAllowRestrictions} />
            </TabPane>
            <TabPane tabId="terms">
              <IFrame src={`/terms/${_user}`} sandbox={iFrameAllowRestrictions} />
            </TabPane>
          </TabContent>
        </TabsWrapper>
        {!modalData ? (
          <>
            <Button onClick={confirmHandler}>{getTranslationKey('shop.buttons.confirm')}</Button>
            {isShowRevoke ? (
              <Revoke onClick={removeHandler}>{getTranslationKey('shop.termsModal.revoke')}</Revoke>
            ) : null}
          </>
        ) : (
          <Button onClick={onCloseClick}>{getTranslationKey('shop.buttons.close')}</Button>
        )}
      </ModalContentWrapper>
    </Modal>
  ) : null;
}
