import { get, size, map, trim, isFunction, head, compact, eq } from 'lodash';
import { IDictionary, IFormFieldData } from 'modules/ScrShop/store/types';
import { getTranslationKey } from 'helpers/texting';

const required = (value: string) => !Boolean(value); // eslint-disable-line

interface IValidateOptions {
  formValues: IDictionary<string>;
  title: string;
}

type TValidation = (value: string, options: IValidateOptions) => string | undefined;
const maxFunction = (value: string, max: number) => {
  if (size(value) > max) return `Max ${max} Characters`;

  return null;
};
export const validations: IDictionary<TValidation> = {
  required: (value: string, options: IValidateOptions): string | undefined => {
    if (required(value)) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)} ${getTranslationKey('shop.forms.validationRequired')}`;
    }

    return undefined;
  },
  termsRequired: (value: string, options: IValidateOptions): string | undefined => {
    if (required(value)) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)}`;
    }

    return undefined;
  },
  onlyLetters: (value: string, options: IValidateOptions): string | undefined => {
    const regrxp = /^[a-zA-Z\s\-üäöâáß]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)} ${getTranslationKey('shop.forms.validationOnlyLetters')}`;
    }

    return undefined;
  },
  max250: (value: string): any => maxFunction(value, 250),
  emailFormat: (value: string) => {
    const regrxp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const test = value.match(regrxp);
    if (!test) {
      return getTranslationKey('shop.forms.validationEmail');
    }

    return undefined;
  },
  onlyNumber: (value: string, options: IValidateOptions): string | undefined => {
    const regrxp = /^[0-9]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)} ${getTranslationKey('shop.forms.validationOnlyNumbers')}`;
    }

    return undefined;
  },
  onlyValidZipCode: (value: string, options: IValidateOptions): string | undefined => {
    const testForNumbersOnly = value.match(/^[0-9]+$/);
    const title = get(options, 'title', '');

    if (!testForNumbersOnly) {
      return `${getTranslationKey(title)} ${getTranslationKey('shop.forms.validationOnlyNumbers')}`;
    }

    const maxLengthZipCodeMap = { germany: 5, austria: 4, switzerland: 4 };
    const country = get(options.formValues, '_country.value', '');

    if (
      value.length !== maxLengthZipCodeMap[country] &&
      maxLengthZipCodeMap[country] !== undefined
    ) {
      return `${getTranslationKey('shop.forms.maxLengthError', {
        amount: maxLengthZipCodeMap[country]
      })}`;
    }

    return undefined;
  },
  onlyNumberAndSplashes: (value: string, options: IValidateOptions): string | undefined => {
    const regrxp = /^[0-9\\/]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)} ${getTranslationKey('shop.forms.validationOnlyNumbers')}`;
    }

    return undefined;
  },
  onylAlfaNumericAndSlashes: (value: string, options: IValidateOptions) => {
    const regrxp = /^[a-zA-Z0-9\\/]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)} darf nur Zahlen, Buchstaben und Schrägstriche enthalten`;
    }

    return undefined;
  },
  onlyAlfaNumeric: (value: string, options: IValidateOptions) => {
    const regrxp = /^[a-z0-9]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'title', '');

      return `${getTranslationKey(title)} darf nur Zahlen oder Buchstaben enthalten`;
    }

    return undefined;
  },
  matchPassword: (value: string, { formValues }: IValidateOptions) => {
    const password = get(formValues, 'password.value', '');
    if (!eq(password, value)) {
      return 'Password does not match';
    }

    return undefined;
  }
};

export const valueOfElement = (target: Element) => {
  const isCheckbox = get(target, 'type') === 'checkbox';
  const checkboxValue = get(target, 'checked', 0) ? 1 : 0;

  return isCheckbox ? checkboxValue : get(target, 'value', '');
};

export const validateField = (e: HTMLFormElement, formValues: IDictionary<string>) => {
  const value = valueOfElement(e) as string;
  const name = e.getAttribute('name');
  const title = e.getAttribute('data-required') || e.getAttribute('title') || '';
  const validateOptions = {
    formValues,
    title,
    name
  };

  if (!name) {
    return false;
  }

  const elementValidations = e.getAttribute('data-validations') || '';
  const functions = [...elementValidations.split(',')];
  if (size(functions)) {
    const errors = map(functions, (f: string) => {
      const func = validations[trim(f)];

      return isFunction(func) && func(value, validateOptions);
    });
    const topError = head(compact(errors));
    if (topError) {
      return {
        name,
        errorText: topError
      };
    }
  }

  return undefined;
};

export const validateFieldStore = (
  name: string,
  formField: IFormFieldData,
  formValues: IDictionary<string>
) => {
  const { value, title = '', validations: fieldValidations = '' } = formField;
  const validateOptions = {
    formValues,
    title,
    name
  };
  if (!name) {
    return false;
  }
  const functions = [...fieldValidations.split(',')];
  if (size(functions)) {
    const errors = map(functions, (f: string) => {
      const func = validations[trim(f)];
      const finalValue: any = value === null ? '' : value;

      return isFunction(func) && func(finalValue, validateOptions);
    });
    const topError = head(compact(errors));
    if (topError) {
      return {
        name,
        errorText: topError
      };
    }
  }

  return undefined;
};
