import { useEffect } from 'react';
import { IImage } from 'old-store/types/gallery';
import scrollIntoView from 'scroll-into-view';

type IImageScroll = IImage | { _id: string; selectionId?: string; imageId?: string };

export const scrollToImageElement = (element: IImageScroll, time: number) => {
  const currentSelector = element.selectionId
    ? `[data-selection-id='${element.selectionId}']`
    : `[id='${element.imageId}']`;

  const imageElement = document.querySelector(currentSelector) as HTMLImageElement;

  if (imageElement) {
    scrollIntoView(imageElement, { time, align: { lockX: true } });
  }
};

export const scrollToAnchor = (anchorId: string) => {
  const wrapper = document.querySelector(`[data-scroll-id='${anchorId}']`) as HTMLDivElement;

  if (wrapper) {
    wrapper.scrollIntoView();
  }
};

export const useScrollToAnchorWithTimeout = () => {
  let timeoutId: ReturnType<typeof setTimeout>;

  useEffect(() => () => clearTimeout(timeoutId), []);

  const scrollToAnchorWithTimeout = (anchorId: string, interval: number) =>
    (timeoutId = setTimeout(() => scrollToAnchor(anchorId), interval));

  return { scrollToAnchorWithTimeout };
};
