import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

export const ClassicMug_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1136 352 M 955 1075 C 853 1181 472 1176 394 1072 L 385 460 C 482 524 835 532 961 466" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={30.9} left={25} width={55} height={47}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1250 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const ClassicMug_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1136 352 M 1230 1438 L 155 1435 L 147 522 C 378 712 1016 681 1243 534" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={35} left={10} width={80} height={109}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1000 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const ClassicMug_3 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1126 352 M 700 869 C 638 916 445 920 407 859 L 404 553 C 480 591 646 587 705 554" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={35.9} left={26.9} width={35} height={25}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1250 1500" />
      </PreviewImageContainer>
    </>
  );
};
