import { setCookie } from 'helpers/cookie';

export const user = (state = { appInstallation: 'unset' }, action: any) => {
  switch (action.type) {
    case 'set_app_installation_state':
      if (action.payload !== 'forced') {
        setCookie(`appInstallationState-${window.SITE_ID}`, action.payload, 14);
      }

      return { ...state, appInstallation: action.payload };
    case 'show_app_installation_alert_hint':
      return { ...state, appInstallationAlertHint: true };
    case 'confirm_photobook_reminder':
      return { ...state, confirmedPhotoBookReminder: true };
    case 'confirm_single_image_dl_hint':
      setCookie(`confirmedSingleImgDlHint-${window.SITE_ID}`, 'true', 30);

      return { ...state, confirmedSingleImgDlHint: true };
    default:
      return state;
  }
};
