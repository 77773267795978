import styled from 'styled-components';
import { useTheme } from 'hooks/useTheme';
import { useAppSelector } from 'hooks/redux';

interface ISpinner {
  color?: string;
  hoverColor?: string;
  width?: number;
  height?: number;
  border?: number;
}

const LoaderCircle = styled.div<ISpinner>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: ${({ border }) => border}px solid ${({ color }) => color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner: React.FC<ISpinner> = ({
  color,
  width = 25,
  height = 25,
  border = 3,
  ...props
}) => {
  const [theme] = useTheme();
  const { templateName } = useAppSelector((state) => state.collection);

  const spinnerClass = templateName === 'VickyBaumannFineart' ? 'spinner' : 'hover-spinner';

  return (
    <LoaderCircle
      className={spinnerClass}
      color={color || theme.colors.buttonFontColor}
      width={width}
      height={height}
      border={border}
      {...props}
    />
  );
};
