import { ReactComponent as ContactIconDark } from 'assets/images/icons/dark - contact.svg';
import { ReactComponent as ContactIconSimpleFiligree } from 'assets/images/icons/simple filigree - contact.svg';
import { ReactComponent as ContactIconSpringVibes } from 'assets/images/icons/spring - contact.svg';
import { ReactComponent as ContactIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - contact.svg';
import { ReactComponent as ContactIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - contact.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const ContactIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={ContactIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={ContactIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={ContactIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={ContactIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={ContactIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={ContactIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={ContactIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={ContactIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={ContactIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={ContactIconSimpleFiligree} {...props} />
  })();
