const primary = '#233239';

export const theme = {
  colors: {
    primary: '#233239',
    buttonPrimaryBg: '#000',
    buttonPrimaryHoverBg: '#252525',
    buttonPrimaryDisabledBg: '#434343',
    buttonPrimaryColor: '#fff',
    buttonSecondaryBg: '#ededed',
    buttonSecondaryColor: '#000',
    buttonSecondaryHoverBg: '#a5a5a5',
    modalBg: '#fff',
    modalFontColor: primary,
    white: '#fff',
    black: '#000',
    shopPrimary: '#212020'
  },
  font: {
    primary: 'Asap, sans-serif',
    shop: 'Noto Sans, sans-serif'
  },
  inputBorderRadius: 10
};
