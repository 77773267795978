import { useSelector } from 'react-redux';
import { getImagesSelector } from 'modules/ScrShop/store/selectors';
import { chain } from 'lodash';
import { IProduct, IProductGroup } from 'modules/ScrShop/store/types';
import styled from 'styled-components';

const Image = styled.img<any>`
  position: absolute;
  width: ${(props) => props.width};
  top: ${(props) => props.y}%;
  left: ${(props) => props.x}%;
  transform: ${(props) => props.transform};
  z-index: 1;
`;

const GROUP_IMAGES_OPTIONS: any = {
  photobox: [
    {
      proportion: 'horizontal',
      positions: { x: 51.6, y: 11.8 },
      size: { width: 107, height: 0 },
      transform: 'skew(7deg, -6deg)'
    }
  ]
};

interface IProps {
  productOrGroup: IProductGroup | IProduct;
}

export const ProductImagePlacement: React.FC<IProps> = ({ productOrGroup }) => {
  const images = useSelector(getImagesSelector);
  const groupedImages = chain(images)
    .map((image) => {
      let proportion = '';
      if (image.width === image.height) {
        proportion = 'square';
      } else if (image.width > image.height) {
        proportion = 'horizontal';
      } else {
        proportion = 'vertical';
      }

      return {
        ...image,
        proportion
      };
    })
    .groupBy('proportion')
    .value();
  const groupID = productOrGroup._id;
  const groupOptions = GROUP_IMAGES_OPTIONS[groupID];
  let groupImages = [];
  if (groupOptions) {
    groupImages = groupOptions.map((option: any) => ({
      ...option,
      images: groupedImages[option.proportion]
    }));
  }
  console.log(groupImages);

  return (
    <>
      {groupImages.map((imageOption: any) => (
        <Image
          src={imageOption.images[1].url_M}
          key={imageOption.images[1]._id}
          width={imageOption.size.width}
          x={imageOption.positions.x}
          y={imageOption.positions.y}
          transform={imageOption.transform}
        />
      ))}
    </>
  );
};
