import { createAction } from 'redux-act';
import {
  IFetchProductsAction,
  IGetProductSuggestionAction,
  IProductGroup,
  ProductSuggestion
} from 'modules/ScrShop/store/types';

export const fetchProductsAction = createAction<IFetchProductsAction>();
export const fetchProductsActionSuccess = createAction<IProductGroup[]>();
export const fetchProductSuggestion = createAction<IGetProductSuggestionAction>();
export const fetchProductSuggestionSuccess = createAction<ProductSuggestion[]>();
export const setMadeCroppingChanges = createAction<boolean>('SET_MADE_CROPPING_CHANGES');
