import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';

export const CalendarBasic_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 5, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 6, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={6} left={-18} width={50} height={50} transform="rotate(-11deg)">
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>

      <PreviewImageContainer top={42} left={72} width={65} height={48.9} transform="rotate(9deg)">
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>
    </>
  );
};

export const CalendarBasic_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 7, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={25.4}
        left={8}
        width={50}
        height={36.8}
        transform="rotate(-21.4deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>
    </>
  );
};
