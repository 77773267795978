import { Fragment } from 'react';
import styled from 'styled-components';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import media from 'assets/css/media';
import { theme } from 'assets/css/theme';
import { NavLink, useParams } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import {
  getBreadcrumbs,
  getProductBySlug,
  getProductGroupBySlug
} from 'modules/ScrShop/store/selectors';
import { replaceValuesInString } from 'modules/ScrShop/store/utils';
import { get } from 'lodash';

const BreadcrumbWrapper = styled(Breadcrumb)`
  display: none;
  position: relative;
  z-index: 2;
  order: 3;

  .breadcrumb {
    background-color: #ffffff;
    align-items: center;
    margin-bottom: 0;
  }

  .breadcrumb-item {
    font-size: 12px;

    a {
      color: ${theme.colors.primary};
      padding: 0;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    display: none;
  }

  svg {
    margin: 0 5px;
  }

  @media (min-width: ${media.desktop}) {
    display: flex;
  }
`;

interface IParams {
  groupSlug: string;
  productSlug: string;
  [key: string]: string | undefined;
}

export const BreadcrumbComponent: React.FC = () => {
  const breadcrumbs = useSelector(getBreadcrumbs);
  const { groupSlug = '', productSlug = '' } = useParams<IParams>();
  const group = useSelector(getProductGroupBySlug)(groupSlug);
  const product = useSelector(getProductBySlug)(productSlug);
  const groupTitle = get(group, 'title', '');
  const productTitle = get(product, 'title', '');

  return breadcrumbs.length ? (
    <BreadcrumbWrapper>
      {breadcrumbs.map((breadcrumb, key) => (
        <Fragment key={key}>
          {key ? <FaAngleRight /> : null}
          <BreadcrumbItem>
            <NavLink
              to={replaceValuesInString(breadcrumb.link, {
                groupSlug,
                productSlug
              })}
            >
              {replaceValuesInString(breadcrumb.text, {
                groupTitle,
                productTitle
              })}
            </NavLink>
          </BreadcrumbItem>
        </Fragment>
      ))}
    </BreadcrumbWrapper>
  ) : null;
};
