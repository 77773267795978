import { useAppSelector } from 'hooks/redux';

interface IResult {
  company?: string;
  email?: string;
}

export const useBrandInfo = (): IResult => {
  const { collectionCreator } = useAppSelector((state) => state.collection);
  const userBrands = useAppSelector((state) => state.userBrand);

  const getCompany = () => {
    if (userBrands.company && userBrands.company.length > 0) {
      return userBrands.company;
    }

    return collectionCreator.company;
  };

  const getEmail = () => {
    if (userBrands.email && userBrands.email.length > 0) {
      return userBrands.email;
    }

    return collectionCreator.email;
  };

  return {
    company: getCompany(),
    email: getEmail()
  };
};
