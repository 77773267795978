import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useAppSelector } from 'hooks/redux';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';

export const Triplex20x20_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 3, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 4, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 5, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={54}
        left={-2.9}
        width={29.9}
        height={29.8}
        transform="rotate(-23deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={40}
        left={30.5}
        width={30.4}
        height={29.9}
        transform="rotate(-23.5deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={26}
        left={63}
        width={29.6}
        height={29.8}
        transform="rotate(-23deg)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>
    </>
  );
};
