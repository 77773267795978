import { ReactComponent as FacebookIconDark } from 'assets/images/icons/dark - facebook.svg';
import { ReactComponent as FacebookIconSpringVibes } from 'assets/images/icons/spring - facebook.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const FacebookIcon = (props: IIconProps) =>
  useTemplateVariant({
    dark: () => <BaseIcon icon={FacebookIconDark} {...props} />,
    springVibes: () => <BaseIcon icon={FacebookIconSpringVibes} {...props} />,
    _default_: () => <i className="fa fa-facebook" style={{ color: props.color }} />
  })();
