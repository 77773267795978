import styled from 'styled-components';
import { partial, last } from 'lodash';
import { useDispatch } from 'react-redux';
import { setModalStateAction } from 'modules/ScrShop/store/actions';
import { useLocation } from 'react-router-dom';
// tslint:disable-next-line:no-implicit-dependencies
import environment from 'environment';
import { getTranslationKey } from 'helpers/texting';

const Wrapper = styled.div`
  margin-top: auto;
  position: relative;
  font-size: 12px;
`;
const LinkWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
`;
const Link = styled.a`
  margin: 0 5px;
  text-decoration: underline;
`;
const VatTextWrapper = styled.p`
  text-align: center;
  margin-bottom: 0;
  padding: 0 5px 0 5px;
  color: #909090;
  font-size: 11px;
`;

export function Footer() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathArr = pathname.split('/');
  const isHideLinks = environment.isApp && last(pathArr) !== 'shop';
  const openTermsModalHandler = (section: string, e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(
      setModalStateAction({
        modal: 'isShowTermsModal',
        state: true,
        customData: section
      })
    );
  };

  return !isHideLinks ? (
    <Wrapper>
      <VatTextWrapper>{getTranslationKey('shop.footer.vatShippingText')}</VatTextWrapper>
      <LinkWrapper>
        <Link href="/" onClick={partial(openTermsModalHandler, 'imprint')}>
          {getTranslationKey('shop.footer.imprint')}
        </Link>
        |
        <Link href="/" onClick={partial(openTermsModalHandler, 'privacy')}>
          {getTranslationKey('shop.footer.privacy')}
        </Link>
        |
        <Link href="/" onClick={partial(openTermsModalHandler, 'terms')}>
          {getTranslationKey('shop.footer.terms')}
        </Link>
      </LinkWrapper>
    </Wrapper>
  ) : null;
}
