import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';
import { fetchCountriesOnSuccess } from 'modules/ScrShop/store/actions';

export const fetchCountriesSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Countries.get);
    console.log(response);

    ApiErrors.checkOnApiError(response);
    yield put(fetchCountriesOnSuccess(response.result));
  } catch (e) {
    console.log(e);
  }
};
