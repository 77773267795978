import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { UpsellingPackageCard } from 'components/UpsellModal/UpsellingPackageCard';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addUpdateProductToCartAction,
  fetchDigitalPricingForUpselling,
  fetchPackagesForUpselling,
  setCropImageAction,
  setSelectedImagedSuccessAction,
  setSelectedProductIDAction
} from 'modules/ScrShop/store/actions';
import { useIsCheckoutReady } from 'modules/ScrShop/store/hooks/is-checkout-ready';
import {
  getUpsellingDigitalPricingListSelector,
  getUpsellingPackagesSelector
} from 'modules/ScrShop/store/selectors';
import type { ISelection } from 'old-store/types/gallery';
import { toggleUpsellModal } from 'store/slices/modals';
import { selectCurrentLikedImages } from 'store/slices/selection/selectors';
import { UpsellingAllImagesCard } from 'components/UpsellModal/UpsellingAllImagesCard';
import { HiSparkles } from 'react-icons/hi';
import { TbEdit } from 'react-icons/tb';
import { FiShoppingBag } from 'react-icons/fi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { getTranslationKey } from 'helpers/texting';
import { PriceBreakdown } from './PriceBreakdown';
import { getEndCustomer } from 'old-store/actions/endCustomer';
import { useCurrency } from 'hooks/useCurrency';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { media } from 'assets/css/media';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styled, { css } from 'styled-components';
import { useSwipeCoordinates } from 'hooks/useSwipeCoordinates';
import { Button } from 'reactstrap';
import { get } from 'lodash';
import { IPackage } from 'modules/ScrShop/store/types';
import { useSliderIndex } from './useSliderIndex';
import { useIndexedImages } from 'hooks/useIndexedImages';

const SwiperContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .slick-track {
    display: flex;
    align-items: flex-end;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-dots {
    bottom: -10px;
    li {
      margin: 0;
    }
  }

  .slick-slide {
    height: auto;
    padding: 0 5px;
    > div {
      height: 100%;
    }
  }
`;

const arrowStyles = css`
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  fill: #000;
`;

const LeftArrow = styled(FaAngleLeft)`
  ${arrowStyles}
  left: -25px;
`;
const RightArrow = styled(FaAngleRight)`
  ${arrowStyles}
  right: -25px;
`;

const PriceContent = styled.div`
  margin-top: 40px;
  @media all and (max-width: ${media.desktopLg}) {
    margin-top: 25px;
  }
`;

const EconomyContent = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f7eef;
  font-size: 14px;
  font-weight: bold;
`;

const SparkledIcon = styled(HiSparkles)`
  margin-right: 5px;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-top: 30px;
  @media all and (max-width: ${media.desktopLg}) {
    margin-top: 0;
  }
`;

const StyledButton = styled(Button)<{ isDarkTemplate: boolean }>`
  && {
    width: calc(50% - 20px);
    border-radius: 40px !important;
    padding: 6px 12px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    border: ${({ isDarkTemplate }) => (isDarkTemplate ? '1px solid #000 !important' : '')};

    svg {
      margin-right: 10px;
      width: 22px;
      height: 22px;
      fill: none !important;
    }

    &:first-child {
      background: #fff;
      color: #707070;

      @media all and (max-width: ${media.desktopLg}) {
        margin-bottom: 15px;
      }
    }

    @media all and (max-width: ${media.desktopLg}) {
      width: 100%;
    }

    &:disabled {
      cursor: no-drop !important;
      pointer-events: all;
    }
  }
`;
interface IProps {
  selection: ISelection;
  toggleSaveSelectionModal: () => void;
}

export const UpsellingSection: React.FC<IProps> = ({ selection, toggleSaveSelectionModal }) => {
  const collection = useAppSelector((state) => state.collection);
  const selectedImagesAmount = useAppSelector(selectCurrentLikedImages).length;
  const upsellingPackages = useAppSelector(getUpsellingPackagesSelector);
  const digitalPricingList = useAppSelector(getUpsellingDigitalPricingListSelector);
  const { filteredImagesArr: images } = useIndexedImages({});
  const { likes } = useAppSelector((state) => state.selection);
  const { swipeCoordinates, startSwiping, stopSwiping } = useSwipeCoordinates();
  const isDesktop = useBreakpoint('desktopXl');
  const sliderRef = useRef<any>(null);
  const currency = useCurrency();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [selectedPackage, setSelectedPackage] = useState<null | IPackage>(null);
  const [isReadyForRedirectToBasket, setIsReadyForRedirectToBasket] = useState(false);
  const isCheckoutReady = useIsCheckoutReady();

  useEffect(() => {
    dispatch(fetchPackagesForUpselling(collection.digitalShop.digitalPricingList || ''));
    dispatch(fetchDigitalPricingForUpselling(collection.digitalShop.digitalPricingList || ''));
    dispatch(getEndCustomer());
  }, [dispatch]);

  useEffect(() => {
    if (isCheckoutReady && isReadyForRedirectToBasket) {
      // close modal before redirecting
      dispatch(toggleUpsellModal());

      history.push('/shop/basket');
    }
  }, [isReadyForRedirectToBasket, isCheckoutReady]);

  const alreadyPurchasedPackage = upsellingPackages.find(
    ({ id }) => id === collection.digitalShop.alreadyPurchasedTier
  );

  // const isCustomerSelectedRightAmountOfImages =
  //   alreadyPurchasedPackage && selectedImagesAmount === alreadyPurchasedPackage.imagesQuantity;
  // const isCustomerSelectedMoreImages =
  //   alreadyPurchasedPackage && selectedImagesAmount > alreadyPurchasedPackage.imagesQuantity;

  const addImagesToCart = (packageId: string) => {
    dispatch(setSelectedProductIDAction(packageId));
    dispatch(setSelectedImagedSuccessAction(likes));
    likes.forEach((favorite: any) => {
      dispatch(setCropImageAction({ imageID: favorite, cropData: null }));
    });

    dispatch(addUpdateProductToCartAction({ selectionId: selection._id }));

    setIsReadyForRedirectToBasket(true);
  };

  const addImagesToCartWithSwipeHandler = (packageId: string) => {
    if (swipeCoordinates.start !== swipeCoordinates.end) return;
    addImagesToCart(packageId);
  };

  const toggleSaveSelection = () => {
    if (swipeCoordinates.start !== swipeCoordinates.end) return;
    toggleSaveSelectionModal();
  };

  const allPackages = [...upsellingPackages];
  const allImagesPackages = allPackages.filter((item) => item.allImagesIncluded);
  const commonPackages = allPackages.filter((item) => !item.allImagesIncluded);
  const packagesSortedByTierWithInitialPrice = commonPackages
    .filter(
      (pckg) =>
        pckg.imagesQuantity >=
        (alreadyPurchasedPackage ? alreadyPurchasedPackage.imagesQuantity : 0)
    )
    .sort((a, b) => a.imagesQuantity - b.imagesQuantity);
  const packagesSortedByTier = packagesSortedByTierWithInitialPrice.map((pckg) => ({
    ...pckg,
    grossTotalPrice: alreadyPurchasedPackage
      ? Math.max(pckg.grossTotalPrice - alreadyPurchasedPackage.grossTotalPrice, 0)
      : pckg.grossTotalPrice
  }));

  const packagesReversed = [...packagesSortedByTier].reverse();
  // const largestPackage = packagesReversed[0];
  const matchingPackage = packagesReversed.find(
    (pckg) => pckg.imagesQuantity === selectedImagesAmount
  );
  const matchingPackageExtraImages = packagesReversed.find(
    (pckg) => selectedImagesAmount > pckg.imagesQuantity
  );

  const sortedPackages = [...upsellingPackages].sort((a, b) => {
    if (a.imagesQuantity === null && b.imagesQuantity === null) return 0;
    if (a.imagesQuantity === null) return 1;
    if (b.imagesQuantity === null) return -1;

    return a.imagesQuantity - b.imagesQuantity;
  });

  const lowestTierPackage = [...upsellingPackages].sort((a, b) => {
    if (a.imagesQuantity !== null && b.imagesQuantity !== null) {
      return a.imagesQuantity - b.imagesQuantity;
    }

    return 0;
  })[0];

  const allImagesReversed = [...allPackages].reverse();
  const allImagesPackage = allImagesReversed.find((pckg) => pckg.imagesQuantity === null);
  const allImagesMatch = selectedImagesAmount === images?.length && allImagesPackage;

  const currentMatchingPackage = matchingPackage || matchingPackageExtraImages;
  const matchedPackage = allImagesMatch || selectedPackage || currentMatchingPackage;

  const matchedPackageWithInitialPrice = packagesSortedByTierWithInitialPrice.find(
    (pckg) => pckg.id === matchedPackage?.id
  );

  const sameAmountPackages = packagesSortedByTier.filter(
    ({ imagesQuantity }) => imagesQuantity === matchedPackage?.imagesQuantity
  );

  const matchedPackageIdx = sortedPackages.findIndex((item) => item.id === matchedPackage?.id);
  const nextTierPackage = sortedPackages[matchedPackageIdx + 1];
  const nextTierImageQuantity = get(nextTierPackage, 'imagesQuantity');
  const imagesForNextTier = nextTierImageQuantity
    ? nextTierImageQuantity - selectedImagesAmount
    : images.length - selectedImagesAmount;

  const notEnoughImagesSelected = selectedImagesAmount < packagesSortedByTier[0]?.imagesQuantity;

  const getSavings = (pckg) => {
    const quantity = get(pckg, 'imagesQuantity') || images.length;
    const costPerImage = get(pckg, 'grossTotalPrice') / quantity;

    const lowestTierQuantity = get(lowestTierPackage, 'imagesQuantity') || images.length;
    const lowestTierCostPerImage = get(lowestTierPackage, 'grossTotalPrice') / lowestTierQuantity;

    if (
      lowestTierPackage &&
      lowestTierPackage?.id !== pckg?.id &&
      lowestTierCostPerImage > costPerImage
    ) {
      return Math.round(100 * (1 - costPerImage / lowestTierCostPerImage));
    }

    return null;
  };

  const selectPackage = (packageItem: IPackage) => setSelectedPackage(packageItem);

  const otherPackagesCards = packagesSortedByTier.map((pckg) => (
    <UpsellingPackageCard
      imagePackage={pckg}
      key={pckg.id}
      selectedImagesAmount={selectedImagesAmount}
      addPackageToCart={addImagesToCartWithSwipeHandler}
      isAlreadyPurchasedPackage={
        !!alreadyPurchasedPackage && pckg.id === alreadyPurchasedPackage.id
      }
      matchedPackage={matchedPackage}
      toggleSaveSelectionModal={toggleSaveSelection}
      packagesSortedByTier={packagesSortedByTier}
      sameAmountPackages={sameAmountPackages}
      selectPackage={selectPackage}
      savings={getSavings(pckg)}
      bestseller={pckg?.bestseller}
    />
  ));

  const allImagesPackagesCards = allImagesPackages?.map((pckg) => (
    <UpsellingAllImagesCard
      key={pckg.id}
      imagePackage={pckg}
      alreadyPurchasedPackage={alreadyPurchasedPackage}
      isSwiping={swipeCoordinates.start !== swipeCoordinates.end}
      savings={getSavings(pckg)}
      matchedPackage={matchedPackage}
      bestseller={pckg?.bestseller}
      images={images}
    />
  ));

  const allPackagesCards = [...otherPackagesCards, ...allImagesPackagesCards];

  const priceBreakDownRows: Array<[any, number, number]> = [];

  if (matchedPackage) {
    priceBreakDownRows.push([
      matchedPackage.name,
      matchedPackage.imagesQuantity || selectedImagesAmount,
      matchedPackageWithInitialPrice?.grossTotalPrice || matchedPackage.grossTotalPrice
    ]);

    const currentQuantity = selectedImagesAmount - matchedPackage.imagesQuantity;

    if (currentQuantity && matchedPackage.imagesQuantity) {
      priceBreakDownRows.push([
        getTranslationKey('shop.upselling.additionalData'),
        currentQuantity,
        currentQuantity * matchedPackage.grossAdditionalImagePrice
      ]);
    }
  }

  if (alreadyPurchasedPackage) {
    priceBreakDownRows.push([
      getTranslationKey('shop.upselling.alreadyPurchased'),
      -alreadyPurchasedPackage.imagesQuantity,
      -alreadyPurchasedPackage.grossTotalPrice
    ]);
  }

  const { sliderIndex, setSliderIndex } = useSliderIndex({
    matchedPackage,
    packages: [...packagesSortedByTier, ...allImagesPackages],
    slickGoTo: (index: number) => sliderRef?.current?.slickGoTo(index)
  });

  const slidesToShow = isDesktop ? 3 : 1;
  const totalSlides = allPackagesCards.length - (slidesToShow - 1);

  const isFirstSlide = sliderIndex === 0 || allPackagesCards.length <= 3;
  const isLastSlide = sliderIndex === totalSlides - 1 || allPackagesCards.length <= 3;

  const slideLeftArrow = () => sliderRef?.current?.slickPrev();
  const slideRightArrow = () => sliderRef?.current?.slickNext();

  const showLowerPart = !!priceBreakDownRows.length || !matchedPackage;

  const isDarkTemplate = collection.templateName === 'dark' && collection.type === 'ScrAppBook2';

  if (!digitalPricingList) return null;

  return (
    <div className="w-100 px-3 px-lg-4">
      <SwiperContainer
        onMouseDown={startSwiping}
        onMouseUp={stopSwiping}
        onMouseLeave={stopSwiping}
        onTouchStart={startSwiping}
        onTouchEnd={stopSwiping}
      >
        {!isFirstSlide && <LeftArrow onClick={slideLeftArrow} />}
        <Slider
          ref={sliderRef}
          speed={300}
          slidesToScroll={1}
          slidesToShow={slidesToShow}
          initialSlide={sliderIndex}
          useTransform={false}
          arrows={false}
          infinite={false}
          dots={!isDesktop}
          beforeChange={(_: number, newIndex: number) => setSliderIndex(newIndex)}
        >
          {allPackagesCards}
        </Slider>
        {!isLastSlide && <RightArrow onClick={slideRightArrow} />}
      </SwiperContainer>

      {showLowerPart && (
        <PriceContent>
          <PriceBreakdown
            currency={currency}
            rows={priceBreakDownRows}
            showFilesOnly={!matchedPackage}
          />
        </PriceContent>
      )}

      {!notEnoughImagesSelected && imagesForNextTier > 0 && getSavings(nextTierPackage) && (
        <EconomyContent>
          <SparkledIcon />
          {getTranslationKey('shop.upselling.selectMoreImages', {
            files: imagesForNextTier,
            savings: String(getSavings(nextTierPackage))
          })}
        </EconomyContent>
      )}

      {notEnoughImagesSelected && (
        <EconomyContent>
          <SparkledIcon />
          {getTranslationKey('shop.upselling.addMoreImages', {
            files: packagesSortedByTier[0]?.imagesQuantity
          })}
        </EconomyContent>
      )}

      {showLowerPart && (
        <ButtonSection>
          <StyledButton onClick={toggleSaveSelectionModal}>
            <TbEdit />
            <span>{getTranslationKey('shop.upselling.continueSelection')}</span>
          </StyledButton>
          <StyledButton
            className="sb-btn"
            disabled={!matchedPackage}
            isDarkTemplate={isDarkTemplate}
            onClick={() => addImagesToCart(matchedPackage!.id)}
          >
            <FiShoppingBag />
            <span>{getTranslationKey('shop.upselling.goToCart')}</span>
          </StyledButton>
        </ButtonSection>
      )}
    </div>
  );
};
