import { Modal } from 'reactstrap';
import { toggleSelectionSentCongratsModal } from 'store/slices/modals';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { getTranslationKey } from 'helpers/texting';
import styled from 'styled-components';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { SendToPhotographerIcon } from 'components/icons/SendToPhotographerIcon';
import { useTheme } from 'hooks/useTheme';

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledFaTelegramPlane = styled(SendToPhotographerIcon)`
  display: flex;
  width: 40px;
  height: 40px;
  margin: 20px 0;
`;

export const SelectionSentCongratsModal = () => {
  const dispatch = useAppDispatch();
  const [theme] = useTheme();
  const { selectionSentCongratsModal } = useAppSelector((state) => state.modals);

  const toggleSentSelectionCongrats = () => dispatch(toggleSelectionSentCongratsModal());

  return (
    <Modal
      data-cy="selectionSentCongratsModal"
      centered
      isOpen={selectionSentCongratsModal.isOpen}
      toggle={toggleSentSelectionCongrats}
      zIndex={999999}
      size="md"
    >
      <ModalHeader data-cy="toggleSentSelectionCongrats" toggle={toggleSentSelectionCongrats}>
        {getTranslationKey('selections.completedSelection')}
      </ModalHeader>
      <ModalBody>
        <BodyContent>
          <div>{getTranslationKey('selections.chooseSelectionModal.gz')}</div>
          <StyledFaTelegramPlane color={theme.colors.modalFontColorLight} />
          <div>{getTranslationKey('selections.selectionWasSubmitted')}</div>
        </BodyContent>
      </ModalBody>
    </Modal>
  );
};
