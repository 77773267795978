import Queue from 'yocto-queue';
import Api from 'old-store/utils/API';

export type TEvent = {
  name: string;
  timestamp: Date;
  payload: unknown;
  app: 'gallerys';
  anonymousId: string | null;
  recordedAt?: Date;
};

export type TOptions = {
  bypassQueue?: boolean;
};

const ANALYTICS_MAX_Q_SIZE = window.ANALYTICS_MAX_Q_SIZE
  ? parseInt(window.ANALYTICS_MAX_Q_SIZE)
  : 100;
const ANALYTICS_FLUSH_WINDOW_MS = window.ANALYTICS_FLUSH_WINDOW_MS
  ? parseInt(window.ANALYTICS_FLUSH_WINDOW_MS)
  : 10_000;

let analytics: Analytics;
export function initAnalytics(): Analytics {
  return analytics || (analytics = new Analytics());
}

class Analytics {
  private readonly queue: Queue<TEvent>;
  private flushTimer: NodeJS.Timeout | null;

  constructor() {
    this.queue = new Queue<TEvent>();
    this.flushTimer = null;
    this.flush = this.flush.bind(this);

    // flush when user leaves the page
    document.addEventListener('visibilitychange', () => this.flush(true));
    window.addEventListener('pagehide', () => this.flush(true));
    window.addEventListener('beforeunload', () => this.flush(true));

    // send events from local storage
    const storedEvents = localStorage.getItem('analyticsEvents');
    if (storedEvents) {
      localStorage.removeItem('analyticsEvents');
      JSON.parse(storedEvents).map((event: TEvent) => {
        event.timestamp = new Date(event.timestamp);
        this.track(event);
      });
    }
  }

  public track(event: TEvent, options?: TOptions): void {
    if (options?.bypassQueue) {
      Api.Analytics.trackEvent(event, true);
      return;
    }

    event.recordedAt = new Date();
    this.queue.enqueue(event);
    if (!this.flushTimer) {
      this.flushTimer = setTimeout(this.flush, ANALYTICS_FLUSH_WINDOW_MS);
    }
    this.flushIfNeeded();
  }

  private flushIfNeeded(): void {
    const oldestEvent = this.queue.peek();
    if (!oldestEvent) return;

    const timestamp = (oldestEvent.recordedAt || oldestEvent.timestamp).getTime();
    const timeSinceOldestEvent = Date.now() - timestamp;
    if (
      this.queue.size >= ANALYTICS_MAX_Q_SIZE ||
      timeSinceOldestEvent >= ANALYTICS_FLUSH_WINDOW_MS
    ) {
      this.flush();
    }
  }

  private async flush(beacon?: boolean): Promise<void> {
    const events: TEvent[] = [];
    try {
      if (this.queue.size === 0) return;
      console.log(`[Analytics]: flushing ${this.queue.size} events`);

      if (this.flushTimer) {
        clearTimeout(this.flushTimer);
        this.flushTimer = null;
      }

      while (this.queue.peek()) {
        events.push(this.queue.dequeue()!);
      }

      const attempts = 5;
      for (let i = 0; i < attempts; i++) {
        try {
          await Api.Analytics.trackEventBulk(events, beacon);
          return;
        } catch (err) {
          if (i === attempts - 1) {
            console.warn(
              `[Analytics]: failed to send events to server after ${i + 1} attempts,`,
              'saving events to local storage...',
              err
            );
            const storedEvents = JSON.parse(localStorage.getItem('analyticsEvents') || '[]');
            localStorage.setItem('analyticsEvents', JSON.stringify([...storedEvents, ...events]));
          } else {
            console.warn(
              '[Analytics]:',
              `${i + 1}/${attempts}`,
              'failed to send events to server, retrying...',
              err
            );
          }
        }
      }
    } catch (err) {
      console.error('[Analytics]: failed to flush events queue', err);
    }
  }
}
