import { IImage } from 'old-store/types/gallery';
import { usePreview } from 'react-dnd-preview';

import styled from 'styled-components';

interface IDragPreviewSlideProps {
  item: { image: IImage; with: number; height: number };
  image?: IImage;
  width?: number;
  height?: number;
  offsetWidth?: number;
  offsetHeight?: number;
  display: boolean;
  itemType: string;
  monitor: any;
  ref: { current: any };
  style: {
    WebkitTransform: string;
    left: number;
    pointerEvents: string;
    position: string;
    top: number;
    transform: string;
  };
}

const PreviewSlideContainer = styled.div<{ width?: number; height?: number }>`
  cursor: grab;
  width: ${({ width }) => width || 0}px;
  height: ${({ height }) => height || 0}px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const MobileDragLayer = () => {
  const preview = usePreview<IDragPreviewSlideProps>();

  if (!preview.display) return null;

  const { style, item } = preview;

  const offsetWidth = item?.offsetWidth || 0;
  const offsetHeight = item?.offsetHeight || 0;

  return (
    <PreviewSlideContainer style={style} width={offsetWidth} height={offsetHeight}>
      <img src={item?.image?.url} alt="" />
    </PreviewSlideContainer>
  );
};
