import { Modal, Form, Row, Col } from 'reactstrap';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { togglePasswordModal } from 'store/slices/modals';
import { signUpFlyCustomer } from 'store/slices/endCustomer/actions';
import { getTranslationKey } from 'helpers/texting';
import { Input } from 'modules/ScrShop/components/Forms';
import { useToggle } from 'hooks/useToggle';
import { Button } from 'modules/ScrShop/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    text-transform: initial !important;
  }
`;

export const CreateAccountWithPasswordModal = () => {
  const dispatch = useAppDispatch();
  const endCustomer = useAppSelector((state) => state.endCustomer);
  const { passwordModal } = useAppSelector((state) => state.modals);
  const { toggle: isValidated, openToggle: enableValidation } = useToggle();
  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(getTranslationKey('mandatoryField'))
        .min(6, getTranslationKey('enterPasswordModal.passwordIsTooShort'))
        .oneOf(
          [Yup.ref('passwordConfirmation'), null],
          getTranslationKey('enterPasswordModal.passwordsMustMatch')
        ),
      passwordConfirmation: Yup.string()
        .required(getTranslationKey('mandatoryField'))
        .min(6, getTranslationKey('enterPasswordModal.passwordIsTooShort'))
        .oneOf(
          [Yup.ref('password'), null],
          getTranslationKey('enterPasswordModal.passwordsMustMatch')
        )
    }),
    onSubmit: (values) => {
      dispatch(
        signUpFlyCustomer({
          email: endCustomer.email?.toLowerCase() || '',
          firstName: endCustomer.firstName || '',
          lastName: endCustomer.lastName || '',
          password: values.password
        })
      );
    }
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  const toggleModal = () => dispatch(togglePasswordModal());

  return (
    <Modal centered isOpen={passwordModal.isOpen} toggle={toggleModal} size="md" zIndex={999999}>
      <StyledModalHeader toggle={toggleModal}>
        {getTranslationKey('signupWithPassword.createAccount')}
      </StyledModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs="12">
              <p>{getTranslationKey('signupWithPassword.description')}</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Input
                id="passwordID"
                name="password"
                type="password"
                label={getTranslationKey('shop.forms.passwordTitle')}
                placeholder={getTranslationKey('shop.forms.passwordTitle')}
                value={values.password}
                onChange={handleChange}
                handleBlur={handleBlur}
                errorText={isValidated ? errors.password : ''}
                withEye
              />
            </Col>
            <Col xs="12">
              <Input
                id="passwordConfirmationID"
                name="passwordConfirmation"
                type="password"
                label={getTranslationKey('shop.forms.confirmPasswordPlaceHolder')}
                placeholder={getTranslationKey('shop.forms.passwordConfirmation')}
                value={values.passwordConfirmation}
                onChange={handleChange}
                handleBlur={handleBlur}
                errorText={isValidated ? errors.passwordConfirmation : ''}
                withEye
              />
            </Col>
            <Col className="mt-2" xs="12">
              <Button type="submit" className="sb-btn" onClick={onSubmit}>
                {getTranslationKey('signupWithPassword.createAccount')}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
