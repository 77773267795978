import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';
import { useMemo } from 'react';

const PreviewBorder = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  border: 0.1px solid white;
  border-radius: 8%;
`;

export const Sticker16x = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 5, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={7.8} left={16.7} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={7.8} left={32.8} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={7.8} left={48.9} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={7.8} left={65.1} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={29} left={16.7} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={29} left={32.8} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={29} left={48.9} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={29} left={65.1} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50.2} left={16.7} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50.2} left={32.8} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50.2} left={48.9} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50.2} left={65.1} width={16.5} height={21.2}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={70.7} left={16.7} width={16.5} height={21.3}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={70.7} left={32.8} width={16.5} height={21.3}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={70.7} left={48.9} width={16.5} height={21.3}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={70.7} left={65.1} width={16.5} height={21.3}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>
    </>
  );
};

export const Sticker16x_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={6.7}
        left={19.7}
        width={36}
        height={28.7}
        transform="matrix3d(1,0.35,0,0,-1.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={6.7}
        left={19.7}
        width={36}
        height={29.7}
        transform="matrix3d(1,0.35,0,0,-1.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={19.3}
        left={55.7}
        width={37}
        height={29.7}
        transform="matrix3d(1,0.34,0,0,-1.05,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={31.8}
        left={92.6}
        width={39}
        height={29.7}
        transform="matrix3d(1,0.34,0,0,-1.05,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={36}
        left={-11.4}
        width={36}
        height={30.7}
        transform="matrix3d(1,0.36,0,0,-1.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={49}
        left={24.6}
        width={38}
        height={30.7}
        transform="matrix3d(1,0.34,0,0,-1.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={62}
        left={62.8}
        width={39}
        height={30.55}
        transform="matrix3d(1,0.342,0,0,-1.053,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={76}
        left={100}
        width={39}
        height={30.55}
        transform="matrix3d(1,0.342,0,0,-1.053,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={80}
        left={-6.4}
        width={38}
        height={30.7}
        transform="matrix3d(1,0.34,0,0,-1.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={92.7}
        left={31}
        width={38}
        height={30.7}
        transform="matrix3d(1,0.34,0,0,-1.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1150" />
      </PreviewImageContainer>
    </>
  );
};

export const Sticker16x_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 4, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 979 433 C 937 433 937 433 921 447 L 409 824 C 345 867 338 868 371 875 C 554 919 644 934 816 967 C 874 974 874 977 952 911 C 1140 753 1206 705 1404 470 C 1442 425 1442 426 1341 429 M 407 822 L 404 819 L 309 851 L 291 862 L 332 875 L 407 822 L 309 851 M 332 875 L 891 1022 L 1479 812 L 1457 798 C 906 997 894 1003 883 1004 L 339 870 M 696 613 L 215 515 L -165 733 L 292 863 L 410 823" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={26.8}
        left={-19}
        width={33.3}
        height={17.2}
        zIndex={3}
        transform="matrix3d(1,0.22,0,0,-1.5,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 700" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={33.5}
        left={14.4}
        width={32.3}
        height={16.9}
        zIndex={2}
        transform="matrix3d(1,0.22,0,0,-1.6,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 600" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={28.5}
        left={57}
        width={50}
        height={56}
        zIndex={1}
        transform="matrix3d(1,0,0,-0.0008,-1.3,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1700" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.9}
        left={109}
        width={2}
        height={53}
        zIndex={1}
        transform="rotate(69deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1500" x={-1300} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={48.8}
        left={-12}
        width={33.3}
        height={17.2}
        zIndex={3}
        transform="matrix3d(1,0.28,0,0,-3,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 600" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={58}
        left={21}
        width={39}
        height={16}
        zIndex={3}
        transform="matrix3d(1,0.26,0,0,-2.9,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 600" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={74}
        left={-25}
        width={39}
        height={16}
        zIndex={3}
        transform="matrix3d(1,0.26,0,0,-2.9,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 600" />
      </PreviewImageContainer>
    </>
  );
};
