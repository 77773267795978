import { useCallback, useState } from 'react';
import { useInterval } from 'hooks/useInterval';
import moment from 'moment';
import styled from 'styled-components';
import { Exist } from 'components/Exist';

const Wrapper = styled.div`
  span:not(:last-child) {
    &:after {
      content: ':';
    }
  }
`;

interface IProps {
  timeTillDate: string;
  displayValues: string[];
  timeFormat: string;
  interval?: number;
}

const isShowValue = (values: string[], valueLabel: string, value: any) =>
  value && values.indexOf(valueLabel) > -1;

export const CountDown: React.FC<IProps> = ({
  timeTillDate,
  timeFormat,
  displayValues,
  interval = 1000
}) => {
  const [state, setState] = useState({
    days: '',
    hours: '',
    minutes: '',
    seconds: ''
  });
  const getCountdown = useCallback(() => {
    const then: any = moment(timeTillDate, timeFormat);
    const now: any = moment();
    const countdown = moment(then - now);
    const days = countdown.format('D');
    const hours = countdown.format('HH');
    const minutes = countdown.format('mm');
    const seconds = countdown.format('ss');
    setState({
      days,
      hours,
      minutes,
      seconds
    });
  }, [timeTillDate, timeFormat]);

  useInterval(() => {
    getCountdown();
  }, interval);

  return (
    <Wrapper className="countdown-timer">
      <Exist when={isShowValue(displayValues, 'd', state?.days)}>
        <span>{state.days}</span>
      </Exist>
      <Exist when={isShowValue(displayValues, 'h', state?.hours)}>
        <span>{state.hours}</span>
      </Exist>
      <Exist when={isShowValue(displayValues, 'm', state?.minutes)}>
        <span>{state.minutes}</span>
      </Exist>
      <Exist when={isShowValue(displayValues, 's', state?.seconds)}>
        <span>{state.seconds}</span>
      </Exist>
    </Wrapper>
  );
};
