import { createReducer } from 'redux-act';
import { IShippingReducer } from 'modules/ScrShop/store/types';
import { DEFAULT_SHIPPING_FORM_STATE } from 'modules/ScrShop/store/constants/checkout';
import { setShippingValueAction } from 'modules/ScrShop/store/actions/shipping';
import { setCollection } from 'store/slices/collection';

const initialState: IShippingReducer = DEFAULT_SHIPPING_FORM_STATE;

export const shipping = createReducer<IShippingReducer>({}, initialState);
shipping.on(setShippingValueAction, (state, payload) => ({
  ...state,
  ...payload
}));
// @ts-ignore
shipping.on(setCollection, (state) => {
  const persistData = localStorage.getItem(`shipping_${window.SITE_ID}`);
  const persistState = persistData ? JSON.parse(persistData) : DEFAULT_SHIPPING_FORM_STATE;

  return {
    ...state,
    ...persistState
  };
});
