import { useState } from 'react';
import { Modal } from 'modules/ScrShop/components/Modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getModalState } from 'modules/ScrShop/store/selectors';
import { applyCouponAction, setModalStateAction } from 'modules/ScrShop/store/actions';
import { Button } from 'modules/ScrShop/components/Buttons';
import { Input } from 'reactstrap';
import { getTranslationKey } from 'helpers/texting';

const ModalContentWrapper = styled.div`
  padding: 20px;

  input {
    margin-bottom: 10px;
  }
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

export function CouponModal() {
  const dispatch = useDispatch();
  const isShowModal = useSelector(getModalState)('isShowCouponModal');
  const [code, setCode] = useState('');
  const onCloseClick = () => {
    dispatch(
      setModalStateAction({
        modal: 'isShowCouponModal',
        state: false
      })
    );
  };
  const confirmHandler = () => {
    if (code) {
      dispatch(
        applyCouponAction({
          code
        })
      );
      onCloseClick();
    }
  };
  const onChangeHandler = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCode(value);
  };

  return isShowModal ? (
    // @ts-ignore
    <Modal onClose={onCloseClick} width="50%" zIndex={999999}>
      <ModalContentWrapper>
        <Title>{getTranslationKey('shop.checkoutOverViewPage.coupon')}</Title>
        <Input name="code" type="text" placeholder="Code" value={code} onChange={onChangeHandler} />
        <Button onClick={confirmHandler}>{getTranslationKey('shop.buttons.confirm')}</Button>
      </ModalContentWrapper>
    </Modal>
  ) : null;
}
