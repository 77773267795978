import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { getTranslationKey } from 'helpers/texting';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import { getDownloadUrl } from 'helpers/downloadUrl';
import {
  toggleSelectionActionsModal,
  toggleInfoModal,
  toggleDownloadPinModal,
  toggleSaveSelectionModal,
  toggleSendSelectionModal,
  toggleShareModal,
  toggleUpsellModal
} from 'store/slices/modals';
import { ButtonBox } from 'components/DesktopMenu/MenuElements';
import { SendToPhotographerIcon } from 'components/icons/SendToPhotographerIcon';
import { SaveSelectionIcon } from 'components/icons/SaveSelectionIcon';
import { SelectionIcon } from 'components/icons/SelectionIcon';
import { ShopIcon } from 'components/icons/ShopIcon';
import { ShareIcon } from 'components/icons/ShareIcon';
import { DownloadIcon } from 'components/icons/DownloadIcon';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { useTheme } from 'hooks/useTheme';
import { setSharingUrl } from 'store/slices/sharing';
import { useScrollToAnchorWithTimeout } from 'helpers/scrollToAnchor';
import { incrementImagesDownloadCount } from 'store/slices/app';

import styled from 'styled-components';

const ActionText = styled.div<{ color: string }>`
  font-size: 18px;
  color: ${({ color }) => color};
`;

const ActionTextTop = styled(ActionText)<{ color: string }>`
  margin-top: -5px;
  margin-bottom: 15px;
  color: ${({ color }) => color};
`;

const ActionTextBottom = styled(ActionText)<{ color: string }>`
  margin-top: 20px;
  color: ${({ color }) => color};
`;

export const SelectionActionsModal = () => {
  const dispatch = useAppDispatch();
  const { selectionActionsModal } = useAppSelector((state) => state.modals);

  const history = useHistory();
  const [theme] = useTheme();
  const { all } = useAppSelector((state) => state.selection);
  const collection = useAppSelector((state) => state.collection);
  const environment = useAppSelector((state) => state.environment);
  const { scrollToAnchorWithTimeout } = useScrollToAnchorWithTimeout();
  const {
    digitalShop,
    globalShopIsActive,
    shopActive: printShopActive
  } = useAppSelector((state) => state.collection);

  const currentSelection = all.find((selection) => selection.current);

  const toggleModal = () => dispatch(toggleSelectionActionsModal());

  const closeBeforeAction = (callback: () => void): void => {
    toggleModal();
    callback();
  };

  const downloadSelection = () => {
    const { url, infoModalText, infoModalText2 } = getDownloadUrl(
      // @ts-ignore
      { selection_id: currentSelection._id },
      { collection, environment }
    );

    if (infoModalText || infoModalText2) {
      dispatch(toggleInfoModal({ text1: infoModalText, text2: infoModalText2 }));
    }

    if (url) {
      if (collection.downloadPinActive && collection.downloadPin) {
        dispatch(toggleDownloadPinModal({ action: 'startDownload', url }));
      } else {
        // @ts-ignore
        window.downloadFile(url);
        dispatch(incrementImagesDownloadCount());
      }
    }
  };

  const handleSendSelection = () => dispatch(toggleSendSelectionModal());
  const handleSaveSelection = () => dispatch(toggleSaveSelectionModal());

  const handleShare = () => {
    dispatch(toggleShareModal({ singleGalleryShare: true }));
    dispatch(setSharingUrl({ type: 'selection', identifier: currentSelection?._id, url: '' }));
  };

  const handlePurchase = () => {
    if (digitalShop.enabled && digitalShop.digitalPricingList) {
      dispatch(toggleUpsellModal());
    } else if (printShopActive) {
      history.push('/shop');
    }
  };

  const goToSelectionPage = () => {
    history.push('/selections');
    if (currentSelection) scrollToAnchorWithTimeout(currentSelection._id, 400);
  };

  const isGermanSelected = window.LANGUAGE === 0;
  const isEnglishSelected = window.LANGUAGE === 1;

  const isSelectionPage = history.location.pathname.includes('/selections');
  const shopIsActive = globalShopIsActive && (digitalShop.enabled || printShopActive);

  return (
    <Modal
      centered
      isOpen={selectionActionsModal.isOpen}
      toggle={toggleModal}
      size="md"
      zIndex={999999}
    >
      <ModalHeader toggle={toggleModal}>{getTranslationKey('selections.selectAction')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            {isGermanSelected && (
              <ActionTextTop color={theme.colors.modalFontColorLight}>
                {getTranslationKey('selections.shareSelectedImage')}
              </ActionTextTop>
            )}

            <ButtonBox
              data-cy="SendToPhotographerIcon "
              icon={<SendToPhotographerIcon size={22} color={theme.colors.menuIconsColor} />}
              className="sb-btn w-100 my-2"
              centered={false}
              onClick={() => closeBeforeAction(handleSendSelection)}
            >
              {getTranslationKey('selections.sendSelection')}
            </ButtonBox>

            {shopIsActive && (
              <ButtonBox
                data-cy="ShopIcon"
                icon={<ShopIcon size={22} color={theme.colors.menuIconsColor} />}
                className="sb-btn w-100 my-2"
                centered={false}
                onClick={() => closeBeforeAction(handlePurchase)}
              >
                {getTranslationKey('selections.shop')}
              </ButtonBox>
            )}

            {!isSelectionPage && (
              <ButtonBox
                data-cy="SelectionIcon"
                icon={<SelectionIcon size={22} color={theme.colors.menuIconsColor} />}
                className="sb-btn w-100 my-2"
                centered={false}
                onClick={() => closeBeforeAction(goToSelectionPage)}
              >
                {getTranslationKey('selections.showSelection')}
              </ButtonBox>
            )}

            <ButtonBox
              data-cy="SaveSelectionIcon"
              icon={<SaveSelectionIcon size={22} color={theme.colors.menuIconsColor} />}
              className="sb-btn w-100 my-2"
              centered={false}
              onClick={() => closeBeforeAction(handleSaveSelection)}
            >
              {getTranslationKey('selections.saveSelection')}
            </ButtonBox>

            {collection.download && (
              <ButtonBox
                data-cy="DownloadIcon"
                icon={<DownloadIcon size={22} color={theme.colors.menuIconsColor} />}
                className="sb-btn w-100 my-2"
                centered={false}
                onClick={() => closeBeforeAction(downloadSelection)}
              >
                {getTranslationKey('selections.downloadSelection')}
              </ButtonBox>
            )}

            {collection.sharing.show && (
              <ButtonBox
                data-cy="ShareIcon"
                icon={<ShareIcon size={22} color={theme.colors.menuIconsColor} />}
                className="sb-btn w-100 my-2"
                centered={false}
                onClick={() => closeBeforeAction(handleShare)}
              >
                {getTranslationKey('selections.shareSelection')}
              </ButtonBox>
            )}

            {isEnglishSelected && (
              <ActionTextBottom color={theme.colors.modalFontColorLight}>
                {getTranslationKey('selections.shareSelectedImage')}
              </ActionTextBottom>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
