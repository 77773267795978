export const repositionImagesAccordingToFrames = ({
  spreadImages,
  bestMatchingArray,
  currentImagesOrientations
}) => {
  const occupiedFrameIndexes = new Set<number>();

  const newSpreadImages = spreadImages
    .map((image, idx) => {
      const imageOrientation = currentImagesOrientations[idx];
      let bestFrameIndex = -1;

      for (let i = 0; i < bestMatchingArray.length; i++) {
        const isPositionFree = !occupiedFrameIndexes.has(i);
        const isImageFits = bestMatchingArray[i] === imageOrientation;

        if (isPositionFree && isImageFits) {
          bestFrameIndex = i;
          break;
        }
      }

      if (bestFrameIndex >= 0) {
        occupiedFrameIndexes.add(bestFrameIndex);

        return { ...image, frameIndex: bestFrameIndex };
      }

      return { ...image, frameIndex: null };
    })
    .map((image) => {
      if (image.frameIndex === null) {
        const rangeIndexes = [...Array(bestMatchingArray.length).keys()];
        const availableIndex = rangeIndexes.find((i) => !occupiedFrameIndexes.has(i));

        if (availableIndex !== undefined) {
          occupiedFrameIndexes.add(availableIndex);

          return { ...image, frameIndex: availableIndex };
        }
      }

      return image;
    });

  return newSpreadImages;
};
