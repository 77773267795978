import { ChangeEvent, useState } from 'react';
import { useLinkShare } from 'components/ShareSelection/useLinkShare';
import { useNativeShare } from 'components/ShareSelection/useNativeShare';
import { getTranslationKey } from 'helpers/texting';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useIndexedImages } from 'hooks/useIndexedImages';
import { IModalsSharingState, setSharingUrl } from 'store/slices/sharing';
import { isShareApiAvailable } from 'helpers/isShareAvailable';

interface IShareData {
  shareOptions: Array<{ name: string; value: string }>;
  selectionOptions: Array<{ name: string; value: string }>;
  galleriesOptions: Array<{ label: string; value: string }>;
  isHiddenShown: boolean;
  sharing: IModalsSharingState;
  selectedGalleries: Array<{ label: string; value?: string }>;
  selectSharingType: (e: ChangeEvent<HTMLSelectElement>) => void;
  shareSelections: (e: ChangeEvent<HTMLSelectElement>) => void;
  shareGalleries: (changedGalleries: any) => void;
  onlyPublicImages: boolean;
  handleHiddenImagesToggle: () => void;
  copied?: boolean;
  onShare: () => void;
  onShareSingleImage: (identifier: string, showToast?: boolean) => void;
  onSubmitShareFunc?: any;
}

interface ISelectedGalleries {
  label: string;
  value: string;
}

export const useShareData = (): IShareData => {
  const dispatch = useAppDispatch();
  const linkShare = useLinkShare();
  const nativeShare = useNativeShare();
  const sharing = useAppSelector((state) => state.sharing);
  const { all: selections } = useAppSelector((state) => state.selection);
  const { _id: userId } = useAppSelector((state) => state.endCustomer);
  const { galleries } = useAppSelector((state) => state.collection);
  const [selectedGalleries, setSelectedGalleries] = useState<ISelectedGalleries[]>([]);
  const { filteredImagesArr } = useIndexedImages({});

  const selectSharingType = (e: ChangeEvent<HTMLSelectElement>) => {
    const identifier = e.target.value === 'all-images' ? 'all-images' : '';
    dispatch(setSharingUrl({ type: e.target.value, identifier, url: '' }));
  };

  const shareSelections = (e: ChangeEvent<HTMLSelectElement>) =>
    dispatch(setSharingUrl({ type: 'selection', identifier: e.target.value, url: '' }));

  const shareGalleries = (changedGalleries: ISelectedGalleries[]) => {
    const galleryIds = changedGalleries.map((gallery) => gallery.value).join(',');

    dispatch(setSharingUrl({ type: 'gallery', identifier: galleryIds, url: '' }));
    setSelectedGalleries(changedGalleries);
  };

  const isHiddenShown = filteredImagesArr.some((image) => image.hiddenBy.includes(userId));

  const menuOptions = [
    { name: getTranslationKey('shareView.completeGallery'), value: 'all-images' },
    { name: getTranslationKey('shareView.specificAlbum'), value: 'gallery' },
    { name: getTranslationKey('shareView.specificSelection'), value: 'selection' }
  ];

  const shareOptions = menuOptions.filter(({ value }) =>
    value === 'selection' ? selections.length : true
  );
  const selectionOptions = selections.map(({ _id, name }) => ({
    name: name || getTranslationKey('selections.chooseSelectionModal.currentSelection'),
    value: _id
  }));
  const galleriesOptions = galleries
    .filter(({ forPurchasedImages }) => !forPurchasedImages)
    .map(({ _id, title }) => ({ label: title, value: _id }));

  const commonData = {
    shareOptions,
    selectionOptions,
    galleriesOptions,
    isHiddenShown,
    sharing,
    selectSharingType,
    shareSelections,
    shareGalleries,
    selectedGalleries
  };

  const additionalData = isShareApiAvailable() ? nativeShare : linkShare;

  return Object.assign(commonData, additionalData);
};
