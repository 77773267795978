import {
  LeporelloSingleImage_0,
  LeporelloSingleImage_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Leporello/LeporelloSingleImage';
import {
  LeporelloDoubleImage_0,
  LeporelloDoubleImage_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Leporello/LeporelloDoubleImage';

export const leporello = {
  'mini-leporello-7x7-single-image-page': LeporelloSingleImage_0,
  'mini-leporello-7x7-single-image-page_0': LeporelloSingleImage_0,
  'mini-leporello-7x7-single-image-page_1': LeporelloSingleImage_1,

  'mini-leporello-7x7-double-image-page': LeporelloDoubleImage_0,
  'mini-leporello-7x7-double-image-page_0': LeporelloDoubleImage_0,
  'mini-leporello-7x7-double-image-page_1': LeporelloDoubleImage_1
};
