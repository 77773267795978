import { fetchProductsAction } from '../../modules/ScrShop/store/actions/products';

export const setAppInstallationState = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'set_app_installation_state', payload });
  } catch (e) {
    console.log(e);
  }
};

export const showAppInstallationAlertHint = () => (dispatch) => {
  try {
    dispatch({ type: 'show_app_installation_alert_hint' });
  } catch (e) {
    console.log(e);
  }
};

export const confirmSingleImageDownloadHint = () => (dispatch) => {
  try {
    dispatch({ type: 'confirm_single_image_dl_hint' });
  } catch (e) {
    console.log(e);
  }
};

export const fetchProducts = () => (dispatch) => {
  try {
    dispatch(fetchProductsAction({ collection_id: window.SITE_ID }));
  } catch (e) {
    console.log('e', e);
  }
};
