import { FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { getNumberOfCheckoutProducts } from 'modules/ScrShop/store/selectors/checkout';

const CardProductsLabel = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e01d1d;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -1px;
`;

const LinkButton = styled(NavLink)`
  background: #000;
  position: fixed;
  bottom: 25px;
  left: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

export function StickyBasketLink() {
  const productsInCart = useSelector(getNumberOfCheckoutProducts);

  return productsInCart ? (
    <LinkButton to="/shop/basket">
      <FaShoppingCart size={18} color="#fff" />
      <CardProductsLabel>{productsInCart}</CardProductsLabel>
    </LinkButton>
  ) : null;
}
