import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'old-store/utils/axios';
import { setCollectionCreator, setDownloadPin } from 'store/slices/collection';
import { RootState } from 'old-store/store';
import { updateSiteTracking } from 'old-store/actions/environment';

export const checkDownloadPin =
  (
    pin: string,
    collection_id: string
  ): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      await axios.post('/api/download/downloadPin/check', { pin, collection_id });

      dispatch(setDownloadPin(pin));

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

export const getCollectionCreator = createAsyncThunk(
  'collection/collectionCreator',
  async (_, { dispatch }: any) => {
    try {
      const {
        data: { result }
      } = await axios.get(`/api/collections/${window.SITE_ID}/user`);

      dispatch(setCollectionCreator(result));
      dispatch(
        updateSiteTracking({
          builderDomain: result?.builderDomain || '',
          galleryDomain: result?.customDomain || ''
        })
      );
    } catch (e) {
      console.log('e', e);
    }
  }
);
