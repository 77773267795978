import { useEffect, useRef } from 'react';

interface IMouseCoordinates {
  x: number;
  y: number;
}

export function useMouseCoordinates() {
  const mouseCoordinates = useRef<IMouseCoordinates>({ x: 0, y: 0 });

  useEffect(() => {
    const mouseMove = ({ pageX, pageY }: any) => {
      mouseCoordinates.current = { x: pageX, y: pageY };
    };
    document.addEventListener('mousemove', mouseMove);

    return () => document.removeEventListener('mousemove', mouseMove);
  }, []);

  return [mouseCoordinates];
}
