import { ReactComponent as TwitterIconDark } from 'assets/images/icons/dark - twitter.svg';
import { ReactComponent as TwitterIconSimpleFiligree } from 'assets/images/icons/simple filigree - twitter.svg';
import { ReactComponent as TwitterIconSpringVibes } from 'assets/images/icons/spring - twitter.svg';
import { ReactComponent as TwitterIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - twitter.svg';
import { ReactComponent as TwitterIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - twitter.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const TwitterIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={TwitterIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={TwitterIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={TwitterIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={TwitterIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={TwitterIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={TwitterIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={TwitterIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={TwitterIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={TwitterIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={TwitterIconSimpleFiligree} {...props} />
  })();
