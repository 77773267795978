import { Alert, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getCheckoutStore } from 'modules/ScrShop/store/selectors/checkout';
import moment from 'moment';

export function OrderDeadlineBanner({
  colMd = 12,
  colXs = 12
}: {
  colMd: number | undefined;
  colXs: number | undefined;
}) {
  const { _laboratory: laboratory } = useSelector(getCheckoutStore);
  const showBanner = moment().isBetween(moment('2023-11-20'), moment('2023-12-24'));

  if (!showBanner) return null;

  const renderBanner = function () {
    if (!laboratory) {
      return (
        <Alert color="danger">
          Bitte beachte die Bestellfristen für Weihnachten. Lege ein gewünschtes Produkt in den
          Warenkorb, damit die Lieferfristen angezeigt werden. <br />
          <strong>Digitale Dateien werden direkt nach Zahlungseingang bereitgestellt.</strong>
        </Alert>
      );
    }
    if (laboratory === '5f92ee2cac100ce9bf64384c') {
      // nPhoto - fertig für 2023
      return (
        <Alert color="danger">
          <strong>Bestellfrist für Lieferung bis Weihnachten:</strong>
          <ul className="mb-0">
            <li>Fotoabzüge, Fotokalender und Mini-Leporello: 01.12.</li>
            <li>Alle weiteren Produkte: 28.11.</li>
            <li>Digitale Dateien werden direkt nach Zahlungseingang bereitgestellt.</li>
          </ul>
          Bitte beachte, dass deine Bestellung bis zu diesem Datum bezahlt sein muss! Solltest du
          per Banküberweisung bezahlen, plane etwas mehr Zeit ein oder sende uns eine
          Überweisungsbestätigung indem du auf die Bestellbestätigungs E-Mail antwortest.
        </Alert>
      );
    }
    if (laboratory === '60914f66db344022409079f9') {
      // Pixelfotoexpress - warte auf Daten für 2023
      return (
        <Alert color="danger">
          Bestellfrist für Lieferung bis Weihnachten:
          <ul className="mb-0">
            <li>Fotoabzüge: 18.12.</li>
            <li>Alle weiteren Produkte: 15.12.</li>
            <li>Digitale Dateien werden direkt nach Zahlungseingang bereitgestellt.</li>
          </ul>
          Bitte beachte, dass deine Bestellung bis zu diesem Datum bezahlt sein muss! Solltest du
          per Banküberweisung bezahlen, plane etwas mehr Zeit ein oder sende uns eine
          Überweisungsbestätigung indem du auf die Bestellbestätigungs E-Mail antwortest.
        </Alert>
      );
    }
    if (laboratory === '64dcc2ccd599833c1c3784a2') {
      // Picanova - warte auf Daten für 2023
      return (
        <Alert color="danger">
          Bestellfrist für Lieferung bis Weihnachten:
          <ul className="mb-0">
            <li>Fotoabzüge: 18.12.</li>
            <li>Alle weiteren Produkte: 15.12.</li>
            <li>Digitale Dateien werden direkt nach Zahlungseingang bereitgestellt.</li>
          </ul>
          Bitte beachte, dass deine Bestellung bis zu diesem Datum bezahlt sein muss! Solltest du
          per Banküberweisung bezahlen, plane etwas mehr Zeit ein oder sende uns eine
          Überweisungsbestätigung indem du auf die Bestellbestätigungs E-Mail antwortest.
        </Alert>
      );
    }
  };

  return (
    <Col md={colMd} xs={colXs}>
      {renderBanner()}
    </Col>
  );
}
