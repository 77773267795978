import { Modal, Form, Row, Col } from 'reactstrap';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { toggleLoginModal } from 'store/slices/modals';
import { getTranslationKey } from 'helpers/texting';
import { resetPassword, signInFlyCustomer } from 'store/slices/endCustomer/actions';
import { Button } from 'modules/ScrShop/components/Buttons';
import { useToggle } from 'hooks/useToggle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Input } from 'modules/ScrShop/components';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { LinkBtn } from 'components/IconButton/LinkButton';

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    text-transform: initial !important;
  }
`;

const ResetPassword = styled(LinkBtn)`
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  margin: 15px 0 0;
`;

const SuccessMessage = styled.p`
  margin: 15px 0 0;
  font-size: 14px;
`;

export const AccountLoginModal = () => {
  const dispatch = useAppDispatch();
  const endCustomer = useAppSelector((state) => state.endCustomer);
  const { loginModal } = useAppSelector((state) => state.modals);
  const { toggle: isValidated, openToggle: enableValidation } = useToggle();
  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(getTranslationKey('mandatoryField'))
        .min(6, getTranslationKey('enterPasswordModal.passwordIsTooShort'))
    }),
    onSubmit: (values) => {
      dispatch(
        signInFlyCustomer({
          selectionName: endCustomer.selectionName,
          message: endCustomer.message,
          email: endCustomer.email?.toLowerCase() || '',
          firstName: endCustomer.firstName || '',
          lastName: endCustomer.lastName || '',
          password: values.password
        })
      );
    }
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  const toggleModal = () => dispatch(toggleLoginModal());

  return (
    <Modal centered isOpen={loginModal.isOpen} toggle={toggleModal} size="md" zIndex={999999}>
      <StyledModalHeader toggle={toggleModal}>
        {getTranslationKey('shop.forms.loginToExistingAccount')}
      </StyledModalHeader>
      <ModalBody style={{ paddingBottom: 0 }}>
        <p>
          {getTranslationKey('shop.forms.accountNotification', {
            userName: endCustomer.firstName || ''
          })}
        </p>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs="12">
              <Input
                disabled
                id="emailID"
                name="email"
                type="email"
                label={getTranslationKey('email')}
                placeholder={getTranslationKey('email')}
                value={endCustomer.email || ''}
                onChange={handleChange}
              />
            </Col>
            <Col xs="12">
              <Input
                id="passwordID"
                name="password"
                type="password"
                label={getTranslationKey('shop.forms.passwordPlaceHolder')}
                placeholder={getTranslationKey('shop.forms.passwordPlaceHolder')}
                value={values.password}
                onChange={handleChange}
                handleBlur={handleBlur}
                errorText={isValidated ? errors.password : ''}
                withEye
              />
            </Col>
            <Col className="mt-2" xs="12">
              <Button type="submit" className="sb-btn" onClick={onSubmit}>
                {getTranslationKey('shop.buttons.login')}
              </Button>
            </Col>
            <Col xs="12">
              <ResetPassword onClick={() => dispatch(resetPassword(endCustomer.email))}>
                {getTranslationKey('resetPassword')}
              </ResetPassword>
            </Col>
            <Col xs="12">
              {endCustomer?.showPasswordChangeSuccess && (
                <SuccessMessage>
                  {getTranslationKey('forgotPasswordModal.successMessage')}
                </SuccessMessage>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
