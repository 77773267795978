import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IModalsSharingState {
  openSharingCard?: string;
  url?: string;
  isLoading: boolean;
  collectionUrl?: string;
  identifier?: string;
  type: string;
  storedUrls: { [name: string]: string };
  cachedData: { [name: string]: File[] };
}

interface ISharingPayload {
  type: string;
  identifier?: string;
  url: string;
}

interface ISharingFilesPayload {
  type: string;
  identifier: string;
  hiddenStatus: boolean;
  imageFilesArr: File[];
}

const initialState: IModalsSharingState = {
  type: '',
  isLoading: false,
  // @ts-ignore
  url: encodeURIComponent(window.SITE_URL),
  // @ts-ignore
  collectionUrl: encodeURIComponent(window.SITE_URL),
  storedUrls: {},
  cachedData: {}
};

const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    setOpenSharingCard(state, action: PayloadAction<string | undefined>) {
      state.openSharingCard = action.payload;
    },
    closeSharingCard(state) {
      state.openSharingCard = undefined;
      state.url = undefined;
    },
    startSharingLoad(state) {
      state.isLoading = true;
    },
    stopSharingLoad(state) {
      state.isLoading = false;
    },
    removeSharingUrl(state) {
      state.url = undefined;
    },
    setSharingUrl(state, action: PayloadAction<ISharingPayload>) {
      const { type, identifier, url } = action.payload;

      state.type = type;
      state.identifier = identifier;
      state.url = url;
      state.storedUrls[`${type}${identifier || ''}Url`] = action.payload.url;
    },
    setCachedFiles(state, action: PayloadAction<ISharingFilesPayload>) {
      const { type, identifier, hiddenStatus, imageFilesArr } = action.payload;

      state.cachedData[`${type}_${identifier}_${hiddenStatus}`] = imageFilesArr;
    }
  }
});

export const {
  setOpenSharingCard,
  closeSharingCard,
  removeSharingUrl,
  setSharingUrl,
  startSharingLoad,
  stopSharingLoad,
  setCachedFiles
} = sharingSlice.actions;
export const sharingReducer = sharingSlice.reducer;
