import styled from 'styled-components';
import { Currency } from 'dinero.js';

import { displayProductPrice } from 'modules/ScrShop/store/utils';
import { getTranslationKey } from 'helpers/texting';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentLikedImages } from 'store/slices/selection/selectors';
import media from 'assets/css/media';

interface IProps {
  rows: Array<[any, number, number]>;
  currency: Currency;
  showFilesOnly: boolean;
}

interface IImageCard {
  image: string;
  rotateDeg: number;
  zIndex: number;
  top: number;
  left: number;
}

const HideMobile = styled.div`
  display: none;
  @media all and (max-width: ${media.desktopXl}) {
    display: block;
  }
`;
const HideDesktop = styled.div`
  display: block;
  @media all and (max-width: ${media.desktopXl}) {
    display: none;
  }
`;

const PriceBreakdownWrapper = styled.div`
  margin-top: 5px;
  padding: 0 5px;
`;

const PriceBreakdownTableWrapper = styled.div`
  background: #f5f4f4;
  border-radius: 20px;
  color: #000;
  margin-bottom: 15px;
  display: flex;
  padding: 20px;
  @media all and (max-width: ${media.desktopXl}) {
    flex-direction: column;
  }
`;

const ImagesContent = styled.div<{ justify: string }>`
  display: flex;
  align-items: center;
  width: 15%;
  margin-right: 10px;
  @media all and (max-width: ${media.desktopXl}) {
    width: 100%;
    margin-right: 0;
    justify-content: ${({ justify }) => justify};
    border-bottom: 1px dashed #b7b7b7;
  }
`;
const ImagesWrap = styled.div`
  position: relative;
  width: 100%;
  @media all and (max-width: ${media.desktopXl}) {
    width: 30%;
    height: 100px;
  }
`;
const PriceContent = styled.div`
  width: 85%;
  @media all and (max-width: ${media.desktopXl}) {
    width: 100%;
  }
`;

const PriceTitle = styled.h3`
  &&&& {
    font-size: 22px !important;
    font-weight: 800;
    margin-bottom: 20px;
    color: #000 !important;
  }
`;

const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  font-size: 22px;
  border-bottom: 1px dashed #b7b7b7;
  @media all and (max-width: ${media.desktopXl}) {
    width: 100%;
    flex-direction: column;
    border-bottom: 0;
  }
`;

const PacketAmount = styled.div`
  margin-top: 10px;
  color: #707070;
`;

const PacketItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageCardWrap = styled.div<{ rotateDeg: number; top: number; left: number; zIndex: number }>`
  width: 40px;
  height: 55px;
  border: 1px solid white;
  transform: translate(-50%, -50%) rotate(${({ rotateDeg }) => rotateDeg}deg);
  z-index: ${({ zIndex }) => zIndex};
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageCard = ({ image, rotateDeg, zIndex, top, left }: IImageCard) => (
  <ImageCardWrap rotateDeg={rotateDeg} zIndex={zIndex} top={top} left={left}>
    <img src={image} alt="" />
  </ImageCardWrap>
);

const ImagesItems = () => {
  const { images = [] } = useAppSelector((state) => state.images);

  const firstImageUrl = images[0]?.url_S;
  const secondImageUrl = images[1]?.url_S || firstImageUrl;
  const thirdImageUrl = images[2]?.url_S || secondImageUrl || firstImageUrl;

  return (
    <ImagesWrap>
      {firstImageUrl && (
        <ImageCard image={firstImageUrl} left={50} top={50} rotateDeg={0} zIndex={3} />
      )}
      {secondImageUrl && (
        <ImageCard image={secondImageUrl} left={38} top={41} rotateDeg={-12} zIndex={2} />
      )}
      {thirdImageUrl && (
        <ImageCard image={thirdImageUrl} left={29} top={49} rotateDeg={-18} zIndex={1} />
      )}
    </ImagesWrap>
  );
};

export const PriceBreakdown: React.FC<IProps> = ({ rows = [[]], currency, showFilesOnly }) => {
  const selectedImagesAmount = useAppSelector(selectCurrentLikedImages).length;
  const totalPrice = rows.map((row) => row[2]).reduce((a, b) => a + b, 0);
  const [title] = rows[0] || [];

  const currentPrice = totalPrice < 0 ? 0 : totalPrice;

  return (
    <PriceBreakdownWrapper>
      <PriceTitle>{getTranslationKey('shop.upselling.yourSelection')}</PriceTitle>
      <PriceBreakdownTableWrapper>
        <ImagesContent justify="space-between">
          <ImagesItems />
          <HideMobile>
            <TotalAmount>
              <span>{selectedImagesAmount + ' ' + getTranslationKey('shop.upselling.files')}</span>
              {title && <span>{displayProductPrice(currentPrice, currency as Currency)}</span>}
            </TotalAmount>
          </HideMobile>
        </ImagesContent>
        <PriceContent>
          <HideDesktop>
            <TotalAmount>
              <span>{selectedImagesAmount + ' ' + getTranslationKey('shop.upselling.files')}</span>
              {title && <span>{displayProductPrice(currentPrice, currency as Currency)}</span>}
            </TotalAmount>
          </HideDesktop>
          {title && !showFilesOnly && (
            <PacketAmount>
              {rows.map((cells, idx) => (
                <PacketItem key={idx}>
                  <span>
                    {idx === 0 ? '1 x' : `${cells[1]} x`} {cells[0]}
                  </span>
                  <span>{displayProductPrice(cells[2], currency)}</span>
                </PacketItem>
              ))}
            </PacketAmount>
          )}
        </PriceContent>
      </PriceBreakdownTableWrapper>
    </PriceBreakdownWrapper>
  );
};
