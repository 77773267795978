import styled from 'styled-components';
import { getTranslationKey } from 'helpers/texting';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

interface IProps {
  isSingle: boolean;
  imageIndex: number;
  selectedImagesLength: number;
}

export const CropHeader: React.FC<IProps> = ({ isSingle, imageIndex, selectedImagesLength }) => (
  <Wrapper>
    {!isSingle ? (
      <div>
        {getTranslationKey('shop.image')} {imageIndex + 1} / {selectedImagesLength}
      </div>
    ) : (
      <div />
    )}
  </Wrapper>
);
