import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useAppSelector } from 'hooks/redux';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';

export const Triplex15x23_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 0, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 1, fallbackImages);
  const verticalImage3 = getImageByIdx(verticalImages, 2, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={48.8}
        left={-15.1}
        width={33}
        height={50.3}
        transform="rotate(-22deg)"
      >
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={33}
        left={22.6}
        width={33.5}
        height={51.2}
        transform="rotate(-22deg)"
      >
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={18.5}
        left={58.8}
        width={34.8}
        height={51.2}
        transform="rotate(-22deg)"
      >
        <PreviewImage image={verticalImage3.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>
    </>
  );
};
