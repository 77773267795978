import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';

export const PictureWithFrame20x20_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 20, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 21, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={26.8} left={0} width={42} height={47.1}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer top={31.8} left={64} width={36} height={36}>
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const PictureWithFrame30x21_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 22, fallbackImages);
  const verticalImage1 = getImageByIdx(verticalImages, 8, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={34.6} left={3.3} width={44} height={31}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer top={24.6} left={63.1} width={40} height={51}>
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const PictureWithFrame30x30_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 23, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 24, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={31.5} left={-5} width={44} height={42.5}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer top={24.4} left={54.7} width={50} height={55.2}>
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const PictureWithFrame42x30_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 23, fallbackImages);
  const verticalImage1 = getImageByIdx(verticalImages, 9, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={20} left={-5.3} width={44.1} height={60}>
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 1750" />
      </PreviewImageContainer>

      <PreviewImageContainer top={31.5} left={54.5} width={53} height={37}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>
    </>
  );
};

export const PictureWithFrame70x50_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 10, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 11, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={27.5} left={-5} width={42} height={44.7}>
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 1750" />
      </PreviewImageContainer>

      <PreviewImageContainer top={26.3} left={51} width={50} height={47.5}>
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1500 1400" />
      </PreviewImageContainer>
    </>
  );
};

export const Pic_PictureWithFrame20x20_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 2, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={28.4} left={19} width={62} height={43.1}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const Pic_PictureWithFrame20x20_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 2, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={25.5} left={15.5} width={42.4} height={31}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};
