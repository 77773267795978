import { createReducer } from 'redux-act';
import {
  fetchPhotoboksFontsSuccess,
  fetchTemplatesFontsSuccess,
  setLoadedFonts
} from 'old-store/actions';
import { TemplateFont } from 'old-store/types/templates';

export interface ITemplatesReducerState {
  fonts: TemplateFont[];
  photobookFonts: TemplateFont[];
  loadedFonts: string[];
}

const initialState: ITemplatesReducerState = {
  fonts: [],
  photobookFonts: [],
  loadedFonts: []
};

export const templates = createReducer<ITemplatesReducerState>({}, initialState);

templates.on(fetchTemplatesFontsSuccess, (state: ITemplatesReducerState, payload: any[]) => ({
  ...state,
  fonts: payload
}));

templates.on(fetchPhotoboksFontsSuccess, (state: ITemplatesReducerState, payload: any[]) => ({
  ...state,
  photobookFonts: payload
}));

templates.on(setLoadedFonts, (state: ITemplatesReducerState, payload: string[]) => ({
  ...state,
  loadedFonts: [
    ...state.loadedFonts,
    ...payload.filter((font) => !state.loadedFonts.includes(font))
  ]
}));
