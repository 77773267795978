import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import { getFormValues, isFormHasError } from 'modules/ScrShop/store/utils/helpers';
import { IChangePasswordAction, ISagaAction } from 'modules/ScrShop/store/types';
import { getEndCustomerStore } from 'modules/ScrShop/store/selectors';
import { showGlobalError } from 'modules/ScrShop/store/actions/errors';
import { setShippingValueAction } from 'modules/ScrShop/store/actions';
import { getTranslationKey } from 'helpers/texting';

export const changePasswordSaga = function* ({
  payload
}: ISagaAction<IChangePasswordAction>): SagaIterator {
  const hasError = isFormHasError(payload.formValues);
  const endCustomer = yield select(getEndCustomerStore);
  if (hasError) {
    return;
  }
  const formValues: any = {
    collection_id: window.SITE_ID,
    ...getFormValues(payload.formValues)
  };
  try {
    const response = yield call(Api.Auth.changePassword, formValues);
    ApiErrors.checkOnApiError(response);
    // if end customer has changed password then they're no longer a fly user
    localStorage.removeItem('flyUserId');
    yield put({
      type: 'fetch_endCustomer',
      payload: {
        ...endCustomer,
        passwordIsSet: 1
      }
    });
    yield put(
      showGlobalError({
        title: 'Success',
        text: getTranslationKey(
          payload.context === 'account'
            ? 'shop.accountPage.changePassConfirm'
            : 'shop.checkoutConfirmPage.changePassConfirm'
        )
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const revokeTermsModalSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Auth.revokePrivacyCheckBox);
    const endCustomer = yield select(getEndCustomerStore);
    ApiErrors.checkOnApiError(response);
    yield put({
      type: 'fetch_endCustomer',
      payload: {
        ...endCustomer,
        ...response
      }
    });
    yield put(
      setShippingValueAction({
        gtcAndPrivacyCheckbox: {
          value: response.gtcAndPrivacyCheckbox,
          error: ''
        }
      })
    );
  } catch (e) {
    console.log(e);
  }
};
