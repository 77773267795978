import { createReducer } from 'redux-act';
import { ICountriesReducerState } from 'modules/ScrShop/store/types';
import { fetchCountriesAction, fetchCountriesOnSuccess } from 'modules/ScrShop/store/actions';

const initialState: ICountriesReducerState = {
  items: [],
  isFetched: false
};

export const countries = createReducer<ICountriesReducerState>({}, initialState);
countries.on(fetchCountriesAction, (state) => ({
  ...state
}));
countries.on(fetchCountriesOnSuccess, (state, payload) => ({
  ...state,
  items: payload,
  isFetched: true
}));
