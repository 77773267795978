import type { IImage, ISelection, IGallery, IndexedImage } from 'old-store/types/gallery';

import { getSortedAndfilteredImgs } from 'helpers/sorting';

export const filterImagesByGalleries = (
  galleries: IGallery[],
  images: IImage[]
): {
  [key: string]: IndexedImage[];
} => {
  const checkIfGifFile = (img: IImage) => img.extension.toLowerCase() === 'gif';
  const checkCompressionStatus = (img: IImage) =>
    checkIfGifFile(img) || (!checkIfGifFile(img) && img.compressionStatus === 'done');
  const checkIfWidthAndHeightExists = (img: IImage) => Boolean(img?.width) && Boolean(img?.height);

  const sortedImagesByGalleries = galleries?.reduce((acc, gallery) => {
    const checkIfSameGallery = (img: IImage) => img.gallery_id === gallery._id;

    const imgFilter = (img: IImage) =>
      checkIfSameGallery(img) && checkCompressionStatus(img) && checkIfWidthAndHeightExists(img);
    const sorting = { sortBy: gallery.sortBy, sortOrder: gallery.sortOrder };

    const sortedImgs = getSortedAndfilteredImgs(images, imgFilter, sorting);
    const sortedTitleImgs = sortedImgs.map((img, idx) => ({
      ...img,
      galleryTitle: gallery.title,
      id: idx,
      index: idx,
      src: img.url,
      selectionId: '',
      imageId: `${gallery._id}_${idx}`
    }));

    // @ts-ignore
    acc[gallery._id] = sortedTitleImgs;

    return acc;
  }, {});

  return sortedImagesByGalleries;
};

export const filterImagesBySelections = (
  images: IImage[],
  all: ISelection[]
): {
  [key: string]: IImage[];
} => {
  const favoritesMap = all?.reduce((acc, item) => {
    const favoritesArr = item.favorites.map(({ _image }) => _image);

    // @ts-ignore
    acc[item._id] = images
      .filter(({ _id }) => favoritesArr.includes(_id))
      .map((image) => ({
        ...image,
        selectionId: `${item._id}_${image.index}`,
        src: image.url
      }))
      .sort((a, b) => favoritesArr.indexOf(a._id) - favoritesArr.indexOf(b._id));

    return acc;
  }, {});

  return favoritesMap;
};
