import { useState } from 'react';

export const useToggle = (initialState?: boolean) => {
  const [toggle, setToggle] = useState<boolean>(!!initialState);

  const openToggle = () => setToggle(true);
  const closeToggle = () => setToggle(false);
  const handleToggle = () => setToggle((prev: boolean) => !prev);
  const setToggleState = (state: boolean) => setToggle(state);

  return { toggle, openToggle, closeToggle, handleToggle, setToggleState };
};
