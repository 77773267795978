import { ReactComponent as SendToPhotographerIconDark } from 'assets/images/icons/dark - send to photographer.svg';
import { ReactComponent as SendToPhotographerIconSimpleFiligree } from 'assets/images/icons/simple filigree - send to photographer.svg';
import { ReactComponent as SendToPhotographerIconSpringVibes } from 'assets/images/icons/spring - send to photographer.svg';
import { ReactComponent as SendToPhotographerIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - send to photographer.svg';
import { ReactComponent as SendToPhotographerIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - send to photographer.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const SendToPhotographerIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={SendToPhotographerIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={SendToPhotographerIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={SendToPhotographerIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={SendToPhotographerIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={SendToPhotographerIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => (
      <BaseIcon icon={SendToPhotographerIconVickyBaumannFineart} {...props} />
    ),
    breeze: () => <BaseIcon icon={SendToPhotographerIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={SendToPhotographerIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={SendToPhotographerIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={SendToPhotographerIconSimpleFiligree} {...props} />,
    _default_: () => (
      <i className="fa fa-send" style={{ fontSize: props.size + 'px', color: props.color }} />
    )
  })();
