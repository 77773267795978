// tslint:disable-next-line:no-implicit-dependencies
import queryString from 'query-string';
import initEnv from 'environment';
import { updateSiteTracking } from 'old-store/actions/environment';
import { createReducer } from 'redux-act';

export const environment = createReducer({}, initEnv);
environment.on('user_touched', (state) => ({ ...state, touched: true }));
environment.on(updateSiteTracking, (state, domains) => {
  const { t } = queryString.parse(window.location.search);

  if (t) {
    return { ...state, siteTracking: Boolean(Number(t)) };
  }

  const currentSiteId = window.location.pathname.split('/')[1];
  const initialReferrer = window.localStorage.getItem(`initialReferrer-${currentSiteId}`);
  const currentReferrer =
    initialReferrer || document?.referrer || window.frames?.top?.document?.referrer;

  if (
    currentReferrer?.includes(domains.builderDomain) &&
    !domains.galleryDomain.includes(window.location.host)
  ) {
    window.localStorage.removeItem(`initialReferrer-${currentSiteId}`);

    return { ...state, siteTracking: false };
  }

  return state;
});
