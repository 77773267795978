import { createSelector } from '@reduxjs/toolkit';

import { selectImages } from 'store/slices/images/selectors';
import type { IStore } from 'old-store/types';

export const selectSelections = (state: IStore) => state.selection.all;
export const selectAllUserSelections = (state: IStore) => state.selection.allUserSelections;

const selectLikes = (state: IStore) => state.selection.likes;

export const selectSortedSelections = createSelector(selectSelections, (selections = []) =>
  // if we don't copyt the array sorting fails
  // this occurs because .sort tries to mutate the state which isn't allowed on Redux properties
  [...selections].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
);

export const selectSortedAllUserSelections = createSelector(
  selectAllUserSelections,
  (selections = []) =>
    // if we don't copyt the array sorting fails
    // this occurs because .sort tries to mutate the state which isn't allowed on Redux properties
    [...selections].sort(
      (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )
);

// get the liked images of the current selection
export const selectCurrentLikedImages = createSelector(selectLikes, selectImages, (likes, images) =>
  images
    .filter((item) => likes.includes(item._id))
    .sort((a, b) => likes.indexOf(a._id) - likes.indexOf(b._id))
);
