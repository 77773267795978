import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITours {
  downloadTour: boolean;
  selectionTour: boolean;
  tourStep: number;
}

interface IAppState {
  extendModeEnabled: boolean;
  mobileMenuExtended: boolean;
  previousAnchor: string;
  hoverImageId: string;
  imagesDownloaded: number;
  isSelectionSessionStarted: boolean;
  tours: ITours;
}

const initialState: IAppState = {
  extendModeEnabled: false,
  mobileMenuExtended: false,
  previousAnchor: '',
  hoverImageId: '',
  imagesDownloaded: 0,
  isSelectionSessionStarted: false,
  tours: {
    downloadTour: false,
    selectionTour: false,
    tourStep: 0
  }
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    enableExtendMode(state) {
      state.extendModeEnabled = true;
    },
    disableExtendMode(state) {
      state.extendModeEnabled = false;
    },
    toggleMobileMenu(state) {
      state.mobileMenuExtended = !state.mobileMenuExtended;
    },
    closeMobileMenu(state) {
      state.mobileMenuExtended = false;
    },
    savePreviousAnchor(state, { payload }: PayloadAction<string>) {
      state.previousAnchor = payload;
    },
    saveHoverImageId(state, { payload }: PayloadAction<string>) {
      state.hoverImageId = payload;
    },
    removeHoverImageId(state) {
      state.hoverImageId = '';
    },
    incrementImagesDownloadCount(state) {
      state.imagesDownloaded += 1;
    },
    enableSelectionSession(state) {
      state.isSelectionSessionStarted = true;
    },
    handleTour(state, { payload }: PayloadAction<string>) {
      state.tours[payload] = !state.tours[payload];
    },
    goToNextTourStep(state) {
      state.tours.tourStep += 1;
    }
  }
});

export const {
  toggleMobileMenu,
  closeMobileMenu,
  enableExtendMode,
  disableExtendMode,
  savePreviousAnchor,
  saveHoverImageId,
  removeHoverImageId,
  incrementImagesDownloadCount,
  enableSelectionSession,
  handleTour,
  goToNextTourStep
} = appSlice.actions;

export const appReducer = appSlice.reducer;
