import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

const Overlay = styled.div<{
  width: number;
  height: number;
  top: number;
  left: number;
  transform: string;
  gradient: string;
}>`
  position: absolute;
  z-index: 5;
  width: ${({ width }) => `${width}%`};
  height: ${({ height }) => `${height}%`};
  top: ${({ top }) => `${top}%`};
  left: ${({ left }) => `${left}%`};
  transform: ${({ transform }) => transform};
  background: ${({ gradient }) => gradient};
  opacity: 0.5;
  filter: blur(1px);
`;

export const Acryglas_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 18, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -26 161 L 1491 157 L 1496 162 L 1491 1391 L 1484 1398 L -70 1230" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-13}
        left={-0.95}
        width={100}
        height={100}
        zIndex={1}
        transform="matrix3d(1,0.14,0,0,-0.002,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} y={80} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={9}
        left={99}
        width={1.5}
        height={100}
        transform="matrix3d(1,-0.3,0,0,-0.0024,1,0,0,0,0,1,0,0,0,0,1)"
        boxShadow="-3px 1px 12px -2px rgb(0 0 0 / 75%);"
        zIndex={2}
      >
        <PreviewImage image={horizontalImage.url_M} x={-1420} />
      </PreviewImageContainer>
    </>
  );
};

export const Acryglas_10x15_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <Overlay
        top={15}
        left={0}
        width={30}
        height={70}
        transform="rotate(195deg)"
        gradient="linear-gradient(180deg, rgba(255, 255, 255, 0.6993391106442577) 0%, rgba(255, 255, 255, 0.1545211834733894) 100%)"
      />
      <PreviewImageContainer top={21.3} left={11.4} width={77.3} height={57.7}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const Acryglas_10x15_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={6}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 409 833 L 404 307 L 1093 312 L 1097 824" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <Overlay
        top={17}
        left={20}
        width={20}
        height={40}
        transform="rotate(195deg)"
        gradient="linear-gradient(180deg, rgba(255,255,255,0.6503195028011204) 0%, rgba(255,255,255,0.10970325630252098) 100%)"
      />
      <PreviewImageContainer top={20} left={26.2} width={47} height={36}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};
