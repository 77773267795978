import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';

export const CalendarPreview = ({ backgroundImage, galleryId }) => {
  const horizontalImage1 = useGalleryImage('horizontal', 6, galleryId);
  const horizontalImage2 = useGalleryImage('horizontal', 7, galleryId);
  const verticalImage1 = useGalleryImage('vertical', 0, galleryId);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={21.3} left={-8.7} width={20} height={22}>
        <PreviewImage image={horizontalImage1.url_M} />
      </PreviewImageContainer>
      <PreviewImageContainer top={17.3} left={64.1} width={20.5} height={15}>
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1000" x={-100} />
      </PreviewImageContainer>
      <PreviewImageContainer top={23} left={89.6} width={20} height={45}>
        <PreviewImage image={verticalImage1.url_M} />
      </PreviewImageContainer>
    </>
  );
};
