import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICollection } from 'types/collection';

export interface ICollectionState extends ICollection {
  download: boolean;
  checkPw: boolean;
  downloadToken?: string;
  downloadPin?: string;
  collectionCreator?: any;
  password?: string;
  trackingStatus?: 'opt-in' | 'opt-out';
  fetched: boolean;
  userEmail?: string;
  customDomainUrl?: string;
}

const initialState: ICollectionState = {
  checkPw: false,
  buttonStyle: {
    backgroundColor: '',
    color: ''
  },
  comment: false,
  created_at: '',
  deleted: 0,
  isShareSite: null,
  imageExpireTime: '',
  description: '',
  tracking: {
    ga: '',
    fb: '',
    gtm_id: '',
    fullstory_id: ''
  },
  download: true,
  downloadPinActive: false,
  isHideImageFeatureEnabled: false,
  showOnlyPurchasedPrints: false,
  globalShopIsActive: true,
  font: {
    fontFamily: '',
    name: ''
  },
  signedWildCardUrl: '',
  imgsFolder: '',
  language: 1,
  like: true,
  mjMessageId: [],
  name: '',
  sharing: {
    show: true
  },
  showAppInstall: true,
  eventDate: null,
  showImageCaptionInGallery: false,
  styles: {
    backgroundColor: '',
    buttonColor: ''
  },
  templateName: '',
  title: '',
  type: '',
  updated_at: '',
  userBrand: {
    _id: '',
    name: '',
    _user: '',
    defaultValues: false,
    _v: 0
  },
  __v: 0,
  _id: '',
  _user: '',
  galleryStyle: 'justified',
  shopCtaPlacement: 'after-last',
  galleries: [],
  shopActive: false,
  collectionCreator: {},
  _productPricingList: '',
  salesAutomationConfig: {
    _id: '',
    couponCodeAdvertising: {
      enabled: false,
      startInDays: 0,
      endsAfterDays: 1,
      couponId: null
    },
    abandonedShoppingCartReminder: {
      enabled: false
    },
    emptyCartProductSuggestion: {
      enabled: false
    },
    freeShipping: {
      enabled: false,
      startsFrom: 99
    },
    couponCodeAfterPurchase: {
      enabled: false,
      couponId: null
    },
    galleryExpires: {
      enabled: false
    }
  },
  design: {
    disableDecorations: false,
    loadFonts: [],
    imageSpacing: '',
    variables: {
      primaryColor: '',
      secondaryColor: ''
    }
  },
  digitalShop: {
    enabled: false,
    manualOrderProcessing: false,
    digitalPricingList: null,
    alreadyPurchasedTier: null
  },
  fetched: false,
  sharePolicyType: null,
  customDomainUrl: ''
};

const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    setTrackingStatus: (state, action: PayloadAction<'in' | 'out'>) => {
      state.trackingStatus = `opt-${action.payload}` as 'opt-in' | 'opt-out';
    },
    setCollection: (state, action: PayloadAction<ICollection>) => {
      const download = action.payload?.galleries?.some((gallery) => gallery.download);

      return { ...state, ...action.payload, download };
    },
    setGalleries: (state, action) => {
      state.galleries = action.payload;
    },
    setCollectionCreator: (state, action) => {
      state.collectionCreator = action.payload;
    },
    setCheckPassword: (state, action: PayloadAction<boolean>) => {
      state.checkPw = action.payload;
    },
    setDownloadToken: (state, action: PayloadAction<string>) => {
      state.downloadToken = action.payload;
    },
    setDownloadPin: (state, action: PayloadAction<string>) => {
      state.downloadPin = action.payload;
    },
    setCollectionFetchStatus: (state, action: PayloadAction<boolean>) => {
      state.fetched = action.payload;
    },
    setGlobalShopStatus: (state, action: PayloadAction<boolean>) => {
      state.globalShopIsActive = action.payload;
    }
  }
});

export const {
  setTrackingStatus,
  setCollection,
  setGalleries,
  setCheckPassword,
  setDownloadToken,
  setDownloadPin,
  setCollectionCreator,
  setCollectionFetchStatus,
  setGlobalShopStatus
} = collectionSlice.actions;
export const collectionReducer = collectionSlice.reducer;
