import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getShopStoreWithoutImages } from 'modules/ScrShop/store/selectors';

export function PersistShop() {
  const shop = useSelector(getShopStoreWithoutImages);

  useEffect(() => {
    try {
      localStorage.setItem(`shop_${window.SITE_ID}`, JSON.stringify(shop));
    } catch (e) {
      console.log('Local Storage is full: ', e);
    }
  }, [shop]);

  return null;
}
