import { IOrder, IPayment } from 'modules/ScrShop/store/types';

export const ORDER_DEFAULTS: IOrder = {
  billing: {},
  createdAt: '',
  currency: 'EUR',
  delivered: false,
  discountTotal: 0,
  grandProductGrossPrice: 0,
  grandProductPrice: 0,
  grandProductTax: 0,
  grandShippingGrossPrice: 0,
  grandShippingPrice: 0,
  grandShippingTax: 0,
  grandTax: 0,
  grandTotal: 0,
  grossProductPrice: 0,
  grossShippingPrice: 0,
  orderNumber: 0,
  paid: false,
  paymentBalance: 0,
  paymentTotal: 0,
  payments: [{}],
  productDiscount: 0,
  productPrice: 0,
  productQuantity: 0,
  productTax: 0,
  products: [],
  shipping: {},
  shippingDiscount: 0,
  shippingPrice: 0,
  shippingTax: 0,
  status: '',
  taxTotal: 0,
  updatedAt: '',
  _collection: '',
  _endCustomer: '',
  _id: '',
  _laboratory: '',
  _shoppingCart: '',
  _user: ''
};

export const PAYMENT_DEFAULTS: IPayment = {
  paypal: {
    paymentId: ''
  },
  status: '',
  success: false,
  _id: '',
  _endCustomer: '',
  _user: '',
  _order: '',
  _shoppingCart: '',
  amount: 0,
  method: 'paypal',
  async: true,
  authorized: false,
  currency: 'EUR',
  test: true,
  checkoutUrl: '',
  createdAt: '',
  updatedAt: '',
  paymentNumber: 0
};
