import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';
import { find, flatten, findIndex } from 'lodash';

export const getProductsStore = ({ products }: IStore) => products;
export const getProductSuggestions = ({ products }: IStore) => products.suggestions;
export const getIsProductsLoading = createSelector(getProductsStore, ({ isLoading }) => isLoading);
export const getProductsGroupsSelector = createSelector(getProductsStore, ({ groups }) => groups);
export const getProductGroupByID = createSelector(
  getProductsGroupsSelector,
  (groups) => (groupID: string) => find(groups, { _id: groupID })
);
export const getProductGroupBySlug = createSelector(
  getProductsGroupsSelector,
  (groups) => (groupSlug: string) => find(groups, { slug: groupSlug })
);
export const getAllProduct = createSelector(getProductsGroupsSelector, (groups) =>
  flatten(groups.map((group) => group.products))
);
export const getProductByID = createSelector(
  getAllProduct,
  (products) => (productID: string) => find(products, { _id: productID })
);
export const getProductBySlug = createSelector(
  getAllProduct,
  (products) => (productSlug: string) => find(products, { slug: productSlug })
);
export const getProductIndex = createSelector(
  getAllProduct,
  (products) => (productSlug: string) => findIndex(products, { slug: productSlug })
);
