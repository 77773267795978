import { calculateFrameAspectRatio } from './calculateFrameAspectRatio';

export function calculateFrameAspectAccuracy(
  frame,
  dimensionWidth: number,
  dimensionHeight: number
) {
  const { orientation, frameWidth, frameHeight } = calculateFrameAspectRatio(
    frame,
    dimensionWidth,
    dimensionHeight
  );

  const frameSizesSum = orientation === 'horizontal' ? frameWidth : frameHeight;

  return { orientation, frameSizesSum };
}
