import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';

export const CalendarHD_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 3, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={17.5}
        left={-3.8}
        width={39.4}
        height={56.9}
        transform="rotate(-8.2deg)"
      >
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1050 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={17.1}
        left={39.6}
        width={39.8}
        height={57}
        transform="rotate(-24deg)"
      >
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1050 1500" />
      </PreviewImageContainer>
    </>
  );
};

// eslint-disable-next-line
export const CalendarHD_1 = ({ backgroundImage }) => {
  // const { images } = useAppSelector((state) => state.images);
  // const maskId = 'calendar-hd_1';
  // const candidateImg = images[0];

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          // maskId={maskId}
          // mask={
          //   <mask id={maskId}>
          //     <rect width="100%" height="100%" fill="white" />
          //     <path d="M -562 351 L 1036 1588 L 1209 1536 L 389 946 L 1668 -25 L 252 -177" />
          //   </mask>
          // }
        />
      </PreviewImageContainer>

      {/* <PreviewImageContainer
        top={40}
        left={-31}
        width={120}
        height={120}
        zIndex={1}
        transform="rotate(-54deg)"
      >
        <PreviewImage image={candidateImg.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer> */}
    </>
  );
};
