import { useTheme } from 'hooks/useTheme';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled, { css } from 'styled-components';

const shared = css`
  background: ${(props) => props.theme.colors.accent};
  width: 100%;
  height: 50px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.accentHover};
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const TextBox = styled.div`
  ${shared}
`;

export const LinkBox = styled(Link)`
  ${shared}

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const AnkerBox = styled.a`
  ${shared}

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

const linkBoxStyles = css`
  ${shared}

  padding-left: 10px;
  padding-right: 10px;

  justify-content: flex-start;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const IconAnkerBox = styled.a`
  ${linkBoxStyles}
`;

export const IconLinkBox = styled(Link)`
  ${linkBoxStyles}
`;

export const IconLinkBoxHash = styled(HashLink)<{ child?: boolean }>`
  ${linkBoxStyles}
`;

const ActionBoxStyles = css<{ hasIcon?: boolean }>`
  ${shared}

  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border: none;

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      justify-content: flex-start;
    `}

  &:disabled {
    cursor: no-drop;
  }

  &:hover {
    .hover-spinner {
      border: 3px solid ${({ theme }) => theme.colors.imageHoverIconsColor};
      border-bottom-color: transparent;
    }
  }
`;
const ActionBoxStyledButton = styled.button<{ hasIcon?: boolean }>`
  ${ActionBoxStyles}
`;
const ActionBoxStyledLink = styled.a<{ hasIcon?: boolean }>`
  ${ActionBoxStyles}
`;

export const ActionBox = (props) => {
  const [theme] = useTheme();

  return props.href ? (
    <ActionBoxStyledLink {...props} theme={theme} />
  ) : (
    <ActionBoxStyledButton {...props} theme={theme} />
  );
};

export const InsetWrapper = styled.div`
  margin-left: 20px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;
