import classnames from 'classnames';
import styled from 'styled-components';

import { getCleanUrl } from 'helpers/url';
import { useTemplateVariant } from 'hooks/useTemplateVariant';
import { useTheme } from 'hooks/useTheme';
import { logStatistics } from 'old-store/actions';
import { ACTIONS, SCOPES } from 'old-store/constants/statistics';
import { FacebookIcon } from 'components/icons/FacebookIcon';
import { InstagramIcon } from 'components/icons/InstagramIcon';
import { PinterestIcon } from 'components/icons/PinterestIcon';
import { TwitterIcon } from 'components/icons/TwitterIcon';
import { YoutubeIcon } from 'components/icons/YoutubeIcon';
import { useAppSelector } from 'hooks/redux';
import { getTranslationKey } from 'helpers/texting';

interface IProps {
  socials: any;
  popup?: boolean;
}

const LinkStyled = styled.a<{ iconHoverColor?: string }>`
  text-decoration: none;
  ${(props) =>
    props.iconHoverColor &&
    `&:hover {
      svg {
        fill: ${props.iconHoverColor};
        strok: ${props.iconHoverColor};
      }
    }
  `}
`;

const IconWrapper = styled.div``;

const SocialTypeToIconMap: {
  [key: string]: (theme: any) => any;
} = {
  facebook: (theme: any) => <FacebookIcon size={24} color={theme.colors.modalFontColor} />,
  instagram: (theme: any) => <InstagramIcon size={24} color={theme.colors.modalFontColor} />,
  twitter: (theme: any) => <TwitterIcon size={24} color={theme.colors.modalFontColor} />,
  pinterest: (theme: any) => <PinterestIcon size={24} color={theme.colors.modalFontColor} />,
  youtube: (theme: any) => <YoutubeIcon size={24} color={theme.colors.modalFontColor} />
};

export const SocialIcons: React.FC<IProps> = ({ socials, popup = false }) => {
  const [theme] = useTheme();
  const iconHoverColor = useTemplateVariant({
    dark: theme.colors.menuBg
  });

  return (
    <div className={classnames('social-icons', { 'popup-app': popup, 'p-l-65': popup })}>
      {Object.keys(socials).map((key) => {
        const social = socials[key];

        if (!social || social.length === 0) return null;

        return (
          <LinkStyled
            iconHoverColor={iconHoverColor}
            key={key}
            href={getCleanUrl(social)}
            onClick={() => {
              logStatistics({
                action: ACTIONS.CLICK,
                scope: SCOPES.CONTACT,
                actionData: key
              });
            }}
            target="_blank"
            rel="noreferrer"
          >
            <IconWrapper theme={theme}>{SocialTypeToIconMap[key](theme)}</IconWrapper>
          </LinkStyled>
        );
      })}
    </div>
  );
};

export const Socials: React.FC = () => {
  const userBrands = useAppSelector((state) => state.userBrand);

  return (
    <div>
      <h2 className="footer-title">{getTranslationKey('photographerView.social')}</h2>
      <SocialIcons socials={userBrands.socials} />
    </div>
  );
};
