import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { isShareApiAvailable } from 'helpers/isShareAvailable';
import { getShareLink } from 'store/slices/sharing/actions';
import { useToggle } from 'hooks/useToggle';
import { ACTIONS, ACTIONS_DATA } from 'old-store/constants/statistics';
import { useLogSharingStatistics } from '../../hooks/useLogSharingStatistics';
import { usePrevious } from 'hooks/usePrevious';
import { getEndCustomer } from 'old-store/actions';

export const useNativeShare = () => {
  const dispatch = useAppDispatch();
  const { type, identifier } = useAppSelector((state) => state.sharing);
  const { toggle: onlyPublicImages, handleToggle: handleHiddenImagesToggle } = useToggle(true);
  const previousPublicImages = usePrevious(onlyPublicImages);
  const { logSharingStatistics } = useLogSharingStatistics();

  const postRequest = (linkUrl: string, type: string, identifier: string) => {
    logSharingStatistics({
      identifier,
      scope: type,
      action: ACTIONS.SHARE,
      actionData: ACTIONS_DATA.LINK
    });

    const dataObj = { url: decodeURIComponent(linkUrl) };

    if (isShareApiAvailable() && navigator.canShare(dataObj)) {
      navigator.share(dataObj);
    }

    if (type === 'selection') {
      // get end customer because when sharing a selection
      // a new saved selection is created and when using getEndCustomer
      // all selections are loaded aswell
      dispatch(getEndCustomer());
    }
  };

  const onShare = () => {
    dispatch(
      getShareLink({
        type,
        identifier,
        onlyPublicImages,
        callback: postRequest,
        refetch: onlyPublicImages !== previousPublicImages
      })
    );
  };

  const onShareSingleImage = (identifier: string) => {
    dispatch(
      getShareLink({
        type: 'image',
        identifier,
        onlyPublicImages,
        callback: postRequest,
        refetch: true
      })
    );
  };

  return {
    onlyPublicImages,
    handleHiddenImagesToggle,
    onShare,
    onShareSingleImage
  };
};
