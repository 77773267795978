import { Modal, ModalBody } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { toggleShareModal } from 'store/slices/modals';
import { ShareSelection } from 'components/ShareSelection';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { getTranslationKey } from 'helpers/texting';

export const ShareModal: React.FC = () => {
  const { shareModal } = useAppSelector((state) => state.modals);
  const dispatch = useAppDispatch();

  const handleToggle = () => dispatch(toggleShareModal());

  if (!shareModal.isOpen) return null;

  return (
    <Modal centered isOpen={shareModal.isOpen} toggle={handleToggle} size="lg" zIndex={999999}>
      <ModalHeader toggle={handleToggle}>{getTranslationKey('shareView.title')}</ModalHeader>
      <ModalBody>
        <ShareSelection />
      </ModalBody>
    </Modal>
  );
};
