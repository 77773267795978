import { TemplateFont } from '../../../../../old-store/types/templates';

export const createFontLink = (fonts: TemplateFont[]) => {
  if (fonts) {
    // eslint-disable-next-line
    for (const font of fonts) {
      // eslint-disable-next-line
      if (!font.url) continue;
      const existingLink = document.querySelector(`[href="${font.url}"]`);
      // eslint-disable-next-line
      if (existingLink) continue;

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = font.url;

      document.head.appendChild(link);
    }
  }
};
