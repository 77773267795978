import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Alert, Modal, Spinner as SpinnerGrow } from 'reactstrap';
import { LinkBtn } from 'components/IconButton/LinkButton';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { getCookie } from 'helpers/cookie';
import { getDownloadUrl } from 'helpers/downloadUrl';
import { getTranslationKey } from 'helpers/texting';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { confirmSingleImageDownloadHint } from 'old-store/actions';
import { closeSingleImageDownload } from 'store/slices/modals';
import { galleriesMapSelector } from 'old-store/selectors/gallery';
import { incrementImagesDownloadCount } from 'store/slices/app';
import { getImageFileFromURL } from 'helpers/getImageFileFromUrl';
// import { useImageFileFromUrl } from './hooks/useImageFileFromUrl';
import { Spinner } from 'components/Loaders/Spinner';

import styled from 'styled-components';

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none !important;
  }
`;

const SpinnerWrap = styled.div`
  margin-bottom: 3px;
`;

const CustomSpinner = () => (
  <SpinnerWrap>
    <Spinner width={15} height={15} />
  </SpinnerWrap>
);

const NoTouch: React.FC<IProps> = ({ isLoading, setIsLoading }) => {
  const dispatch = useAppDispatch();
  const { curGalleryImage } = useAppSelector((state) => state.image);
  const collection = useAppSelector((state) => state.collection);
  const environment = useAppSelector((state) => state.environment);
  const { isMobileDevice, iosVersion } = useAppSelector((state) => state.environment);
  const galleriesMap = useAppSelector(galleriesMapSelector);
  const [showDownloadStarted, setShowDownloadStarted] = useState(false);
  const sharingIframeRef = useRef<HTMLIFrameElement | null>(null);
  const [fileLoading, setFileLoading] = useState('');
  const [downloadedFiles, setDownloadedFiles] = useState({});

  const currentGalleryId = curGalleryImage?.gallery_id || '';
  const isHighQualityAllowed = !galleriesMap[currentGalleryId]._watermark;

  const isMobile = isMobileDevice || (iosVersion && iosVersion >= 15);

  useEffect(() => {
    // Iframe needed because of loosing the user's context after file download, while using common "window.navigator".
    // When context is lost, the 2+ downloads would throw a "NotAllowedError" - ref: (https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#notallowederror).
    // It happens in some rare IOS versions (e.g: 15.5).
    const sharingIframe = document.createElement('iframe') as HTMLIFrameElement;
    sharingIframe.style.display = 'none';
    document.documentElement.appendChild(sharingIframe);

    sharingIframeRef.current = sharingIframe;
  }, []);

  const urlLow = getDownloadUrl(
    {
      image_id: curGalleryImage?._id
    },
    { collection, environment, quality: 'low' }
  ).url;

  const urlHigh = getDownloadUrl(
    {
      image_id: curGalleryImage?._id
    },
    { collection, environment }
  ).url;

  // const [lowQualityFile, isLowQualityFileLoading] = useImageFileFromUrl(urlLow);
  // const [hightQualityFile, isHighQualityFileLoading] = useImageFileFromUrl(urlHigh);

  const isShareApiAvailable = Boolean(navigator.share) && Boolean(navigator.canShare);
  const isLowQualityBtnLoading = isShareApiAvailable && fileLoading === 'low';
  const isHighQualityBtnLoading = isShareApiAvailable && fileLoading === 'high';

  function share(file: File) {
    const sharingIframeWindow = sharingIframeRef.current?.contentWindow;

    if (sharingIframeWindow) {
      // Iframe allows to reload the context after sharing window is closed, without whole window reload.
      // Thanks to that 2+ download will work as expected for all the environments.
      sharingIframeWindow?.navigator
        .share({ files: [file] })
        .finally(() => sharingIframeWindow?.location.reload());
    }
  }

  const loadFile = async (downloadUrl: string, quality: string) => {
    const downloadedFile = downloadedFiles[downloadUrl];

    if (downloadedFile) return share(downloadedFile);

    setFileLoading(quality);

    const fileName = get(curGalleryImage, 'originalImageName', '');
    const file = await getImageFileFromURL(downloadUrl, fileName);

    setDownloadedFiles((prev) => ({ ...prev, [downloadUrl]: file }));
    setFileLoading('');
  };

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement>,
    file: string,
    quality: string
  ) => {
    try {
      const { isMobileSafari, isApp, isMobileChrome, isChromeOnIOs, isAndroidDevice } = environment;
      const downloadedFile = downloadedFiles[file];

      const isShareAvailable =
        isMobile && isShareApiAvailable && !isAndroidDevice && !downloadedFile;
      const isFileDownloaded = downloadedFile && navigator.canShare({ files: [downloadedFile] });

      if (isShareAvailable || isFileDownloaded) {
        e.preventDefault();

        loadFile(file, quality);

        return;
      }

      if (isApp) {
        if (isMobileSafari) {
          setIsLoading(true);
          setTimeout(() => setIsLoading(false), 15000);
        } else if (isMobileChrome) {
          setShowDownloadStarted(true);
          setTimeout(() => setShowDownloadStarted(false), 7500);
        }
      }

      if (isChromeOnIOs) {
        window.open(get(e, 'target.href'));
        e.preventDefault();

        return null;
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      dispatch(incrementImagesDownloadCount());
    }
  };

  if (isLoading) {
    return (
      <div className="mb-5 mt-5 text-center">
        <SpinnerGrow type="grow" color="dark" />
        <br />
        <p>{getTranslationKey('pleaseWait')}</p>
      </div>
    );
  }

  return (
    <DownloadButtonsWrapper>
      {urlLow && curGalleryImage?.extension?.toLowerCase() !== 'gif' && (
        <a
          className="sb-btn w-100-p m-b-10 d-flex align-items-center"
          style={{
            cursor: isLowQualityBtnLoading ? 'not-allowed' : 'pointer',
            opacity: isLowQualityBtnLoading ? 0.6 : 1
          }}
          role="button"
          href={urlLow}
          download
          onClick={(e) => handleDownload(e, urlLow, 'low')}
        >
          {isLowQualityBtnLoading && <CustomSpinner />}
          <span className="ms-2">
            {downloadedFiles[urlLow]
              ? getTranslationKey('saveImageLow')
              : getTranslationKey('downloadImageLow')}
          </span>
        </a>
      )}
      {isHighQualityAllowed && urlHigh && (
        <a
          className="sb-btn w-100-p d-flex align-items-center"
          style={{
            cursor: isHighQualityBtnLoading ? 'not-allowed' : 'pointer',
            opacity: isHighQualityBtnLoading ? 0.6 : 1
          }}
          role="button"
          href={urlHigh}
          download
          onClick={(e) => handleDownload(e, urlHigh, 'high')}
        >
          {isHighQualityBtnLoading && <CustomSpinner />}
          <span className="ms-2">
            {downloadedFiles[urlHigh]
              ? getTranslationKey('saveImageHigh')
              : getTranslationKey('downloadImageHigh')}
          </span>
        </a>
      )}
      {showDownloadStarted && (
        <Alert color="success" className="m-t-10">
          {getTranslationKey('downloadStarted')}
        </Alert>
      )}
    </DownloadButtonsWrapper>
  );
};

export const SingleImageDownloadModal: React.FC = () => {
  const {
    singleImageDownloadModal: { isOpen }
  } = useAppSelector((state) => state.modals);
  const { isMobileDevice, isAndroidDevice, iosVersion } = useAppSelector(
    (state) => state.environment
  );

  const dispatch = useAppDispatch();

  const shouldLoad = !(!isMobileDevice || isAndroidDevice || (iosVersion && iosVersion >= 13));

  const [isLoading, setIsLoading] = useState(shouldLoad);

  useEffect(() => {
    if (getCookie(`confirmedSingleImgDlHint-${window.SITE_ID}`) === 'true') {
      dispatch(confirmSingleImageDownloadHint());
    }
  }, []);

  const handleClose = () => {
    setIsLoading(false);
    dispatch(closeSingleImageDownload());
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      wrapClassName="modal-primary modal-site download-selection-modal"
      size="medium"
      zIndex={999999}
    >
      <ModalHeader toggle={handleClose}>
        {getTranslationKey('downloadSingleImageModalHeader')}
      </ModalHeader>
      <ModalBody>
        <NoTouch isLoading={isLoading} setIsLoading={setIsLoading} />
        <LinkBtn
          className="text-center m-t-10 cursor-pointer"
          style={{ textDecoration: 'underline' }}
          onClick={handleClose}
          aria-hidden="true"
        >
          {getTranslationKey('closeWindow')}
        </LinkBtn>
      </ModalBody>
    </Modal>
  );
};
