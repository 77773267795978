import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import styled from 'styled-components';

const WhiteBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 20px solid #fff;
`;

export const FineartBox_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 11, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={30}
        left={45.7}
        width={50}
        height={33.3}
        transform="rotate(-20deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const FineartBox_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 12, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 13, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 14, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={11.4}
        left={50.8}
        width={34.4}
        height={20.7}
        transform="rotate(-6deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 985" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={33.9}
        left={63.5}
        width={34}
        height={20.8}
        transform="rotate(3.7deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 985" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={66.7}
        left={43.6}
        width={32.7}
        height={21.75}
        transform="rotate(-10.7deg)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const FineartBox_2 = ({ backgroundImage, withBorder }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 12, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={20.9} left={2.8} width={95} height={65}>
        {withBorder && <WhiteBorder />}
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};
