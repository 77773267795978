import styled, { css } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { media } from 'assets/css/media';
import { IDictionary } from 'modules/ScrShop/store/types';

interface IModalWrapper {
  width?: string;
  height?: string;
}

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getModalInnerStyled = (params: IModalWrapper) => css`
  max-width: ${params.width || '85%'};
  max-height: ${params.height || '85%'};
  width: 100%;
  overflow: hidden;

  @media screen and (max-height: ${params.height || '600px'}) {
    overflow-y: auto;
  }

  @media screen and (max-width: ${media.mobile}) {
    width: 95%;
    max-width: 95%;
    max-height: 98%;
    overflow-y: auto;
  }
`;

const ModalInner = styled.div`
  background: #fff;
  position: relative;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${getModalInnerStyled};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: none;
  border-radius: 0;
  border: none;
  width: 30px;
  z-index: 2;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex: 0 0 auto;
  }
`;

interface IProps {
  children?: React.ReactNode;
  isCloseable?: boolean;
  onClose: (args: React.SyntheticEvent) => null | void;
  width?: string;
  height?: string;
  style?: IDictionary<string>;
  className?: string;
  isClosableOutSide?: true;
}

const preventClickPlaceholder = (e: React.SyntheticEvent) => e.stopPropagation();

export const Modal: React.FC<IProps> = ({
  children = null,
  isCloseable = true,
  onClose,
  isClosableOutSide,
  ...rest
}: IProps) => {
  const outSideClickHAndler = (e: React.SyntheticEvent) => {
    if (isClosableOutSide) {
      onClose(e);
    }
  };

  return (
    <ModalWrapper onClick={outSideClickHAndler}>
      <ModalInner {...rest} onClick={preventClickPlaceholder}>
        {isCloseable && (
          <CloseButton onClick={onClose} className="close-button">
            <FaTimes color="#151029" size={30} />
          </CloseButton>
        )}
        {children}
      </ModalInner>
    </ModalWrapper>
  );
};
