import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getImageUrls } from 'helpers/imageUrls';
import { IImage } from 'old-store/types/gallery';

export interface IUserBrand {
  _id: string;
  name: string;
  defaultValues: boolean;
  logo: IImage | null;
  logoLight: IImage | null;
  studioLogo: IImage | null;
  icon: IImage | null;
  website: string;
  email: string;
  tel: string;
  company: string;
  socials: {
    facebook: string;
    instagram: string;
    twitter: string;
    pintrest: string;
    youtube: string;
  };
}

const initialState: IUserBrand = {
  _id: '',
  name: '',
  defaultValues: true,
  logo: null,
  logoLight: null,
  studioLogo: null,
  icon: null,
  website: '',
  email: '',
  tel: '',
  company: '',
  socials: {
    facebook: '',
    instagram: '',
    twitter: '',
    pintrest: '',
    youtube: ''
  }
};

const userBrandSlice = createSlice({
  name: 'userBrand',
  initialState,
  reducers: {
    setUserBrand: (state, action: PayloadAction<IUserBrand>) => {
      const { logo, logoLight, studioLogo } = action.payload;

      return {
        ...state,
        ...action.payload,
        logo: logo && { ...logo, ...getImageUrls(logo) },
        logoLight: logoLight && { ...logoLight, ...getImageUrls(logoLight) },
        studioLogo: studioLogo && { ...studioLogo, ...getImageUrls(studioLogo) }
      };
    }
  }
});

export const { setUserBrand } = userBrandSlice.actions;
export const userBrandReducer = userBrandSlice.reducer;
