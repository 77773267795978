import { Modal, Form, Row, Col } from 'reactstrap';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { toggleSendSelectionModal } from 'store/slices/modals';
import { disableUpdateSelectionState } from 'store/slices/selection';
import { saveSelection } from 'store/slices/selection/actions';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { getTranslationKey } from 'helpers/texting';
import { Input } from 'modules/ScrShop/components/Forms';
import { TextArea } from 'components/Forms/TextArea';
import { useToggle } from 'hooks/useToggle';
import { Button } from 'modules/ScrShop/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    text-transform: initial !important;
  }
  .close {
    z-index: 9;
  }
`;

export const SendSelectionModal = () => {
  const validationWithouthAuth = {
    firstName: Yup.string().required(getTranslationKey('mandatoryField')),
    lastName: Yup.string().required(getTranslationKey('mandatoryField')),
    email: Yup.string()
      .email(getTranslationKey('checkEmailFormat'))
      .required(getTranslationKey('mandatoryField')),
    selectionName: Yup.string()
      .max(50, getTranslationKey('maxLength', { max: 50 }))
      .required(getTranslationKey('mandatoryField')),
    message: Yup.string().max(1000, getTranslationKey('maxLength', { max: 1000 }))
  };

  const validationWithAuth = {
    selectionName: Yup.string()
      .max(50, getTranslationKey('maxLength', { max: 50 }))
      .required(getTranslationKey('mandatoryField')),
    message: Yup.string().max(1000, getTranslationKey('maxLength', { max: 1000 }))
  };

  const dispatch = useAppDispatch();
  const endCustomer = useAppSelector((state) => state.endCustomer);
  const { sendSelectionModal } = useAppSelector((state) => state.modals);
  const { currentSelection } = useAppSelector((state) => state.selection);
  const { toggle: isValidated, openToggle: enableValidation } = useToggle();
  const validationObj = endCustomer.authed ? validationWithAuth : validationWithouthAuth;

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: endCustomer.firstName ?? '',
      lastName: endCustomer.lastName ?? '',
      email: endCustomer.email ?? '',
      selectionName: currentSelection.name ?? '',
      message: ''
    },
    validationSchema: Yup.object(validationObj),
    onSubmit: async (values, { resetForm }) => {
      dispatch(disableUpdateSelectionState());

      if (endCustomer.authed) {
        dispatch(
          saveSelection({
            firstName: endCustomer.firstName || '',
            lastName: endCustomer.lastName || '',
            email: endCustomer.email?.toLowerCase() || '',
            selectionName: values.selectionName || '',
            message: values.message,
            withCongratsModal: true
          })
        );
      } else {
        dispatch(saveEndCustomer(values));

        dispatch(
          saveSelection({ ...values, email: values.email.toLowerCase(), withCongratsModal: true })
        );
      }

      resetForm();
    }
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  const toggleModal = () => dispatch(toggleSendSelectionModal());

  return (
    <Modal
      centered
      isOpen={sendSelectionModal.isOpen}
      toggle={toggleModal}
      size="md"
      zIndex={999999}
    >
      <StyledModalHeader toggle={toggleModal}>
        {getTranslationKey('selections.sendSelection')}
      </StyledModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row>
            {!endCustomer.authed && (
              <>
                <Col xs="6">
                  <Input
                    id="firstNameID"
                    name="firstName"
                    type="text"
                    label={getTranslationKey('shop.forms.firstName')}
                    placeholder={getTranslationKey('shop.forms.firstName')}
                    value={values.firstName}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={isValidated ? errors.firstName : ''}
                  />
                </Col>
                <Col xs="6">
                  <Input
                    id="lastNameID"
                    name="lastName"
                    type="text"
                    label={getTranslationKey('shop.forms.lastName')}
                    placeholder={getTranslationKey('shop.forms.lastName')}
                    value={values.lastName}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={isValidated ? errors.lastName : ''}
                  />
                </Col>
                <Col xs="12">
                  <Input
                    id="emailID"
                    name="email"
                    type="email"
                    label={getTranslationKey('shop.forms.emailPlaceHolder')}
                    placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                    value={values.email}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={isValidated ? errors.email : ''}
                  />
                </Col>
              </>
            )}
            <Col xs="12">
              <Input
                id="selectionNameID"
                name="selectionName"
                type="text"
                max={50}
                label={getTranslationKey('selections.saveSelectionModal.selectionName')}
                placeholder={getTranslationKey(
                  'selections.saveSelectionModal.selectionNamePlaceholder'
                )}
                value={values.selectionName}
                onChange={handleChange}
                handleBlur={handleBlur}
                errorText={isValidated ? errors.selectionName : ''}
              />
            </Col>
            <Col xs="12">
              <TextArea
                id="messageID"
                name="message"
                type="text"
                max={1000}
                label={getTranslationKey('selections.saveSelectionModal.message')}
                placeholder={getTranslationKey('typeMessageHere')}
                value={values.message}
                onChange={handleChange}
                errorText={isValidated ? errors.message : ''}
              />
            </Col>
            <Col className="mt-2" xs="12">
              <Button data-cy="Submit" type="submit" onClick={onSubmit} className="sb-btn">
                {getTranslationKey('submit')}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
