import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

export const PfePostkarte10x15 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 6, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={13.3} left={10.7} width={66} height={44}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const PfePostkarte10x21 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 7, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={8.9} left={12.6} width={70.7} height={35.3}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 700" />
      </PreviewImageContainer>
    </>
  );
};

export const PfePostkarte12x18 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 8, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 391 175 L 193 469 C 231 467 233 477 168 510 C 158 524 194 523 132 561 L -163 998 L 1415 1455 L 1838 541" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={2}
        left={35}
        width={110}
        height={76}
        zIndex={1}
        transform="matrix3d(1,0.43,0,0.00002,-1,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 900" />
      </PreviewImageContainer>
    </>
  );
};

export const PfePostkarte12x23 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 9, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={10.3} left={39.9} width={60} height={41.4}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 900" />
      </PreviewImageContainer>
    </>
  );
};
