import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { getShareLink, getEndCustomer } from 'old-store/actions';
import { ACTIONS, ACTIONS_DATA } from 'old-store/constants/statistics';
import { usePrevious } from 'hooks/usePrevious';
import { useToggle } from 'hooks/useToggle';
import { useLogSharingStatistics } from 'hooks/useLogSharingStatistics';

let copyTimer: ReturnType<typeof setTimeout>;

export function useLinkShare() {
  const dispatch = useAppDispatch();
  const { type, identifier } = useAppSelector((state) => state.sharing);
  const [copied, setCopied] = useState(false);
  const { toggle: onlyPublicImages, handleToggle: handleHiddenImagesToggle } = useToggle(true);
  const previousPublicImages = usePrevious(onlyPublicImages);
  const { logSharingStatistics } = useLogSharingStatistics();

  useEffect(() => () => clearTimeout(copyTimer), []);

  const postRequest = (linkUrl: string, type: string, identifier: string) => {
    logSharingStatistics({
      identifier,
      scope: type,
      action: ACTIONS.SHARE,
      actionData: ACTIONS_DATA.LINK
    });

    // is not working in Safari without the timeout, because of the security policy
    setTimeout(() => navigator.clipboard.writeText(decodeURIComponent(linkUrl)));
    setCopied(true);
    copyTimer = setTimeout(() => setCopied(false), 5000);

    if (type === 'selection') {
      // get end customer because when sharing a selection
      // a new saved selection is created and when using getEndCustomer
      // all selections are loaded aswell
      dispatch(getEndCustomer());
    }
  };

  const onShare = () => {
    dispatch(
      getShareLink({
        type,
        identifier,
        onlyPublicImages,
        callback: postRequest,
        refetch: onlyPublicImages !== previousPublicImages
      })
    );
  };

  const onShareSingleImage = (identifier: string, showToast?: boolean) => {
    dispatch(
      getShareLink({
        type: 'image',
        identifier,
        onlyPublicImages,
        callback: postRequest,
        refetch: true,
        showToast
      })
    );
  };

  return {
    copied,
    onlyPublicImages,
    handleHiddenImagesToggle,
    onShare,
    onShareSingleImage
  };
}
