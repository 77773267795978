import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const ankerStyles = css`
  color: #000000;

  &:hover {
    color: #000000;
    text-decoration: none;
  }
`;

export const Anker = styled.a`
  ${ankerStyles}
`;

export const LinkAnker = styled(Link)`
  ${ankerStyles}
`;

export const LinkAnkerHash = styled(HashLink)`
  ${ankerStyles}
`;

export const HoverCard = styled.div`
  background: ${(props) => props.theme.colors.primary};
  padding: 15px;
  width: 300px;
  border-radius: 10px;
  position: absolute;
  z-index: 999;
  left: 75px;
  display: none;
  margin-top: -20px;

  ${Wrapper}:hover & {
    display: block;
  }

  &:hover {
    display: block;
  }
`;
