import { ReactComponent as ShopIconDark } from 'assets/images/icons/dark - shop.svg';
import { ReactComponent as ShopIconSimpleFiligree } from 'assets/images/icons/simple filigree - shop.svg';
import { ReactComponent as ShopIconSpringVibes } from 'assets/images/icons/spring - shop.svg';
import { ReactComponent as ShopIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - shop.svg';
import { ReactComponent as ShopIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - shop.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const ShopIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={ShopIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={ShopIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={ShopIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={ShopIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={ShopIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={ShopIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={ShopIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={ShopIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={ShopIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={ShopIconSimpleFiligree} {...props} />
  })();
