import { ReactComponent as DownloadIconDark } from 'assets/images/icons/dark - download.svg';
import { ReactComponent as DownloadIconSimpleFiligree } from 'assets/images/icons/simple filigree - download.svg';
import { ReactComponent as DownloadIconSpringVibes } from 'assets/images/icons/spring - download.svg';
import { ReactComponent as DownloadIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - download.svg';
import { ReactComponent as DownloadIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - download.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const DownloadIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={DownloadIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={DownloadIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={DownloadIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={DownloadIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={DownloadIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={DownloadIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={DownloadIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={DownloadIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={DownloadIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={DownloadIconSimpleFiligree} {...props} />
  })();
