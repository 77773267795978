import { Modal } from 'reactstrap';

import { LinkBtn } from 'components/IconButton/LinkButton';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { getTranslationKey } from 'helpers/texting';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { toggleInfoModal } from 'store/slices/modals';

export const InfoModal: React.FC = () => {
  const { infoModal } = useAppSelector((state) => state.modals);

  const dispatch = useAppDispatch();

  const handleToggle = () => dispatch(toggleInfoModal());

  return (
    // @ts-ignore
    <Modal centered isOpen={infoModal.isOpen} toggle={handleToggle} size="lg" zIndex={999999}>
      <ModalHeader className="info-modal-title" toggle={handleToggle}>
        {infoModal.title || getTranslationKey('hint')}
      </ModalHeader>
      <ModalBody>
        {infoModal.text1 && <p>{infoModal.text1}</p>}
        {infoModal.text2 && <p>{infoModal.text2}</p>}
        {infoModal.text3 && <p>{infoModal.text3}</p>}

        {/* eslint-disable-next-line */}
        <LinkBtn
          className="text-center m-t-10 cursor-pointer"
          style={{ textDecoration: 'underline' }}
          onClick={handleToggle}
        >
          {infoModal.buttonText || getTranslationKey('closeWindow')}
        </LinkBtn>
      </ModalBody>
    </Modal>
  );
};
