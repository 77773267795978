import {
  AcrylicBlock_10x10_1,
  AcrylicBlock_10x10_2,
  AcrylicBlock_10x10_3
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Gifts/AcrylicBlock';
import {
  ClassicMug_1,
  ClassicMug_2,
  ClassicMug_3
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Gifts/ClassicMug';
import {
  MagicMug_1,
  MagicMug_2,
  MagicMug_3
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Gifts/MagicMug';

export const gifts = {
  'pic-arcylic-block-10x10': AcrylicBlock_10x10_1,
  'pic-arcylic-block-10x10_0': AcrylicBlock_10x10_1,
  'pic-arcylic-block-10x10_1': AcrylicBlock_10x10_2,
  'pic-arcylic-block-10x10_2': AcrylicBlock_10x10_3,
  'pic-arcylic-block-15x10_0': AcrylicBlock_10x10_1,
  'pic-arcylic-block-15x10_1': AcrylicBlock_10x10_2,
  'pic-arcylic-block-15x10_2': AcrylicBlock_10x10_3,
  'pic-arcylic-block-20x15_0': AcrylicBlock_10x10_1,
  'pic-arcylic-block-20x15_1': AcrylicBlock_10x10_2,
  'pic-arcylic-block-20x15_2': AcrylicBlock_10x10_3,
  'pic-arcylic-block-20x20_0': AcrylicBlock_10x10_1,
  'pic-arcylic-block-20x20_1': AcrylicBlock_10x10_2,
  'pic-arcylic-block-20x20_2': AcrylicBlock_10x10_3,

  'pic-classic-mug-portrait-right': ClassicMug_1,
  'pic-classic-mug-portrait-right_0': ClassicMug_1,
  'pic-classic-mug-portrait-right_1': ClassicMug_2,
  'pic-classic-mug-portrait-right_2': ClassicMug_3,
  'pic-classic-mug-portrait-left_0': ClassicMug_1,
  'pic-classic-mug-portrait-left_1': ClassicMug_2,
  'pic-classic-mug-portrait-left_2': ClassicMug_3,
  'pic-classic-mug-portrait-panorama_0': ClassicMug_1,
  'pic-classic-mug-portrait-panorama_1': ClassicMug_2,
  'pic-classic-mug-portrait-panorama_2': ClassicMug_3,
  'pic-classic-mug-landscape-right_0': ClassicMug_1,
  'pic-classic-mug-landscape-right_1': ClassicMug_2,
  'pic-classic-mug-landscape-right_2': ClassicMug_3,
  'pic-classic-mug-landscape-left_0': ClassicMug_1,
  'pic-classic-mug-landscape-left_1': ClassicMug_2,
  'pic-classic-mug-landscape-left_2': ClassicMug_3,

  'pic-magic-mug-portrait-right': MagicMug_1,
  'pic-magic-mug-portrait-right_0': MagicMug_1,
  'pic-magic-mug-portrait-right_1': MagicMug_2,
  'pic-magic-mug-portrait-right_2': MagicMug_3,
  'pic-magic-mug-portrait-left_0': MagicMug_1,
  'pic-magic-mug-portrait-left_1': MagicMug_2,
  'pic-magic-mug-portrait-left_2': MagicMug_3,
  'pic-magic-mug-portrait-panorama_0': MagicMug_1,
  'pic-magic-mug-portrait-panorama_1': MagicMug_2,
  'pic-magic-mug-portrait-panorama_2': MagicMug_3,
  'pic-magic-mug-landscape-right_0': MagicMug_1,
  'pic-magic-mug-landscape-right_1': MagicMug_2,
  'pic-magic-mug-landscape-right_2': MagicMug_3,
  'pic-magic-mug-landscape-left_0': MagicMug_1,
  'pic-magic-mug-landscape-left_1': MagicMug_2,
  'pic-magic-mug-landscape-left_2': MagicMug_3
};
