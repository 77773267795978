import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';

export const TriplexPreview = ({ backgroundImage, galleryId }) => {
  const horizontalImage1 = useGalleryImage('horizontal', 3, galleryId);
  const horizontalImage2 = useGalleryImage('horizontal', 4, galleryId);
  const horizontalImage3 = useGalleryImage('horizontal', 5, galleryId);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={20.4}
        left={6.1}
        width={22.3}
        height={22.6}
        transform="rotate(-9deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={15.7}
        left={33.5}
        width={23}
        height={23}
        transform="rotate(-9deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={11.3}
        left={59.9}
        width={23}
        height={23}
        transform="rotate(-9deg)"
      >
        <PreviewImage image={horizontalImage3.url_M} />
      </PreviewImageContainer>
    </>
  );
};
