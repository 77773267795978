import {
  PhotoboxLarge_0,
  PhotoboxLarge_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Photobox/PhotoboxLarge';
import {
  FineartBox_0,
  FineartBox_1,
  FineartBox_2
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Photobox/FineartBox';

export const photobox = {
  'photobox-large-10-15-3': PhotoboxLarge_0,
  'photobox-large-10-15-3_0': PhotoboxLarge_0,
  'photobox-large-10-15-3_1': PhotoboxLarge_1,

  'photobox-large-15-23-3': PhotoboxLarge_1,
  'photobox-large-15-23-3_0': PhotoboxLarge_1,
  'photobox-large-15-23-3_1': PhotoboxLarge_0,

  'fineart-box-15x23': FineartBox_0,
  'fineart-box-15x23_0': FineartBox_0,
  'fineart-box-15x23_1': FineartBox_1,
  'fineart-box-15x23_2': FineartBox_2
};
