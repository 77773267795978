import { createAction } from 'redux-act';
import { IBreadcrumb, IChangePasswordAction, IModalStateAction } from 'modules/ScrShop/store/types';

export const setSectionTitleAction = createAction<string>();
export const setSiteGlobalClass = createAction<string>();
export const toggleGlobalLoadingAction = createAction<boolean>();
export const toggleCheckoutLoadingAction = createAction<boolean>();
export const setBackButtonRouteAction = createAction<string>();

export const changePasswordAction = createAction<IChangePasswordAction>();

export const setModalStateAction = createAction<IModalStateAction>();

export const revokeTermsAction = createAction();

export const setBreadcrumbs = createAction<IBreadcrumb[]>();
