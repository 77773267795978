import { createReducer } from 'redux-act';
import { DEFAULT_CHECKOUT_FLOW_STORE } from 'modules/ScrShop/store/constants/checkout-flow';
import { ICheckoutFlowReducer } from 'modules/ScrShop/store/types';
import {
  cleanUpCheckoutFlowAction,
  setCheckOutFlowAction
} from 'modules/ScrShop/store/actions/checkout-flow';
import { cloneDeep } from 'lodash';

export const checkoutFlow = createReducer<ICheckoutFlowReducer>(
  {},
  cloneDeep(DEFAULT_CHECKOUT_FLOW_STORE)
);
checkoutFlow.on(setCheckOutFlowAction, (state, payload) => ({
  ...state,
  ...payload
}));
checkoutFlow.on(cleanUpCheckoutFlowAction, () => cloneDeep(DEFAULT_CHECKOUT_FLOW_STORE));
