import { IImage } from 'old-store/types/gallery';
import { lightOrDarkColor } from './light-or-dark-color';

export const getLogoUrl = (
  templateName: string,
  logoLight: IImage | null,
  logo: IImage | null,
  header?: boolean
): string | undefined => {
  const headerLogosDark = ['Breeze', 'dark', 'Calm'];
  const headerLogosLight = ['VickyBaumannFineart', 'VickyBaumannBoho', 'Capri', 'Rose'];
  const cardLogosLight = ['Capri', 'Rose'];

  if (header && headerLogosDark.includes(templateName)) return logo?.url;
  if (header && headerLogosLight.includes(templateName)) return logoLight?.url;
  if (cardLogosLight.includes(templateName)) return logoLight?.url;

  const backgroundElement = document.getElementById('rounded-logo-photographer');

  if (backgroundElement) {
    const { backgroundColor } = window.getComputedStyle(backgroundElement);

    const backgroundColorIsLight = lightOrDarkColor(backgroundColor) === 'light';

    if (backgroundColorIsLight) return logo?.url;

    return logoLight?.url;
  }

  return logo?.url;
};
