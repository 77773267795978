import { useEffect } from 'react';
import { Modal } from 'reactstrap';

import { ModalBody } from 'components/Modal/ModalBody';
import { Photographer } from 'components/Photographer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { togglePhotographerModal } from 'store/slices/modals';

export const PhotographerModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { photographerModal } = useAppSelector((state) => state.modals);
  const { templateName } = useAppSelector((state) => state.collection);

  useEffect(() => {
    if (!photographerModal.isOpen) return;

    const listener = (event: Event) => {
      // @ts-expect-error Event type missing classList
      if (event.target.classList.contains('fade')) {
        dispatch(togglePhotographerModal());
      }
    };

    document.addEventListener('touchstart', listener);

    return () => {
      const isNewShopPage = window.location.pathname.includes('/shop-new');
      const isPhotobookPage = window.location.pathname.includes('/photobook');

      if (isNewShopPage || isPhotobookPage) {
        document.body.classList.remove(templateName);
      }
      document.removeEventListener('touchstart', listener);
    };
  }, [photographerModal.isOpen]);

  return (
    <Modal
      wrapClassName="photographer-modal"
      centered
      isOpen={photographerModal.isOpen}
      toggle={() => dispatch(togglePhotographerModal())}
      size="md"
      draggable={false}
      scrollable={false}
      zIndex={999999}
    >
      <ModalBody className="p-0">
        <Photographer
          data-cy="Photographer-modal"
          isModalOpen={photographerModal.isOpen}
          closeModal={() => dispatch(togglePhotographerModal())}
          viewClassName="d-lg-block"
        />
      </ModalBody>
    </Modal>
  );
};
