import { SCOPES } from 'old-store/constants/statistics';
import { useAppDispatch } from 'hooks/redux';
import { logStatistics } from 'old-store/actions';

export function useLogSharingStatistics() {
  const dispatch = useAppDispatch();

  const logSharingStatistics = (data: any = {}) => {
    const scopedData = { ...data };

    if (data.scope === SCOPES.GALLERY) scopedData.galleryIds = data.identifier;
    if (data.scope === SCOPES.SELECTION) scopedData.selection_id = data.identifier;
    if (data.scope === SCOPES.IMAGE) scopedData.image_id = data.identifier;

    dispatch(logStatistics(scopedData));
  };

  return { logSharingStatistics };
}
