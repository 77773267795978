import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'modules/ScrShop/components/Modal';
import { clearGlobalError } from 'modules/ScrShop/store/actions/errors';
import { getErrorsState } from 'modules/ScrShop/store/selectors';
import { Button } from 'modules/ScrShop/components/Buttons';

const ModalContentWrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const Text = styled.div`
  font-size: 18px;
  margin: 30px 0;
  text-align: center;
  line-height: 1.5;
`;

export function GlobalErrors() {
  const dispatch = useDispatch();
  const { text, title, confirmText, closeCallback } = useSelector(getErrorsState);

  const onCloseClick = () => {
    dispatch(clearGlobalError());
    if (closeCallback) {
      closeCallback();
    }
  };

  return text ? (
    // @ts-ignore
    <Modal onClose={onCloseClick} data-cy="component-global-error" zIndex={999999}>
      <ModalContentWrapper onClick={onCloseClick}>
        <Title>{title || 'Error'}</Title>
        <Text data-cy="component-error-text">{text}</Text>
        {confirmText ? (
          <Button data-cy="component-confirm-modal" onClick={onCloseClick}>
            {confirmText}
          </Button>
        ) : null}
      </ModalContentWrapper>
    </Modal>
  ) : null;
}
