import { createReducer } from 'redux-act';
import { IError } from 'modules/ScrShop/store/types';
import { clearGlobalError, showGlobalError } from 'modules/ScrShop/store/actions/errors';

interface IErrorReducer {
  text?: string;
  title?: string;
  confirmText?: string;
  closeCallback?: () => void;
}

export const error = createReducer<IErrorReducer>({}, {})
  .on(showGlobalError, (state: IErrorReducer, data: IError) => data)
  .on(clearGlobalError, () => ({}));
