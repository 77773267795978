import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { toggleDownloadPinModal, toggleInfoModal } from 'store/slices/modals';
import { getDownloadUrl } from 'helpers/downloadUrl';

interface IOptions {
  gallery_id?: string;
  collection_id?: string;
  selection_id?: string;
}

export const useDownload = () => {
  const dispatch = useAppDispatch();
  const environment = useAppSelector((state) => state.environment);
  const collection = useAppSelector((state) => state.collection);

  const downloadGallery = (options: IOptions, quality: string) => {
    const { url, infoModalText, infoModalText2 } = getDownloadUrl(
      // @ts-ignore
      options,
      { collection, environment, quality }
    );

    if (infoModalText || infoModalText2) {
      dispatch(toggleInfoModal({ text1: infoModalText, text2: infoModalText2 }));
    }

    if (url) {
      if (collection.downloadPinActive && collection.downloadPin) {
        dispatch(toggleDownloadPinModal({ action: 'startDownload', url }));
      } else {
        // @ts-ignore
        window.downloadFile(url);
      }
    }
  };

  return downloadGallery;
};
