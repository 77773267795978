import { useLayoutEffect } from 'react';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { overviewPreviewMap } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview';
import { productsPreviewMap } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { preloadImage } from 'helpers/preloader';
import { useOnOrientationChange } from 'hooks/useOrientationChange';
import { useForceUpdate } from 'hooks/useForceUpdate';

const previewMap = {
  ...overviewPreviewMap,
  ...productsPreviewMap
};

const DefaultPreview = ({ backgroundImage }) => (
  <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
    <PreviewImage image={backgroundImage} viewBox="0 0 800 800" />
  </PreviewImageContainer>
);

export const PersonalizedPreview = ({ children, groupSlug, image, withBorder, galleryId }: any) => {
  const CurrentPreviewComponent = previewMap[groupSlug] || DefaultPreview;
  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {
    // preloading images before they are rendered
    (async () => {
      await preloadImage(image);
    })();
  }, []);

  useOnOrientationChange(forceUpdate);

  return (
    <>
      <CurrentPreviewComponent
        backgroundImage={image}
        withBorder={withBorder}
        galleryId={galleryId}
      />
      {children}
    </>
  );
};
