import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

export const Cushion_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 427 335 C 638 430 803 432 1003 399 C 1024 550 1022 735 1010 1082 C 935 1096 555 1132 425 1182 C 514 805 446 510 427 335" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={23.5}
        left={20}
        width={79}
        height={56}
        transform="matrix3d(1, -0.1, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const Cushion_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 263 462 C 703 563 1045 521 1555 529 L 1543 1262 L 331 1251" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={25} left={17} width={90} height={125}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1000 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const Cushion_3 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 406 386 C 684 483 847 466 1085 476 C 1074 570 1040 907 1073 1098 C 825 1076 558 1024 345 1061 C 448 817 440 534 406 386" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={25} left={23} width={55} height={74}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1000 1500" />
      </PreviewImageContainer>
    </>
  );
};
