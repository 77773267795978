import { useWindowWidth } from '@react-hook/window-size/throttled';

interface IBreakpoints {
  desktopXXl: number;
  desktopXl: number;
  desktopLg: number;
  desktop: number;
  mobile: number;
  mediumSmall: number;
  small: number;
}

export const breakpoints: IBreakpoints = {
  desktopXXl: 1280,
  desktopXl: 992,
  desktopLg: 960,
  desktop: 771,
  mobile: 770,
  mediumSmall: 560,
  small: 400
};

export const useBreakpoint = (breakpoint: keyof IBreakpoints | number): boolean => {
  const width = useWindowWidth();

  if (typeof breakpoint === 'number') {
    return width > breakpoint;
  }

  const minWidth = breakpoints[breakpoint];

  return width > minWidth;
};
