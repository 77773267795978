import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

const Overlay = styled.div<{ width: number; height: number; top: number; left: number }>`
  position: absolute;
  z-index: 4;
  width: ${({ width }) => `${width}%`};
  height: ${({ height }) => `${height}%`};
  top: ${({ top }) => `${top}%`};
  left: ${({ left }) => `${left}%`};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 35%,
    rgba(0, 0, 0, 0.7203475140056023) 65%,
    rgba(0, 0, 0, 0.17412902661064422) 94%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const MagicMug_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1136 352 M 955 1075 C 853 1181 472 1176 394 1072 L 392 461 C 477 543 831 540 956 466" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <Overlay width={38} height={28} top={30} left={26} />
      <PreviewImageContainer top={30.9} left={25} width={55} height={47}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1250 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const MagicMug_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1136 352 M 1230 1438 L 155 1435 L 147 522 C 378 712 1016 681 1231 535" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <Overlay width={80} height={45} top={34} left={8} />
      <PreviewImageContainer top={35} left={10} width={80} height={109}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1000 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const MagicMug_3 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1126 352 M 843 1078 C 799 1123 604 1135 554 1074 L 550 751 C 597 791 787 799 848 748" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <Overlay width={21} height={16} top={49} left={36} />
      <PreviewImageContainer top={51} left={36.9} width={45} height={25}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1250 1500" />
      </PreviewImageContainer>
    </>
  );
};
