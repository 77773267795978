import { ReactComponent as YoutubeIconDark } from 'assets/images/icons/dark - youtube.svg';
import { ReactComponent as YoutubeIconSpringVibes } from 'assets/images/icons/spring - youtube.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const YoutubeIcon = (props: IIconProps) =>
  useTemplateVariant({
    dark: () => <BaseIcon icon={YoutubeIconDark} {...props} />,
    springVibes: () => <BaseIcon icon={YoutubeIconSpringVibes} {...props} />,
    _default_: () => <i className="fa fa-youtube" style={{ color: props.color }} />
  })();
