import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

export const MixPix_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 4, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 5, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 6, fallbackImages);
  const horizontalImage4 = getImageByIdx(horizontalImages, 7, fallbackImages);
  const horizontalImage5 = getImageByIdx(horizontalImages, 8, fallbackImages);
  const horizontalImage6 = getImageByIdx(horizontalImages, 9, fallbackImages);
  const horizontalImage7 = getImageByIdx(horizontalImages, 10, fallbackImages);
  const horizontalImage8 = getImageByIdx(horizontalImages, 11, fallbackImages);
  const horizontalImage9 = getImageByIdx(horizontalImages, 12, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-24.9}
        left={9}
        width={32}
        height={29}
        transform="matrix3d(1, 0.12, 0, 0.0001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-21.3}
        left={42}
        width={24.8}
        height={29}
        transform="matrix3d(1, 0.12, 0, 0.0001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-18.4}
        left={70.6}
        width={22.5}
        height={29}
        transform="matrix3d(1, 0.12, 0, 0.0001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={9.8}
        left={9}
        width={28.5}
        height={30.3}
        transform="matrix3d(1, 0.08, 0, 0.0001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage4.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={13}
        left={42}
        width={25}
        height={28.8}
        transform="matrix3d(1, 0.055, 0, 0.0001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage5.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={16}
        left={70.5}
        width={22}
        height={26.8}
        transform="matrix3d(1, 0.055, 0, 0.0001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage6.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={46.3}
        left={9}
        width={28.9}
        height={30.5}
        transform="matrix3d(1, 0.03, 0, 0.0002, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage7.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.2}
        left={42}
        width={25.2}
        height={28.3}
        transform="matrix3d(1, 0.03, 0, 0.0002, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage8.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.9}
        left={70.6}
        width={22.8}
        height={26.9}
        transform="matrix3d(1, 0.03, 0, 0.0002, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
      >
        <PreviewImage image={horizontalImage9.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const MixPix_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 753 416 L 1439 685 L 1439 685 L 748 1016 L 748 1016 L 58 683" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={15}
        left={23}
        width={100}
        height={48}
        transform="matrix3d(1, 0.35, 0, 0, -0.7, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 900" />
      </PreviewImageContainer>
    </>
  );
};

export const MixPix_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -780 -930 L 2515 -1217 L 1861 588 L 839 927 L 837 928 L -1297 -125" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-35}
        left={32}
        width={105}
        height={61}
        transform="matrix3d(1, 0.46, 0, 0, -0.9, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>
    </>
  );
};

export const MixPix_3 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 5, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 6, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 7, fallbackImages);
  const horizontalImage4 = getImageByIdx(horizontalImages, 8, fallbackImages);
  const horizontalImage5 = getImageByIdx(horizontalImages, 9, fallbackImages);
  const horizontalImage6 = getImageByIdx(horizontalImages, 10, fallbackImages);
  const horizontalImage7 = getImageByIdx(horizontalImages, 11, fallbackImages);
  const horizontalImage8 = getImageByIdx(horizontalImages, 12, fallbackImages);
  const horizontalImage9 = getImageByIdx(horizontalImages, 13, fallbackImages);
  const horizontalImage10 = getImageByIdx(horizontalImages, 14, fallbackImages);
  const horizontalImage11 = getImageByIdx(horizontalImages, 15, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={6} left={9.4} width={18} height={17.4}>
        <PreviewImage image={horizontalImage1.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={6} left={32.5} width={18} height={17.4}>
        <PreviewImage image={horizontalImage2.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={6} left={55.8} width={18} height={17.4}>
        <PreviewImage image={horizontalImage3.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={6} left={78.8} width={18} height={17.4}>
        <PreviewImage image={horizontalImage3.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={29.2} left={9.4} width={18} height={17.4}>
        <PreviewImage image={horizontalImage4.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={29.2} left={32.5} width={18} height={17.4}>
        <PreviewImage image={horizontalImage5.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={29.2} left={55.8} width={18} height={17.4}>
        <PreviewImage image={horizontalImage6.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={29.2} left={78.8} width={18} height={17.4}>
        <PreviewImage image={horizontalImage7.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={52.6} left={9.4} width={18} height={17.4}>
        <PreviewImage image={horizontalImage8.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={52.6} left={32.5} width={18} height={17.4}>
        <PreviewImage image={horizontalImage9.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={52.6} left={55.8} width={18} height={17.4}>
        <PreviewImage image={horizontalImage10.url_M} />
      </PreviewImageContainer>

      <PreviewImageContainer top={52.6} left={78.8} width={18} height={17.4}>
        <PreviewImage image={horizontalImage11.url_M} />
      </PreviewImageContainer>
    </>
  );
};

export const MixPix_4 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 5, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 745 426 L 1309 672 L 1309 673 L 756 983 L 755 984 L 190 672" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={14}
        left={36}
        width={60}
        height={34}
        transform="matrix3d(1, 0.6, 0, 0, -1.2, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 900" />
      </PreviewImageContainer>
    </>
  );
};
