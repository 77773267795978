import { call, put } from 'redux-saga/effects';
import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';
import { SagaIterator } from 'redux-saga';
import { fetchPhotoboksFontsSuccess } from 'old-store/actions';

export const fetchPhotobookFontsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Photobook.getPhotobookFonts);
    ApiErrors.checkOnApiError(response);

    yield put(fetchPhotoboksFontsSuccess(response?.result?.fonts));
  } catch (e) {
    console.log(e);
  }
};
