import styled from 'styled-components';
import media from 'assets/css/media';

const Wrapper = styled.div<{ isSingle: boolean }>`
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  max-width: 100%;
  position: relative;
  flex: 0 0 auto;
  @media (min-width: ${media.desktop}) {
    max-width: 600px;
  }

  &.active {
    box-shadow: ${(props) => (props.isSingle ? 'none' : ' 0 0 10px 1px rgb(160 160 160 / 53%)')};
  }
`;

interface IProps {
  isSingle: boolean;
}

export const HighlightCard: React.FC<IProps> = ({ children, isSingle }) => (
  <Wrapper className="highlight-card" isSingle={isSingle}>
    {children}
  </Wrapper>
);
