import { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductBySlug, getProductGroupBySlug } from 'modules/ScrShop/store/selectors';
import { fetchProductsAction } from 'modules/ScrShop/store/actions';

interface IParams {
  groupSlug: string;
  productSlug: string;
  [key: string]: string;
}

export const HOCCheckForGroupProduct: React.FC = ({ children }) => {
  const { groupSlug = '', productSlug = '' } = useParams<IParams>();
  const dispatch = useDispatch();
  const group = useSelector(getProductGroupBySlug)(groupSlug);
  const product = useSelector(getProductBySlug)(productSlug);
  useEffect(() => {
    if (!group || !product) {
      dispatch(
        fetchProductsAction({
          collection_id: window.SITE_ID
        })
      );
    }
  }, []); //eslint-disable-line

  if (!groupSlug || !productSlug) {
    return <Redirect to="/shop" />;
  }

  return <>{children}</>; // eslint-disable-line
};
