import { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { GiBackwardTime } from 'react-icons/gi';
import { theme } from 'assets/css/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCroppedImageCountAction,
  deleteCroppedImageAction,
  setCropImageAction,
  setMadeCroppingChanges,
  setSelectedImagedSuccessAction
} from 'modules/ScrShop/store/actions';
import { mapValues, isNumber, get } from 'lodash';
import {
  getProductBySlug,
  getProductsStore,
  getSelectedImagesIDsSelector,
  getShopCroppedImageByIDSelector
} from 'modules/ScrShop/store/selectors';
import { IImage, IProductDimensions } from 'modules/ScrShop/store/types';
import { useImageAspectRatio } from 'modules/ScrShop/store/hooks';

// tslint:disable-next-line:no-implicit-dependencies
import 'cropperjs/dist/cropper.css';
import { useParams } from 'react-router-dom';
import { FaExclamationCircle } from 'react-icons/fa';
import { CountButtons } from '../CountButtons';
import { trackEventAction } from 'old-store/actions';
import { getTranslationKey } from 'helpers/texting';

const Wrapper = styled.div<{ isCropEnabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .cropper-view-box {
    outline: none;
    border: ${(props) => (props.isCropEnabled ? 'red dotted 3px' : 'transparent solid 3px')};
  }

  .cropper-modal {
    background-color: #ffffff;
    opacity: ${(props) => (props.isCropEnabled ? 0.6 : 1)};
  }

  .cropper-face {
    background: transparent;
    opacity: 1;
  }
`;
const CropperContainer = styled.div`
  padding: 20px;
  position: relative;
`;
const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 20px;
  width: 100%;
  margin-top: 20px;

  button {
    flex-direction: column;
    max-width: 48%;
    position: relative;
    border-radius: 10px;
    padding: 30px 0 10px;

    svg {
      font-size: 25px;
      top: -10px;
      position: absolute;
    }
  }
`;

const RevertButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 12px;
  border-radius: 50%;
  z-index: 2;
  background: ${theme.colors.buttonSecondaryBg};
  color: ${theme.colors.buttonSecondaryColor};
`;
const Cover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
`;

const ExclamationIcon = styled(FaExclamationCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  color: #ff0000;
  z-index: 2;
`;

const WarningText = styled.div`
  text-align: center;
  color: #ff0000;
`;

const roundDecimal = (val: number, maxFractionDigits: number): string =>
  parseFloat(val.toFixed(maxFractionDigits)).toString();

interface IProps {
  productAspectRatio: {
    aspectRatioX: number;
    aspectRatioY: number;
    autoRotateAspectRatio: boolean;
  };
  image: IImage;
  // isSingle: boolean;
  folded: boolean;
  dimensions: IProductDimensions;
  productID: string;
  smallSizesImages: string[];
  isCanChangeQuantity: boolean;
}

// Disable Reason: libs has too much props. Optimizing will take more time but this is not necessary for now.
// tslint:disable-next-line:max-func-body-length
export const CropImage: React.FC<IProps> = ({
  image,
  productAspectRatio,
  folded,
  dimensions,
  productID,
  isCanChangeQuantity,
  smallSizesImages
}) => {
  const dispatch = useDispatch();
  const selectedImages = useSelector(getSelectedImagesIDsSelector);
  const { _id, url_M, width, height } = image;
  const isImageFirstInList = selectedImages.indexOf(_id) === 0;
  const isImageLastInList = selectedImages.indexOf(_id) === selectedImages.length - 1;
  const cropperRef = useRef<HTMLImageElement>(null);
  const { groupSlug, productSlug }: any = useParams();
  const product = useSelector(getProductBySlug)(productSlug);
  const cropperContainerRef = useRef<HTMLImageElement>(null);
  const idSelector = `${productSlug}-${_id}`;
  const cropImage = useSelector(getShopCroppedImageByIDSelector)(idSelector);
  const croppedImage = useSelector(getShopCroppedImageByIDSelector)(idSelector);
  // move flag to the product model
  const isCanChangeOrder = ['photobook', 'triplex', 'leporellos', 'calendar'].includes(groupSlug);
  const quantity = get(cropImage, 'quantity', 1);
  const [zoomValueState, setZoomValue] = useState(0);
  const [cropperInstance, setCropper] = useState<any>();
  const [isCropEnabled, changeIsCropEnabled] = useState(false);
  const [isWasCropped, setIsWasCropped] = useState(false);
  const { isMadeCroppingChanges } = useSelector(getProductsStore);
  const initialAspectRatioXY = useImageAspectRatio(
    width,
    height,
    productAspectRatio.aspectRatioX,
    productAspectRatio.aspectRatioY,
    productAspectRatio.autoRotateAspectRatio
  );
  const isSquareCropBox = initialAspectRatioXY.aspectRatioX === initialAspectRatioXY.aspectRatioY;
  // move flag to the product model
  const allowRotate = !['leporellos', 'calendar'].includes(groupSlug);

  const cropBoxInitialAspectRatio =
    initialAspectRatioXY.aspectRatioX / initialAspectRatioXY.aspectRatioY;
  // each time user wants to rotate crop box we recalculate its aspect ratio based on this fraction
  // then we switch numerator and denominator
  // so each time crop box is switched between vertical and horizontal positioning
  const [cropBoxAspectRatioFraction, setCropBoxAspectRatioFraction] = useState({
    numerator: initialAspectRatioXY.aspectRatioY,
    denominator: initialAspectRatioXY.aspectRatioX
  });
  const [aspectRatioState, setAspectRatioState] = useState(initialAspectRatioXY);
  const isImageSmall = smallSizesImages.includes(image._id);

  const [initCropper, setInitCropper] = useState(false);
  const [updatedAxis, setUpdatedAxis] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const timeoutRef = useRef<any>();
  const cropperFace: HTMLElement | null = cropperContainerRef.current
    ? cropperContainerRef.current.querySelector('.cropper-face')
    : null;

  useEffect(() => {
    changeIsCropEnabled(true);
  }, [folded]); //eslint-disable-line

  const getCroppedData = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const { height: croppingHeight, width: croppingWidth, x, y } = cropper.getData();

    const { left, top, width, height } = cropper.getCanvasData();
    const { naturalHeight, naturalWidth, rotate } = cropper.getImageData();

    return mapValues(
      {
        width,
        height,
        croppingWidth,
        croppingHeight,
        naturalHeight,
        naturalWidth,
        x,
        y,
        left,
        top,
        rotate,
        zoomLevel: zoomValueState,
        croppedAspectRatio: aspectRatioState,
        aspectRatio: cropper.options.aspectRatio,
        cropBoxData: cropper.getCropBoxData()
      },
      (value, key) =>
        isNumber(value) && key !== 'zoomLevel' && key !== 'aspectRatio'
          ? roundDecimal(value, 0)
          : value
    );
  };

  useEffect(() => {
    if (cropperFace) {
      const boundingRect = cropperFace.getBoundingClientRect();

      if (folded && dimensions) {
        const foldedZoneSizeVertical =
          boundingRect.height * (dimensions.depth / (dimensions.depth * 2 + dimensions.height)) * 2;
        const foldedZoneSizeHorizontal =
          boundingRect.width * (dimensions.depth / (dimensions.depth * 2 + dimensions.width)) * 2;

        cropperFace.style.borderTop = `${foldedZoneSizeVertical}px solid rgba(255, 0, 0, 0.25)`;
        cropperFace.style.borderBottom = `${foldedZoneSizeVertical}px solid rgba(255, 0, 0, 0.25)`;
        cropperFace.style.borderLeft = `${foldedZoneSizeHorizontal}px solid rgba(255, 0, 0, 0.25)`;
        cropperFace.style.borderRight = `${foldedZoneSizeHorizontal}px solid rgba(255, 0, 0, 0.25)`;
      }
    }
  }, [cropperFace, getCroppedData]); //eslint-disable-line

  const cropHandler = () => {
    const data = {
      imageID: idSelector,
      cropData: getCroppedData()
    };
    dispatch(setCropImageAction(data));
  };

  useEffect(() => {
    if (isMadeCroppingChanges) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => cropHandler(), 1000);
    }
  }, [updatedAxis]); //eslint-disable-line

  useEffect(() => {
    sessionStorage.setItem('isMadeCroppingChanges', JSON.stringify(isMadeCroppingChanges));
  }, [isMadeCroppingChanges]); //eslint-disable-line

  useEffect(() => {
    if (initCropper) {
      setInitCropper(false);
    }
  }, [initCropper]); //eslint-disable-line
  useEffect(() => {
    if (cropperInstance) {
      if (isCropEnabled) {
        cropperInstance.enable();
      } else {
        cropperInstance.disable();
      }
    }
  }, [isCropEnabled, cropperInstance, croppedImage]); //eslint-disable-line

  const toggleCropModeHandler = () => {
    changeIsCropEnabled(!isCropEnabled);
    if (isCropEnabled) {
      cropHandler();
    }
  };

  const enableClickCropHandler = useCallback(() => {
    if (!isCropEnabled) {
      toggleCropModeHandler();
    }
  }, [isCropEnabled]); //eslint-disable-line
  if (!url_M) {
    return null;
  }

  const cropperInitHandle = (cropper: any) => {
    setCropper(cropper);
    changeIsCropEnabled(false);
  };

  const rotateCropBoxArea = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const aspectRatio =
      cropBoxAspectRatioFraction.numerator / cropBoxAspectRatioFraction.denominator;
    setCropBoxAspectRatioFraction({
      numerator: cropBoxAspectRatioFraction.denominator,
      denominator: cropBoxAspectRatioFraction.numerator
    });

    cropper.setAspectRatio(aspectRatio);
  };

  const rotateHandler = () => {
    if (cropperInstance) {
      cropperInstance.enable();

      rotateCropBoxArea();
      setIsWasCropped(true);

      cropHandler();
      if (!isCropEnabled) {
        cropperInstance.disable();
      }

      if (!isMadeCroppingChanges) {
        dispatch(setMadeCroppingChanges(true));
      }

      dispatch(
        trackEventAction({
          name: 'clicked-action',
          payload: {
            button_id: 'rotate',
            location: 'cropping',
            image_id: _id,
            product_group_id: groupSlug,
            product_id: productSlug
          }
        })
      );
    }
  };

  const revertCropHandler = () => {
    if (cropperInstance) {
      cropperInstance.enable();
      cropperInstance.reset();
      setZoomValue(0);
      cropHandler();
      setIsWasCropped(false);
      if (!isCropEnabled) {
        cropperInstance.disable();
      }
    }
  };

  const displayCropByOptions = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    if (croppedImage) {
      const { left, top, width, height, croppedAspectRatio, aspectRatio, cropBoxData } =
        croppedImage;

      cropper
        .setAspectRatio(aspectRatio)
        .setCanvasData({ width: +width, height: +height })
        .moveTo(left, top)
        .setCropBoxData(cropBoxData);

      if (croppedAspectRatio.aspectRatioX && croppedAspectRatio.aspectRatioY) {
        setAspectRatioState(croppedAspectRatio);
      }

      setInitCropper(true);
    } else {
      cropHandler();
    }
  };

  const handleUpdateMovingOptions = (e: any) => {
    const isMadeCroppingChanges = JSON.parse(sessionStorage.getItem('isMadeCroppingChanges') || '');

    setUpdatedAxis({
      x: e.detail.originalEvent.x,
      y: e.detail.originalEvent.y
    });

    if (!isMadeCroppingChanges) {
      dispatch(setMadeCroppingChanges(true));
    }
  };

  const moveUpCallback = () => {
    const imageIndex = selectedImages.indexOf(_id);
    const updatedSelectedImages = selectedImages.filter((imageId) => imageId !== _id);
    updatedSelectedImages.splice(imageIndex - 1, 0, _id);

    dispatch(setSelectedImagedSuccessAction(updatedSelectedImages));
  };

  const moveDownCallback = () => {
    const imageIndex = selectedImages.indexOf(_id);
    const updatedSelectedImages = selectedImages.filter((imageId) => imageId !== _id);
    updatedSelectedImages.splice(imageIndex + 1, 0, _id);

    dispatch(setSelectedImagedSuccessAction(updatedSelectedImages));
  };

  const deleteHandler = () => {
    dispatch(deleteCroppedImageAction(_id));
    dispatch(
      trackEventAction({
        name: 'clicked-action',
        payload: {
          button_id: 'delete',
          location: 'cropping',
          image_id: _id,
          product_group_id: groupSlug,
          product_id: productSlug
        }
      })
    );
  };
  const updateCountHandler = (isAdding: boolean) => {
    if (!isAdding && quantity === 1) {
      return;
    }
    const data = {
      imageID: idSelector,
      quantity: isAdding ? quantity + 1 : quantity - 1,
      productID
    };
    dispatch(addCroppedImageCountAction(data));
    dispatch(
      trackEventAction({
        name: 'clicked-action',
        payload: {
          button_id: isAdding ? 'increase-amount' : 'decrease-amount',
          location: 'cropping',
          quantity: data.quantity,
          image_id: _id,
          product_group_id: groupSlug,
          product_id: productSlug
        }
      })
    );
  };

  return (
    <Wrapper isCropEnabled={isCropEnabled}>
      {isWasCropped ? (
        <RevertButton onClick={revertCropHandler}>
          <GiBackwardTime size={20} />
        </RevertButton>
      ) : null}
      <CropperContainer onClick={enableClickCropHandler} ref={cropperContainerRef}>
        {isImageSmall && (
          <>
            <ExclamationIcon size={20} id="exclamation-tooltip" />
            <UncontrolledTooltip target="exclamation-tooltip">
              {getTranslationKey('smallImage')}
            </UncontrolledTooltip>
          </>
        )}
        {!isCropEnabled ? <Cover /> : null}
        <Cropper
          src={url_M}
          style={{ width: '100%', height: '350px' }}
          aspectRatio={cropBoxInitialAspectRatio}
          viewMode={1}
          ref={cropperRef}
          guides={false}
          checkCrossOrigin={false}
          background={false}
          cropBoxMovable
          cropBoxResizable
          dragMode="move"
          autoCropArea={1}
          zoomOnTouch
          center
          zoomOnWheel={false}
          ready={displayCropByOptions}
          onInitialized={cropperInitHandle}
          cropmove={handleUpdateMovingOptions}
        />
      </CropperContainer>
      {isImageSmall && product && (
        <>
          <WarningText>{getTranslationKey('smallImage')}</WarningText>
          <br />
        </>
      )}
      {folded && <span>{getTranslationKey('shop.foldedImageCropTooltip')}</span>}
      <CTAWrapper>
        <CountButtons
          deleteCallback={deleteHandler}
          isCanChangeQuantity={isCanChangeQuantity}
          updateCountCallback={updateCountHandler}
          quantity={quantity}
          moveUpCallback={!isImageFirstInList && isCanChangeOrder ? moveUpCallback : null}
          moveDownCallback={!isImageLastInList && isCanChangeOrder ? moveDownCallback : null}
          rotateCallback={rotateHandler}
          showRotateButton={!isSquareCropBox && allowRotate}
          size={20}
        />
      </CTAWrapper>
    </Wrapper>
  );
};

export * from './CroppHeader';
