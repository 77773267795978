// new iPad pro detection fix (it has the same user agent as Mac)
export function isIpadOS() {
  return (
    navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
  );
}

export function isMobileSafari() {
  const { userAgent } = navigator;

  return userAgent.match(/iPod|iPhone|iPad/i) || isIpadOS();
}

export function isOperaMini() {
  const { userAgent } = navigator;

  return userAgent.match(/Opera Mini/i);
}

export function isAndroidBrowser() {
  const { userAgent } = navigator;

  return userAgent.match(/Android/i) && userAgent.match(/Mozilla/i);
}

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;
