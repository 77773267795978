import { intersection } from 'lodash';
import { useLocation } from 'react-router-dom';

import { ITemplateVariantConfig } from 'types/hooks/useTemplateVariant';
import { useAppSelector } from 'hooks/redux';

const TemplateToConfigKeyMap: { [key: string]: keyof ITemplateVariantConfig } = {
  ScrAppBook_dark: 'classic',
  ScrAppBook2_dark: 'dark',
  ScrAppBook2_VickyBaumannFineart: 'vickyBaumannFineart',
  ScrAppBook2_VickyBaumannBoho: 'vickyBaumannBoho',
  ScrAppBook2_SimpleFiligree: 'simpleFiligree',
  ScrAppBook2_SpringVibesBlue: 'springVibesBlue',
  ScrAppBook2_SpringVibesRose: 'springVibesRose',
  ScrAppBook2_SpringVibesYellow: 'springVibesYellow',
  ScrAppBook2_Breeze: 'breeze',
  ScrAppBook2_Capri: 'capri',
  ScrAppBook2_Rose: 'rose',
  ScrAppBook2_Calm: 'calm'
};

export const useTemplateVariant = (config: ITemplateVariantConfig) => {
  const { pathname } = useLocation();
  const isShop = intersection(pathname.split('/'), ['shop']).length;

  const { templateName, type } = useAppSelector((state) => state.collection);

  if (isShop) return config._default_ || config.classic || null;

  const templateId = `${type}_${templateName}`;
  const configKey = TemplateToConfigKeyMap[templateId];

  if (config[configKey]) return config[configKey];

  if (templateName.startsWith('SpringVibes') && config.springVibes) return config.springVibes;

  if (config._default_) return config._default_;

  return null;
};
