import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';
import { PAYMENT_DEFAULTS } from 'modules/ScrShop/store/constants/orders';

export const getOrdersStore = ({ orders }: IStore) => orders;
export const getOrderSelector = createSelector(getOrdersStore, ({ order }) => order);
export const getLastPaymentSelector = createSelector(
  getOrdersStore,
  ({ order }) => order.payments[0] || PAYMENT_DEFAULTS
);
export const getPaymentRedirectSelector = createSelector(
  getOrdersStore,
  ({ redirectToPayment }) => redirectToPayment
);

export const getCapturingStatus = createSelector(
  getOrdersStore,
  ({ capturingStatus }) => capturingStatus
);
