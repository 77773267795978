import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';

export const getCheckoutFlowStore = ({ checkoutFlow }: IStore) => checkoutFlow;
export const getCheckoutLoginForm = createSelector(
  getCheckoutFlowStore,
  ({ loginForm }) => loginForm
);
export const getIsPricingUpdating = createSelector(
  getCheckoutFlowStore,
  ({ isPricingUpdating }) => isPricingUpdating
);
