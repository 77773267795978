import { Fragment } from 'react';
import styled from 'styled-components';

const RadioStyled = styled.input`
  display: none;
`;

const RadioButtonWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  background: #f9f9f9;
  border-radius: 50%;
  &.disabled {
    &:after {
      content: '';
      background: #f7f7f7;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.3;
    }
  }

  @media screen and (max-width: 840px) {
    width: 100%;
  }
`;

const RadioButtonStyled = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #f9f9f9;
  flex: 1 1 auto;
  cursor: pointer;
  min-width: 110px;
  width: 50%;
  font-size: 14px;
  border-radius: 30px;

  @media screen and (max-width: 840px) {
    min-width: auto;
  }

  ${RadioStyled}:checked + & {
    background-color: #ededed;
  }
`;

interface IOption {
  readonly value: number | string;
  readonly text: string;
}

interface IProps {
  readonly name: string;
  readonly disabled?: boolean;
  readonly options: IOption[];
  formValue: string;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const compare = (value: string | number, to_compare: string | number) => value === to_compare;
const getClassName = (comparator: boolean) => (comparator ? 'disabled' : '');
export function Switch({ name, options, disabled, formValue, onChange }: IProps) {
  return (
    <RadioButtonWrapper className={getClassName(Boolean(disabled))}>
      {options
        ? options.map(({ value, text }, index) => (
            <Fragment key={index}>
              <RadioStyled
                type="radio"
                id={`${name}_${value}`}
                name={name}
                value={value}
                checked={compare(value, formValue)}
                onChange={onChange}
                disabled={disabled}
              />
              <RadioButtonStyled htmlFor={`${name}_${value}`}>{text}</RadioButtonStyled>
            </Fragment>
          ))
        : null}
    </RadioButtonWrapper>
  );
}
