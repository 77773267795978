import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';

export const PhotoboxLarge_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 8, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 9, fallbackImages);
  const verticalImage1 = getImageByIdx(verticalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={29}
        left={3.4}
        width={48}
        height={28.1}
        transform="rotate(-27deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 970" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={65}
        left={7.9}
        width={45}
        height={28.6}
        transform="rotate(-4.7deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1010" />
      </PreviewImageContainer>

      <PreviewImageContainer top={20.9} left={72} width={28} height={43.8} transform="rotate(6deg)">
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>
    </>
  );
};

export const PhotoboxLarge_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 10, fallbackImages);
  const verticalImage1 = getImageByIdx(verticalImages, 6, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={11} left={3.4} width={39} height={58.8} transform="rotate(-8deg)">
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={65.7}
        left={62.8}
        width={45}
        height={35}
        transform="rotate(-24.8deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1100" />
      </PreviewImageContainer>
    </>
  );
};
