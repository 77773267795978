import { SagaIterator } from 'redux-saga';
import { all, takeLatest, fork } from 'redux-saga/effects';
import {
  addUpdateProductToCartAction,
  applyCouponAction,
  capturePayment,
  changeCheckoutProductQuantityAction,
  changePasswordAction,
  checkEndCustomerEmail,
  checkEndCustomerPassword,
  claimCheckoutAction,
  cleanUpCheckout,
  cleanUpOrders,
  clearShopAction,
  convertToOrderAction,
  createAccountWithPasswordAction,
  createUpdateCustomerAction,
  deleteCroppedImageAction,
  deleteProductFormCheckoutAction,
  fetchDigitalPricingForUpselling,
  fetchGlobalShopSettingsForCollectionAction,
  fetchLaboratoryAction,
  fetchPackagesForUpselling,
  fetchProductsAction,
  fetchProductSuggestion,
  loginCustomerAction,
  loginCustomerWithPasswordAction,
  recalculatePricingAction,
  resetPassword,
  revokeTermsAction,
  setSelectedImagedAction,
  setSelectedImagesAction,
  setShopEditModeAction,
  fetchCountriesAction,
  fetchCheckoutAction
} from 'modules/ScrShop/store/actions';
import {
  fetchProductsSaga,
  fetchProductSuggestionSaga
} from 'modules/ScrShop/store/sagas/products';
import {
  checkEndCustomerEmailSaga,
  checkEndCustomerPasswordSaga,
  clearShopSaga,
  createAccountWithPasswordSaga,
  deleteCroppedImageSaga,
  fetchDigitalPricingForUpsellingSaga,
  fetchGlobalShopSettingsForCollectionSaga,
  fetchLaboratorySaga,
  fetchPackagesForUpsellingSaga,
  loginCustomerWithPasswordSaga,
  resetPasswordSaga,
  setSelectedImageSaga,
  setSelectedImagesSaga,
  setShopEditModeSaga,
  setGroupedImagesSaga,
  fetchProductRecListSaga,
  fetchProductBestsellerListSaga
} from 'modules/ScrShop/store/sagas/shop';
import {
  addProductToCartSaga,
  changeCheckoutProductQuantitySaga,
  claimCheckoutSaga,
  cleanCheckoutSaga,
  deleteProductFormCheckoutSaga,
  fetchCheckoutSaga,
  recalculatePricingSaga
} from 'modules/ScrShop/store/sagas/checkout';
import { createUpdateCustomerSaga, loginCustomerSaga } from 'modules/ScrShop/store/sagas/shipping';
import {
  applyCouponSaga,
  capturePaymentSaga,
  cleanUpOrdersSaga,
  convertToOrderSaga,
  fetchOrderSaga
} from 'modules/ScrShop/store/sagas/orders';
import { changePasswordSaga, revokeTermsModalSaga } from 'modules/ScrShop/store/sagas/global';
import { fetchCountriesSaga } from 'modules/ScrShop/store/sagas/countries';
import {
  fetchProductBestsellerList,
  fetchProductRecList,
  groupImagesBySizeType
} from 'modules/ScrShop/store/actions/shop';

export const shopSaga = function* (): SagaIterator {
  yield all([
    fork(fetchCheckoutSaga),
    fork(fetchOrderSaga),
    // Shop
    takeLatest(fetchProductsAction, fetchProductsSaga),
    takeLatest(fetchProductSuggestion, fetchProductSuggestionSaga),
    takeLatest(setSelectedImagedAction, setSelectedImageSaga),
    takeLatest(setSelectedImagesAction, setSelectedImagesSaga),
    takeLatest(deleteCroppedImageAction, deleteCroppedImageSaga),
    takeLatest(setShopEditModeAction, setShopEditModeSaga),
    takeLatest(clearShopAction, clearShopSaga),
    takeLatest(checkEndCustomerPassword, checkEndCustomerPasswordSaga),
    takeLatest(loginCustomerWithPasswordAction, loginCustomerWithPasswordSaga),
    takeLatest(checkEndCustomerEmail, checkEndCustomerEmailSaga),
    takeLatest(createAccountWithPasswordAction, createAccountWithPasswordSaga),
    takeLatest(fetchLaboratoryAction, fetchLaboratorySaga),
    takeLatest(recalculatePricingAction, recalculatePricingSaga),
    // Basket
    takeLatest(addUpdateProductToCartAction, addProductToCartSaga),
    takeLatest(deleteProductFormCheckoutAction, deleteProductFormCheckoutSaga),
    takeLatest(changeCheckoutProductQuantityAction, changeCheckoutProductQuantitySaga),
    takeLatest(claimCheckoutAction, claimCheckoutSaga),
    takeLatest(cleanUpCheckout, cleanCheckoutSaga),
    takeLatest(fetchCheckoutAction, fetchCheckoutSaga),
    // Shipping
    takeLatest(createUpdateCustomerAction, createUpdateCustomerSaga),
    // Orders
    takeLatest(convertToOrderAction, convertToOrderSaga),
    takeLatest(cleanUpOrders, cleanUpOrdersSaga),
    takeLatest(capturePayment, capturePaymentSaga),
    // Auth
    takeLatest(loginCustomerAction, loginCustomerSaga),
    takeLatest(changePasswordAction, changePasswordSaga),
    takeLatest(revokeTermsAction, revokeTermsModalSaga),
    takeLatest(applyCouponAction, applyCouponSaga),
    takeLatest(resetPassword, resetPasswordSaga),
    // Shop
    takeLatest(
      fetchGlobalShopSettingsForCollectionAction,
      fetchGlobalShopSettingsForCollectionSaga
    ),
    takeLatest(fetchPackagesForUpselling, fetchPackagesForUpsellingSaga),
    takeLatest(fetchDigitalPricingForUpselling, fetchDigitalPricingForUpsellingSaga),
    takeLatest(groupImagesBySizeType, setGroupedImagesSaga),
    takeLatest(fetchProductRecList, fetchProductRecListSaga),
    takeLatest(fetchProductBestsellerList, fetchProductBestsellerListSaga),
    // Countries
    takeLatest(fetchCountriesAction, fetchCountriesSaga)
  ]);
};

export * from './products';
