import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';

export const PhotoboxPreview = ({ backgroundImage, galleryId }) => {
  const horizontalImage1 = useGalleryImage('horizontal', 8, galleryId);
  const horizontalImage2 = useGalleryImage('horizontal', 9, galleryId);
  const horizontalImage3 = useGalleryImage('horizontal', 10, galleryId);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={11.5}
        left={50.9}
        width={31.4}
        height={20.7}
        transform="rotate(-6deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1250 700" x={-150} y={0} />
      </PreviewImageContainer>
      <PreviewImageContainer
        top={34}
        left={63.5}
        width={31.5}
        height={20.7}
        transform="rotate(3.6deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1250 700" x={-150} />
      </PreviewImageContainer>
      <PreviewImageContainer
        top={66.8}
        left={43.6}
        width={32.6}
        height={21.7}
        transform="rotate(-10.6deg)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1250 700" x={-150} />
      </PreviewImageContainer>
    </>
  );
};
