import { filterImagesBySelections, filterImagesByGalleries } from 'helpers/filterImages';
import { getTitleImage } from 'helpers/getTitleImage';
import { moveArrayItem } from 'helpers/moveArrayItem';
import { IGallery, IndexedImage } from 'old-store/types/gallery';
import { useAppSelector } from 'hooks/redux';
import { selectSortedGalleries } from 'store/slices/collection/selectors';
import { useMemo } from 'react';

interface IImageMap {
  [key: string]: IndexedImage[];
}

interface IIndexedImages {
  filteredImagesArr: IndexedImage[];
  filteredImagesMap: IImageMap;
}

interface IIndexedProps {
  galleriesItems?: IGallery[];
}

export const useIndexedImages = ({ galleriesItems }: IIndexedProps): IIndexedImages => {
  const { collection, images, selection } = useAppSelector((state) => ({
    collection: state.collection,
    images: state.images.images,
    selection: state.selection.all
  }));
  const galleries = useAppSelector(selectSortedGalleries);
  const currentGalleries = galleriesItems || galleries;

  const isSelectionPage = window.location.pathname.includes('/selections');

  const filteredImages = useMemo(
    () => filterImagesByGalleries(currentGalleries, images),
    [currentGalleries, images]
  );

  const processedImagesForSelection = useMemo<IIndexedImages | null>(() => {
    if (!isSelectionPage) return null;

    const allFilteredImages = Object.values(filteredImages).flat();
    const selectedImages = filterImagesBySelections(allFilteredImages, selection);
    const selectedImagesArr = Object.values(selectedImages).flat();

    return {
      filteredImagesArr: selectedImagesArr as IndexedImage[],
      filteredImagesMap: selectedImages
    };
  }, [isSelectionPage, filteredImages, selection]);

  const processedImagesForGalleries = useMemo<IIndexedImages | null>(() => {
    if (isSelectionPage) return null;

    const updateTitleImage = (images: IndexedImage[], galleryId: string) => {
      const { titleImage } = getTitleImage(images, collection.type);

      if (titleImage) {
        const titleImageIdx = images.findIndex((img) => img._id === titleImage._id);
        moveArrayItem(images, titleImageIdx, 0);

        return images.map((img, idx) => ({
          ...img,
          index: idx,
          imageId: `${galleryId}_${idx}`
        }));
      }

      return images;
    };

    const updatedFilteredImages = Object.entries(filteredImages).reduce<IImageMap>(
      (newAcc, [galleryId, images]) => ({
        ...newAcc,
        [galleryId]: updateTitleImage(images, galleryId)
      }),
      {}
    );

    const updatedImagesArr = Object.values(updatedFilteredImages).flat();

    return {
      filteredImagesArr: updatedImagesArr as IndexedImage[],
      filteredImagesMap: updatedFilteredImages as IImageMap
    };
  }, [isSelectionPage, filteredImages, collection.type]);

  const fallbackImages = {
    filteredImagesArr: [] as IndexedImage[],
    filteredImagesMap: {} as IImageMap
  };

  return processedImagesForSelection || processedImagesForGalleries || fallbackImages;
};
