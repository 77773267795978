import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';

export const getGalleries = (store: IStore) => store.collection.galleries;
export const getGalleriesIds = createSelector(getGalleries, (galleries) =>
  galleries.map(({ _id }) => _id)
);
export const getNotPurchasedGalleries = createSelector(getGalleries, (galleries) =>
  galleries.filter((gallery) => !gallery.forPurchasedImages)
);
export const getPurchasedGalleries = createSelector(getGalleries, (galleries) =>
  galleries.filter((gallery) => gallery.forPurchasedImages)
);
export const getPurchasedGalleriesIds = createSelector(getPurchasedGalleries, (galleries) =>
  galleries.map(({ _id }) => _id)
);
