import { fetchSlideshowsSuccess } from 'old-store/actions';
import { ISlideshow } from 'old-store/types/slideshowsCollections';
import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { selectGalleries } from 'store/slices/collection/selectors';
import { ISlide, ISong } from '../types/slideshowsCollections';

const getStartTime = (soundtracks: ISong[], trackIndex: number) =>
  soundtracks.reduce(
    (total, song, index) => (index < trackIndex ? total + song.durationSeconds : total),
    0
  );

export const fetchSlideshowsSaga = function* (): SagaIterator {
  try {
    const galleries = yield select(selectGalleries);
    const slideshows: string[] = [];

    galleries?.forEach((gallery: any) => {
      gallery.slideshows.forEach((slideshow: string) => {
        if (!slideshows.includes(slideshow)) {
          slideshows.push(slideshow);
        }
      });
    });

    const responses = yield all(slideshows.map((id: string) => call(Api.Slideshow.fetch, id)));

    ApiErrors.checkOnApiError(responses);

    const fetchedSlideshows = responses.map((res: any) => res.result);
    fetchedSlideshows.forEach((slideshow: ISlideshow) => {
      slideshow.slides = slideshow.slides.map((slide: ISlide) => ({ // eslint-disable-line
        ...slide,
        slideId: slide.id
      }));
      slideshow.soundtracks = slideshow.soundtracks.map((track: ISong, i: number) => { // eslint-disable-line
        const startTime = getStartTime(slideshow.soundtracks, i);

        return {
          ...track,
          startTime
        };
      });
    });

    yield put(fetchSlideshowsSuccess(fetchedSlideshows));
  } catch (e) {
    console.log(e);
  }
};
