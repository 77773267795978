import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImage, ISelection } from 'old-store/types/gallery';

type CurrentImage = Partial<IImage> & Pick<IImage, '_id'>;

interface IImageState {
  commentModal: {
    isOpen: boolean;
    buttonClassName: string;
    image?: IImage;
    color: string;
  };
  curGalleryImage: CurrentImage;
}

export interface IParams {
  image?: IImage;
  selection?: ISelection;
  emptySelection: boolean;
  buttonClassName: string;
  color?: string;
}

const initialState: IImageState = {
  commentModal: {
    isOpen: false,
    buttonClassName: '',
    color: ''
  },
  curGalleryImage: { _id: '' }
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    closeCommentImageModal: (state) => {
      state.commentModal.isOpen = false;
    },
    openCommentImageModal: (state, data: PayloadAction<IParams>) => {
      state.commentModal.isOpen = true;
      state.commentModal.buttonClassName = data.payload.buttonClassName;
      state.commentModal.image = data.payload.image;
      state.commentModal.color = data.payload.color ?? '';
    },
    setCurrentGalleryImage: (state, data: PayloadAction<CurrentImage>) => {
      state.curGalleryImage = data.payload;
    }
  }
});

export const { closeCommentImageModal, openCommentImageModal, setCurrentGalleryImage } =
  imageSlice.actions;
export const imageReducer = imageSlice.reducer;
