import { createReducer } from 'redux-act';
import {
  capturePaymentFailure,
  capturePaymentSuccess,
  convertToOrderSuccessAction,
  setRedirectToPaymentAction,
  resetCapturePaymentStatus
} from 'modules/ScrShop/store/actions';
import { IOrdersReducer } from 'modules/ScrShop/store/types';
import { ORDER_DEFAULTS } from 'modules/ScrShop/store/constants/orders';
import { setCollection } from 'store/slices/collection';
import { cloneDeep } from 'lodash';

const initialState: IOrdersReducer = cloneDeep({
  order: ORDER_DEFAULTS,
  orders: [],
  redirectToPayment: false,
  capturingStatus: {
    success: false,
    failure: false
  }
});

export const orders = createReducer<IOrdersReducer>({}, initialState);
orders.on(convertToOrderSuccessAction, (state, payload) => ({
  ...state,
  order: payload
}));
orders.on(setRedirectToPaymentAction, (state, payload) => ({
  ...state,
  redirectToPayment: payload
}));

// @ts-ignore
orders.on(setCollection, (state) => {
  const persistData = localStorage.getItem(`orders_${window.SITE_ID}`);
  const persistState = persistData ? JSON.parse(persistData) : ORDER_DEFAULTS;

  return {
    ...state,
    ...persistState
  };
});

orders.on(capturePaymentSuccess, (state) => ({
  ...state,
  capturingStatus: {
    success: true,
    failure: false
  }
}));

orders.on(capturePaymentFailure, (state) => ({
  ...state,
  capturingStatus: {
    success: false,
    failure: true
  }
}));

orders.on(resetCapturePaymentStatus, (state) => ({
  ...state,
  capturingStatus: {
    success: false,
    failure: false
  }
}));
