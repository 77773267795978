import Dexie from 'dexie';

const db = new Dexie('database');

db.version(4).stores({
  sites: '++id, data',
  passwords: '++site_id, password',
  user: '++site_id, appInstallationState, appInstallationTrackedWithGA, confirmedSingleImgDlHint'
});

db.version(3).stores({
  sites: '++id, data',
  passwords: '++site_id, password',
  user: '++site_id, appInstallationState, confirmedSingleImgDlHint'
});

db.version(2).stores({
  sites: '++id, data',
  passwords: '++site_id, password'
});

export default db;
