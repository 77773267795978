import ReactDOM from 'react-dom';
import './registerUnknownFunctions';
import { Provider } from 'react-redux';
import queryString from 'query-string';
import FastClick from 'fastclick';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
// import environment from './environment';
import App from './App';
import { store } from 'old-store/store';
import 'moment/locale/de.js'; // eslint-disable-line
import mixpanel from 'mixpanel-browser';
import { setTrackingStatus } from 'store/slices/collection';
import { setSharingUrl } from 'store/slices/sharing';
import { getCookie, setCookie } from 'helpers/cookie';

console.log('[APP-START] imported all packages in index.js');
console.log('env variables', process.env);
if (process.env.API_HOST) {
  console.log('[APP-START] API_HOST detected setting window.API_URL to', process.env.API_HOST);
  window.API_URL = process.env.API_HOST;
}

try {
  moment.locale('de');
  momentLocalizer();

  console.log('[APP-START] init moment');

  let { auth } = queryString.parse(window.location.search);

  console.log('[APP-START] parse auth from search string =>', auth);

  auth = !auth ? true : !!+auth;

  const pathArray = window.location.pathname.split('/');

  window.SITE_PATH = pathArray[1]; // eslint-disable-line

  console.log('process.env.NODE_ENV', process.env.NODE_ENV);

  if (process.env.NODE_ENV === 'development') {
    if (pathArray[1] !== '') {
      console.log("We're in ", process.env.NODE_ENV);

      if (pathArray[1] === 'share') {
        window.SITE_ID = pathArray[2]; // eslint-disable-line
        window.SITE_URL = `${window.location.protocol}//${window.location.host}/share/${window.SITE_ID}`;
      } else if (pathArray[1] === 'p') {
        window.SITE_ID = pathArray[2]; // eslint-disable-line
        window.SITE_URL = `${window.location.protocol}//${window.location.host}/p/${window.SITE_ID}`;
      } else {
        window.SITE_ID = pathArray[1]; // eslint-disable-line
        window.SITE_URL = `${window.location.protocol}//${window.location.host}/${window.SITE_ID}`;
      }
    }

    store.dispatch({
      type: 'set_auth',
      payload: auth
    });

    store.dispatch(
      setSharingUrl({
        type: '',
        identifier: '',
        url: encodeURIComponent(window.SITE_URL)
      })
    );
  }

  if (window.localStorage && window.localStorage.getItem(`tracking-${window.SITE_ID}`)) {
    console.log(
      '[APP-START] window.localStorage.getItem(tracking- + window.SITE_ID) =>',
      window.localStorage.getItem(`tracking-${window.SITE_ID}`)
    );

    store.dispatch(setTrackingStatus(window.localStorage.getItem(`tracking-${window.SITE_ID}`)));
  }

  store.dispatch({
    type: 'set_endCustomer_pre_auth',
    payload:
      window.document &&
      window.document.cookie &&
      window.document.cookie.includes('endCustomerToken')
  });

  console.log('[APP-START] set_endCustomer_pre_auth');

  window.addEventListener(
    'touchstart',
    function onFirstTouch() {
      store.dispatch({
        type: 'user_touched'
      });
      window.removeEventListener('touchstart', onFirstTouch, false);
    },
    false
  );

  if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', () => FastClick.attach(document.body), false);
  }

  console.log('[APP-START] added event listener touchstart');

  window.deferredPrompt = null; // importante!!!
  mixpanel.init(window.MIXPANEL_KEY || 'eead74a10d7d2c237dd7259493e9e085', {
    loaded(mixpanel) {
      // NOTE: we should treat fly users as anonymous users, so we should keep anonymousId for fly end customers
      // and remove it for permanent users

      const isPermanentUser =
        getCookie('endCustomerToken') && !window.localStorage.getItem('flyUserId');
      if (
        isPermanentUser &&
        (window.localStorage.getItem('anonymousId') || getCookie('anonymousId'))
      ) {
        window.localStorage.removeItem('anonymousId');
        setCookie('anonymousId', '', 0);
      }

      const isAnon = !getCookie('endCustomerToken') || window.localStorage.getItem('flyUserId');
      if (isAnon && (!window.localStorage.getItem('anonymousId') || !getCookie('anonymousId'))) {
        const distinctId = mixpanel.get_distinct_id();
        window.localStorage.setItem('anonymousId', distinctId);
        setCookie('anonymousId', distinctId);
      }
    }
  });

  const render = function () {
    console.log(store.getState());
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.querySelector('#app')
    );
  };

  render();

  /* if (
    !environment.isBuilder &&
    !environment.isSamsungBrowser &&
    'serviceWorker' in navigator &&
    process.env.NODE_ENV !== 'development'
  ) {
    console.log('[APP-START] serviceWorker is in navigator add event listener load');
    window.addEventListener('load', () => {
      console.log('[APP-START] site loaded add serviceworker');
      navigator.serviceWorker
        .register(
          process.env.NODE_ENV === 'development'
            ? '/service-worker-leadsuite.js'
            : `${window.location.protocol}//${window.location.hostname}/service-worker-leadsuite.js`,
          { scope: '/' }
        )
        .then((registration) => {
          console.log('[ServiceWorker] Registered on scope', registration.scope);

          // We don't want to check for updates on first load or we will get a false
          // positive. registration.active will be falsy on first load.
          if (registration.active) {
            // Check if an updated sw.js was found
            registration.addEventListener('updatefound', () => {
              console.log('[ServiceWorker] Update found. Waiting for install to complete.');
              const installingWorker = registration.installing;
              // Watch for changes to the worker's state. Once it is "installed", our cache
              // has been updated with our new files, so we can prompt the user to instantly
              // reload.
              installingWorker.addEventListener('statechange', () => {
                if (installingWorker.state === 'installed') {
                  console.log('[ServiceWorker] Install complete. Triggering update prompt.');
                  window.location.reload(true);
                }
              });
            });
          }

          console.log('[APP-START] start render()');
          render();
        })
        .catch((err) => {
          console.log('[ServiceWorker] Failed to Register', err);
          render();
        });
      render();
    });
  } else {
    console.log('[APP-START] serviceWorker is not in navigator render()');
    render();
  } */
} catch (e) {
  console.log('[APP-START] catch error in index.js =>', e);
  window.logToServer(e);
}
