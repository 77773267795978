import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { getTranslationKey } from 'helpers/texting';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTheme } from 'hooks/useTheme';
import axios from 'old-store/utils/axios';
import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Modal, Row } from 'reactstrap';
import { incrementImagesDownloadCount } from 'store/slices/app';
import { setDownloadPin } from 'store/slices/collection';
import { toggleDownloadPinModal } from 'store/slices/modals';
import styled from 'styled-components';

interface IState {
  [key: string]: string;
}

const InputElement = styled.input`
  border: 1px solid ${(props) => props.theme.colors.inputBorderColor};
  box-shadow: none;
  color: ${(props) => props.theme.colors.inputColor};
  background: #fff;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.inputBorderRadius}px;
  display: inline-flex;
  height: 45px;
  width: 45px;
  appearance: auto;
  &:disabled {
    opacity: 0.7;
  }

  &:focus-visible {
    outline: none;
  }
`;

export const DownloadPinModal = () => {
  const [state, setState] = useState<IState>({
    pin1: '',
    pin2: '',
    pin3: '',
    pin4: ''
  });
  const [checkingPin, setCheckingPin] = useState<boolean>(false);
  const [pinCorrect, setPinCorrect] = useState<null | boolean>(null);
  const [theme] = useTheme();
  const dispatch = useAppDispatch();
  const { downloadPinModal } = useAppSelector((state) => state.modals);
  const collection = useAppSelector((state) => state.collection);
  const pinRef1 = useRef<any>(null);
  const pinRef2 = useRef<any>(null);
  const pinRef3 = useRef<any>(null);
  const pinRef4 = useRef<any>(null);
  const timeoutRef = useRef<any>(null);

  const resetInputs = () => {
    setState({ pin1: '', pin2: '', pin3: '', pin4: '' });
    // @ts-ignore
    pinRef1?.current?.focus();
  };

  const performAction = (correctPin: string) => {
    const { url } = downloadPinModal;

    switch (downloadPinModal.action) {
      case 'startDownload':
        // @ts-ignore
        window.downloadFile(url);
        dispatch(incrementImagesDownloadCount());
        break;
      case 'next':
        // @ts-ignore
        downloadPinModal.next(correctPin);
        break;
      default:
        console.log('no action after download pin set');
    }

    dispatch(toggleDownloadPinModal());
  };

  const checkDownloadPin = async (data: { pin: string; collection_id: string }) => {
    try {
      await axios.post('/api/download/downloadPin/check', data);

      dispatch(setDownloadPin(data.pin));

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

  const checkPin = async (newState?: IState) => {
    const currentState = newState || state;

    const pinArray = Object.values(currentState);
    const pin = pinArray.join('');
    const isPinCompleted = pinArray.every((pin) => Boolean(pin));

    if (isPinCompleted) {
      setCheckingPin(true);
      const result = await checkDownloadPin({ pin, collection_id: collection._id });
      if (result) performAction(pin);
      else resetInputs();

      setCheckingPin(false);
      setPinCorrect(result);
    }
  };

  useEffect(() => {
    if (downloadPinModal.isOpen) {
      timeoutRef.current = window.setTimeout(() => pinRef1?.current?.focus());
      checkPin();
    }

    return () => clearTimeout(timeoutRef.current);
  }, [downloadPinModal.isOpen, pinRef1]);

  const changePin = (key: string, value: string, nextRef: any) => {
    setState((prev) => {
      const currentValue = value.toString().toUpperCase().slice(0, 1);
      const newState = { ...prev, [key]: currentValue };
      checkPin(newState);

      return newState;
    });

    if (nextRef) nextRef.current.focus();
  };

  const inputArray = [
    { index: 1, ref: pinRef1 },
    { index: 2, ref: pinRef2 },
    { index: 3, ref: pinRef3 },
    { index: 4, ref: pinRef4 }
  ];

  return (
    <Modal
      isOpen={downloadPinModal.isOpen}
      toggle={() => dispatch(toggleDownloadPinModal())}
      wrapClassName="modal-dark modal-site download-pin-modal"
      className="w-300 mx-auto"
      size="sm"
      zIndex={999999}
    >
      <ModalHeader toggle={() => dispatch(toggleDownloadPinModal())}>
        {getTranslationKey('downloadPin.modalTitle')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>{getTranslationKey('downloadPin.hint')}</p>
          </Col>
        </Row>
        <Row className="text-center">
          {inputArray.map(({ index, ref }) => (
            <Col xs="3" key={index}>
              <InputElement
                theme={theme}
                className="download-pin-input"
                autoFocus={index === 1}
                ref={ref}
                type="text"
                disabled={checkingPin}
                value={state[`pin${index}`]}
                onChange={({ target: { value } }) =>
                  changePin(`pin${index}`, value, inputArray[index]?.ref)
                }
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            {pinCorrect === false && (
              <Alert color="danger" className="mb-0 mt-3">
                {getTranslationKey('downloadPin.pinWrong')}
              </Alert>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
