export enum ShopCouponTypesEnum {
  Total = 'total',
  Product = 'product',
  ProductGroup = 'productGroup',
  Shipping = 'shipment'
}

export enum ShopCouponValueTypeEnum {
  Fixed = 'fixed',
  Percent = 'percent'
}

export interface IShopCouponDiscount {
  type: ShopCouponTypesEnum;
  valueType: ShopCouponValueTypeEnum;
  productGroupId?: string | null;
  productId?: string | null;
  quantityMax?: number | null;
  valueFixed: number;
  valuePercent: number;
}

export interface IShopCoupon {
  id: string;
  user: string;
  name: string;
  description?: string;
  active: boolean;
  code: string;
  codeLowerCase?: string;
  validUntilDate: string;
  discounts: IShopCouponDiscount[];
}
