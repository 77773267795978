import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

export const Canvas20x30_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 15, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 219 331 L 1244 336 L 1293 1108 L 266 1208 L 252 1198 L 202 349" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={23.27}
        left={13.4}
        width={1.3}
        height={59.3}
        transform="matrix3d(0.94,-0.99,0,-0.004,0.058,0.977747,0,0,0,0,1,0,0,0,0,1)"
        boxShadow="-3px 1px 12px -2px rgb(0 0 0 / 75%);"
        zIndex={2}
      >
        <PreviewImage image={horizontalImage.url_M} x={0} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={22}
        left={14.5}
        width={70}
        height={60}
        zIndex={1}
        transform="rotate(-3.4deg)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-280} />
      </PreviewImageContainer>
    </>
  );
};

export const Canvas40x60_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 16, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 413 172 L 991 212 L 991 932 L 416 938 L 405 929 L 401 182" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={11.5}
        left={26.2}
        width={1.4}
        height={52}
        transform="matrix3d(0.94,-0.4,0,-0.001,0.005,1,0,0,0,0,1,0,0,0,0,1)"
        boxShadow="-3px 1px 12px -2px rgb(0 0 0 / 75%);"
        zIndex={2}
      >
        <PreviewImage image={horizontalImage.url_M} x={0} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={11}
        left={27.5}
        width={39}
        height={52}
        transform="rotate(-0.3deg)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1500" x={-35} />
      </PreviewImageContainer>
    </>
  );
};

export const Canvas60x90_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 17, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 451 93 L 977 95 L 979 789 L 449 790 L 442 784 L 444 102" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={6.3}
        left={29.2}
        width={1.1}
        height={46.8}
        transform="matrix3d(0.94,-0.3,0,0,-0.003,1,0,0,0,0,1,0,0,0,0,1)"
        boxShadow="-3px 1px 12px -2px rgb(0 0 0 / 75%);"
        zIndex={2}
      >
        <PreviewImage image={horizontalImage.url_M} x={0} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={6}
        left={30.2}
        width={36}
        height={46.8}
        zIndex={1}
        transform="rotate(0.16deg)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1500" x={-35} />
      </PreviewImageContainer>
    </>
  );
};

export const Canvas20x20_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={17.4} left={10.5} width={80} height={58.5}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1170" x={0} />
      </PreviewImageContainer>
    </>
  );
};

export const Canvas20x20_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={23} left={39} width={60} height={24}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 980" x={0} />
      </PreviewImageContainer>
    </>
  );
};
