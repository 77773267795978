import { createReducer } from 'redux-act';
import {
  setSelectedProductIDAction,
  setSelectedImagedSuccessAction,
  clearShopAction,
  setOptionAction,
  setCropImageAction,
  addCroppedImageCountAction,
  deleteCroppedImageSuccessAction,
  setShopEditModeAction,
  updateShopDataAction,
  changeGalleryToggleStateAction,
  setCropImagesAction,
  fetchGlobalShopSettingsForCollectionActionSuccess,
  setEndCustomerEmailWasNotFound,
  setEndCustomerHasPassword,
  toggleLoginWithPasswordModalAction,
  toggleEnterPasswordModalAction,
  setPasswordForLoginWithPassword,
  setEmailForLoginWithPassword,
  resetLoginWithPasswordFlow,
  setLoginFlowStatus,
  setCreateAccountWithPasswordFlowStatus,
  resetSignupWithPasswordFlow,
  setForgotPasswordFlowStatus,
  fetchLaboratoryActionSuccess,
  fetchPackagesForUpsellingSuccess,
  fetchDigitalPricingForUpsellingSuccess,
  startFetchingPackagesForUpselling,
  stopFetchingPackagesForUpselling
} from 'modules/ScrShop/store/actions';
import { setCollection } from 'store/slices/collection';
import { IShopReducer } from 'modules/ScrShop/store/types';
import { cloneDeep, omit } from 'lodash';
import {
  setGroupedImages,
  setGroupedByGalleriesImages,
  fetchProductRecListSuccess,
  fetchProductBestsellerListSuccess
} from 'modules/ScrShop/store/actions/shop';

const initialData = {
  selectedProductID: '',
  cartSelectedProductID: '',
  selectedImages: [],
  groupedImages: { horizontal: [], vertical: [], fallback: [] },
  groupedByGalleriesImages: { horizontal: {}, vertical: {}, fallback: {} },
  globalShopSettings: {
    id: '',
    userId: '',
    printShopActive: true
  },
  options: {
    border: '',
    finish: '',
    'border-color': '',
    color: '',
    surface: '',
    'color-combination': '',
    material: ''
  },
  croppedImages: {},
  isEditMode: false,
  galleriesToggleStates: {},
  upselling: {
    packages: [],
    fetchingPackages: false,
    digitalPricingList: null
  },
  forgotPasswordFlowStatus: {
    showForgotPasswordModal: false,
    showSuccessMessage: false,
    emailDoesNotExists: false,
    redirectToLoginModal: false,
    email: ''
  },
  laboratory: {
    id: '',
    name: '',
    default: false,
    shippingCountries: [],
    defaultShippingTime: {
      from: 7,
      to: 10
    },
    shippingTimeByProductGroups: []
  },
  loginFlowStatus: {
    showLoginWithPasswordModal: false,
    showEnterPasswordModal: false,
    endCustomerHasPassword: null,
    emailDoesNotExists: null,
    password: '',
    email: '',
    passwordIsInvalid: false
  },
  createAccountWithPasswordFlowStatus: {
    showEmailModal: false,
    showPasswordModal: false,
    emailIsAlreadyTaken: false,
    email: '',
    password: ''
  },
  productRecList: {
    id: '',
    createdAt: '',
    productPricingList: '',
    endCustomer: null,
    recommendationSystemVersion: '',
    products: []
  },
  productBestsellerList: {
    id: '',
    createdAt: '',
    productPricingList: '',
    endCustomer: null,
    recommendationSystemVersion: '',
    products: []
  }
};

const initialState: IShopReducer = cloneDeep(initialData);

export const shop = createReducer<IShopReducer>({}, initialState);
// @ts-ignore
shop.on(setCollection, (state) => {
  const persistData = localStorage.getItem(`shop_${window.SITE_ID}`);
  const persistState = persistData ? JSON.parse(persistData) : cloneDeep(initialData);

  const stateWithoutGroupedImages = omit(persistState, [
    'groupedImages',
    'groupedByGalleriesImages',
    'productRecList'
  ]);

  return {
    ...state,
    ...stateWithoutGroupedImages
  };
});
shop.on(setSelectedProductIDAction, (state, payload) => ({
  ...state,
  selectedProductID: payload
}));
shop.on(setSelectedImagedSuccessAction, (state, payload) => ({
  ...state,
  selectedImages: payload
}));
shop.on(clearShopAction, (state) => {
  const { groupedImages, groupedByGalleriesImages } = state;
  const newInitialData = cloneDeep(initialData);
  // @ts-ignore
  newInitialData.groupedImages = groupedImages;
  newInitialData.groupedByGalleriesImages = groupedByGalleriesImages;

  return newInitialData;
});
shop.on(setOptionAction, (state, payload) => ({
  ...state,
  options: {
    ...state.options,
    [payload.key]: payload.value
  }
}));
shop.on(setCropImageAction, (state, payload) => {
  const prevData = state.croppedImages[payload.imageID] || {};

  return {
    ...state,
    croppedImages: {
      ...state.croppedImages,
      [payload.imageID]:
        payload.cropData === null
          ? null
          : {
              ...prevData,
              ...payload.cropData
            }
    }
  };
});
shop.on(setCropImagesAction, (state, payload) => ({
  ...state,
  croppedImages: payload
}));
shop.on(addCroppedImageCountAction, (state, payload) => {
  const prevData = state.croppedImages[payload.imageID];

  return {
    ...state,
    croppedImages: {
      ...state.croppedImages,
      [payload.imageID]: prevData && {
        ...prevData,
        quantity: payload.quantity
      }
    }
  };
});
shop.on(deleteCroppedImageSuccessAction, (state, payload) => ({
  ...state,
  croppedImages: payload
}));
shop.on(setShopEditModeAction, (state, payload) => ({
  ...state,
  selectedProductID: payload.productID,
  cartSelectedProductID: payload.cartSelectedProductID,
  isEditMode: true
}));
shop.on(updateShopDataAction, (state, payload) => ({
  ...state,
  ...payload
}));
shop.on(changeGalleryToggleStateAction, (state, payload) => ({
  ...state,
  galleriesToggleStates: {
    ...state.galleriesToggleStates,
    [payload.galleryID]: payload.state
  }
}));
shop.on(fetchGlobalShopSettingsForCollectionActionSuccess, (state, payload) => ({
  ...state,
  globalShopSettings: payload
}));
shop.on(setEndCustomerHasPassword, (state, payload) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    endCustomerHasPassword: payload
  }
}));
shop.on(setEndCustomerEmailWasNotFound, (state, payload) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    emailDoesNotExists: payload
  }
}));

shop.on(toggleLoginWithPasswordModalAction, (state) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    showLoginWithPasswordModal: !state.loginFlowStatus.showLoginWithPasswordModal
  }
}));

shop.on(toggleEnterPasswordModalAction, (state) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    showEnterPasswordModal: !state.loginFlowStatus.showEnterPasswordModal
  }
}));

shop.on(setPasswordForLoginWithPassword, (state, payload) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    password: payload
  }
}));

shop.on(setEmailForLoginWithPassword, (state, payload) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    email: payload
  }
}));

shop.on(resetLoginWithPasswordFlow, (state) => ({
  ...state,
  loginFlowStatus: initialData.loginFlowStatus
}));

shop.on(setLoginFlowStatus, (state, payload) => ({
  ...state,
  loginFlowStatus: {
    ...state.loginFlowStatus,
    ...payload
  }
}));

shop.on(setCreateAccountWithPasswordFlowStatus, (state, payload) => ({
  ...state,
  createAccountWithPasswordFlowStatus: {
    ...state.createAccountWithPasswordFlowStatus,
    ...payload
  }
}));

shop.on(setForgotPasswordFlowStatus, (state, payload) => ({
  ...state,
  forgotPasswordFlowStatus: {
    ...state.forgotPasswordFlowStatus,
    ...payload
  }
}));

shop.on(resetSignupWithPasswordFlow, (state) => ({
  ...state,
  createAccountWithPasswordFlowStatus: initialData.createAccountWithPasswordFlowStatus
}));

shop.on(fetchLaboratoryActionSuccess, (state, payload) => ({
  ...state,
  laboratory: payload
}));

shop.on(fetchPackagesForUpsellingSuccess, (state, payload) => ({
  ...state,
  upselling: {
    ...state.upselling,
    packages: payload
  }
}));

shop.on(fetchDigitalPricingForUpsellingSuccess, (state, payload) => ({
  ...state,
  upselling: {
    ...state.upselling,
    digitalPricingList: payload
  }
}));

shop.on(fetchProductRecListSuccess, (state, payload) => ({
  ...state,
  productRecList: payload
}));

shop.on(fetchProductBestsellerListSuccess, (state, payload) => ({
  ...state,
  productBestsellerList: payload
}));

shop.on(startFetchingPackagesForUpselling, (state) => ({
  ...state,
  upselling: {
    ...state.upselling,
    fetchingPackages: true
  }
}));

shop.on(stopFetchingPackagesForUpselling, (state) => ({
  ...state,
  upselling: {
    ...state.upselling,
    fetchingPackages: false
  }
}));

shop.on(setGroupedImages, (state, payload: any) => ({
  ...state,
  groupedImages: payload
}));

shop.on(setGroupedByGalleriesImages, (state, payload: any) => ({
  ...state,
  groupedByGalleriesImages: payload
}));
