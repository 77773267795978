import styled from 'styled-components';
import { Navbar } from 'reactstrap';
import media from 'assets/css/media';
import { FaAngleLeft, FaShoppingCart, FaHome, FaCreditCard, FaGrinHearts } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';

import { theme } from 'assets/css/theme';
import { useDispatch, useSelector } from 'react-redux';
import { clearShopAction } from 'modules/ScrShop/store/actions';
import { useHistory } from 'react-router-dom';
import { getBackButtonRoute } from 'modules/ScrShop/store/selectors';

const Wrapper = styled(Navbar)`
  background: #ffffff;
  padding-top: 20px;
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;
const BackButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  position: absolute;

  svg {
    display: block;
  }
`;
const StepsIcons = styled.div`
  margin: auto;
  display: flex;
  align-items: center;

  svg {
    margin: 0 5px;
  }
`;
const IconWrap = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isActive ? theme.colors.buttonPrimaryBg : theme.colors.buttonSecondaryBg};
`;

type TStepNames = 'basket' | 'shipping' | 'payment' | 'confirm';

interface IProps {
  activeStep: TStepNames;
  isHideBackButton?: boolean;
  backAction?: () => void;
}

export const CheckoutHeader: React.FC<IProps> = ({ activeStep, isHideBackButton, backAction }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const backButtonRoute = useSelector(getBackButtonRoute);
  const moveBackHandler = () => {
    if (activeStep === 'basket') {
      dispatch(clearShopAction());
    }
    if (backAction) {
      backAction();
    }
    if (backButtonRoute.length) {
      history.push(backButtonRoute);
    } else {
      history.goBack();
    }
  };

  const isIconActive = (icons: TStepNames[]) => icons.indexOf(activeStep) > -1;

  return (
    <Wrapper sticky="top">
      {!isHideBackButton ? (
        <BackButton onClick={moveBackHandler} data-cy="component-back-button">
          <FaAngleLeft size={20} />
        </BackButton>
      ) : null}
      <StepsIcons>
        <IconWrap isActive={isIconActive(['basket', 'shipping', 'payment', 'confirm'])}>
          <FaShoppingCart size={25} />
        </IconWrap>
        <IconWrap isActive={isIconActive(['shipping', 'payment', 'confirm'])}>
          <BsThreeDots />
          <FaHome size={25} />
        </IconWrap>
        <IconWrap isActive={isIconActive(['payment', 'confirm'])}>
          <BsThreeDots />
          <FaCreditCard size={25} />
        </IconWrap>
        <IconWrap isActive={isIconActive(['confirm'])}>
          <BsThreeDots />
          <FaGrinHearts size={25} />
        </IconWrap>
      </StepsIcons>
    </Wrapper>
  );
};
