import queryString from 'query-string';
import { detectIE, getSiteTracking } from './helper';
import axios from 'axios';

const { detect } = require('detect-browser');

let browser = null;

try {
  browser = detect();
} catch (e) {
  window.logToServer('error detect browser =>', e);
}

window.logToServer = async function log(message) {
  try {
    console.log('[LOG-TO-SERVER] ', JSON.stringify(message));

    axios.post('/api/log/gallerys', { message: JSON.stringify(message) });
  } catch (e) {
    console.log('[LOG-TO-SERVER] FAILED');
  }
};

const { t, debug, isApp, isPreview } = queryString.parse(window.location.search);

const subdomain = window.location.hostname.split('.').shift();
const ua = window.navigator.userAgent;
const uaLower = ua.toLowerCase();

const environment = {
  isRetina: window.devicePixelRatio && window.devicePixelRatio > 1,
  isIDevice: /iphone|ipod|ipad/i.test(ua),
  isAndroidDevice: /(android)/i.test(ua),
  isMobileChrome:
    ua.includes('Android') &&
    /Chrome\/[.0-9]*/.test(ua) &&
    !ua.includes('Version') &&
    !uaLower.includes('samsungbrowser') &&
    !uaLower.includes('opr/'),
  isMobileIE: ua.includes('Windows Phone'),
  ieVersion: detectIE(),
  isApp:
    isApp === '1' ||
    window.navigator.standalone ||
    window.matchMedia('(display-mode: standalone)').matches,
  isMobileDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua),
  isNewIpad:
    window.navigator.userAgent.match(/Mac/) &&
    window.navigator.maxTouchPoints &&
    window.navigator.maxTouchPoints > 2,
  isFirefox: ua.indexOf('Firefox') > 0,
  isSamsungBrowser:
    uaLower.includes('mozilla/5.0') &&
    uaLower.includes('android ') &&
    uaLower.includes('samsungbrowser'),
  isFacebookApp() {
    const uaNew = ua || window.navigator.vendor || window.opera;

    return uaNew.includes('FBAN') || ua.includes('FBAV');
  },
  isDev: process.env.NODE_ENV === 'development',
  subdomain,
  upperleveldomain: window.location.hostname.split('.').join('.'),
  isBuilder:
    !!document.getElementById('root') ||
    window.location.hostname === 'studio.leadsuite.eu' ||
    (subdomain && subdomain.includes('builder')),
  siteTracking: getSiteTracking(t),
  isPortfolioModule: window.location.pathname.includes('/p/'),
  isPreview: isPreview === '1'
};

if (browser) {
  environment.browserName = browser.name;
  environment.browserVersion = browser.version;
}

environment.isChromeOnIOs = environment.isIDevice && !!navigator.userAgent.match('CriOS');

environment.isMobileSafari =
  environment.isIDevice && ua.indexOf('Safari') > -1 && ua.indexOf('CriOS') < 0; // chrome on ios _ua.indexOf("CriOS") > -1
ua.indexOf('FxiOS') < 0; // eslint-disable-line

if (environment.isIDevice) {
  const extract = ua.match(/OS (\d+)_(\d+)_?(\d+)?/);
  environment.iosVersion = parseInt(extract[1] || 0, 10);
}

environment.isNotSafariOnIos = environment.isIDevice && !environment.isMobileSafari;

environment.isNotChromeOnAndroid = environment.isAndroidDevice && !environment.isMobileChrome;

environment.OS = environment.isIDevice // eslint-disable-line
  ? 'ios'
  : environment.isAndroidDevice // eslint-disable-line
  ? 'android'
  : environment.isMobileIE
  ? 'windows'
  : 'unsupported';

environment.isTablet =
  (environment.isMobileSafari && ua.indexOf('iPad') > -1) ||
  (environment.isMobileChrome && ua.indexOf('Mobile') < 0);

environment.displayWebAppInstallation =
  debug === 'ath' || (!environment.isApp && environment.isMobileDevice && !environment.isBuilder);

if (process.env.NODE_ENV === 'development') {
  environment._ua = ua;
  environment.isTest = true;
  window.logToServer(environment);

  /* environment.isApp = true;
  environment.iosVersion = 13;

  environment.isMobileDevice = true;
  environment.isMobileSafari = true; */
}

if (!window.downloadFile) {
  window.downloadFile = function (sUrl) {
    // If in Chrome or Safari - download via virtual link click
    if (window.downloadFile.isChrome || window.downloadFile.isSafari) {
      // Creating new link node.
      const link = document.createElement('a');
      link.href = sUrl;

      if (link.download !== undefined) {
        // Set HTML5 download attribute. This will prevent file from opening if supported.
        const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
        link.download = fileName;
      }

      // Dispatching click event.
      if (document.createEvent) {
        const e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);

        return true;
      }
    }

    window.open(sUrl, '_self');

    return true;
  };

  window.downloadFile.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  window.downloadFile.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
}

export default environment;
