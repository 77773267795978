import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

export const FramedPoster_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 3, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 409 337 L 1125 445 L 1125 445 L 1120 1053 L 1119 1054 L 412 1162" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={22.5}
        left={22.5}
        width={77}
        height={59}
        transform="perspective(250px) rotateY(8deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>
    </>
  );
};

export const FramedPoster_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 3, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 214 -169 L 2421 58 L 2421 56 L 934 1005 L 934 1005 L -357 213" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-76}
        left={20.5}
        width={140}
        height={90}
        transform="rotate(44deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 600" />
      </PreviewImageContainer>
    </>
  );
};

export const FramedPoster_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 3, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={21.3} left={26.2} width={47.8} height={36}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};
