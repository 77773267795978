import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';

export const FineartPrint15x23_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 0, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={11.2}
        left={-27}
        width={43.5}
        height={54.5}
        transform="rotate(-7deg)"
      >
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1500 1500" x={-100} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={77.8}
        left={61.3}
        width={35}
        height={44}
        transform="rotate(-74deg)"
      >
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1500 1500" x={-300} />
      </PreviewImageContainer>
    </>
  );
};

export const FineartPrint15x23_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={20.2}
        left={31.5}
        width={71.5}
        height={33.5}
        transform="rotate(-6deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 940" x={-100} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={53.5}
        left={50.8}
        width={55.5}
        height={33.8}
        transform="rotate(4deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 945" x={-100} />
      </PreviewImageContainer>
    </>
  );
};
