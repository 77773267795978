import styled from 'styled-components';
import { theme } from 'assets/css/theme';
import {
  FaTrash,
  FaPlusCircle,
  FaMinusCircle,
  FaChevronDown,
  FaChevronUp,
  FaRedo
} from 'react-icons/fa';
import { partial } from 'lodash';

const IconButton = styled.div<{ isNotInteractive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) =>
    !props.isNotInteractive &&
    `&:hover {
		background: ${theme.colors.buttonPrimaryBg};
		color: ${theme.colors.buttonPrimaryColor};
	}`}
  ${(props) =>
    props.isNotInteractive &&
    `border-left: 1px solid #fff;
		border-right: 1px solid #fff;
		cursor: default;
		&:hover {
			background: transparent;
			color: initial;
		}`}
`;
const ButtonGroup = styled.div<{ buttonsSize: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background: ${theme.colors.buttonSecondaryBg};
  color: ${theme.colors.buttonSecondaryColor};
  overflow: hidden;
  ${IconButton} {
    width: ${(props) => props.buttonsSize * 2.2};
    height: ${(props) => props.buttonsSize * 2.2};
    svg {
      width: ${(props) => props.buttonsSize};
    }
  }
`;

interface IProps {
  deleteCallback: () => void;
  updateCountCallback: (action: boolean) => void;
  moveDownCallback?: (() => void) | null;
  moveUpCallback?: (() => void) | null;
  rotateCallback?: () => void;
  quantity: number;
  size?: number;
  isCanChangeQuantity?: boolean;
  showRotateButton?: boolean;
}

export const CountButtons: React.FC<IProps> = ({
  deleteCallback,
  moveDownCallback,
  moveUpCallback,
  updateCountCallback,
  rotateCallback,
  quantity,
  size = 12,
  isCanChangeQuantity,
  showRotateButton = false
}) => (
  <ButtonGroup buttonsSize={size}>
    {moveDownCallback && (
      <IconButton onClick={moveDownCallback}>
        <FaChevronDown />
      </IconButton>
    )}
    {moveUpCallback && (
      <IconButton onClick={moveUpCallback}>
        <FaChevronUp />
      </IconButton>
    )}
    <IconButton data-cy="component-delete-button" onClick={deleteCallback}>
      <FaTrash />
    </IconButton>
    {showRotateButton && (
      <IconButton onClick={rotateCallback} data-cy="component-rotate-button">
        <FaRedo />
      </IconButton>
    )}
    {isCanChangeQuantity ? (
      <>
        <IconButton onClick={partial(updateCountCallback, false)} data-cy="component-move-down">
          <FaMinusCircle />
        </IconButton>
        <IconButton isNotInteractive data-cy="component-quantity">
          {quantity}
        </IconButton>
        <IconButton onClick={partial(updateCountCallback, true)} data-cy="component-move-up">
          <FaPlusCircle />
        </IconButton>
      </>
    ) : null}
  </ButtonGroup>
);
