import { getTranslationKey } from 'helpers/texting';
import { SingleSelectItem } from 'components/SelectionFields/SingleSelectItem';
import { MultiSelectItem } from 'components/SelectionFields/MultiSelectItem';
import { useShareData } from 'components/ShareSelection/useShareData';
import { ShareSelectionButton } from 'components/ShareSelection/ShareSelectionButton';
import { Checkbox } from 'components/Checkbox';
import { useEffect, useState } from 'react';

export const ShareSelection: React.FC = () => {
  const {
    shareOptions,
    selectionOptions,
    galleriesOptions,
    isHiddenShown,
    selectedGalleries,
    sharing,
    selectSharingType,
    shareSelections,
    shareGalleries,
    onlyPublicImages,
    handleHiddenImagesToggle,
    copied,
    onShare
  } = useShareData();

  const [showShareButton, setShowShareButton] = useState(false);

  useEffect(() => {
    switch (sharing.type) {
      case 'all-images':
        setShowShareButton(true);
        break;
      case 'gallery':
        setShowShareButton(!!selectedGalleries.length);
        break;
      case 'selection':
        setShowShareButton(!!(sharing.identifier && sharing.identifier));
        break;
      default:
        setShowShareButton(false);
    }
  }, [sharing, selectedGalleries]);

  return (
    <div>
      <SingleSelectItem
        withDefaultPlaceholder
        value={sharing.type.replace('image', 'default') || 'default'}
        label={getTranslationKey('shareView.whatToShare')}
        onChange={selectSharingType}
        options={shareOptions}
      />

      {sharing.type === 'selection' && !!selectionOptions.length && (
        <SingleSelectItem
          withDefaultPlaceholder
          value={sharing.identifier || 'default'}
          label={getTranslationKey('shareView.whichSelection')}
          onChange={shareSelections}
          options={selectionOptions}
        />
      )}

      {sharing.type === 'gallery' && (
        <MultiSelectItem
          value={selectedGalleries}
          onChange={shareGalleries}
          options={galleriesOptions}
        />
      )}

      {isHiddenShown && (
        <Checkbox
          id="hidden-images-id"
          label={getTranslationKey('shareView.shareHiddenImages')}
          onClick={handleHiddenImagesToggle}
          checked={!onlyPublicImages}
          disabled={!sharing.type}
          type="rounded"
        />
      )}

      {showShareButton && <ShareSelectionButton copied={copied} onShare={onShare} />}
    </div>
  );
};
