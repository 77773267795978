import { useRef, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'modules/ScrShop/components/Forms';
import { Col, Form, Modal, Row } from 'reactstrap';
import { Button } from 'modules/ScrShop/components/Buttons';
import {
  resetPassword,
  setForgotPasswordFlowStatus,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShop/store/actions';
import { forgotPasswordFlowStatusSelector } from 'modules/ScrShop/store/selectors';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { getTranslationKey } from 'helpers/texting';

function ForgotPasswordModal() {
  const dispatch = useDispatch();
  const {
    emailDoesNotExists,
    showSuccessMessage,
    showForgotPasswordModal,
    redirectToLoginModal,
    email
  } = useSelector(forgotPasswordFlowStatusSelector);
  const ref = useRef<RefObject<HTMLInputElement>>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const setEmail = (email: string) =>
    dispatch(setForgotPasswordFlowStatus({ email: email.toLowerCase() }));
  const toggleModal = () =>
    dispatch(setForgotPasswordFlowStatus({ showForgotPasswordModal: !showForgotPasswordModal }));

  const onClose = () => {
    dispatch(
      setForgotPasswordFlowStatus({
        showForgotPasswordModal: !showForgotPasswordModal,
        showSuccessMessage: false,
        emailDoesNotExists: false,
        redirectToLoginModal: false,
        email: ''
      })
    );

    if (redirectToLoginModal) {
      dispatch(setForgotPasswordFlowStatus({ redirectToLoginModal: false }));
      dispatch(toggleLoginWithPasswordModalAction());
    }
  };

  const onEmailSubmit = (event: any) => {
    event.preventDefault();
    dispatch(resetPassword(email.toLowerCase()));
  };

  const onSuccessMessageSubmit = (event: any) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Modal centered isOpen={showForgotPasswordModal} toggle={toggleModal} size="md" zIndex={999999}>
      <ModalHeader toggle={toggleModal}>
        {getTranslationKey('forgotPasswordModal.title')}
      </ModalHeader>
      <ModalBody>
        {!showSuccessMessage ? (
          <Form onSubmit={onEmailSubmit}>
            <Row>
              <Col xs="12">
                <p>{getTranslationKey('forgotPasswordModal.text')}</p>
                <Input
                  ref={ref}
                  name="email"
                  type="email"
                  label={getTranslationKey('shop.forms.emailTitle')}
                  placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                  value={email}
                  onChange={(e: React.BaseSyntheticEvent) => setEmail(e.currentTarget.value)}
                />
              </Col>
              <Col xs="12">
                {emailDoesNotExists && (
                  <p className="text-center">
                    {getTranslationKey('loginAccountModal.emailNotFound')}
                  </p>
                )}
                <Button
                  className="sb-btn sb-btn-icon w-100-p text-center"
                  disabled={false}
                  type="submit"
                >
                  {getTranslationKey('forgotPasswordModal.resetPassword')}
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form onSubmit={onSuccessMessageSubmit}>
            <p>{getTranslationKey('forgotPasswordModal.successMessage')}</p>
            {/* input should be present in order to submit the form with an Enter */}
            <input
              autoFocus
              ref={hiddenInputRef}
              onBlur={() => hiddenInputRef?.current?.focus()}
              style={{ opacity: 0, height: 0, padding: 0, border: 0 }}
            />
            <Button
              className="sb-btn sb-btn-icon w-100-p text-center"
              disabled={false}
              onClick={onClose}
              type="submit"
            >
              {getTranslationKey('forgotPasswordModal.close')}
            </Button>
          </Form>
        )}
      </ModalBody>
    </Modal>
  );
}

export default ForgotPasswordModal;
