import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getTranslationKey } from 'helpers/texting';
import { ISelection } from 'old-store/types/gallery';
import { signUpFlyCustomer } from 'store/slices/endCustomer/actions';
import { saveSelection } from 'store/slices/selection/actions';

interface IDownloadPinModal {
  action?: 'startDownload' | 'next';
  url?: string;
  next?: () => void;
}

interface IInfoModal {
  title?: string;
  text1?: string;
  text2?: string;
  text3?: string;
  buttonText?: string;
}
interface IShareModal {
  singleImageShare?: boolean;
  singleGalleryShare?: boolean;
}

interface IIsOpen {
  isOpen: boolean;
}

interface ISelectionId {
  selectionId?: string;
  selectionName?: string;
}

interface IPhotobookId {
  photobookId?: string;
}

interface IPhotobookPageIdx {
  spreadIndex: number;
}

interface IModalsState {
  downloadPinModal: IDownloadPinModal & IIsOpen;
  infoModal: IInfoModal & IIsOpen;
  downloadModal: IIsOpen;
  photographerModal: IIsOpen;
  shareModal: IIsOpen & IShareModal;
  sendSelectionModal: IIsOpen & ISelectionId;
  passwordModal: IIsOpen;
  loginModal: IIsOpen;
  singleImageDownloadModal: IIsOpen;
  cancelSelectionModal: IIsOpen;
  saveSelectionModal: IIsOpen;
  selectionSentCongratsModal: IIsOpen;
  upsellModal: IIsOpen & ISelectionId;
  selectionActionsModal: IIsOpen;
  deletePhotobookConfirmationModal: IIsOpen & IPhotobookId;
  deleteSlideConfirmationModal: IIsOpen & IPhotobookPageIdx;
  discountModal: IIsOpen;
  newSlideLimitReachedModal: IIsOpen;
}

const initialState: IModalsState = {
  downloadPinModal: {
    isOpen: false
  },
  cancelSelectionModal: {
    isOpen: false
  },
  infoModal: {
    isOpen: false
  },
  downloadModal: {
    isOpen: false
  },
  photographerModal: {
    isOpen: false
  },
  shareModal: {
    isOpen: false,
    singleImageShare: false,
    singleGalleryShare: false
  },
  singleImageDownloadModal: {
    isOpen: false
  },
  sendSelectionModal: {
    isOpen: false
  },
  passwordModal: {
    isOpen: false
  },
  upsellModal: {
    isOpen: false
  },
  loginModal: {
    isOpen: false
  },
  saveSelectionModal: {
    isOpen: false
  },
  selectionSentCongratsModal: {
    isOpen: false
  },
  selectionActionsModal: {
    isOpen: false
  },
  deletePhotobookConfirmationModal: {
    isOpen: false
  },
  discountModal: {
    isOpen: false
  },
  newSlideLimitReachedModal: {
    isOpen: false
  },
  deleteSlideConfirmationModal: {
    isOpen: false,
    spreadIndex: 0
  }
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleDownloadPinModal(state, action: PayloadAction<IDownloadPinModal | undefined>) {
      state.downloadPinModal.isOpen = !state.downloadPinModal.isOpen;

      if (action.payload?.action) {
        state.downloadPinModal.action = action.payload.action;
      }

      if (action.payload?.url) {
        state.downloadPinModal.url = action.payload.url;
      }

      if (action.payload?.next) {
        state.downloadPinModal.next = action.payload.next;
      }
    },
    toggleInfoModal(state, action: PayloadAction<IInfoModal | undefined>) {
      state.infoModal.isOpen = !state.infoModal.isOpen;

      if (action.payload?.title) {
        state.infoModal.title = action.payload.title;
      }

      if (action.payload?.text1) {
        state.infoModal.text1 = action.payload.text1;
      }

      if (action.payload?.text2) {
        state.infoModal.text2 = action.payload.text2;
      }

      if (action.payload?.text3) {
        state.infoModal.text3 = action.payload.text3;
      }

      if (action.payload?.buttonText) {
        state.infoModal.buttonText = action.payload.buttonText;
      }
    },
    toggleDownloadModal(state) {
      state.downloadModal.isOpen = !state.downloadModal.isOpen;
    },
    togglePhotographerModal(state) {
      state.photographerModal.isOpen = !state.photographerModal.isOpen;
    },
    toggleCancelSelectionModal(state) {
      state.cancelSelectionModal.isOpen = !state.cancelSelectionModal.isOpen;
    },
    closePhotographerModal(state) {
      state.photographerModal.isOpen = false;
    },
    toggleShareModal(state, action: PayloadAction<IShareModal | undefined>) {
      state.shareModal.isOpen = !state.shareModal.isOpen;

      state.shareModal.singleImageShare = !!action.payload?.singleImageShare;
      state.shareModal.singleGalleryShare = !!action.payload?.singleGalleryShare;
    },
    toggleSendSelectionModal(state, action: PayloadAction<ISelection | undefined>) {
      state.sendSelectionModal.isOpen = !state.sendSelectionModal.isOpen;

      // this is used if we send an already saved selection
      if (action.payload) {
        state.sendSelectionModal.selectionId = action.payload._id;
        state.sendSelectionModal.selectionName = action.payload.name;
      }
    },
    toggleUpsellModal(state, action: PayloadAction<string | undefined>) {
      state.upsellModal.isOpen = !state.upsellModal.isOpen;

      state.upsellModal.selectionId = action.payload;
    },
    togglePasswordModal(state) {
      state.passwordModal.isOpen = !state.passwordModal.isOpen;
    },
    toggleLoginModal(state) {
      state.loginModal.isOpen = !state.loginModal.isOpen;
    },
    openSingleImageDownload(state) {
      state.singleImageDownloadModal.isOpen = true;
    },
    closeSingleImageDownload(state) {
      state.singleImageDownloadModal.isOpen = false;
    },
    toggleSaveSelectionModal(state) {
      state.saveSelectionModal.isOpen = !state.saveSelectionModal.isOpen;
    },
    toggleSelectionSentCongratsModal(state) {
      state.selectionSentCongratsModal.isOpen = !state.selectionSentCongratsModal.isOpen;
    },
    toggleSelectionActionsModal(state) {
      state.selectionActionsModal.isOpen = !state.selectionActionsModal.isOpen;
    },
    toggleDeletePhotobookConfirmationModal(state, action: PayloadAction<string | undefined>) {
      state.deletePhotobookConfirmationModal.isOpen =
        !state.deletePhotobookConfirmationModal.isOpen;

      state.deletePhotobookConfirmationModal.photobookId = action.payload;
    },
    toggleDiscountModal(state) {
      state.discountModal.isOpen = !state.discountModal.isOpen;
    },
    toggleNewSlideLimitReachedModal(state) {
      state.newSlideLimitReachedModal.isOpen = !state.newSlideLimitReachedModal.isOpen;
    },
    toggleDeleteSlideConfirmationModal(state, action: PayloadAction<number | undefined>) {
      state.deleteSlideConfirmationModal.isOpen = !state.deleteSlideConfirmationModal.isOpen;
      state.deleteSlideConfirmationModal.spreadIndex = action.payload || 0;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveSelection.fulfilled, (state, { payload }) => {
      state.sendSelectionModal.isOpen = false;
      state.loginModal.isOpen = false;
      state.saveSelectionModal.isOpen = false;

      if (payload?.withPasswordModal) state.passwordModal.isOpen = true;
      if (payload?.withCongratsModal) state.selectionSentCongratsModal.isOpen = true;
      else toast.success(getTranslationKey('selectionSaved'), { theme: 'colored' });
    });
    builder.addCase(signUpFlyCustomer.fulfilled, (state) => {
      state.passwordModal.isOpen = false;
      state.selectionSentCongratsModal.isOpen = false;
    });
  }
});

export const {
  toggleDownloadPinModal,
  toggleInfoModal,
  toggleDownloadModal,
  togglePhotographerModal,
  toggleShareModal,
  toggleSendSelectionModal,
  togglePasswordModal,
  toggleLoginModal,
  toggleSaveSelectionModal,
  toggleCancelSelectionModal,
  toggleSelectionSentCongratsModal,
  toggleUpsellModal,
  toggleSelectionActionsModal,
  openSingleImageDownload,
  toggleDeletePhotobookConfirmationModal,
  closeSingleImageDownload,
  toggleDiscountModal,
  toggleNewSlideLimitReachedModal,
  toggleDeleteSlideConfirmationModal
} = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
