import { SagaIterator } from 'redux-saga';
import { takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchPhotobookFonts, fetchSlideshows, fetchTemplateFonts } from 'old-store/actions';
import { trackEventAction } from 'old-store/actions/analytics';
import { fetchSlideshowsSaga } from 'old-store/sagas/slideshowsCollections';
import { trackEventSaga } from './analytics';
import { fetchTemplateFontsSaga } from './templates';
import { fetchPhotobookFontsSaga } from './photobookFonts';

export const coreSaga = function* (): SagaIterator {
  // Slideshow
  yield takeLatest(fetchSlideshows, fetchSlideshowsSaga);
  yield takeLatest(fetchTemplateFonts, fetchTemplateFontsSaga);
  yield takeLatest(fetchPhotobookFonts, fetchPhotobookFontsSaga);

  // Analytics
  yield takeEvery(trackEventAction, trackEventSaga);
};
