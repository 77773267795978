import { createSlice } from '@reduxjs/toolkit';

import { fetchBanners } from 'store/slices/banners/actions';
import type { IShopCoupon } from 'types/coupons';

interface IBannersState {
  couponCodeAdvertising: {
    coupon: IShopCoupon;
    endsAt: string;
    amountOfImagesBetweenBanners: number;
  };
  freeShipping: {
    startsFrom: number;
  };
  couponCodeAfterPurchase: {
    coupon: IShopCoupon;
  };
  emptyCartProductSuggestion: {
    enabled: true;
  };
}

const initialState: Partial<IBannersState> = {};

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBanners.fulfilled, (_, { payload }) => payload);
  }
});

export const bannersReducer = bannersSlice.reducer;
