import { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getTranslationKey } from 'helpers/texting';
import { useCloseIconUrl } from './icons/CloseIcon';

const AlertStyled = styled(Alert)`
  .close {
    background-color: #333333 !important;
    vertical-align: middle;
    margin-right: 8px;
    border: none;
    mask-image: url('${(props) => props.closeIconUrl}');
    -webkit-mask-image: url('${(props) => props.closeIconUrl}');
    mask-repeat: no-repeat;
    width: 10px;
    height: 10px;

    &:before {
      content: '';
    }

    span {
      display: none;
    }
  }
  background-color: none !important;
`;

const AlertWrapper = ({ dismissAppInstallationHint }) => {
  const closeIconUrl = useCloseIconUrl();

  return (
    <AlertStyled
      closeIconUrl={closeIconUrl}
      color="warning"
      isOpen
      onClick={dismissAppInstallationHint}
      toggle={dismissAppInstallationHint}
    >
      {getTranslationKey('appinstallation.alertHint')}
    </AlertStyled>
  );
};

class AppInstallationAlertHint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAppInstallationHint: false
    };
    this.dismissAppInstallationHint = this.dismissAppInstallationHint.bind(this);
  }

  componentDidMount() {
    if (this.props.user.appInstallationAlertHint === true) {
      this.setState({ showAppInstallationHint: true }, () =>
        setTimeout(this.dismissAppInstallationHint, 8000)
      );
    }
  }

  dismissAppInstallationHint() {
    this.setState({ showAppInstallationHint: false });
  }

  render() {
    return this.state.showAppInstallationHint ? (
      <Row className="justify-content-center text-center cursor-pointer">
        <Col xl="3" lg="4" md="12" xs="12" className="app-installation-alert">
          <AlertWrapper dismissAppInstallationHint={this.dismissAppInstallationHint} />
        </Col>
      </Row>
    ) : null;
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, null)(AppInstallationAlertHint);
