import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';
import { v4 as uuidv4 } from 'uuid';

export const StickersPreview = ({ backgroundImage, galleryId }) => {
  const verticalImage = useGalleryImage('vertical', 6, galleryId);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = verticalImage._id ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              {/* Left card */}
              <path d="M 196 685 L 204 1526 L 649 1514 L 256 683" />
              {/* Left card */}

              {/* Middle card */}
              <path d="M 159 425 L 296 713 L 511 622 L 361 333 M 183 439 C 179 432 178 432 185 429 L 341 367 C 350 362 350 366 353 371 L 473 591 C 473 596 488 610 472 617 L 312 686 C 297 693 296 675 292 670" />
              <path d="M 712 532 L 852 817 L 968 764 L 1098 359 M 1098 359 L 1137 344 L 1154 387 L 1095 369" />
              <path d="M 838 852 L 895 994 L 847 1142 L 605 1250 L 488 1006" />
              <path d="M 826 1208 L 728 1513 L 624 1302" />
              <path d="M 957 798 L 924 902 L 886 831" />
              {/* Middle card */}
            </mask>
          }
        />
      </PreviewImageContainer>

      {/* Left card */}
      <PreviewImageContainer
        top={44.3}
        left={-30}
        width={65}
        height={56.3}
        transform="rotate(-0.4deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={61.1}
        left={13}
        width={20}
        height={20}
        zIndex={1}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={76.4}
        left={13}
        width={20}
        height={20}
        zIndex={1}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={91.8}
        left={13}
        width={20}
        height={20}
        zIndex={1}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={107}
        left={13}
        width={20}
        height={20}
        zIndex={1}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={91.8}
        left={33}
        width={20}
        height={20}
        zIndex={1}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={107}
        left={33}
        width={20}
        height={20}
        zIndex={1}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>
      {/* Left card */}

      {/* Middle card */}
      <PreviewImageContainer
        top={21}
        left={4}
        width={13.7}
        height={20.8}
        borderRadius={5}
        transform="rotate(-13deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1150 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={26.5}
        left={9.3}
        width={17}
        height={24.1}
        transform="rotate(-25.6deg)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={22.2}
        left={24}
        width={18}
        height={21}
        transform="matrix3d(1,0.065,0,0.0001,0.03,1,0,0,0,0,1,0,0,0,0,1) rotate(-26.9deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1330" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={16}
        left={37.7}
        width={18}
        height={21.2}
        transform="matrix3d(1,0.065,0,0.0001,0.03,1,0,0,0,0,1,0,0,0,0,1) rotate(-26.9deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1330" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={9.8}
        left={51.5}
        width={18}
        height={20.8}
        transform="matrix3d(0.95,0.099,0,0,0.03,1,0,0,0,0,1,0,0,0,0,1) rotate(-26.9deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.6}
        left={19.7}
        width={16.8}
        height={20.53}
        transform="matrix3d(0.95,0.085,0,0,0.025,1,0,0,0,0,1,0,0,0,0,1) rotate(-26deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={41.5}
        left={33.8}
        width={16.4}
        height={20.8}
        transform="matrix3d(0.95,0.06,0,0,0.02,1,0,0,0,0,1,0,0,0,0,1) rotate(-26deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={35.3}
        left={47.5}
        width={16}
        height={20.9}
        zIndex={1}
        transform="matrix3d(0.95,0.06,0,0,0.02,1,0,0,0,0,1,0,0,0,0,1) rotate(-26deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={28}
        left={60.3}
        width={21}
        height={21}
        zIndex={1}
        transform="matrix3d(0.95,0.06,0,0,0.02,1,0,0,0,0,1,0,0,0,0,1) rotate(-26deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={56}
        left={56}
        width={21}
        height={28}
        zIndex={1}
        transform="matrix3d(0.95,0.05,0,0,0.02,1,0,0,0,0,1,0,0,0,0,1) rotate(66deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={77}
        left={58}
        width={18}
        height={21}
        zIndex={1}
        transform="matrix3d(0.95,0.05,0,0,0.02,1,0,0,0,0,1,0,0,0,0,1) rotate(66deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={45}
        left={82}
        width={18}
        height={28}
        zIndex={1}
        transform="matrix3d(0.95,0.05,0,0,0.02,1,0,0,0,0,1,0,0,0,0,1) rotate(66deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>
      {/* Middle card */}

      {/* Right card */}
      <PreviewImageContainer
        top={34.4}
        left={103.3}
        width={22}
        height={31}
        transform="rotate(107.9deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={55.2}
        left={96.6}
        width={23.5}
        height={31}
        transform="rotate(107.7deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={44}
        left={132.9}
        width={22}
        height={31}
        transform="rotate(107.9deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={64.9}
        left={126.1}
        width={23.32}
        height={31}
        transform="rotate(107.7deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1170" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={92}
        left={69.2}
        width={20}
        height={19.9}
        transform="rotate(-162deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1230" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={96.8}
        left={84.5}
        width={20}
        height={19.7}
        transform="rotate(-162deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1230" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={101.7}
        left={99.7}
        width={20}
        height={19.9}
        transform="rotate(-162deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1230" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={110.9}
        left={63.2}
        width={20}
        height={19.9}
        transform="rotate(-162deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1230" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={115.6}
        left={78.2}
        width={20}
        height={19.9}
        transform="rotate(-162deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1250" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={120.5}
        left={93.4}
        width={20}
        height={19.9}
        transform="rotate(-162deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 1250" />
      </PreviewImageContainer>
      {/* Right card */}
    </>
  );
};
