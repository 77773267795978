import { ReactComponent as SaveSelectionIconDark } from 'assets/images/icons/dark - save selection.svg';
import { ReactComponent as SaveSelectionIconSimpleFiligree } from 'assets/images/icons/simple filigree - save selection.svg';
import { ReactComponent as SaveSelectionIconSpringVibes } from 'assets/images/icons/spring - save selection.svg';
import { ReactComponent as SaveSelectionIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - save selection.svg';
import { ReactComponent as SaveSelectionIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - save selection.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const SaveSelectionIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={SaveSelectionIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={SaveSelectionIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={SaveSelectionIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={SaveSelectionIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={SaveSelectionIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={SaveSelectionIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={SaveSelectionIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={SaveSelectionIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={SaveSelectionIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={SaveSelectionIconSimpleFiligree} {...props} />
  })();
