import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

export const CalendarProPortrait_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 7, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 483 412 L 554 980 L 1736 821 L 1506 106" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={22}
        left={20}
        width={90}
        height={70}
        zIndex={1}
        transform="rotate(-11deg)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>
    </>
  );
};
