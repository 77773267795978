export const getCookiesByteSize = (): number => encodeURIComponent(document.cookie).length;

export const clearAllCookies = (skipDeletion?: Array<string>): void => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
    if (!skipDeletion?.includes(name.trim())) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
    }
  }
};

export const getCookie = (name: string): string | null => {
  let c_value: string | null = document.cookie;
  let c_start = c_value.indexOf(' ' + name + '=');
  if (c_start === -1) {
    c_start = c_value.indexOf(name + '=');
  }

  if (c_start === -1) {
    c_value = null;
  } else {
    c_start = c_value.indexOf('=', c_start) + 1;
    let c_end = c_value.indexOf(';', c_start);

    if (c_end === -1) {
      c_end = c_value.length;
    }

    c_value = unescape(c_value.substring(c_start, c_end));
  }

  return c_value;
};

export const setCookie = (name: string, value: string, exdays?: number | null) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + (exdays ?? 0));

  const formattedValue =
    escape(value) + (exdays == null ? '' : '; expires=' + expirationDate.toUTCString());

  let cookieDomain = '';

  if (window.location.hostname !== 'localhost') cookieDomain = `;domain=.${window.location.host}`;

  document.cookie = name + '=' + formattedValue + ';path=/' + cookieDomain;
};
