import { useTheme } from 'hooks/useTheme';
import { ModalBody as ModalBodyReactsrap, ModalBodyProps } from 'reactstrap';
import styled from 'styled-components';
import { intersection } from 'lodash';
import { useLocation } from 'react-router-dom';

const Body = styled(ModalBodyReactsrap)`
  ${(props) =>
    props.color &&
    `
  color: ${props.color};
  `}
`;

const ModalBody = (props: ModalBodyProps) => {
  const [theme] = useTheme();
  const { pathname } = useLocation();
  const isShop = intersection(pathname.split('/'), ['shop']).length;

  return <Body color={!isShop && theme.colors.modalFontColor} {...props} />;
};

export { ModalBody };
