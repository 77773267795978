import { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getTranslationKey } from 'helpers/texting';
import * as actions from '../old-store/actions';

class AppInstallationScrAppBookPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDevice: null
    };

    this.declineAppInstallation = this.declineAppInstallation.bind(this);
    this.acceptAppInstallation = this.acceptAppInstallation.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.icon = null;
  }

  UNSAFE_componentWillMount() {
    if (!document.body.className.includes('white-background')) {
      document.body.className += ' white-background';
    }
  }

  componentWillUnmount() {
    if (document.body.className.includes('white-background')) {
      document.body.className = document.body.className.replace('white-background', '');
    }
  }

  selectDevice(selectedDevice) {
    this.setState({ selectedDevice });
  }

  declineAppInstallation() {
    this.props.showAppInstallationAlertHint();
    this.props.setAppInstallationState('declined');
  }

  acceptAppInstallation() {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          window.logToServer('User accepted the A2HS prompt');
        } else {
          console.logToServer('User dismissed the A2HS prompt');
          this.declineAppInstallation();
        }
        window.deferredPrompt.deferredPrompt = null;
      });
    } else {
      this.props.setAppInstallationState('accepted');
    }
  }

  showAppInstallationManual() {
    const { environment } = this.props;
    const { selectedDevice } = this.state;

    if (selectedDevice === 'ios' || environment.isIDevice) {
      return this.renderIosAppInstallationManual(environment);
    }
    if (selectedDevice === 'android' || environment.isAndroidDevice) {
      return this.renderAndroidAppInstallationManual(environment);
    }

    return this.renderNotSupportedDevice();
  }

  renderNotSupportedDevice() {
    return (
      <div>
        <Alert color="danger">{getTranslationKey('appinstallation.notSupportedDevice')}</Alert>
        <p className="m-t-10">
          {getTranslationKey('appinstallation.notSupportedDeviceDescription1')}
          <br />
          <br />
          {getTranslationKey('appinstallation.notSupportedDeviceDescription2')}
        </p>
        <button onClick={() => this.selectDevice('ios')} className="site-button w-100-p">
          {getTranslationKey('appinstallation.ios.device')}
        </button>
        <button onClick={() => this.selectDevice('android')} className="site-button w-100-p m-t-10">
          {getTranslationKey('appinstallation.android.device')}
        </button>
      </div>
    );
  }

  renderAndroidAppInstallationManual(environment) {
    if (environment.isNotChromeOnAndroid) {
      return (
        <div>
          <Alert color="danger">{getTranslationKey('appinstallation.wrongBrowser')}</Alert>
          <p className="m-t-10">{getTranslationKey('appinstallation.android.openInChrome')}</p>
          <p className="m-t-10">{window.location.href.replace(window.location.search, '')}</p>
          <a
            className="site-button"
            href={`intent://${window.location.href.replace(
              'https://',
              ''
            )}#Intent;scheme=https;package=com.android.chrome;end`}
          >
            {getTranslationKey('appinstallation.android.openChrome')}
          </a>
        </div>
      );
    }

    return (
      <div>
        <h2 className="mb-5 app-installation-title">
          {getTranslationKey('appinstallation.headlineManual')}
        </h2>
        {this.renderIcon()}

        <ol className="app-installation-steps mt-5">
          <li>
            {getTranslationKey('appinstallation.android.manual1')}:{' '}
            <span className="android-menu-button m-t-15 m-b-15" />
            <br />
            {getTranslationKey('appinstallation.android.manual2')}{' '}
            <strong>{getTranslationKey('appinstallation.android.manual3')}</strong>{' '}
            {getTranslationKey('appinstallation.android.tap')}
          </li>
          <li>{getTranslationKey('appinstallation.android.manual4')}</li>
        </ol>
      </div>
    );
  }

  renderIosAppInstallationManual(environment) {
    if (environment.isNotSafariOnIos) {
      return (
        <div>
          <Alert color="danger">{getTranslationKey('appinstallation.wrongBrowser')}</Alert>
          <p className="m-t-10">{getTranslationKey('appinstallation.ios.openInSafari')}</p>
          <p className="m-t-10">{window.location.href.replace(window.location.search, '')}</p>
          <a className="site-button" href="x-web-search://" target="_blank">
            {getTranslationKey('appinstallation.ios.openSafari')}
          </a>
        </div>
      );
    }

    return (
      <div>
        <h2 className="mb-5 app-installation-title">
          {getTranslationKey('appinstallation.headlineManual')}
        </h2>

        {this.renderIcon()}

        <ol className="app-installation-steps mt-5">
          <li>
            {getTranslationKey('appinstallation.ios.manual1')}:{' '}
            <span className="ios-safari-share-button m-t-5 m-b-5" />
          </li>
          <li>
            {getTranslationKey('appinstallation.ios.select')}{' '}
            <strong>{getTranslationKey('appinstallation.ios.manual3')}</strong>
          </li>
        </ol>

        <hr />
      </div>
    );
  }

  renderIcon() {
    const { collection, images } = this.props;

    if (images && images.images && images.images.length) {
      if (!this.icon) {
        let appIcon = images.images.find((image) => image.isIcon);
        if (!appIcon) appIcon = images.images[0]; // eslint-disable-line

        this.icon = appIcon;
      }

      return (
        <div>
          <img className="site-appicon" alt="app-icon" src={this.icon.url} />
          <br />
          <p>{collection.title}</p>
        </div>
      );
    }

    return null;
  }

  renderAppInstallationHint() {
    return (
      <div>
        <h2 className="app-installation-title">{getTranslationKey('appinstallation.headline')}</h2>
        <p className="pt-3 pb-3 mb-0 text-left f-s-17">
          {getTranslationKey('appinstallation.descriptionScrAppBook')}
        </p>

        <ul className="app-installation-benefits mb-4">
          {Object.keys(getTranslationKey('appinstallation.benefits')).map((benefit) => (
            <li key={benefit}>{getTranslationKey('appinstallation.benefits')[benefit]}</li>
          ))}
        </ul>

        {this.renderIcon()}
        <div className="mt-4">
          <button
            onClick={this.acceptAppInstallation}
            className={classnames('app-installation-button w-100-p')}
          >
            {getTranslationKey('appinstallation.showManual')}
          </button>
          <p
            onClick={this.declineAppInstallation}
            onKeyDown={this.declineAppInstallation}
            className="m-t-20 text-underline cursor-pointer "
          >
            {getTranslationKey('appinstallation.noThanks')}
          </p>
        </div>
      </div>
    );
  }

  render() {
    const {
      user: { appInstallation },
      collection: { templateName }
    } = this.props;

    if (window.location.pathname.includes('/photobook')) return;

    return (
      <div className="app-installation-container white-background h-100 w-100">
        <Row className="justify-content-center text-center">
          <Col className="m-t-20" xl="4" lg="6" md="6" sm="12" xs="12">
            {appInstallation === 'unset'
              ? this.renderAppInstallationHint()
              : this.showAppInstallationManual()}

            {appInstallation !== 'unset' && (
              <p
                onClick={this.declineAppInstallation}
                onKeyDown={this.declineAppInstallation}
                className={classnames('m-t-15 cursor-pointer sticky-bottom-center p-b-20', {
                  'white-background': templateName && !templateName.includes('SpringVibes')
                })}
              >
                <span className="text-underline">{getTranslationKey('appinstallation.abort')}</span>
              </p>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ collection, images, environment, user }) {
  return { collection, images, user, environment };
}

export default connect(mapStateToProps, actions)(AppInstallationScrAppBookPage);
