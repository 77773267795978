import {
  Sticker8x,
  Sticker8x_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Stickers/Stickers8x';
import {
  Sticker10x,
  Sticker10x_0,
  Sticker10x_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Stickers/Stickers10x';
import {
  Sticker16x,
  Sticker16x_0,
  Sticker16x_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Stickers/Stickers16x';

export const stickers = {
  'pfe-8er-sticker-plus': Sticker8x,
  'pfe-8er-sticker-plus_0': Sticker8x_1,
  'pfe-8er-sticker-plus_1': Sticker8x,

  'pfe-10er-sticker': Sticker10x,
  'pfe-10er-sticker_0': Sticker10x_0,
  'pfe-10er-sticker_1': Sticker10x_1,
  'pfe-10er-sticker_2': Sticker10x,

  'pfe-16er-sticker': Sticker16x,
  'pfe-16er-sticker_0': Sticker16x_0,
  'pfe-16er-sticker_1': Sticker16x_1,
  'pfe-16er-sticker_2': Sticker16x
};
