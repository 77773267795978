import { useState, useLayoutEffect } from 'react';

import VisibilitySensor from 'react-visibility-sensor';
import { Container } from 'reactstrap';

import { BottomMenu } from 'components/BottomMenu';
import { Footer } from 'components/Footer';
import { CloseIcon } from 'components/icons/CloseIcon';
import { Imprint } from 'components/Imprint';
import { BackgroundProfileImage } from 'components/Photographer/BackgroundProfileImage';
import { SocialIcons } from 'components/Footer/Socials';
import { useBrandInfo } from 'components/Photographer/useBrandInfo';
import { getCleanUrl } from 'helpers/url';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTheme } from 'hooks/useTheme';
import { logStatistics } from 'old-store/actions';
import { ACTIONS, ACTIONS_DATA, SCOPES } from 'old-store/constants/statistics';
import { getLogoUrl } from 'helpers/get-logo-url';

import classnames from 'classnames';
import styled from 'styled-components';

interface IProps {
  viewClassName?: string;
  closeModal?: () => void;
  isModalOpen?: boolean;
}

const PhotographerWrapper = styled.div`
  .company,
  .user-name,
  .footer-title,
  i {
    color: ${(props) => props.theme.colors.modalFontColor};
  }
`;

const CloseButton = styled.div`
  position: absolute;
  z-index: 9;
  top: 5px;
  right: 5px;
  font-size: 28px;
  display: block;
`;

// This component has tons of different conditions, it's not clear what is happening here
// And it's super easy to break something as well

// TODO
// Create a map of separate templates, for example:
// const templateMap = {
//   SimpleFiligree: <SimpleFiligreeTemplate />,
//   VickyBaumannBoho: <VickyBaumannBohoTemplate />
// };
// And put all the logic from this file into these components

export const Photographer: React.FC<IProps> = ({ viewClassName, closeModal, isModalOpen }) => {
  const dispatch = useAppDispatch();
  const { collectionCreator, templateName } = useAppSelector((state) => state.collection);
  const userBrands = useAppSelector((state) => state.userBrand);
  const { isApp } = useAppSelector((state) => state.environment);
  const { logo, studioLogo, logoLight } = useAppSelector((state) => state.userBrand);
  const [theme] = useTheme();

  const [logoUrl, setLogoUrl] = useState(getLogoUrl(templateName, logoLight, logo));

  const brandInfo = useBrandInfo();

  useLayoutEffect(() => {
    const logoUrl = getLogoUrl(templateName, logoLight, logo);
    if (logoUrl) setLogoUrl(logoUrl);
  }, []);

  // the first part "d-lg-none" is only shown on small devices in the footer, the app view in the card and in the photographer popup
  // for the popup a d-lg-block is added so that it's also visible on big screens

  // viewClassName is only set by the photographer poup. If that is set, the lower part does not have to be shown

  return (
    <PhotographerWrapper
      theme={theme}
      className="photographer animated fadeIn"
      style={{ position: 'relative' }}
    >
      {isModalOpen && (
        <CloseButton
          data-cy="closeButton"
          onClick={closeModal}
          onKeyDown={closeModal}
          role="button"
          tabIndex={0}
        >
          <CloseIcon size={20} color={theme.colors.modalFontColor} aria-hidden="true" />
        </CloseButton>
      )}
      <div
        className={classnames('photographer-view', viewClassName, {
          'd-lg-none': !isApp && !isModalOpen
        })}
      >
        <BackgroundProfileImage
          url={studioLogo ? studioLogo.url : `${window.CDN_URL}/public/contact-default-3.jpg`}
        >
          {logoUrl && (
            <div id="rounded-logo-photographer" className="rounded-logo">
              <img src={logoUrl} alt="" />
            </div>
          )}
        </BackgroundProfileImage>

        <VisibilitySensor
          partialVisibility
          resizeCheck
          delayedCall
          minTopValue={40}
          onChange={(isVisible) => {
            if (
              !viewClassName && // indicates that this is not in the modal
              isVisible &&
              // @ts-ignore
              !window.trackedContactDataViewScroll
            ) {
              dispatch(
                logStatistics({
                  action: ACTIONS.VIEW,
                  scope: SCOPES.CONTACT,
                  actionData: ACTIONS_DATA.PAGE
                })
              );
              // @ts-ignore
              window.trackedContactDataViewScroll = true;
            }
          }}
        >
          <Container>
            <ul className="mb-0 p-l-65">
              {brandInfo.company && (
                <li>
                  <h3 className="company">{brandInfo.company}</h3>
                  <hr style={{ marginRight: '-30px' }} />
                </li>
              )}
              <li className="user-name-container">
                <span className="user-name">
                  {collectionCreator.firstName || ''} {collectionCreator.lastName || ''}{' '}
                </span>
              </li>
              {userBrands.tel && userBrands.tel.length > 0 && (
                <li>
                  <a
                    href={`tel:${userBrands.tel}`}
                    onClick={() => {
                      dispatch(
                        logStatistics({
                          action: ACTIONS.CLICK,
                          scope: SCOPES.CONTACT,
                          actionData: ACTIONS_DATA.TEL
                        })
                      );
                    }}
                  >
                    {userBrands.tel}
                  </a>
                </li>
              )}
              {brandInfo.email && (
                <li>
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`mailto:${brandInfo.email}`, '_self');

                      dispatch(
                        logStatistics({
                          action: ACTIONS.CLICK,
                          scope: SCOPES.CONTACT,
                          actionData: ACTIONS_DATA.EM
                        })
                      );
                    }}
                    href="#"
                  >
                    {brandInfo.email}
                  </a>
                </li>
              )}
              {userBrands.website && userBrands.website.length > 0 && (
                <li>
                  {/* eslint-disable-next-line */}
                  <a
                    target="_blank"
                    href={getCleanUrl(userBrands.website)}
                    onClick={() => {
                      dispatch(
                        logStatistics({
                          action: ACTIONS.CLICK,
                          scope: SCOPES.CONTACT,
                          actionData: ACTIONS_DATA.WWW
                        })
                      );
                    }}
                  >
                    {userBrands.website}
                  </a>
                </li>
              )}
            </ul>

            <SocialIcons socials={userBrands.socials} popup />
          </Container>
        </VisibilitySensor>
      </div>
      {!viewClassName && (
        <div
          className={classnames(
            {
              'd-lg-block': !isApp
            },
            'photographer-view d-none m-b-50'
          )}
        >
          <Footer />
        </div>
      )}
      <div className={classnames('imprint-container', { 'd-lg-block': isApp })}>
        <Imprint separator="|" />
      </div>

      {isApp && <BottomMenu />}
    </PhotographerWrapper>
  );
};
