import styled from 'styled-components';
import { Modal } from 'reactstrap';

import { UpsellingSection } from 'components/UpsellModal/UpsellingSection';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { toggleSendSelectionModal, toggleUpsellModal } from 'store/slices/modals';
import type { ISelection } from 'old-store/types/gallery';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { getTranslationKey } from 'helpers/texting';
import { useEffect } from 'react';
import { trackEventAction } from 'old-store/actions';

const StyledModal = styled(Modal)`
  .modal-header {
    background: #fff !important;
    color: #000 !important;
    border-bottom: 0 !important;
    .modal-title {
      padding: 4px 30px !important;
      text-transform: initial !important;
      width: 100% !important;
      &:before {
        content: none !important;
      }
    }
  }
  .modal-content {
    background: #fff;
    .close {
      background: #000;
    }
  }
  .modal-body {
    color: #000;
  }
`;

const StyledHeader = styled(ModalHeader)`
  padding-top: 30px;
  padding-bottom: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const UpsellModal = () => {
  const collection = useAppSelector((state) => state.collection);
  const { currentSelection } = useAppSelector((state) => state.selection);
  const { upselling } = useAppSelector((state) => state.shop);
  const selection = useAppSelector((state) => state.selection);
  const { upsellModal } = useAppSelector((state) => state.modals);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (upsellModal.isOpen) {
      dispatch(
        trackEventAction({
          name: 'modal-window-opened',
          payload: {
            modal_id: 'upsell',
            url: window.location.href
          }
        })
      );
    }
  }, [upsellModal.isOpen]);

  const toggleModal = () => dispatch(toggleUpsellModal());

  const getSelection = (): ISelection => {
    if (upsellModal.selectionId) {
      return (
        selection.all.find((selection) => selection._id === upsellModal.selectionId) ??
        currentSelection
      );
    }

    return currentSelection;
  };

  return (
    <StyledModal
      centered
      isOpen={upsellModal.isOpen}
      toggle={toggleModal}
      size="lg"
      zIndex={999999}
      className="upsell-modal"
    >
      <StyledHeader toggle={toggleModal}>
        {getTranslationKey('shop.upselling.selectPackage')}
      </StyledHeader>
      <ModalBody>
        {collection &&
          collection.digitalShop &&
          collection.digitalShop.enabled &&
          collection.digitalShop.digitalPricingList && (
            <UpsellingSection selection={getSelection()} toggleSaveSelectionModal={toggleModal} />
          )}
        {upselling.packages.length === 0 && !upselling.fetchingPackages && (
          <ButtonWrapper>
            <button
              className="sb-btn"
              onClick={() => {
                toggleModal();
                dispatch(toggleSendSelectionModal());
              }}
            >
              {getTranslationKey('selections.saveSelectionModal.alternative')}
            </button>
          </ButtonWrapper>
        )}
      </ModalBody>
    </StyledModal>
  );
};
