import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrderSelector } from 'modules/ScrShop/store/selectors/orders';
import { useHistory } from 'react-router-dom';

export function NotCompletedPaymentFlow() {
  const lastOrder = useSelector(getOrderSelector);
  const [redirectLink, setRedirectLink] = useState('');
  const history = useHistory();

  useEffect(() => {
    const { status } = lastOrder;
    if (status === 'payment_pending') {
      setRedirectLink('/shop/payment');
    }
    if (status === 'payment_complete') {
      setRedirectLink('/shop/confirm');
    }
    if (redirectLink.length) {
      history.push(redirectLink);
    }
	}, [] ); // eslint-disable-line

  return null;
}
