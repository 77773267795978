import { useDispatch, useSelector } from 'react-redux';
import {
  getCollectionStore,
  getSiteGlobalClassNameSelector
} from 'modules/ScrShop/store/selectors';
import { setSiteGlobalClass } from 'modules/ScrShop/store/actions';
import { useEffect } from 'react';

export function ShopRoutesFunctions() {
  const dispatch = useDispatch();
  const { templateName } = useSelector(getCollectionStore);
  const bodyClassName = useSelector(getSiteGlobalClassNameSelector);
  const { body } = document;
  useEffect(() => {
    if (templateName && !bodyClassName.length) {
      dispatch(setSiteGlobalClass(body.className));
      body.className = '';

      return function test() {
        return null;
      };
    }

    return () => {
      body.className = bodyClassName;
      dispatch(setSiteGlobalClass(''));
    };
  }, [dispatch, bodyClassName]); //eslint-disable-line

  return null;
}
