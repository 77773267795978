interface IMediaPoints {
  desktopXXl: string;
  desktopXl: string;
  desktopLg: string;
  desktop: string;
  mobile: string;
  mediumSmall: string;
  small: string;
}

export const media: IMediaPoints = {
  desktopXXl: '1024px',
  desktopXl: '992px',
  desktopLg: '960px',
  desktop: '771px',
  mobile: '770px',
  mediumSmall: '560px',
  small: '400px'
};

export default media;
