import { IImage } from 'old-store/types/gallery';

interface ITitleImage {
  titleImage: IImage | undefined;
  filteredImagesWithoutTitleImg: IImage[];
}

export const getTitleImage = (images: IImage[] = [], collectionType: string): ITitleImage => {
  const fallbackObj = { titleImage: undefined, filteredImagesWithoutTitleImg: images };
  // we only want to show title images on the classic template
  if (collectionType !== 'ScrAppBook') return fallbackObj;

  const isSmallScreen = document.body.clientWidth <= 992;

  const newImages = [...images];
  const titleImageIdx = newImages.findIndex((img) =>
    isSmallScreen ? img.isSmallScreenTitleImage : img.isTitleImage
  );

  const titleImage = images[titleImageIdx];

  if (titleImage) {
    newImages.splice(titleImageIdx, 1);
    return { titleImage, filteredImagesWithoutTitleImg: newImages };
  }

  if (images.length > 0) return { titleImage: images[0], filteredImagesWithoutTitleImg: newImages };

  return fallbackObj;
};
