import {
  ClassicPrint9x13,
  ClassicPrint10x15_0,
  ClassicPrint15x23_0,
  ClassicPrint20x30_0,
  ClassicPrint30x45_0
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Prints/ClassicPrints';
import {
  FineartPrint15x23_0,
  FineartPrint15x23_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Prints/FineArtPrints';
import {
  SquarePrint15x15_0,
  SquarePrint15x15_1,
  SquarePrint15x15_2,
  SquarePrint20x20_0
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Prints/SquarePrints';

export const prints = {
  'classic-print-9x13': ClassicPrint9x13,
  'classic-print-9x13_0': ClassicPrint9x13,

  'pfe-classic-print-9x13': ClassicPrint9x13,
  'pfe-classic-print-9x13_0': ClassicPrint9x13,

  'classic-print-10x15': ClassicPrint10x15_0,
  'classic-print-10x15_0': ClassicPrint10x15_0,
  'classic-print-13x18': ClassicPrint10x15_0,
  'classic-print-13x18_0': ClassicPrint10x15_0,
  'classic-print-13x19': ClassicPrint10x15_0,
  'classic-print-13x19_0': ClassicPrint10x15_0,
  'classic-print-15x23': ClassicPrint15x23_0,
  'classic-print-15x23_0': ClassicPrint15x23_0,
  'classic-print-20x30': ClassicPrint20x30_0,
  'classic-print-20x30_0': ClassicPrint20x30_0,
  'classic-print-30x45': ClassicPrint30x45_0,
  'classic-print-30x45_0': ClassicPrint30x45_0,

  'pfe-classic-print-10x15': ClassicPrint10x15_0,
  'pfe-classic-print-10x15_0': ClassicPrint10x15_0,
  'pfe-classic-print-13x18': ClassicPrint10x15_0,
  'pfe-classic-print-13x18_0': ClassicPrint10x15_0,
  'pfe-classic-print-13x19': ClassicPrint10x15_0,
  'pfe-classic-print-13x19_0': ClassicPrint10x15_0,
  'pfe-classic-print-15x23': ClassicPrint15x23_0,
  'pfe-classic-print-15x23_0': ClassicPrint15x23_0,
  'pfe-classic-print-20x30': ClassicPrint20x30_0,
  'pfe-classic-print-20x30_0': ClassicPrint20x30_0,
  'pfe-classic-print-30x45': ClassicPrint30x45_0,
  'pfe-classic-print-30x45_0': ClassicPrint30x45_0,

  'fineart-print-15x23': FineartPrint15x23_0,
  'fineart-print-15x23_0': FineartPrint15x23_0,
  'fineart-print-15x23_1': FineartPrint15x23_1,

  'square-print-15x15': SquarePrint15x15_0,
  'square-print-15x15_0': SquarePrint15x15_0,
  'square-print-15x15_1': SquarePrint15x15_1,
  'square-print-15x15_2': SquarePrint15x15_2,
  'square-print-20x20': SquarePrint20x20_0,
  'square-print-20x20_0': SquarePrint20x20_0,
  'square-print-20x20_1': SquarePrint15x15_1,
  'square-print-20x20_2': SquarePrint15x15_2,
  'square-print-30x30': SquarePrint15x15_1,
  'square-print-30x30_0': SquarePrint15x15_1,
  'square-print-30x30_1': SquarePrint15x15_1,
  'square-print-30x30_2': SquarePrint15x15_2,

  'pfe-square-print-15x15': SquarePrint15x15_0,
  'pfe-square-print-15x15_0': SquarePrint15x15_0,
  'pfe-square-print-15x15_1': SquarePrint15x15_1,
  'pfe-square-print-15x15_2': SquarePrint15x15_2,
  'pfe-square-print-21x21': SquarePrint20x20_0,
  'pfe-square-print-21x21_0': SquarePrint20x20_0,
  'pfe-square-print-21x21_1': SquarePrint15x15_1,
  'pfe-square-print-21x21_2': SquarePrint15x15_2,
  'pfe-square-print-30x30': SquarePrint15x15_1,
  'pfe-square-print-30x30_0': SquarePrint15x15_1,
  'pfe-square-print-30x30_1': SquarePrint15x15_1,
  'pfe-square-print-30x30_2': SquarePrint15x15_2,

  'pic-classic-print-9x13': ClassicPrint9x13,
  'pic-classic-print-9x13_0': ClassicPrint9x13,
  'pic-classic-print-10x15': ClassicPrint10x15_0,
  'pic-classic-print-10x15_0': ClassicPrint10x15_0,
  'pic-classic-print-13x18': ClassicPrint10x15_0,
  'pic-classic-print-13x18_0': ClassicPrint10x15_0,
  'pic-classic-print-13x19': ClassicPrint10x15_0,
  'pic-classic-print-13x19_0': ClassicPrint10x15_0,
  'pic-classic-print-15x23': ClassicPrint15x23_0,
  'pic-classic-print-15x23_0': ClassicPrint15x23_0,
  'pic-classic-print-20x30': ClassicPrint20x30_0,
  'pic-classic-print-20x30_0': ClassicPrint20x30_0,
  'pic-classic-print-30x45': ClassicPrint30x45_0,
  'pic-classic-print-30x45_0': ClassicPrint30x45_0,

  'pic-square-print-15x15': SquarePrint15x15_0,
  'pic-square-print-15x15_0': SquarePrint15x15_0,
  'pic-square-print-15x15_1': SquarePrint15x15_1,
  'pic-square-print-15x15_2': SquarePrint15x15_2,
  'pic-square-print-21x21': SquarePrint20x20_0,
  'pic-square-print-21x21_0': SquarePrint20x20_0,
  'pic-square-print-21x21_1': SquarePrint15x15_1,
  'pic-square-print-21x21_2': SquarePrint15x15_2,
  'pic-square-print-30x30': SquarePrint15x15_1,
  'pic-square-print-30x30_0': SquarePrint15x15_1,
  'pic-square-print-30x30_1': SquarePrint15x15_1,
  'pic-square-print-30x30_2': SquarePrint15x15_1
};
