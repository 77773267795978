import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

const PreviewBorder = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  border: 0.1px solid white;
  border-radius: 8%;
`;

export const Sticker10x = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={17.5} left={-8.8} width={23.8} height={32.5}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer top={17.5} left={15} width={23.8} height={32.5}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer top={17.5} left={38.8} width={23.8} height={32.5}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer top={17.5} left={62.6} width={23.8} height={32.5}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer top={17.5} left={86.4} width={23.8} height={32.5}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2000" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50} left={-8.8} width={24} height={31.6}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50} left={15} width={24} height={31.6}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50} left={38.8} width={23.8} height={31.6}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50} left={62.6} width={23.8} height={31.6}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>

      <PreviewImageContainer top={50} left={86.4} width={23.8} height={31.6}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1900" />
      </PreviewImageContainer>
    </>
  );
};

export const Sticker10x_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 4, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 705 767 L 728 769 L 719 820 C 717 829 720 833 724 834 L 1378 899 C 1420 889 1399 856 1431 468 L 1453 469 L 1441 917 L 691 846 L 705 767" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={24.8}
        left={9}
        width={46.8}
        height={27.2}
        transform="matrix3d(1,0.082,0,0,-0.43,1,0,-0.0003,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={5.4}
        left={51.9}
        width={46}
        height={41}
        transform="matrix3d(1,0.156,0,0,-0.12,1.16,0,0,0,0,1,0,0,0,0,1)"
        borderRadius={7}
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={9}
        left={54}
        width={54}
        height={43}
        zIndex={1}
        transform="matrix3d(1,0.24,0,0.00039,-0.2,1.11,0,-0.0002,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={31.3}
        left={96.9}
        width={46.5}
        height={29.8}
        transform="matrix3d(1,0.082,0,0,-0.04,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={52.6}
        left={0}
        width={48.8}
        height={32.1}
        transform="matrix3d(1,0.083,0,0,-0.43,1,0,-0.0003,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={56.5}
        left={47.5}
        width={51.7}
        height={32.2}
        transform="matrix3d(1,0.09,0,0,-0.336,1,0,-0.00033,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={61}
        left={97.9}
        width={49.5}
        height={32}
        transform="matrix3d(1,0.09,0,0,-0.23,1,0,-0.0004,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>
    </>
  );
};

export const Sticker10x_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={6}
        left={0}
        width={46}
        height={29.6}
        transform="matrix3d(1,0.455,0,0,-1.6,1,0,-0.0002,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={27}
        left={46}
        width={47}
        height={29.7}
        transform="matrix3d(1,0.45,0,0,-1.6,1,0,-0.0002,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.6}
        left={91.9}
        width={49}
        height={30.5}
        transform="matrix3d(1,0.47,0,0,-1.5,1,0,-0.0002,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={56.4}
        left={-2}
        width={48}
        height={28.7}
        transform="matrix3d(1,0.456,0,0,-1.6,1,0,-0.0002,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={78.23}
        left={46}
        width={48}
        height={28.6}
        transform="matrix3d(1,0.47,0,0,-1.5,1,0,-0.0002,0,0,1,0,0,0,0,1)"
      >
        <PreviewBorder />
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 800" />
      </PreviewImageContainer>
    </>
  );
};
