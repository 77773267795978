import { useLocation } from 'react-router-dom';
import { ModalHeader as ModalHeaderReactsrap, ModalHeaderProps } from 'reactstrap';
import styled from 'styled-components';
import { intersection } from 'lodash';

import { useTheme } from 'hooks/useTheme';
import { theme as defaultTheme } from 'assets/css/theme';
import { useCloseIconUrl } from 'components/icons/CloseIcon';

const Header = styled(ModalHeaderReactsrap)<{ closeIconUrl: string }>`
  width: 100%;
  .close {
    background-color: ${(props) => props.theme.colors.modalFontColor} !important;
    border: none;
    mask-image: url('${(props) => props.closeIconUrl}');
    -webkit-mask-image: url('${(props) => props.closeIconUrl}');
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;

    &:before {
      content: '';
    }

    span {
      display: none;
    }
  }
  background-color: ${(props) => props.theme.colors.modalBg} !important;
`;

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const [theme] = useTheme();
  const closeIconUrl = useCloseIconUrl();
  const { pathname } = useLocation();
  const isShop = intersection(pathname.split('/'), ['shop']).length;

  return <Header closeIconUrl={closeIconUrl} theme={isShop ? defaultTheme : theme} {...props} />;
};
