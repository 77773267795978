import { get as _get, orderBy as _orderBy } from 'lodash';
import naturalCompare from 'natural-compare-lite';

import { getTranslationKey } from 'helpers/texting';
import db from './database';

export const deleteCache = async () => {
  try {
    window.deleteAllCookies();

    if (window.caches) {
      const { caches } = window;

      const cacheNames = await caches.keys();
      for (let i = 0; i < cacheNames.length; i++) {
        const cache = await caches.open(cacheNames[i]); //eslint-disable-line

        if (cache && cache.keys) {
          const requests = await cache.keys(); //eslint-disable-line

          for (let j = 0; j < requests.length; j++) {
            await cache.delete(requests[j]); //eslint-disable-line
          }
        }
      }

      db.sites.delete(window.SITE_ID).catch((e) => {
        if (e && e.inner) {
          console.log('DataBaseError helper deleteCache inner:', e.inner, e);
        } else console.log('DataBaseError helper deleteCache:', e);
      });

      return true;
    }

    return false;
  } catch (e) {
    alert(getTranslationKey('resetAppCacheError'));
    console.log(e);

    return false;
  }
};

export const stripTrailingSlash = (str) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }

  return str;
};

export const getGalleryImagesSorted = (gallery, images) => {
  try {
    let galleryImages = images.filter((img) => img.gallery_id === gallery._id);

    if (gallery.sortBy === 'originalImageName') {
      galleryImages = galleryImages.sort((i1, i2) =>
        naturalCompare(i1.originalImageName, i2.originalImageName)
      );

      if (gallery.sortOrder === 'desc') galleryImages = galleryImages.reverse();
    } else {
      galleryImages = _orderBy(galleryImages, [gallery.sortBy], [gallery.sortOrder]);
    }

    return galleryImages;
  } catch (e) {
    return null;
  }
};

export const getGalleryImagesAndTitleImage = (gallery, images) => {
  try {
    const isSmallScreen = document.body.clientWidth <= 992;
    let galleryImages = [];
    let allImages = [];
    let titleImage = null;

    images.forEach((img) => {
      if (img.gallery_id === gallery._id) {
        if (isSmallScreen ? img.isSmallScreenTitleImage : img.isTitleImage) {
          titleImage = img;
        } else galleryImages.push(img);
      }
    });

    if (!titleImage) titleImage = images.find((img) => img.isTitleImage);

    if (!titleImage && galleryImages.length) {
      titleImage = galleryImages.splice(0, 1)[0]; //eslint-disable-line
    }

    if (gallery.sortBy === 'originalImageName') {
      galleryImages = galleryImages.sort((i1, i2) =>
        naturalCompare(i1.originalImageName, i2.originalImageName)
      );

      if (gallery.sortOrder === 'desc') galleryImages = galleryImages.reverse();
    } else {
      galleryImages = _orderBy(galleryImages, [gallery.sortBy], [gallery.sortOrder]);
    }

    allImages = titleImage ? [titleImage, ...galleryImages] : galleryImages;

    return { galleryImages, titleImage, allImages };
  } catch (e) {
    return null;
  }
};

export const getImagesByTypes = (types = [], images) => {
  try {
    const foundImages = {};
    images.forEach((image) => {
      if (types.contains(image.type)) foundImages[image.type] = image;
    });

    return foundImages;
  } catch (e) {
    return null;
  }
};

export const getImageByType = (type, images) => {
  try {
    return images.filter((image) => image.gallery_id === type)[0];
  } catch (e) {
    return null;
  }
};

export const getAppIcon = (images) => {
  try {
    const filteredImages = images.filter(
      (image) => image.extension.toLowerCase() !== 'gif' && !image.isCollectionHeader
    );

    if (filteredImages) {
      let appIcon = filteredImages.find((image) => image.isIcon);
      if (!appIcon) appIcon = filteredImages.find((i) => !i.isTemplateImage);

      return appIcon || {};
    }

    return {};
  } catch (e) {
    return {};
  }
};

export const addMetaIconTags = (appIcon) => {
  try {
    if (!appIcon || !appIcon.url) return;

    if (!document.querySelector("meta[property='og:image']")) {
      const link = document.createElement('meta');
      link.setAttribute('property', 'og:image');
      link.content = appIcon?.url_M || appIcon?.url;
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if (!document.querySelector("meta[property='twitter:image']")) {
      const link2 = document.createElement('meta');
      link2.setAttribute('property', 'twitter:image');
      link2.content = appIcon?.url_M || appIcon?.url;
      document.getElementsByTagName('head')[0].appendChild(link2);
    }

    if (!document.getElementById('link-shortcut-icon')) {
      const link3 = document.createElement('link');
      link3.id = 'link-shortcut-icon';
      link3.rel = 'shortcut icon';
      link3.href = appIcon?.url_M || appIcon?.url;
      document.head.appendChild(link3);
    }

    if (!document.getElementById('link-icon')) {
      const link4 = document.createElement('link');
      link4.id = 'link-icon';
      link4.rel = 'icon';
      link4.href = appIcon?.url_M || appIcon?.url;
      document.head.appendChild(link4);
    }

    if (!document.getElementById('link-apple-touch-icon')) {
      const link5 = document.createElement('link');
      link5.id = 'link-apple-touch-icon';
      link5.rel = 'apple-touch-icon';
      link5.href = appIcon?.url_M || appIcon?.url;
      document.head.appendChild(link5);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getGalleryImages = (images, galleryId) => {
  try {
    let i = images.filter((image) => image.gallery_id === galleryId);

    if (i.length && i[0].order !== undefined && i[0].order != null) {
      i = _orderBy(i, ['order'], ['asc']);
    }

    return i;
  } catch (e) {
    return null;
  }
};

export const detectIE = () => {
  try {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');

      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const dateOrderResolver = (sortBy, image) => {
  try {
    const datesSorts = ['createdAt', 'deleteAt', 'recordedAt', 'uploadedAt'];
    const isInDateSort = datesSorts.includes(sortBy);
    const sortValue = _get(image, sortBy, null);

    return isInDateSort ? new Date(sortValue).getTime() : sortValue;
  } catch (e) {
    console.log(e);

    return image[sortBy];
  }
};

export const getSiteTracking = (t) => {
  if (t) return Boolean(Number(t));

  return true;
};
