import {
  CalendarProLandscape_0,
  CalendarProLandscape_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Calendar/CalendarProLandscape';
import { CalendarProPortrait_0 } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Calendar/CalendarProPortrait';
import {
  CalendarBasic_0,
  CalendarBasic_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Calendar/CalendarBasic';
import {
  CalendarHD_0,
  CalendarHD_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Calendar/CalendarHD';

export const calendar = {
  'calendar-pro-landscape': CalendarProLandscape_0,
  'calendar-pro-landscape_0': CalendarProLandscape_0,
  'calendar-pro-landscape_1': CalendarProLandscape_1,
  'calendar-pro-portrait': CalendarProPortrait_0,
  'calendar-pro-portrait_0': CalendarProPortrait_0,
  'calendar-pro-portrait_1': CalendarProLandscape_1,
  'calendar-hd': CalendarHD_0,
  'calendar-hd_0': CalendarHD_0,
  'calendar-hd_1': CalendarHD_1,
  'calendar-basic': CalendarBasic_0,
  'calendar-basic_0': CalendarBasic_0,
  'calendar-basic_1': CalendarBasic_1
};
