import styled from 'styled-components';
import { deleteCache } from 'helper';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTheme } from 'hooks/useTheme';
import { setAppInstallationState } from 'old-store/actions';
import { getTranslationKey } from 'helpers/texting';

interface IRenderProps {
  separator: React.ReactNode;
}

interface IProps {
  separator?: React.ReactNode;
}

// const Tracking: React.FC<IRenderProps> = ({ separator }) => {
//   const { tracking, isGotPhoto } = useAppSelector((state) => state.site);
//   const { trackingStatus } = useAppSelector((state) => state.collection);

//   const dispatch = useAppDispatch();

//   if (!tracking || (tracking && !tracking.ga && !tracking.fb)) return null;

//   return (
//     <div className="inline">
//       {separator}
//       <button
//         onClick={(e) => {
//           e.preventDefault();
//           const status =
//             trackingStatus === 'opt-in' || (isGotPhoto && trackingStatus !== 'opt-out')
//               ? 'out'
//               : 'in';

//           dispatch(setTrackingStatus(status));
//         }}
//       >
//         {trackingStatus === 'opt-in' || (isGotPhoto && trackingStatus !== 'opt-out')
//           ? Texts[window.LANGUAGE].optOutFooter
//           : Texts[window.LANGUAGE].optInFooter}
//       </button>
//     </div>
//   );
// };

const ImprintWrapper = styled.footer`
  span {
    color: ${(props) => props.theme.colors.modalFontColor};
  }

  a {
    color: ${(props) => props.theme.colors.modalFontColor};
    &:hover {
      color: ${(props) => props.theme.colors.hoverColor};
    }
  }
`;

const AppInstallation: React.FC<IRenderProps> = ({ separator }) => {
  const { showAppInstall } = useAppSelector((state) => state.collection);
  // @ts-ignore
  const { displayWebAppInstallation } = useAppSelector((state) => state.environment);

  const dispatch = useAppDispatch();

  if (!showAppInstall || !displayWebAppInstallation) return null;

  return (
    <div className="inline">
      {separator}
      <a
        href="/"
        onClick={async (e) => {
          e.preventDefault();

          dispatch(setAppInstallationState('forced'));
        }}
      >
        {getTranslationKey('appinstallation.showManualFooter')}
      </a>
    </div>
  );
};

const AppCacheReset: React.FC<IRenderProps> = ({ separator }) => {
  if (!window.caches) return null;

  return (
    <div className="inline">
      {separator}
      <a
        href="/"
        onClick={async (e) => {
          e.preventDefault();

          if (window.confirm(getTranslationKey('resetAppCacheConfirmText'))) {
            await deleteCache();
            setTimeout(() => window.location.reload(), 1000);
          }
        }}
      >
        {getTranslationKey('resetAppCache')}
      </a>
    </div>
  );
};

export const Imprint: React.FC<IProps> = ({ separator = <span>&bull;</span> }) => {
  const [theme] = useTheme();

  return (
    <ImprintWrapper theme={theme} className="text-center">
      <div className="cursor-pointer copyright m-t-0 pointer">
        <a href={`/imprint/${window.SITE_ID}`} target="_blank" rel="noopener noreferrer">
          {getTranslationKey('imprint')}
        </a>
        {separator}
        <a href={`/privacy/${window.SITE_ID}`} target="_blank" rel="noopener noreferrer">
          {getTranslationKey('privacy')}
        </a>
        {/* <Tracking separator={separator} /> */}
        <AppInstallation separator={separator} />
        <AppCacheReset separator={separator} />
      </div>
    </ImprintWrapper>
  );
};
