import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';
import media from 'assets/css/media';

const Expand = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  svg {
    margin-right: 5px;
    transition: transform 0.4s linear;
  }
`;
const ExpandContent = styled.div`
  overflow: hidden;
  transition: all 0.3s linear;
`;
const ExpandSection = styled.div<{
  $isExpanded: boolean;
  $isHideTitleOnDesktop?: boolean;
}>`
  ${ExpandContent} {
    max-height: ${(props) => (props.$isExpanded ? '100vh' : 0)};
  }
  svg {
    transform: rotate(${(props) => (props.$isExpanded ? '180deg' : 0)});
  }
  ${Expand} {
    @media (min-width: ${media.desktop}) {
      display: ${(props) => (props.$isHideTitleOnDesktop ? 'none' : 'flex')};
    }
  }
`;

interface IProps {
  $isOpen: boolean;
  callback: () => void;
  title: string;
  isHideTitleOnDesktop?: boolean;
}

export const ExpandableSection: React.FC<IProps> = ({
  $isOpen,
  children,
  callback,
  title,
  isHideTitleOnDesktop
}) => (
  <ExpandSection $isExpanded={$isOpen} $isHideTitleOnDesktop={isHideTitleOnDesktop}>
    <Expand onClick={callback}>
      <FaAngleDown />
      {title}
    </Expand>
    <ExpandContent>{children}</ExpandContent>
  </ExpandSection>
);
