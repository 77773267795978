import { Modal } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { getTranslationKey } from 'helpers/texting';
import { useEffect } from 'react';
import { fetchMessage, trackMessageView } from 'store/slices/messages/actions';
import { selectIsOpenedMessage, selectMessage } from 'store/slices/messages/selectors';
import { IMessage, toggleMessage } from 'store/slices/messages';
import { ActionBox } from 'components/MainMenu/Box';
import styled from 'styled-components';

const ModalHeaderStyled = styled(ModalHeader)`
  h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const ModalBodyStyled = styled(ModalBody)`
  max-height: 80vh;
  overflow-y: auto;
`;

const LinkButton: React.FC<{ cta: IMessage['cta'] }> = ({ cta }) => {
  if (!cta) return null;

  let { url } = cta;
  let target = '_self';

  if (url) {
    url = url.replace('https://', '').replace('http://', '');
    switch (cta.type) {
      case 'http':
        url = `https://${url}`;
        target = '_blank';
        break;
      case 'mailto':
        url = `mailto:${url}`;
        break;
      case 'tel':
        url = `tel:${url}`;
        break;
      default:
    }
  }

  return (
    <ActionBox href={url} target={target} className="sb-btn">
      {cta.text || getTranslationKey('ctaPreviewPlaceholder')}
    </ActionBox>
  );
};

export const Message: React.FC = () => {
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => state.collection);
  const message = useAppSelector(selectMessage);
  const isOpened = useAppSelector(selectIsOpenedMessage);

  const toggleMessageModal = () => dispatch(toggleMessage(!isOpened));

  useEffect(() => {
    // "/p/" - stands for portfolio page
    if (window.location.pathname.includes('/p/')) return;

    dispatch(fetchMessage());
  }, []);

  useEffect(() => {
    if (message && isOpened) {
      dispatch(trackMessageView(message.id));
    }
  }, [message, isOpened]);

  if (!message) return null;

  return (
    <Modal
      isOpen={isOpened}
      toggle={() => toggleMessageModal()}
      wrapClassName={type === 'ScrAppBook2' ? 'light' : 'modal-dark'}
      size="md"
      centered
      zIndex={999999}
      className="text-center"
    >
      {message.title && (
        <ModalHeaderStyled toggle={() => toggleMessageModal()}>{message.title}</ModalHeaderStyled>
      )}
      <ModalBodyStyled>
        <p>{message.message}</p>
        {message.publicImageKey && (
          <img
            className="img-fluid mb-3"
            // @ts-ignore
            src={`${window.CDN_URL}/public/${message.publicImageKey}`}
            alt=""
          />
        )}

        <LinkButton cta={message.cta} />
      </ModalBodyStyled>
    </Modal>
  );
};
