export default {
  COLLECTION: 'collection',
  SLIDESHOW: 'slideshow',
  GALLERY: 'gallery',
  IMAGE: 'image',
  CONTACT: 'contact',
  SELECTION: 'selection',
  APP: 'app',
  SHARE: 'share'
};
