import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTranslationKey } from 'helpers/texting';
import { toast } from 'react-toastify';
import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';

export interface IVerifyPasswordProps {
  password: string;
}

interface IFetchPortfolioCollectionsProps {
  skip?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
}

export const fetchPortfolio = createAsyncThunk('portfolios/fetchPortfolio', async () => {
  try {
    const { result } = await Api.Portfolios.fetchPortfolio();

    return result;
  } catch (e) {
    console.log(e);
  }
});

export const fetchPortfolioCollections = createAsyncThunk(
  'portfolios/fetchPortfolioCollections',
  async ({ skip = 0, limit = 30, search = '', sortBy = '' }: IFetchPortfolioCollectionsProps) => {
    try {
      const { result } = await Api.Portfolios.fetchPortfolioCollections(
        skip,
        limit,
        search,
        sortBy
      );

      return result;
    } catch (e) {
      console.log(e);
    }
  }
);

export const verifyPassword = createAsyncThunk(
  'portfolios/verifyPassword',
  async ({ password }: IVerifyPasswordProps, { dispatch }) => {
    try {
      const redirectUrl = `${window.location.origin}/${window.SITE_ID}`;
      const response = await Api.Portfolios.verifyPassword(password, redirectUrl);
      dispatch(fetchPortfolio());
      dispatch(fetchPortfolioCollections({}));

      ApiErrors.checkOnApiError(response);

      return response.result;
    } catch (e) {
      console.log(e);

      toast.error(getTranslationKey('enterPasswordModal.passwordIsInvalid'), {
        theme: 'colored'
      });
    }
  }
);
