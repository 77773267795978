import {
  Pic_PictureWithFrame20x20_0,
  Pic_PictureWithFrame20x20_2,
  PictureWithFrame20x20_0,
  PictureWithFrame30x21_0,
  PictureWithFrame30x30_0,
  PictureWithFrame42x30_0,
  PictureWithFrame70x50_0
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/PictureWithFrame';
import {
  Canvas60x90_0,
  Canvas20x30_0,
  Canvas40x60_0,
  Canvas20x20_0,
  Canvas20x20_2
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/Canvas';
import {
  Aludibond_0,
  Aludibond_1,
  Aludibond_2
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/Aludibond';
import {
  Acryglas_0,
  Acryglas_10x15_0,
  Acryglas_10x15_2
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/Acryglas';
import {
  WoodPrint_0,
  WoodPrint_1
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/WoodPrints';
import {
  FramedPoster_0,
  FramedPoster_1,
  FramedPoster_2
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/FramedPoster';
import {
  MixPix_0,
  MixPix_1,
  MixPix_2,
  MixPix_3,
  MixPix_4
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Wallart/MixPix';

export const wallart = {
  'canvas-20x30': Canvas20x30_0,
  'canvas-20x30_0': Canvas20x30_0,
  'canvas-40x60': Canvas40x60_0,
  'canvas-40x60_0': Canvas40x60_0,
  'canvas-60x90': Canvas60x90_0,
  'canvas-60x90_0': Canvas60x90_0,

  'pfe-canvas-20x30': Canvas20x30_0,
  'pfe-canvas-20x30_0': Canvas20x30_0,
  'pfe-canvas-40x60': Canvas40x60_0,
  'pfe-canvas-40x60_0': Canvas40x60_0,
  'pfe-canvas-60x90': Canvas60x90_0,
  'pfe-canvas-60x90_0': Canvas60x90_0,

  'np-acrylglas-30x21': Acryglas_0,
  'np-acrylglas-30x21_0': Acryglas_0,
  'np-acrylglas-42x30': Acryglas_0,
  'np-acrylglas-42x30_0': Acryglas_0,
  'np-acrylglas-70x50': Acryglas_0,
  'np-acrylglas-70x50_0': Acryglas_0,

  'pfe-acrylglas-20x30': Acryglas_0,
  'pfe-acrylglas-20x30_0': Acryglas_0,
  'pfe-acrylglas-40x60': Acryglas_0,
  'pfe-acrylglas-40x60_0': Acryglas_0,
  'pfe-acrylglas-60x90': Acryglas_0,
  'pfe-acrylglas-60x90_0': Acryglas_0,

  'np-aludibond-30x21': Aludibond_0,
  'np-aludibond-30x21_0': Aludibond_0,
  'np-aludibond-30x21_1': Aludibond_1,
  'np-aludibond-30x21_2': Aludibond_2,
  'np-aludibond-42x30_0': Aludibond_0,
  'np-aludibond-42x30_1': Aludibond_1,
  'np-aludibond-42x30_2': Aludibond_2,
  'np-aludibond-70x50_0': Aludibond_0,
  'np-aludibond-70x50_1': Aludibond_1,
  'np-aludibond-70x50_2': Aludibond_2,

  'pfe-aludibond-20x30': Aludibond_0,
  'pfe-aludibond-20x30_0': Aludibond_0,
  'pfe-aludibond-20x30_1': Aludibond_1,
  'pfe-aludibond-20x30_2': Aludibond_2,
  'pfe-aludibond-40x60_0': Aludibond_0,
  'pfe-aludibond-40x60_1': Aludibond_1,
  'pfe-aludibond-40x60_2': Aludibond_2,
  'pfe-aludibond-60x90_0': Aludibond_0,
  'pfe-aludibond-60x90_1': Aludibond_1,
  'pfe-aludibond-60x90_2': Aludibond_2,

  'picture-w-frame-20x20': PictureWithFrame20x20_0,
  'picture-w-frame-20x20_0': PictureWithFrame20x20_0,
  'picture-w-frame-30x21': PictureWithFrame30x21_0,
  'picture-w-frame-30x21_0': PictureWithFrame30x21_0,
  'picture-w-frame-30x30': PictureWithFrame30x30_0,
  'picture-w-frame-30x30_0': PictureWithFrame30x30_0,
  'picture-w-frame-42x30': PictureWithFrame42x30_0,
  'picture-w-frame-42x30_0': PictureWithFrame42x30_0,
  'picture-w-frame-70x50': PictureWithFrame70x50_0,
  'picture-w-frame-70x50_0': PictureWithFrame70x50_0,

  'pfe-holzdruck-20x30': WoodPrint_0,
  'pfe-holzdruck-20x30_0': WoodPrint_0,
  'pfe-holzdruck-20x30_1': WoodPrint_1,
  'pfe-holzdruck-40x60_0': WoodPrint_0,
  'pfe-holzdruck-40x60_1': WoodPrint_1,
  'pfe-holzdruck-60x90_0': WoodPrint_0,
  'pfe-holzdruck-60x90_1': WoodPrint_1,

  'pic-canvas-20x20': Canvas20x20_0,
  'pic-canvas-20x20_0': Canvas20x20_0,
  'pic-canvas-20x20_2': Canvas20x20_2,
  'pic-canvas-20x60_0': Canvas20x20_0,
  'pic-canvas-20x60_2': Canvas20x20_2,
  'pic-canvas-30x20_0': Canvas20x20_0,
  'pic-canvas-30x20_2': Canvas20x20_2,
  'pic-canvas-30x40_0': Canvas20x20_0,
  'pic-canvas-30x40_2': Canvas20x20_2,
  'pic-canvas-30x60_0': Canvas20x20_0,
  'pic-canvas-30x60_2': Canvas20x20_2,
  'pic-canvas-40x20_0': Canvas20x20_0,
  'pic-canvas-40x20_2': Canvas20x20_2,
  'pic-canvas-40x40_0': Canvas20x20_0,
  'pic-canvas-40x40_2': Canvas20x20_2,
  'pic-canvas-40x60_0': Canvas20x20_0,
  'pic-canvas-40x60_2': Canvas20x20_2,
  'pic-canvas-40x80_0': Canvas20x20_0,
  'pic-canvas-40x80_2': Canvas20x20_2,
  'pic-canvas-60x60_0': Canvas20x20_0,
  'pic-canvas-60x60_2': Canvas20x20_2,
  'pic-canvas-60x80_0': Canvas20x20_0,
  'pic-canvas-60x80_2': Canvas20x20_2,
  'pic-canvas-60x90_0': Canvas20x20_0,
  'pic-canvas-60x90_2': Canvas20x20_2,
  'pic-canvas-75x50_0': Canvas20x20_0,
  'pic-canvas-75x50_2': Canvas20x20_2,
  'pic-canvas-50x100_0': Canvas20x20_0,
  'pic-canvas-50x100_2': Canvas20x20_2,
  'pic-canvas-75x100_0': Canvas20x20_0,
  'pic-canvas-75x100_2': Canvas20x20_2,

  'pic-canvas-8x8': Canvas20x20_0,
  'pic-canvas-8x8_0': Canvas20x20_0,
  'pic-canvas-8x8_2': Canvas20x20_2,
  'pic-canvas-10x8_0': Canvas20x20_0,
  'pic-canvas-10x8_2': Canvas20x20_2,
  'pic-canvas-12x8_0': Canvas20x20_0,
  'pic-canvas-12x8_2': Canvas20x20_2,
  'pic-canvas-14x8_0': Canvas20x20_0,
  'pic-canvas-14x8_2': Canvas20x20_2,
  'pic-canvas-12x10_0': Canvas20x20_0,
  'pic-canvas-12x10_2': Canvas20x20_2,
  'pic-canvas-12x12_0': Canvas20x20_0,
  'pic-canvas-12x12_2': Canvas20x20_2,
  'pic-canvas-16x12_0': Canvas20x20_0,
  'pic-canvas-16x12_2': Canvas20x20_2,
  'pic-canvas-14x10_0': Canvas20x20_0,
  'pic-canvas-14x10_2': Canvas20x20_2,
  'pic-canvas-16x16_0': Canvas20x20_0,
  'pic-canvas-16x16_2': Canvas20x20_2,
  'pic-canvas-20x12_0': Canvas20x20_0,
  'pic-canvas-20x12_2': Canvas20x20_2,
  'pic-canvas-20x16_0': Canvas20x20_0,
  'pic-canvas-20x16_2': Canvas20x20_2,
  'pic-canvas-24x16_0': Canvas20x20_0,
  'pic-canvas-24x16_2': Canvas20x20_2,
  'pic-canvas-24x20_0': Canvas20x20_0,
  'pic-canvas-24x20_2': Canvas20x20_2,
  'pic-canvas-30x16_0': Canvas20x20_0,
  'pic-canvas-30x16_2': Canvas20x20_2,

  'pic-acrylglas-10x15': Acryglas_10x15_0,
  'pic-acrylglas-10x15_0': Acryglas_10x15_0,
  'pic-acrylglas-10x15_2': Acryglas_10x15_2,
  'pic-acrylglas-20x15_0': Acryglas_10x15_0,
  'pic-acrylglas-20x15_2': Acryglas_10x15_2,
  'pic-acrylglas-20x20_0': Acryglas_10x15_0,
  'pic-acrylglas-20x20_2': Acryglas_10x15_2,
  'pic-acrylglas-20x30_0': Acryglas_10x15_0,
  'pic-acrylglas-20x30_2': Acryglas_10x15_2,
  'pic-acrylglas-30x30_0': Acryglas_10x15_0,
  'pic-acrylglas-30x30_2': Acryglas_10x15_2,
  'pic-acrylglas-40x30_0': Acryglas_10x15_0,
  'pic-acrylglas-40x30_2': Acryglas_10x15_2,
  'pic-acrylglas-40x40_0': Acryglas_10x15_0,
  'pic-acrylglas-40x40_2': Acryglas_10x15_2,
  'pic-acrylglas-60x20_0': Acryglas_10x15_0,
  'pic-acrylglas-60x20_2': Acryglas_10x15_2,
  'pic-acrylglas-60x30_0': Acryglas_10x15_0,
  'pic-acrylglas-60x30_2': Acryglas_10x15_2,
  'pic-acrylglas-60x40_0': Acryglas_10x15_0,
  'pic-acrylglas-60x40_2': Acryglas_10x15_2,
  'pic-acrylglas-60x60_0': Acryglas_10x15_0,
  'pic-acrylglas-60x60_2': Acryglas_10x15_2,
  'pic-acrylglas-75x50_0': Acryglas_10x15_0,
  'pic-acrylglas-75x50_2': Acryglas_10x15_2,
  'pic-acrylglas-80x20_0': Acryglas_10x15_0,
  'pic-acrylglas-80x20_2': Acryglas_10x15_2,
  'pic-acrylglas-80x40_0': Acryglas_10x15_0,
  'pic-acrylglas-80x40_2': Acryglas_10x15_2,
  'pic-acrylglas-80x60_0': Acryglas_10x15_0,
  'pic-acrylglas-80x60_2': Acryglas_10x15_2,
  'pic-acrylglas-90x60_0': Acryglas_10x15_0,
  'pic-acrylglas-90x60_2': Acryglas_10x15_2,
  'pic-acrylglas-100x50_0': Acryglas_10x15_0,
  'pic-acrylglas-100x50_2': Acryglas_10x15_2,
  'pic-acrylglas-100x75_0': Acryglas_10x15_0,
  'pic-acrylglas-100x75_2': Acryglas_10x15_2,

  'pic-acrylglas-8x8': Acryglas_10x15_0,
  'pic-acrylglas-8x8_0': Acryglas_10x15_0,
  'pic-acrylglas-8x8_2': Acryglas_10x15_2,
  'pic-acrylglas-12x8_0': Acryglas_10x15_0,
  'pic-acrylglas-12x8_2': Acryglas_10x15_2,
  'pic-acrylglas-16x12_0': Acryglas_10x15_0,
  'pic-acrylglas-16x12_2': Acryglas_10x15_2,
  'pic-acrylglas-20x16_0': Acryglas_10x15_0,
  'pic-acrylglas-20x16_2': Acryglas_10x15_2,
  'pic-acrylglas-24x20_0': Acryglas_10x15_0,
  'pic-acrylglas-24x20_2': Acryglas_10x15_2,
  'pic-acrylglas-36x12_0': Acryglas_10x15_0,
  'pic-acrylglas-36x12_2': Acryglas_10x15_2,

  'pic-passepartout-picture-20x20': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-20x20_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-20x20_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x20_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x20_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x20_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x20_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-30x20_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-30x20_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-30x20_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-30x20_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-30x20_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-30x20_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x30_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-40x30_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x30_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x30_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x30_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x30_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x40_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-40x40_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x40_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x40_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x40_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-40x40_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-60x40_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-60x40_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-60x40_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-60x40_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-60x40_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-60x40_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-80x60_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-80x60_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-80x60_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-80x60_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-80x60_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-80x60_6': Pic_PictureWithFrame20x20_2,

  'pic-passepartout-picture-8x8': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-8x8_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-8x8_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-8x8_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-8x8_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-8x8_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-8x8_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-12x8_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-12x8_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-12x8_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-12x8_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-12x8_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-12x8_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x16_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-16x16_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x16_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x16_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x16_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x16_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x12_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-16x12_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x12_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x12_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x12_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-16x12_6': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x16_0': Pic_PictureWithFrame20x20_0,
  'pic-passepartout-picture-20x16_2': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x16_3': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x16_4': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x16_5': Pic_PictureWithFrame20x20_2,
  'pic-passepartout-picture-20x16_6': Pic_PictureWithFrame20x20_2,

  'pic-framed-poster-30x20': FramedPoster_0,
  'pic-framed-poster-30x20_0': FramedPoster_0,
  'pic-framed-poster-30x20_1': FramedPoster_1,
  'pic-framed-poster-30x20_2': FramedPoster_2,
  'pic-framed-poster-30x20_3': FramedPoster_2,
  'pic-framed-poster-30x20_4': FramedPoster_2,
  'pic-framed-poster-30x20_5': FramedPoster_2,
  'pic-framed-poster-30x20_6': FramedPoster_2,
  'pic-framed-poster-40x30_0': FramedPoster_0,
  'pic-framed-poster-40x30_1': FramedPoster_1,
  'pic-framed-poster-40x30_2': FramedPoster_2,
  'pic-framed-poster-40x30_3': FramedPoster_2,
  'pic-framed-poster-40x30_4': FramedPoster_2,
  'pic-framed-poster-40x30_5': FramedPoster_2,
  'pic-framed-poster-40x30_6': FramedPoster_2,
  'pic-framed-poster-60x40_0': FramedPoster_0,
  'pic-framed-poster-60x40_1': FramedPoster_1,
  'pic-framed-poster-60x40_2': FramedPoster_2,
  'pic-framed-poster-60x40_3': FramedPoster_2,
  'pic-framed-poster-60x40_4': FramedPoster_2,
  'pic-framed-poster-60x40_5': FramedPoster_2,
  'pic-framed-poster-60x40_6': FramedPoster_2,
  'pic-framed-poster-80x60_0': FramedPoster_0,
  'pic-framed-poster-80x60_1': FramedPoster_1,
  'pic-framed-poster-80x60_2': FramedPoster_2,
  'pic-framed-poster-80x60_3': FramedPoster_2,
  'pic-framed-poster-80x60_4': FramedPoster_2,
  'pic-framed-poster-80x60_5': FramedPoster_2,
  'pic-framed-poster-80x60_6': FramedPoster_2,

  'pic-mixpix-20x20': MixPix_0,
  'pic-mixpix-20x20_0': MixPix_0,
  'pic-mixpix-20x20_1': MixPix_1,
  'pic-mixpix-20x20_2': MixPix_2,
  'pic-mixpix-framed-20x20': MixPix_3,
  'pic-mixpix-framed-20x20_0': MixPix_3,
  'pic-mixpix-framed-20x20_1': MixPix_4,
  'pic-mixpix-framed-20x20_2': MixPix_4,

  'pic-mixpix-8x8': MixPix_0,
  'pic-mixpix-8x8_0': MixPix_0,
  'pic-mixpix-8x8_1': MixPix_1,
  'pic-mixpix-8x8_2': MixPix_2,
  'pic-mixpix-framed-8x8': MixPix_3,
  'pic-mixpix-framed-8x8_0': MixPix_3,
  'pic-mixpix-framed-8x8_1': MixPix_4,
  'pic-mixpix-framed-8x8_2': MixPix_4
};
