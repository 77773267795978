import { ButtonBox } from 'components/DesktopMenu/MenuElements';
import { Spinner } from 'components/Loaders/Spinner';
import { getTranslationKey } from 'helpers/texting';
import { useAppSelector } from 'hooks/redux';
import { isShareApiAvailable } from 'helpers/isShareAvailable';
import styled from 'styled-components';

interface IShareSelectionButton {
  copied?: boolean;
  onShare: () => void;
}

const StyledButtonBox = styled(ButtonBox)`
  width: calc(100% - 30px);
  margin: 15px auto 0 auto;
  position: relative;
`;

export const ShareSelectionButton: React.FC<IShareSelectionButton> = ({ copied, onShare }) => {
  const { isLoading, type } = useAppSelector((state) => state.sharing);
  const isDisabled = isLoading || !type;

  const getShareIcon = () => {
    if (isLoading) return <Spinner />;
    if (copied) return <i className="fa fa-check" />;

    return <i className="fa fa-copy" />;
  };

  if (!isShareApiAvailable()) {
    return (
      <StyledButtonBox
        data-cy="share-buton"
        icon={getShareIcon()}
        className="sb-btn m-b-10"
        disabled={isDisabled}
        onClick={onShare}
      >
        {copied ? getTranslationKey('shareView.linkCopied') : getTranslationKey('copyLink')}
      </StyledButtonBox>
    );
  }

  return (
    <>
      <div id="native-share-button" />
      <StyledButtonBox
        data-cy="share-buton"
        icon={getShareIcon()}
        className="sb-btn m-b-10"
        onClick={onShare}
        disabled={isDisabled}
      >
        {getTranslationKey('shop.menu.shareItem')}
      </StyledButtonBox>
    </>
  );
};
