import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setTrackingStatus } from 'store/slices/collection';
import { getTranslationKey } from 'helpers/texting';

export const Tracking: React.FC = () => {
  const { isBuilder, siteTracking } = useAppSelector((state) => state.environment);
  const { trackingStatus, tracking } = useAppSelector((state) => state.collection);

  const dispatch = useAppDispatch();

  const mounted = useRef<boolean>();
  const loggedGA = useRef<boolean>(false);
  const loggedFB = useRef<boolean>(false);
  const loggedGTM = useRef<boolean>(false);

  const handleTracking = async () => {
    if (window.navigator.onLine) {
      if (trackingStatus === 'opt-in' && tracking && siteTracking) {
        if (tracking.ga && !loggedGA.current) {
          loggedGA.current = true;
          ReactGA.initialize(tracking.ga, {
            debug: process.env.NODE_ENV === 'development'
          });
          ReactGA.plugin.require('ec');
          ReactGA.set({ anonymizeIp: true });
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
        if (tracking.fb && !loggedFB.current) {
          loggedFB.current = true;
          const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false // enable logs
          };
          ReactPixel.init(tracking.fb, undefined, options);
          ReactPixel.pageView();
        }
        if (tracking.gtm_id && !loggedGTM.current) {
          loggedGTM.current = true;
          TagManager.initialize({
            gtmId: tracking.gtm_id
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      handleTracking();
      mounted.current = true;
    } else {
      handleTracking();
    }
  });

  const opt = (type: 'out' | 'in') => dispatch(setTrackingStatus(type));

  if (
    siteTracking &&
    !isBuilder &&
    !trackingStatus &&
    tracking &&
    (tracking.ga || tracking.fb || tracking.gtm_id)
  ) {
    return (
      <div className="row">
        <div className="tracking-hint col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-3">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
              {getTranslationKey('trackingHint')}{' '}
              <a href={`/privacy/${window.SITE_ID}`} target="_blank" rel="noopener noreferrer">
                {getTranslationKey('more')}
              </a>
            </div>
            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 text-center">
              <button onClick={() => opt('out')} className="btn-opt-out p-15">
                {getTranslationKey('optOut')}
              </button>
            </div>
            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
              <button onClick={() => opt('in')} className="site-button w-100-p">
                {getTranslationKey('optIn')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
