import { useTheme as useStyledComponentsTheme } from 'styled-components';
import { intersection } from 'lodash';

import { useAppSelector } from 'hooks/redux';
import { theme as defaultTheme } from 'assets/css/theme';
import { useBreakpoint } from 'hooks/useBreakpoint';

interface ITheme {
  colors: {
    [key: string]: string;
  };
  isSecondaryColor?: boolean;
  inputBorderRadius: number;
}

export const useThemeHelper = (): Array<ITheme> => {
  const isShop = intersection(window.location.pathname.split('/'), ['shop']).length;
  const { design, templateName, type } = useAppSelector((state) => state.collection);
  const isDesktop = useBreakpoint('desktopXl');

  if (isShop) return [defaultTheme];

  const primaryColor = design?.variables?.primaryColor as string;
  const secondaryColor = design?.variables?.secondaryColor as string;

  const isDarkTemplate = templateName === 'dark' && type === 'ScrAppBook2';

  const isClassic = templateName === 'dark' && type === 'ScrAppBook';
  const lightTemplates = ['SimpleFiligree', 'Breeze', 'VickyBaumannFineart'];

  const isSecondaryColor =
    !isDesktop &&
    !isClassic &&
    !lightTemplates.includes(templateName) &&
    !templateName.startsWith('SpringVibes') &&
    !isDarkTemplate;

  const theme: ITheme = {
    colors: {
      primary: primaryColor,
      secondary: secondaryColor,
      mobileBorderColor: isDarkTemplate ? primaryColor : secondaryColor,
      mobileIndicatorColor: isDarkTemplate ? secondaryColor : primaryColor,
      accent: '#455773',
      accentHover: '#576e91',
      white: '#fff',
      black: '#000',
      ...design?.variables
    },
    isSecondaryColor,
    inputBorderRadius: design?.variables?.inputBorderRadius as number
  };

  return [theme];
};

export const useTheme = (): ITheme[] => {
  const theme = useStyledComponentsTheme() as ITheme;

  if (!theme) return [defaultTheme];

  return [theme];
};
