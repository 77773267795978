import classNames from 'classnames';
import styled from 'styled-components';

export const LinkBtn = styled.p.attrs<{ className?: string }>((props) => ({
  className: classNames(
    'mt-1 mb-0 cursor-pointer text-underline text-center',
    props.className || ''
  )
}))`
  &:hover {
    color: var(--hover-color);
  }
`;
