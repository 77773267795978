import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOrdersStore } from 'modules/ScrShop/store/selectors/orders';

export function PersistOrders() {
  const orders = useSelector(getOrdersStore);
  const { order } = orders;
  const isPending = order.status === 'payment_pending';
  const persistedObj = {
    ...orders,
    redirectToPayment: false
  };
  useEffect(() => {
    if (isPending) {
      localStorage.setItem(`orders_${window.SITE_ID}`, JSON.stringify(persistedObj));
    }
	}, [isPending] ); //eslint-disable-line

  return null;
}
