import { useState, ChangeEvent } from 'react';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import classnames from 'classnames';

import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { getTranslationKey } from 'helpers/texting';
import { commentImage } from 'store/slices/images/actions';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { useTheme } from 'hooks/useTheme';
import { closeCommentImageModal } from 'store/slices/image';

const Textarea = styled.textarea`
  border: 1px solid ${(props) => props.theme.colors.inputBorderColor};
  color: ${(props) => props.theme.colors.inputColor};
  border-radius: ${(props) => props.theme.inputBorderRadius}px;
  &:focus {
    color: ${(props) => props.theme.colors.inputColor};
    border: 1px solid ${(props) => props.theme.colors.inputBorderColor} !important;
    box-shadow: none !important;
  }
`;

export const CommentModal = () => {
  const dispatch = useAppDispatch();
  const { commentModal } = useAppSelector((state) => state.image);
  const [comment, setComment] = useState('');
  const [theme] = useTheme();

  const changeComment = (e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value);

  const { isOpen, color, buttonClassName, image } = commentModal;

  const saveComment = () => {
    if (comment.trim()) {
      dispatch(
        commentImage({
          imageId: image?._id,
          comment: comment || ''
        })
      );
    }

    dispatch(closeCommentImageModal());
  };

  if (!image) return null;

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => dispatch(closeCommentImageModal())}
      wrapClassName={classnames('modal-site comment-modal', { [color]: !!color })}
      size="sm"
      zIndex={999999}
    >
      <ModalHeader toggle={() => dispatch(closeCommentImageModal())}>
        {getTranslationKey('comment')}
      </ModalHeader>
      <ModalBody>
        <div className="form-group mb-2">
          <Textarea
            theme={theme}
            style={{ height: '150px' }}
            defaultValue={image.comment || ''}
            className="form-control"
            placeholder={getTranslationKey('commentCTA')}
            onChange={changeComment}
          />
        </div>
        <button
          onClick={saveComment}
          className={buttonClassName || 'btn btn-dark btn-icon mx-auto d-block w-100-p'}
        >
          <i className="fa fa-comment" />
          {getTranslationKey('sendComment')}
        </button>
      </ModalBody>
    </Modal>
  );
};
