import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';
import { getAllProduct } from 'modules/ScrShop/store/selectors/products';
import { find, get } from 'lodash';
import { getOverviewImageSrc } from 'modules/ScrShop/store/utils/helpers';

export const getCheckoutStore = ({ checkout }: IStore) => checkout;

export const getCheckoutProducts = createSelector(
  getCheckoutStore,
  getAllProduct,
  (checkout, allProducts) =>
    checkout.products.map((product) => {
      const prod = find(allProducts, {
        _productGroup: product._productGroup,
        _id: product._product
      });
      const minQuantity = get(prod, 'minQuantity');

      return {
        ...product,
        displayImage: getOverviewImageSrc(get(prod, 'images', [])),
        title: get(prod, 'title', ''),
        options: get(prod, 'options', []),
        currency: checkout.currency,
        slug: get(prod, 'slug', ''),
        maxImageSelection: get(prod, 'maxImageSelection', 0),
        canChangeShoppingCartQuantity: get(prod, 'canChangeShoppingCartQuantity', false),
        dimensions: get(prod, 'dimensions', { width: 0, height: 0, unit: '', depth: 0 }),
        minQuantity: minQuantity || null
      };
    })
);

export const getNumberOfCheckoutProducts = createSelector(getCheckoutProducts, (products) =>
  products.reduce((prev, product) => prev + product.quantity, 0)
);

export const getCheckoutProductByID = createSelector(
  getCheckoutProducts,
  (products) => (productID: string) => find(products, { _id: productID })
);

export const getCheckoutIDSelector = createSelector(getCheckoutStore, ({ _id }) => _id);

export const getCheckoutImageSelector = (store: IStore) => (imageID: string, productID: string) => {
  const product = getCheckoutProductByID(store)(productID);
  const images = get(product, 'images', []);

  return find(images, { _image: imageID });
};
