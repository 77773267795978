import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';

export const WoodPrint_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 6, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-16.2}
        left={-126.3}
        width={112}
        height={100}
        transform="matrix3d(1,-0.46,0,0,1.7,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>
    </>
  );
};

export const WoodPrint_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 6, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={35}
        left={-75}
        width={145}
        height={100}
        transform="matrix3d(1,-0.46,0,0,1.1,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 900" />
      </PreviewImageContainer>
    </>
  );
};
