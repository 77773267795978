import { createAction } from 'redux-act';
import {
  IChangeCheckoutProductQuantityAction,
  ICheckoutReducer,
  IRemoveCheckoutProductAction
} from 'modules/ScrShop/store/types/checkout';

export const claimCheckoutAction = createAction();

export const addUpdateProductToCartAction = createAction<{
  selectionId?: string;
  callback?: () => void;
  photobookId?: string;
} | void>();
export const addUpdateProductToCartSuccessAction = createAction<ICheckoutReducer>();

export const updateCheckoutStoreAction = createAction<Partial<ICheckoutReducer>>();

export const deleteProductFormCheckoutAction = createAction<IRemoveCheckoutProductAction>();
export const changeCheckoutProductQuantityAction =
  createAction<IChangeCheckoutProductQuantityAction>();

export const cleanUpCheckout = createAction();
export const fetchCheckoutAction = createAction();
