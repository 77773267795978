import { Col, Modal, Row } from 'reactstrap';
import { DownloadIcon } from 'components/icons/DownloadIcon';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { ButtonBox } from 'components/DesktopMenu/MenuElements';
import { getTranslationKey } from 'helpers/texting';
import { SingleSelectItem } from 'components/SelectionFields/SingleSelectItem';
import { useDownloadData } from 'components/DownloadModal/useDownloadData';
import { useTheme } from 'hooks/useTheme';

export const DownloadModal: React.FC = () => {
  const [theme] = useTheme();
  const {
    downloadModal,
    menuOptions,
    selectDownloadType,
    downloadSelectedItem,
    handleToggle,
    isDownloadDisabled,
    selectionOptions,
    galleriesOptions,
    selectedItem,
    selectDownloadItem,
    typeSelected,
    isHighQualityAvailable
  } = useDownloadData();

  const isSelectionSelected = typeSelected === 'selection' && !!selectionOptions.length;
  const isGallerySelected = typeSelected === 'gallery' && !!galleriesOptions.length;
  const isSpecificImageSelected = typeSelected === 'singleImage';

  const currentTypeSelected = typeSelected.replace('image', 'default') || 'default';
  const isSingleImageLabel =
    currentTypeSelected === 'default' || currentTypeSelected === 'singleImage';

  return (
    <Modal centered isOpen={downloadModal.isOpen} toggle={handleToggle} size="lg">
      <ModalHeader toggle={handleToggle}>{getTranslationKey('downloadModal.title')}</ModalHeader>
      <ModalBody>
        <SingleSelectItem
          withDefaultPlaceholder
          value={currentTypeSelected}
          label={
            isSingleImageLabel
              ? getTranslationKey('downloadImages.whatToDownload')
              : getTranslationKey('downloadImages.whatToDownloadMultiple')
          }
          onChange={selectDownloadType}
          options={menuOptions}
        />
        {isSelectionSelected && (
          <SingleSelectItem
            withDefaultPlaceholder
            value={selectedItem.selection || 'default'}
            label={getTranslationKey('downloadImages.whichSelection')}
            onChange={({ target: { value } }) => selectDownloadItem('selection', value)}
            options={selectionOptions}
          />
        )}
        {isGallerySelected && (
          <SingleSelectItem
            withDefaultPlaceholder
            value={selectedItem.gallery || 'default'}
            label={getTranslationKey('downloadImages.whichGallery')}
            onChange={({ target: { value } }) => selectDownloadItem('gallery', value)}
            options={galleriesOptions}
          />
        )}

        {isSpecificImageSelected && (
          <p style={{ padding: '0 15px' }}>
            {getTranslationKey('downloadImages.specificImagePlaceholder')}
          </p>
        )}

        {!isSingleImageLabel && (
          <Row style={{ padding: '0 15px' }}>
            <Col xs="12">
              <ButtonBox
                icon={<DownloadIcon size={22} color={theme.colors.menuIconsColor} />}
                className="sb-btn w-100 m-b-10"
                onClick={() => downloadSelectedItem('low')}
                centered={false}
                disabled={isDownloadDisabled()}
              >
                {getTranslationKey('downloadImageLow')}
              </ButtonBox>
            </Col>
            {isHighQualityAvailable() && (
              <Col xs="12">
                <ButtonBox
                  icon={<DownloadIcon size={22} color={theme.colors.menuIconsColor} />}
                  className="sb-btn w-100 m-b-10"
                  onClick={() => downloadSelectedItem('high')}
                  centered={false}
                  disabled={isDownloadDisabled()}
                >
                  {getTranslationKey('downloadImageHigh')}
                </ButtonBox>
              </Col>
            )}
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};
