import styled from 'styled-components';
import { FaPen } from 'react-icons/fa';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  padding-left: 10px;
`;
const Card = styled.div<{ flexDirection: string }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection};
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 22px 0 rgb(156 156 156 / 20%);
  position: relative;
`;
const EditButton = styled.div`
  position: absolute;
  right: 14px;
  bottom: 12px;
`;

interface IProps {
  cardTitle: string;
  editAction?: () => void;
  icon?: JSX.Element;
  flexDirection?: string;
}

export const CheckoutOverviewCard: React.FC<IProps> = ({
  children,
  cardTitle,
  editAction,
  icon,
  flexDirection = 'row'
}) => {
  const cardClickHandler = () => {
    if (editAction) {
      editAction();
    }
  };

  return (
    <Wrapper onClick={cardClickHandler}>
      <Title>{cardTitle}</Title>
      <Card flexDirection={flexDirection}>
        {icon}
        {children}
        {editAction ? (
          <EditButton data-cy="component-edit">
            <FaPen size={16} />
          </EditButton>
        ) : null}
      </Card>
    </Wrapper>
  );
};
