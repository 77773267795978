import { setCookie } from './cookie';

export function saveAndSetNewToken(user: string, token: string) {
  const persistData = localStorage.getItem('endCustomerToken');
  const endCustomerTokenMap = persistData ? JSON.parse(persistData) : {};

  localStorage.setItem(
    'endCustomerToken',
    JSON.stringify({ ...endCustomerTokenMap, [user]: token })
  );

  setCookie('endCustomerToken', token, 30);
}
