import styled, { css } from 'styled-components';

import { LinkAnker, LinkAnkerHash, Wrapper } from 'components/DesktopMenu/MenuStyles';
import { IconLinkBox, ActionBox } from 'components/MainMenu/Box';
import { UnstyledButton } from 'styles/Button';

interface ILinkProps {
  to: string;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  icon: React.ReactNode;
}

interface IActionProps {
  id?: string;
  onClick?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  centered?: boolean;
  icon: React.ReactNode;
  disabled?: boolean;
}

const Text = styled.span<{ withIcon?: boolean }>`
  width: 100%;
  white-space: nowrap;
  height: 21px;

  ${({ withIcon }) =>
    withIcon &&
    css`
      margin-right: 0px;
      margin-left: 8px;
    `}
`;

const CenteredText = styled(Text)`
  text-align: center;

  ${({ withIcon }) => withIcon && 'margin-left: 0px;'}
`;

export const LinkBox: React.FC<ILinkProps> = ({ className, to, children, icon }) => (
  <IconLinkBox to={to} className={className}>
    {icon}
    <CenteredText withIcon>{children}</CenteredText>
  </IconLinkBox>
);

export const IconlessLinkBox: React.FC<{ to: string }> = ({ to, children }) => (
  <IconLinkBox to={to} className="sb-btn">
    <CenteredText>{children}</CenteredText>
  </IconLinkBox>
);

export const ButtonBox: React.FC<IActionProps> = ({
  className,
  onClick,
  children,
  icon,
  centered = true,
  ...rest
}) => (
  <ActionBox onClick={onClick} hasIcon className={className} {...rest}>
    {icon}
    {centered ? <CenteredText withIcon>{children}</CenteredText> : <Text withIcon>{children}</Text>}
  </ActionBox>
);

export const IconlessButtonBox: React.FC<{ onClick: () => void }> = ({ onClick, children }) => (
  <ActionBox onClick={onClick} className="sb-btn">
    {children}
  </ActionBox>
);

export const LinkMenuElement: React.FC<ILinkProps> = ({ to, icon }) => (
  <Wrapper>
    <LinkAnker to={to}>{icon}</LinkAnker>
  </Wrapper>
);

export const HashLinkMenuElement: React.FC<ILinkProps> = ({ to, icon }) => (
  <Wrapper>
    <LinkAnkerHash to={to}>{icon}</LinkAnkerHash>
  </Wrapper>
);

export const ActionMenuElement: React.FC<IActionProps> = ({ onClick, icon, ...rest }) => (
  <Wrapper {...rest}>
    <UnstyledButton onClick={onClick}>{icon}</UnstyledButton>
  </Wrapper>
);
