import { Button, Modal } from 'reactstrap';

import { getTranslationKey } from 'helpers/texting';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { toggleCancelSelectionModal } from 'store/slices/modals';
import { cancelSelectionAndDeleteIfUnsaved } from 'store/slices/selection/actions';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';

export const CancelSelectionModal = () => {
  const dispatch = useAppDispatch();

  const { cancelSelectionModal } = useAppSelector((state) => state.modals);

  const toggleCancelSelection = () => dispatch(toggleCancelSelectionModal());

  const cancelSelectionProcess = () => {
    dispatch(cancelSelectionAndDeleteIfUnsaved());
    toggleCancelSelection();
  };

  return (
    <Modal
      centered
      isOpen={cancelSelectionModal.isOpen}
      toggle={toggleCancelSelection}
      zIndex={999999}
      size="md"
    >
      <ModalHeader toggle={toggleCancelSelection}>
        {getTranslationKey('selections.cancelSelectionModal.title')}
      </ModalHeader>
      <ModalBody>{getTranslationKey('selections.cancelSelectionModal.content')}</ModalBody>
      <ModalFooter>
        <Button outline onClick={toggleCancelSelection} className="sb-btn sb-btn-secondary">
          {getTranslationKey('no')}
        </Button>
        <Button onClick={cancelSelectionProcess} className="sb-btn">
          {getTranslationKey('yes')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
