import { ReactComponent as PinterestIconDark } from 'assets/images/icons/dark - pinterest.svg';
import { ReactComponent as PinterestIconSimpleFiligree } from 'assets/images/icons/simple filigree - pinterest.svg';
import { ReactComponent as PinterestIconSpringVibes } from 'assets/images/icons/spring - pinterest.svg';
import { ReactComponent as PinterestIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - pinterest.svg';
import { ReactComponent as PinterestIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - pinterest.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const PinterestIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={PinterestIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={PinterestIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={PinterestIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={PinterestIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={PinterestIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={PinterestIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={PinterestIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={PinterestIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={PinterestIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={PinterestIconSimpleFiligree} {...props} />
  })();
