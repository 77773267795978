import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { IPackage } from 'modules/ScrShop/store/types';
import { displayProductPrice } from 'modules/ScrShop/store/utils';
import { useDispatch } from 'react-redux';
import {
  setSelectedImagesAction,
  setSelectedProductIDAction,
  addUpdateProductToCartAction,
  setSelectedImagedSuccessAction
} from 'modules/ScrShop/store/actions';
import { trackEventAction } from 'old-store/actions/analytics';
import { useHistory } from 'react-router-dom';
import { toggleUpsellModal } from 'store/slices/modals';
import { useIsCheckoutReady } from 'modules/ScrShop/store/hooks/is-checkout-ready';
import { FaCheck, FaStar } from 'react-icons/fa';
import { useToggle } from 'hooks/useToggle';
import { Spinner } from 'components/Loaders/Spinner';
import { useAppSelector } from 'hooks/redux';
import { getTranslationKey } from 'helpers/texting';
import { useCurrency } from 'hooks/useCurrency';
import { Button } from 'reactstrap';
import { media } from 'assets/css/media';
import { IImage } from 'old-store/types/gallery';

interface IProps {
  imagePackage: IPackage;
  alreadyPurchasedPackage?: IPackage;
  isSwiping: boolean;
  savings: number | null;
  matchedPackage?: IPackage;
  bestseller?: boolean;
  images: IImage[];
}

const getBorderColor = (theme, isPackageMatched, bestseller) => {
  if (bestseller) return theme.colors.secondaryButtonFontColor;
  if (isPackageMatched) return '#fff';

  return '#000';
};

const PackageCardWrapper = styled.div<{ isPackageMatched: boolean; bestseller?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: ${({ bestseller }) => (bestseller ? '325px' : '310px')};
  background: #fff;
  border-radius: 20px;
  margin-right: ${() => (window.innerWidth < 992 ? '0px' : '10px')};
  margin-bottom: 10px;
  padding: 30px 20px 20px 20px;
  padding-top: ${({ bestseller }) => (bestseller ? '55px' : '30px')};
  color: #000;
  text-decoration: none;
  position: relative;
  border-radius: 20px;
  &:last-child {
    margin-right: 0;
  }

  @media all and (max-width: ${media.desktopXl}) {
    height: calc(100% - 15px);
    min-height: initial;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 20px;
    border: 1px solid
      ${({ isPackageMatched, bestseller, theme }) =>
        getBorderColor(theme, isPackageMatched, bestseller)};
    border-width: ${({ bestseller }) => (bestseller ? '2px' : '1px')};
    top: ${({ bestseller }) => (bestseller ? '11px' : '0')};
    left: 0;
    height: ${({ bestseller }) => (bestseller ? 'calc(100% - 10px)' : '100%')};
    background: ${({ isPackageMatched }) => (isPackageMatched ? '#F5F4F4' : '#fff')};
    width: 100%;
    z-index: 1;
  }
`;

const BestsellerHeaderBlock = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid ${({ theme }) => theme.colors.secondaryButtonFontColor};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0 20px;
  height: 24px;
  z-index: 2;
  font-weight: bold;
  font-size: 18px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TriangleLeft = styled.div`
  width: 0px;
  height: 0px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid ${({ theme }) => theme.colors.secondaryButtonFontColor};
  position: absolute;
  top: -2px;
  left: -13.7px;
`;

const TriangleRight = styled.div`
  width: 0px;
  height: 0px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid ${({ theme }) => theme.colors.secondaryButtonFontColor};
  position: absolute;
  top: -2px;
  right: -13.7px;
`;

const PackageHeader = styled.div`
  height: 90px;
  position: relative;
  z-index: 2;
  @media all and (max-width: ${media.desktopXl}) {
    height: 80px;
  }
`;

const PackageName = styled.h3`
  &&&& {
    font-size: 20px !important;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000 !important;
    @media all and (max-width: ${media.desktopXl}) {
      margin-bottom: 5px;
    }
  }
`;

const PackagePrice = styled.h4`
  &&&& {
    font-size: 18px;
    font-weight: 600;
    color: #000 !important;
    @media all and (max-width: ${media.desktopXl}) {
      margin-bottom: 5px;
    }
  }
`;

const PackageSavings = styled.div`
  margin-top: 2px;
  font-size: 15px;
  color: #707070;
`;

const ConfirmButton = styled(Button)<{ isMatched: boolean }>`
  border: 0;
  border-radius: 20px;
  background: ${({ isMatched }) => (isMatched ? '#E6E6E6' : '#000')};
  color: ${({ isMatched }) => (isMatched ? '#707070' : '#fff')};
  position: relative;
  z-index: 2;
`;

const BenefitsContent = styled.ul`
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0;
  list-style-type: none;
  color: #707070;
  height: 105px;
  position: relative;
  z-index: 2;
`;

const BenefitItem = styled.li`
  && {
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    span {
      color: #000 !important;
    }
  }
`;

const CheckWrap = styled.div`
  background: #707070;
  border-radius: 50%;
  min-width: 15px;
  min-height: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;

const StyledCheck = styled(FaCheck)`
  color: #f5f4f4;
  width: 10px;
  height: 10px;
`;

const StyledFaStar = styled(FaStar)`
  min-width: 13px;
  min-height: 13px;
  color: ${({ theme }) => theme.colors.secondary};
  fill: ${({ theme }) => theme.colors.secondary};
  margin-right: 5px;
`;

const Checkmark = () => (
  <CheckWrap>
    <StyledCheck />
  </CheckWrap>
);

export const UpsellingAllImagesCard: React.FC<IProps> = ({
  imagePackage,
  alreadyPurchasedPackage,
  isSwiping,
  savings,
  matchedPackage,
  bestseller,
  images
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currency = useCurrency();
  const isCheckoutReady = useIsCheckoutReady();
  const { name, id, grossTotalPrice } = imagePackage;
  const { products } = useAppSelector((state) => state.checkout);
  const [isReadyForRedirectToBasket, setIsReadyForRedirectToBasket] = useState(false);
  const { toggle: isLoading, openToggle: startLoading } = useToggle();

  useEffect(() => {
    if (isCheckoutReady && isReadyForRedirectToBasket && products.length) {
      dispatch(toggleUpsellModal());
      history.push('/shop/basket');
    }
  }, [isCheckoutReady, isReadyForRedirectToBasket, products.length]);

  const selectAllImages = () => {
    dispatch(
      setSelectedImagesAction({
        groupSlug: 'digital',
        productSlug: id,
        imageIDs: images.map(({ _id }) => _id)
      })
    );
    dispatch(
      trackEventAction({
        name: 'clicked-action',
        payload: {
          button_id: 'select-all-images',
          location: 'select-photo',
          product_slug: id,
          product_group_slug: 'digital'
        }
      })
    );
  };

  const addPackageToCart = () => {
    if (isSwiping) return;

    startLoading();
    selectAllImages();
    dispatch(setSelectedProductIDAction(id));
    dispatch(setSelectedImagedSuccessAction(images.map(({ _id }) => _id)));
    dispatch(addUpdateProductToCartAction());
    setIsReadyForRedirectToBasket(true);
  };

  const grossTotalPriceWithDiscount = alreadyPurchasedPackage
    ? Math.max(grossTotalPrice - alreadyPurchasedPackage.grossTotalPrice, 0)
    : grossTotalPrice;

  const pricePerImage = grossTotalPriceWithDiscount / images.length;

  const isPackageMatched = matchedPackage?.id === imagePackage.id;

  return (
    <PackageCardWrapper isPackageMatched={isPackageMatched} bestseller={bestseller}>
      {bestseller && (
        <BestsellerHeaderBlock>
          <TriangleLeft />
          <StyledFaStar />
          {getTranslationKey('shop.upselling.bestseller')}
          <TriangleRight />
        </BestsellerHeaderBlock>
      )}
      <PackageHeader>
        <PackageName>{name}</PackageName>
        <PackagePrice>
          {displayProductPrice(Math.round(grossTotalPriceWithDiscount), currency)}
        </PackagePrice>
        {!!savings && (
          <PackageSavings>
            {getTranslationKey('shop.upselling.packageSavings', {
              savingsPercentage: savings
            })}
          </PackageSavings>
        )}
      </PackageHeader>

      <BenefitsContent>
        <BenefitItem>
          <Checkmark />
          <span>{getTranslationKey('shop.upselling.allImagesIncluded')}</span>
        </BenefitItem>
        <BenefitItem>
          <Checkmark />
          <span>
            {getTranslationKey('shop.upselling.dataPrice', {
              price: displayProductPrice(pricePerImage, currency)
            })}
          </span>
        </BenefitItem>
      </BenefitsContent>

      <div className="d-flex flex-column align-items-center w-100">
        <ConfirmButton
          className="w-100-p mt-1"
          onClick={addPackageToCart}
          isMatched={isPackageMatched}
        >
          {isLoading && <Spinner width={13} height={13} />}
          <span className="ms-1">
            {getTranslationKey(
              `shop.upselling.${isPackageMatched ? 'packageSelected' : 'selectPackageItem'}`
            )}
          </span>
        </ConfirmButton>
      </div>
    </PackageCardWrapper>
  );
};
