import { useTheme } from 'hooks/useTheme';
import { FormPart } from 'modules/ScrShop/components/Forms/FormPart';
import styled from 'styled-components';

export interface IInputProps {
  onClick?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  label?: string;
  errorText?: string;
}

/**
 * TextArea
 */
const TextAreaElement = styled.textarea<IInputProps>`
  border: 1px solid ${(props) => props.theme.colors.inputBorderColor};
  box-shadow: none;
  color: ${(props) => props.theme.colors.inputColor};
  background: #fff;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: ${(props) => props.theme.colors.inputBorderRadius}px;
  display: inline-flex;
  height: 120px;
  width: 100%;
  appearance: auto;
  resize: none;
  &:disabled {
    opacity: 0.7;
  }

  &::placeholder {
    color: #555555;
    font-weight: 400;
    opacity: 0.7;
  }

  &[type='checkbox'] {
    min-width: auto;
  }
  input {
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill:hover {
      background-color: inherit !important;
      background-clip: content-box;
    }
  }

  &:focus-visible {
    outline: none;
  }
`;

type IProps = React.InputHTMLAttributes<HTMLTextAreaElement> & IInputProps;

export const TextArea: React.FC<IProps> = ({ id, label, errorText, className, ...props }) => {
  const [theme] = useTheme();

  return (
    <FormPart
      id={id}
      label={label}
      className={className}
      isDisabled={props.disabled}
      errorText={errorText}
    >
      <TextAreaElement id={id} theme={theme} {...props} />
    </FormPart>
  );
};
