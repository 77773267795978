import { useState, useCallback } from 'react';
import { useToggle } from 'hooks/useToggle';
import { useMouseCoordinates } from 'hooks/useMouseCoodinates';

export const useSwipeCoordinates = () => {
  const [mouseCoordinates] = useMouseCoordinates();
  const { toggle: isSwiping, openToggle: startSlide, closeToggle: stopSlide } = useToggle();
  const [swipeCoordinates, setSwipeCoordinates] = useState({ start: 0, end: 0 });

  // should be assigned to certain container on onMouseDown, onMouseUp listeners
  const startSwiping = useCallback(() => {
    startSlide();
    setSwipeCoordinates({ start: mouseCoordinates.current.x, end: 0 });
  }, [mouseCoordinates.current.x]);

  const stopSwiping = useCallback(() => {
    stopSlide();
    setSwipeCoordinates((prev) => ({ ...prev, end: mouseCoordinates.current.x }));
  }, [mouseCoordinates.current.x]);

  return { isSwiping, swipeCoordinates, startSwiping, stopSwiping };
};
