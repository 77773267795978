import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

export const Sticker8x = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 3, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={10.3}
        left={-20}
        width={70.4}
        height={77.9}
        transform="rotate(0.3deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={31.5}
        left={50.1}
        width={18.8}
        height={28.3}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={50.3}
        left={50.1}
        width={18.8}
        height={28.3}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={69.1}
        left={50.1}
        width={18.9}
        height={28.3}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={86.7}
        left={50}
        width={17.7}
        height={28.3}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={31.5}
        left={78.3}
        width={18.8}
        height={28.2}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={50.3}
        left={78.3}
        width={18.8}
        height={28.2}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={69}
        left={78.3}
        width={18.8}
        height={28.2}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={86.7}
        left={78.3}
        width={17.8}
        height={28.2}
        transform="rotate(-90deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2200" />
      </PreviewImageContainer>
    </>
  );
};

export const Sticker8x_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 3, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={4}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 75 630 C 159 683 161 692 285 647 L 1795 178 L 859 -420 L -546 230" />
              <path d="M -216 832 L 84 623 C 108 605 100 610 150 623 L 821 798 C 873 813 883 814 869 829 L 644 1049 C 595 1096 591 1094 552 1083 C 323 1014 97 960 -216 832" />
              <path d="M 711 980 L 739 956 L 771 980 C 807 1003 824 994 1266 825 C 1279 818 1235 806 1265 811 L 1297 832 L 806 1011 L 777 1024" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-35}
        left={96}
        width={80}
        height={80}
        zIndex={1}
        transform="matrix3d(1,0.42,0,0,-2.7,1,0,0.00061,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={61.1}
        left={-21}
        width={22.1}
        height={77}
        borderRadius={10}
        zIndex={2}
        transform="matrix3d(1,0.01,0,0,-1,0.9,0,0.0004,0,0,1,0,0,0,0,1) rotate(-78.4deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1000 3000" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={69.7}
        left={43}
        width={44}
        height={70}
        zIndex={3}
        transform="rotate(-42deg)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 2500" y={-1800} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={33.9}
        left={48.7}
        width={50}
        height={21.8}
        zIndex={4}
        transform="matrix3d(1,-0.306,0,0.0004,1.7,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1100" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={33.9}
        left={48.7}
        width={50}
        height={21.8}
        zIndex={4}
        transform="matrix3d(1,-0.306,0,0.0004,1.7,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={25}
        left={77}
        width={50}
        height={21.8}
        zIndex={4}
        transform="matrix3d(1,-0.306,0,0.0004,1.7,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={68}
        left={51.3}
        width={50}
        height={28.5}
        zIndex={4}
        transform="matrix3d(1,-0.36,0,0.0005,1.59,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={55}
        left={87}
        width={50}
        height={28.5}
        zIndex={4}
        transform="matrix3d(1,-0.385,0,0.0005,1.59,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};
