import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { shopSaga } from 'modules/ScrShop/store/sagas';
import { coreSaga } from 'old-store/sagas';
import * as coreReducers from 'old-store/reducers/index';
import * as shopReducers from 'modules/ScrShop/store/reducers';
import { reducers as rtkReducers } from 'store';
import { middlewares } from 'store/middlewares';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({ ...coreReducers, ...shopReducers, ...rtkReducers });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([sagaMiddleware, ...middlewares])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(coreSaga);
sagaMiddleware.run(shopSaga);
