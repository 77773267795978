import type { IIconProps } from 'types/icons';
import { useTemplateVariant } from 'hooks/useTemplateVariant';
import { BaseIcon } from 'components/icons/BaseIcon';

import CloseIconDarkUrl, {
  ReactComponent as CloseIconDark
} from 'assets/images/icons/dark - close.svg';
import CloseIconSimpleFiligreeUrl, {
  ReactComponent as CloseIconSimpleFiligree
} from 'assets/images/icons/simple filigree - close.svg';
import CloseIconSpringVibesUrl, {
  ReactComponent as CloseIconSpringVibes
} from 'assets/images/icons/spring - close.svg';
import CloseIconVickyBaumannBohoUrl, {
  ReactComponent as CloseIconVickyBaumannBoho
} from 'assets/images/icons/vicky boho - close.svg';
import CloseIconVickyBaumannFineartUrl, {
  ReactComponent as CloseIconVickyBaumannFineart
} from 'assets/images/icons/vicky fineart - close.svg';

export const CloseIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={CloseIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={CloseIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={CloseIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={CloseIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={CloseIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={CloseIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={CloseIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={CloseIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={CloseIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={CloseIconSimpleFiligree} {...props} />
  })();

export const useCloseIconUrl = () =>
  useTemplateVariant({
    classic: CloseIconSimpleFiligreeUrl,
    dark: CloseIconDarkUrl,
    simpleFiligree: CloseIconSimpleFiligreeUrl,
    springVibes: CloseIconSpringVibesUrl,
    vickyBaumannBoho: CloseIconVickyBaumannBohoUrl,
    vickyBaumannFineart: CloseIconVickyBaumannFineartUrl,
    breeze: CloseIconSimpleFiligreeUrl,
    capri: CloseIconSimpleFiligreeUrl,
    rose: CloseIconSimpleFiligreeUrl,
    calm: CloseIconSimpleFiligreeUrl
  });
