import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { getImageUrls } from 'helpers/imageUrls';
import type { IFavorite, IImage, ISelection } from 'old-store/types/gallery';

interface ILike {
  imageId: string;
  selectionId: string;
  current: boolean;
}
interface IImagesState {
  images: IImage[];
  likes: ILike[];
  hasLikes?: boolean;
  hasOldLikes?: boolean;
}

const initialState: IImagesState = {
  images: [],
  likes: []
};

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<{ images: IImage[]; signedWildCardUrl: string }>) => {
      const { images, signedWildCardUrl = '' } = action.payload;

      const filteredImages = images.filter((i) => i.status === 'uploaded' || i.isPublic);

      state.hasOldLikes = filteredImages.some((image) => image.oldLike);

      state.images = filteredImages.map((image) => {
        const imageUrls = getImageUrls(image, signedWildCardUrl);

        const likes = state.likes.filter((like) => like.imageId === image._id);

        return {
          ...image,
          likes: likes.map((like) => ({ selection_id: like.selectionId })),
          like: likes.filter((l) => l.current).length > 0,
          ...imageUrls
        };
      });
    },
    setExistingImages: (state, action: PayloadAction<IImage[]>) => {
      state.images = action.payload;
    },
    removeImage: (state, action: PayloadAction<string>) => {
      state.images = state.images.filter((image) => image._id !== action.payload);
    },
    fetchSelectionImages: (state, action: PayloadAction<ISelection[] | undefined>) => {
      const selections = action.payload;

      if (!selections) return;

      const currentSelection = selections.find((selection) => selection.current);
      let hasLikes = false;

      const newImages = state.images.map((image) => {
        const newImage: IImage = {
          ...image,
          like: false,
          likes: [],
          comments: []
        };

        let likeUpdatedAt: number;

        selections.forEach((selection) => {
          const favorite = selection.favorites.find((favorite) => favorite._image === image._id);

          if (favorite) {
            if (favorite.like) {
              hasLikes = true;

              if (favorite.likeUpdatedAt) {
                try {
                  likeUpdatedAt = moment(favorite.likeUpdatedAt).unix();
                } catch (e) {
                  console.log('cant convert date', favorite.likeUpdatedAt);
                }
              }

              // @ts-ignore
              newImage.likes.push({ selection_id: selection._id });

              if (currentSelection && selection._id === currentSelection._id) {
                newImage.like = true;
              }

              // @ts-ignore
              newImage[`${selection._id}_likeUpdatedAt`] = likeUpdatedAt;
            }

            if (favorite.comment) {
              newImage.comments.push({
                selection_id: selection._id,
                commentText: favorite.comment
              });

              if (currentSelection && selection._id === currentSelection._id) {
                newImage.comment = favorite.comment;
              }
            }
          }
        });

        return newImage;
      });

      state.images = newImages;
      state.likes = selections
        .map((s) =>
          s.favorites.map((f) => ({
            imageId: f._image,
            selectionId: s._id,
            current: !!currentSelection && s._id === currentSelection._id
          }))
        )
        .reduce((a, b) => [...a, ...b], []);
      state.hasLikes = hasLikes;
    },
    updateSelectionImage: (
      state,
      action: PayloadAction<{ image: IImage; favorite: IFavorite; selection: ISelection }>
    ) => {
      const { payload } = action;
      let hasLikes = false;

      const newImages = state.images.map((image) => {
        if (image._id === payload.image._id) {
          const newImage = {
            ...image,
            likes: payload.image.likes ? [...payload.image.likes] : [],
            comments: payload.image.comments ? [...payload.image.comments] : []
          };

          let likeUpdatedAt;

          if (payload.favorite.like) {
            hasLikes = true;

            if (payload.favorite.likeUpdatedAt) {
              try {
                likeUpdatedAt = moment(payload.favorite.likeUpdatedAt).unix();
              } catch (e) {
                console.log('cant convert date', payload.favorite.likeUpdatedAt);
              }
            }

            newImage.likes.push({ selection_id: payload.selection._id });

            if (payload.selection.current) {
              newImage.like = true;
            }

            // @ts-ignore
            newImage[`${payload.selection._id}_likeUpdatedAt`] = likeUpdatedAt;
          } else {
            newImage.likes = newImage.likes.filter((l) => l.selection_id !== payload.selection._id);
            newImage.like = false;
          }

          if (payload.favorite.comment) {
            newImage.comments.push({
              selection_id: payload.selection._id,
              commentText: payload.favorite.comment
            });

            if (payload.selection.current) {
              newImage.comment = payload.favorite.comment;
            }
          } else {
            newImage.comments = newImage.comments.filter(
              (c) => c.selection_id !== payload.selection._id
            );
          }

          return newImage;
        }

        if (image.like) hasLikes = true;

        return image;
      });

      state.images = newImages;
      state.hasLikes = hasLikes;
    },
    setImageHideStatus: (state, action: PayloadAction<AnyAction>) => {
      state.images = action.payload.updatedImages;
    }
  }
});

export const {
  fetchSelectionImages,
  removeImage,
  updateSelectionImage,
  setImageHideStatus,
  setImages,
  setExistingImages
} = imagesSlice.actions;
export const imagesReducer = imagesSlice.reducer;
