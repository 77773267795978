import { createListenerMiddleware, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { setTrackingStatus, setCollection } from 'store/slices/collection';
import { ICollection } from 'types/collection';

export const collectionMiddleware = createListenerMiddleware();

const setUpEffectAction = (action: PayloadAction<ICollection>) => {
  const { type, templateName, design, _user } = action.payload;

  const isNewShopPage = window.location.pathname.includes('/shop-new');
  const isPhotobookPage = window.location.pathname.includes('/photobook');

  if (isNewShopPage || isPhotobookPage) {
    document.body.className = 'ScrAppBook ShopNew';
  } else if (type === 'ScrAppBook2') {
    document.body.className = `ScrAppBook ${templateName || 'dark'}`;
  } else {
    document.body.className = 'ScrAppBook SimpleFiligree';
  }

  if (design?.disableDecorations && !document.body.className.includes('disable-decorations')) {
    document.body.className += ' disable-decorations';
  }

  window.LANGUAGE = _.defaultTo(
    _.get(action, 'payload.language'),
    _.defaultTo(_.get(_user, 'languageApp'), 0)
  );
};

collectionMiddleware.startListening({
  actionCreator: setCollection,
  effect: setUpEffectAction
});

collectionMiddleware.startListening({
  actionCreator: setTrackingStatus,
  effect: (action: PayloadAction<'in' | 'out'>) => {
    window.localStorage.setItem(`tracking-${window.SITE_ID}`, `opt-${action.payload}`);
  }
});
