import { PrintsPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/PrintsPreview';
import { LeporellosPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/LeporellosPreview';
import { TriplexPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/TriplexPreview';
import { CalendarPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/CalendarPreview';
import { PhotoboxPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/PhotoboxPreview';
import { WallartPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/WallartPreview';
import { GreetingCardsPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/GreetingCardPreview';
import { StickersPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/Stickers';
import { GiftsPreview } from 'modules/ScrShop/components/PersonalizedPreview/OverviewPreview/GiftsPreview';

export const overviewPreviewMap = {
  prints: PrintsPreview,
  leporellos: LeporellosPreview,
  triplex: TriplexPreview,
  calendar: CalendarPreview,
  photobox: PhotoboxPreview,
  wallart: WallartPreview,
  greetingCards: GreetingCardsPreview,
  sticker: StickersPreview,
  gifts: GiftsPreview
};
