import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCheckoutStore } from 'modules/ScrShop/store/selectors/checkout';

export function PersistCheckout() {
  const checkout = useSelector(getCheckoutStore);
  useEffect(() => {
    if (checkout._id) {
      localStorage.setItem(`checkout_${window.SITE_ID}`, JSON.stringify(checkout));
      document.cookie = `checkoutID_${window.SITE_ID}=${checkout._id}`;
    }
  }, [checkout]);

  return null;
}
