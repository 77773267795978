import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import { useTheme } from 'hooks/useTheme';

interface ICheckbox {
  id: string;
  label: string;
  onClick: () => void;
  checked: boolean;
  disabled?: boolean;
  type?: string;
}

const CheckboxContainer = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 15px 0;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  font-size: 20px;
  font-family: ${({ theme }) => theme.colors.font} !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 15px;
  outline: none;
  height: 30px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`;

const CheckmarkWrapper = styled.div`
  height: 30px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const SquareCheckmark = styled(FaCheck)<{ checked: boolean }>`
  min-height: 20px;
  min-width: 20px;
  border: 2px solid ${({ theme }) => theme.colors.inputColor};
  border-radius: ${({ theme }) => theme.inputBorderRadius}px;
  background-color: ${({ checked, theme }) => (checked ? theme.colors.inputColor : 'white')};
  color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
`;

const RoundCheckmark = styled(FaCheck)<{ checked: boolean }>`
  height: 15px;
  width: 15px;
  border: 2px solid ${({ theme }) => theme.colors.inputColor};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ checked, theme }) => (checked ? theme.colors.inputColor : 'white')};
  border-radius: 50%;
  margin-right: 8px;
  path {
    transform: scale(0.7);
    transform-origin: center;
  }
`;

const CheckboxLabel = styled.span`
  color: ${({ theme }) => theme.colors.modalFontColorLight};
`;

export const Checkbox: React.FC<ICheckbox> = ({
  id,
  label,
  onClick,
  checked,
  disabled,
  type = 'square'
}) => {
  const [theme] = useTheme();

  const checkmarkMap = {
    square: <SquareCheckmark theme={theme} checked={checked} />,
    rounded: <RoundCheckmark theme={theme} checked={checked} />
  };

  return (
    <CheckboxContainer htmlFor={id} disabled={disabled}>
      <CheckmarkWrapper className="checkmark-wrapper">{checkmarkMap[type]}</CheckmarkWrapper>
      <CheckboxLabel theme={theme}>{label}</CheckboxLabel>
      <input
        id={id}
        className="needsclick" // needed it to avoid fastclick API on this element, as it breaks the default behavior
        onChange={onClick}
        type="checkbox"
        checked={checked}
        disabled={disabled}
      />
    </CheckboxContainer>
  );
};
