import { useSelector } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import { getCheckoutLoading } from 'modules/ScrShop/store/selectors';
import {
  getCheckoutIDSelector,
  getCheckoutProducts
} from 'modules/ScrShop/store/selectors/checkout';

export const useIsCheckoutReady = () => {
  const checkoutID = useSelector(getCheckoutIDSelector);
  const isCheckoutLoading = useSelector(getCheckoutLoading);
  const checkoutProducts = useSelector(getCheckoutProducts);
  const { emptyCartProductSuggestion = { enabled: false } } = useAppSelector(
    (state) => state.banners
  );

  return !(
    !isCheckoutLoading &&
    !emptyCartProductSuggestion.enabled &&
    (!checkoutID || (checkoutID && !checkoutProducts.length))
  );
};
