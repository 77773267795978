import styled from 'styled-components';
import arrowDown from 'assets/images/ChevronDownImage.svg';
import { theme } from 'assets/css/theme';
import { FormPart } from './index';

const SelectBox = styled.select`
  border: 1px solid #f1f1f1;
  color: ${theme.colors.primary};
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 10px;
  display: inline-flex;
  height: 45px;
  background-image: url('${arrowDown}');
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 12px;
  background-color: #f1f1f1;
  -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  errorText?: string;
}

export const Select: React.FC<IProps> = ({ label, errorText, ...props }) => (
  <FormPart isDisabled={props.disabled} label={label} errorText={errorText}>
    <SelectBox {...props} />
  </FormPart>
);
