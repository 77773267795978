import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useAppSelector } from 'hooks/redux';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';

export const Triplex15x15_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 1, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 2, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={60}
        left={7.5}
        width={24.8}
        height={22.9}
        transform="matrix3d(1,0.09,0,0.0002,-0.12,1.1,0,0,0,0,1,0,0,0,0,1) rotate(1deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={63.6}
        left={36.3}
        width={24.1}
        height={24}
        transform="rotate(6.3deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={66.3}
        left={64}
        width={24.4}
        height={21.9}
        transform="matrix3d(1,0.13,0,0,-0.11,1.13,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>
    </>
  );
};
