import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';
import { v4 as uuidv4 } from 'uuid';

export const WallartPreview = ({ backgroundImage, galleryId }) => {
  const verticalImages1 = useGalleryImage('vertical', 4, galleryId);
  const verticalImages2 = useGalleryImage('vertical', 5, galleryId);
  const verticalImages3 = useGalleryImage('vertical', 6, galleryId);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = verticalImages1._id ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 1057.2388 422.4882 L 1571 412 L 1559 897 L 1140 903 L 1136 893 C 1128 878 1118 860 1108 840 L 1065 781 L 1065 781 L 1065 781 L 1108 840 C 1096 830 1091 836 1083 848 L 1065 781" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={42.9}
        left={-15}
        width={22}
        height={36.5}
        transform="rotate(1deg)"
      >
        <PreviewImage image={verticalImages1.url_M} x={-520} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={43.3}
        left={7}
        width={2}
        height={37.2}
        transform="matrix3d(0.942209,0.4,0,0.0058871,-0.016,0.977747,0,0,0,0,1,0,0,0,0,1)"
        boxShadow="-3px 1px 12px -2px rgb(0 0 0 / 75%);"
      >
        <PreviewImage image={verticalImages1.url_M} x={-1420} />
      </PreviewImageContainer>

      <PreviewImageContainer top={24} left={42.1} width={21} height={32}>
        <PreviewImage image={verticalImages2.url_M} viewBox="0 0 920 1400" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={28}
        left={70}
        width={32}
        height={34}
        transform="rotate(-2deg)"
        zIndex={1}
      >
        <PreviewImage image={verticalImages3.url_M} viewBox="0 0 1500 1650" />
      </PreviewImageContainer>
    </>
  );
};
