import type { IGallery, IImage } from 'old-store/types/gallery';

export const getGalleryCardBackgroundImageUrl = (
  images: IImage[],
  galleryId: string
): string | undefined => {
  try {
    let backgroundImage = images.find((i) => i.gallery_id === galleryId && i.isTitleImage);

    if (!backgroundImage) {
      backgroundImage = images.find((i) => i.gallery_id === galleryId && i.width > i.height);
    }

    if (!backgroundImage) {
      backgroundImage = images.find((i) => i.gallery_id === galleryId);
    }

    return backgroundImage?.url_M;
  } catch (e) {
    console.log(e);
  }
};

export const getSliderImages = (images: IImage[]): IImage[] => {
  try {
    const isSmallScreen = document.body.clientWidth <= 992;
    const titleImageKey = isSmallScreen ? 'isSmallScreenTitleImage' : 'isTitleImage';

    const sliderImages = images.filter((i) => i[titleImageKey]);

    if (sliderImages.length === 0 && isSmallScreen) {
      const titleImage = images.find((img) => img.isTitleImage);
      if (titleImage) sliderImages.push(titleImage);
    }

    if (sliderImages.length === 0) {
      const titleImage = images.find(
        (img) =>
          (isSmallScreen && img.height > img.width) || (!isSmallScreen && img.width > img.height)
      );

      if (titleImage) {
        sliderImages.push(titleImage);
      } else if (images.length) {
        sliderImages.push(images[0]);
      }
    }

    return sliderImages;
  } catch (e) {
    console.log(e);

    // if we fail to find slider images, return the first 5 images
    return images.slice(0, 5);
  }
};

// we need to galleries because only that contains the sort order
export const getSortedSliderImages = (images: IImage[], galleries: IGallery[]): IImage[] => {
  // remove gallery for purchased images because they are not needed to find title imaes
  // then sort galleries by order property
  const sortedGalleries = galleries
    .filter((g) => !g.forPurchasedImages)
    .sort((a, b) => a.order - b.order);

  const sortedImages: IImage[] = [];

  // for each sorted gallery in that order find all the matching images
  // so images are sorted before getting the sliderimages
  sortedGalleries.forEach((gallery) => {
    const galleryImages = images.filter((image) => image.gallery_id === gallery._id);
    if (galleryImages) sortedImages.push(...galleryImages);
  });

  // get all slider images with the already sorted images
  const sliderImages = getSliderImages(sortedImages);

  return sliderImages;
};
