import { useBrandInfo } from 'components/Photographer/useBrandInfo';
import { getTranslationKey } from 'helpers/texting';
import { getCleanUrl } from 'helpers/url';
import { useAppSelector } from 'hooks/redux';

export const Contact: React.FC = () => {
  const { collectionCreator } = useAppSelector((state) => state.collection);
  const userBrands = useAppSelector((state) => state.userBrand);
  const { company, email } = useBrandInfo();

  return (
    <div>
      <h2 className="footer-title">{getTranslationKey('photographerView.title')}</h2>
      <ul className="mb-0 mt-0">
        {company && (
          <li>
            <strong>
              <span className="company">{company}</span>
            </strong>
          </li>
        )}
        <li>
          <span className="user-name">
            {collectionCreator.firstName || ''} {collectionCreator.lastName || ''}{' '}
          </span>
        </li>
        {userBrands.tel && userBrands.tel.length > 0 && (
          <li>
            <a href={`tel:${userBrands.tel}`}>{userBrands.tel}</a>
          </li>
        )}
        {email && (
          <li className="d-flex align-items-center">
            {/* eslint-disable-next-line */}
            <a
              onClick={(e) => {
                e.preventDefault();
                window.open(`mailto:${email}`, '_self');
              }}
              href="#"
            >
              {email}
            </a>
          </li>
        )}
        {userBrands.website && userBrands.website.length > 0 && (
          <li>
            <a target="_blank" rel="noreferrer" href={getCleanUrl(userBrands.website)}>
              {userBrands.website}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
