import { Button } from 'reactstrap';
import styled from 'styled-components';

import { IPackage } from 'modules/ScrShop/store/types';
import { displayProductPrice } from 'modules/ScrShop/store/utils';
import { getTranslationKey } from 'helpers/texting';
import { useCurrency } from 'hooks/useCurrency';
import { FaCheck, FaStar } from 'react-icons/fa';
import { media } from 'assets/css/media';

interface IProps {
  imagePackage: IPackage;
  selectedImagesAmount: number;
  savings: number | null;
  isAlreadyPurchasedPackage: boolean;
  matchedPackage?: IPackage;
  sameAmountPackages?: IPackage[];
  selectPackage: (packageItem: IPackage) => void;
  packagesSortedByTier: IPackage[];
  addPackageToCart: (packageId: string) => void;
  toggleSaveSelectionModal: () => void;
  bestseller?: boolean;
}

const getBorderColor = (theme, isPackageMatched, bestseller) => {
  if (bestseller) return theme.colors.secondaryButtonFontColor;
  if (isPackageMatched) return '#fff';

  return '#000';
};

const PackageCardWrapper = styled.div<{
  isPackageMatched: boolean;
  isClickable: boolean;
  bestseller?: boolean;
  templateName?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: ${({ bestseller }) => (bestseller ? '325px' : '310px')};
  margin-right: ${() => (window.innerWidth < 992 ? '0px' : '10px')};
  margin-bottom: 10px;
  padding: 30px 20px 20px 20px;
  padding-top: ${({ bestseller }) => (bestseller ? '55px' : '30px')};
  color: #000;
  text-decoration: none;
  position: relative;
  border-radius: 20px;
  &:last-child {
    margin-right: 0;
  }
  @media all and (max-width: ${media.desktopXl}) {
    height: calc(100% - 15px);
    min-height: initial;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 20px;
    border: 1px solid
      ${({ isPackageMatched, bestseller, theme }) =>
        getBorderColor(theme, isPackageMatched, bestseller)};
    border-width: ${({ bestseller }) => (bestseller ? '2px' : '1px')};
    top: ${({ bestseller }) => (bestseller ? '11px' : '0')};
    left: 0;
    height: ${({ bestseller }) => (bestseller ? 'calc(100% - 10px)' : '100%')};
    background: ${({ isPackageMatched }) => (isPackageMatched ? '#F5F4F4' : '#fff')};
    width: 100%;
    z-index: 1;
  }
`;

const BestsellerHeaderBlock = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid ${({ theme }) => theme.colors.secondaryButtonFontColor};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0 20px;
  height: 24px;
  z-index: 2;
  font-weight: bold;
  font-size: 18px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TriangleLeft = styled.div`
  width: 0px;
  height: 0px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid ${({ theme }) => theme.colors.secondaryButtonFontColor};
  position: absolute;
  top: -2px;
  left: -13.7px;
`;

const TriangleRight = styled.div`
  width: 0px;
  height: 0px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid ${({ theme }) => theme.colors.secondaryButtonFontColor};
  position: absolute;
  top: -2px;
  right: -13.7px;
`;

const PackageHeader = styled.div`
  height: 90px;
  position: relative;
  z-index: 2;
  @media all and (max-width: ${media.desktopXl}) {
    height: 80px;
  }
`;

const PackageName = styled.h3<{ isPackageMatched: boolean }>`
  &&&& {
    font-size: 20px !important;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ isPackageMatched }) =>
      isPackageMatched ? '#707070 !important' : '#000 !important'};

    @media all and (max-width: ${media.desktopXl}) {
      margin-bottom: 5px;
    }
  }
`;

const PackagePrice = styled.h4<{ isPackageMatched: boolean }>`
  &&&& {
    font-size: 18px;
    font-weight: 600;
    color: ${({ isPackageMatched }) =>
      isPackageMatched ? '#707070 !important' : '#000 !important'};

    @media all and (max-width: ${media.desktopXl}) {
      margin-bottom: 5px;
    }
  }
`;

const PackageSavings = styled.div`
  margin-top: 2px;
  font-size: 15px;
  color: #707070;
`;

const BenefitsContent = styled.ul`
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
  color: #707070;
  height: 105px;
  position: relative;
  z-index: 2;
  @media all and (max-width: ${media.desktopXl}) {
    margin-bottom: 5px;
  }
`;

const BenefitItem = styled.li`
  && {
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    span {
      color: #000 !important;
    }
  }
`;

const CheckWrap = styled.div`
  background: #707070;
  border-radius: 50%;
  min-width: 15px;
  min-height: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;

const StyledCheck = styled(FaCheck)`
  color: #f5f4f4;
  width: 10px;
  height: 10px;
`;

const ConfirmButton = styled(Button)<{ isMatched: boolean }>`
  background: ${({ isMatched }) => (isMatched ? '#E6E6E6' : '#000')};
  color: ${({ isMatched }) => (isMatched ? '#707070' : '#fff')};
  border: 0;
  border-radius: 20px;
  position: relative;
  z-index: 2;
`;

const StyledFaStar = styled(FaStar)`
  min-width: 13px;
  min-height: 13px;
  color: ${({ theme }) => theme.colors.secondary};
  fill: ${({ theme }) => theme.colors.secondary};
  margin-right: 5px;
`;

const Checkmark = () => (
  <CheckWrap>
    <StyledCheck />
  </CheckWrap>
);

export const UpsellingPackageCard: React.FC<IProps> = ({
  imagePackage,
  addPackageToCart,
  isAlreadyPurchasedPackage,
  matchedPackage,
  sameAmountPackages = [],
  selectPackage,
  // packagesSortedByTier,
  selectedImagesAmount,
  toggleSaveSelectionModal,
  savings,
  bestseller
}) => {
  const currency = useCurrency();
  const imagesAmountNeeded = imagePackage.imagesQuantity - selectedImagesAmount;
  const otherPackageIsMatched = !!matchedPackage && matchedPackage.id !== imagePackage.id;
  const isClickable = imagesAmountNeeded > 0 && !isAlreadyPurchasedPackage;

  const isPackageMatched = imagesAmountNeeded <= 0 && !otherPackageIsMatched;
  const pricePerImage = imagePackage.grossTotalPrice / imagePackage.imagesQuantity;

  const selectAnotherPackage = () => {
    const sameAmountPackagesIds = sameAmountPackages.map(({ id }) => id);
    const isPackageWithSameAmount = sameAmountPackagesIds.includes(imagePackage.id);

    if (!isPackageMatched && isPackageWithSameAmount) selectPackage(imagePackage);
    else toggleSaveSelectionModal();
  };

  return (
    <PackageCardWrapper
      isClickable={isClickable}
      isPackageMatched={isPackageMatched}
      bestseller={bestseller}
    >
      {bestseller && (
        <BestsellerHeaderBlock>
          <TriangleLeft />
          <StyledFaStar />
          {getTranslationKey('shop.upselling.bestseller')}
          <TriangleRight />
        </BestsellerHeaderBlock>
      )}
      <PackageHeader>
        <PackageName isPackageMatched={isPackageMatched}>{imagePackage.name}</PackageName>
        <PackagePrice isPackageMatched={isPackageMatched}>
          {displayProductPrice(imagePackage.grossTotalPrice, currency)}
        </PackagePrice>
        {!!savings && (
          <PackageSavings>
            {getTranslationKey('shop.upselling.packageSavings', {
              savingsPercentage: savings
            })}
          </PackageSavings>
        )}
      </PackageHeader>

      <BenefitsContent>
        <BenefitItem>
          <Checkmark />
          <span>
            {getTranslationKey('shop.upselling.dataIncluded', {
              quantity: imagePackage.imagesQuantity
            })}
          </span>
        </BenefitItem>
        <BenefitItem>
          <Checkmark />
          <span>
            {getTranslationKey('shop.upselling.dataPrice', {
              price: displayProductPrice(pricePerImage, currency)
            })}
          </span>
        </BenefitItem>
        <BenefitItem>
          <Checkmark />
          <span>
            {getTranslationKey('shop.upselling.additionalPrice', {
              price: displayProductPrice(imagePackage.grossAdditionalImagePrice, currency)
            })}
          </span>
        </BenefitItem>
      </BenefitsContent>
      <div className="d-flex flex-column align-items-center w-100">
        {isPackageMatched ? (
          <ConfirmButton
            isMatched
            className="w-100-p mt-1"
            onClick={() => addPackageToCart(imagePackage.id)}
          >
            {getTranslationKey('shop.upselling.packageSelected')}
          </ConfirmButton>
        ) : (
          <ConfirmButton className="w-100-p mt-1" onClick={selectAnotherPackage}>
            {getTranslationKey('shop.upselling.selectPackageItem')}
          </ConfirmButton>
        )}
      </div>
    </PackageCardWrapper>
  );
};
