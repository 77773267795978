import styled from 'styled-components';
import media from 'assets/css/media';
import { theme } from 'assets/css/theme';
import { Exist } from 'components/Exist';
import { PromoBanner } from 'components/PromoBanner';
import { intersection } from 'lodash';
import { useState } from 'react';
import { FaAngleLeft, FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavItem } from 'reactstrap';
import { BreadcrumbComponent } from 'modules/ScrShop/components/BreadcrumbComponent';
import { TagManagerPageTracking } from 'modules/ScrShop/components/TagManagerPageTracking';
import { getBackButtonRoute, getSectionTitleSelector } from 'modules/ScrShop/store/selectors';
import { getNumberOfCheckoutProducts } from 'modules/ScrShop/store/selectors/checkout';
import { AccountDropdown } from '../AccountDropdown';
import { getTranslationKey } from 'helpers/texting';

interface INavigation {
  $isHideOnDesktop?: boolean;
  $isHideOnMobile?: boolean;
}

const Navigation = styled(Navbar)<INavigation>`
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgb(189 189 189 / 50%);
  display: ${(props) => (props.$isHideOnMobile ? 'none' : 'flex')};
  padding: 20px;
  @media (min-width: ${media.desktop}) {
    display: ${(props) => (props.$isHideOnDesktop ? 'none' : 'flex')};
  }
`;
const SectionTitle = styled.div`
  flex: 1;
  text-align: left;
  padding-left: 30px;
  @media (min-width: ${media.desktop}) {
    order: 3;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 0;
  }
`;
const Collapsable = styled(Collapse)`
  @media (min-width: ${media.desktop}) {
    order: 5;
  }
`;
const NavSubMenu = styled(Nav)`
  text-align: right;
  @media (min-width: ${media.desktop}) {
    text-align: left;
  }
`;
const NavSubMenuItem = styled(NavItem)`
  padding: 5px 0;
`;
const CartIconWrapper = styled.div`
  margin-right: 10px;
  position: relative;
  margin-left: 10px;

  svg {
    margin-left: 20px;
  }

  @media (min-width: ${media.desktop}) {
    order: 4;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;
const CardProductsLabel = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e01d1d;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
`;
const BackButton = styled.div`
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: ${theme.colors.buttonSecondaryHoverBg};
  position: relative;
  z-index: 2;
  @media (min-width: ${media.desktop}) {
    order: 2;
  }

  span {
    margin-left: 10px;
    @media (min-width: ${media.desktop}) {
      display: block;
    }
  }
`;
// tslint:disable-next-line:no-commented-code
// const Toggle = styled( NavbarToggler )`
// 	@media (min-width: ${ media.desktop }) {
// 		order: 1;
// 	}
// `;
// const AccountIconWrapper = styled( NavLink )``

interface IProps {
  childPosition?: 'top' | 'bottom';
  $isHideOnMobile?: boolean;
  $isHideOnDesktop?: boolean;
  isShowBanner?: boolean;
}

export const HOCMenu: React.FC<IProps> = ({
  children,
  isShowBanner = false,
  childPosition,
  $isHideOnDesktop,
  $isHideOnMobile
}) => {
  const { pathname } = useLocation();
  const productsInCart = useSelector(getNumberOfCheckoutProducts);
  const history = useHistory();
  const sectionTitle = useSelector(getSectionTitleSelector);
  const [collapsed] = useState(true);
  const backButtonRoute = useSelector(getBackButtonRoute);
  const moveBackHandler = () => {
    if (backButtonRoute.length) history.push(backButtonRoute);
    else history.goBack();
  };
  const isHideInner = intersection(pathname.split('/'), ['payment']).length;
  const isHideShoppingCartIcon = intersection(pathname.split('/'), [
    'basket',
    'shipping',
    'confirm'
  ]).length;
  const isChildTop = childPosition === 'top';
  const isChildBottom = childPosition === 'bottom';

  return (
    <>
      <TagManagerPageTracking />
      <Exist when={isChildTop}>
        {children}
        <Exist when={isShowBanner}>
          <Container>
            <PromoBanner type="fullWidth" section="shop" />
          </Container>
        </Exist>
      </Exist>
      <Navigation
        sticky="top"
        $isHideOnDesktop={$isHideOnDesktop}
        $isHideOnMobile={$isHideOnMobile}
      >
        {!isHideInner ? (
          <>
            <BackButton onClick={moveBackHandler} data-cy="component-back-button">
              <FaAngleLeft size={15} />
              <span>{getTranslationKey('shop.menu.galleryTitle')}</span>
            </BackButton>
            <BreadcrumbComponent />
            <SectionTitle>{sectionTitle}</SectionTitle>
            <CartIconWrapper>
              {/* { */}
              {/*	authed ? ( */}
              {/*		<AccountIconWrapper to={ '/account' }> */}
              {/*			<FaUserAlt size={ 25 } color={ theme.colors.primary }/> */}
              {/*		</AccountIconWrapper> */}
              {/*	) : null */}
              {/* } */}
              <div style={{ position: 'relative' }} className="me-3" data-cy="cart-icon">
                {productsInCart && !isHideShoppingCartIcon ? (
                  <NavLink to="/shop/basket">
                    <FaShoppingCart size={25} color={theme.colors.primary} />
                    <CardProductsLabel>{productsInCart}</CardProductsLabel>
                  </NavLink>
                ) : null}
              </div>
              <AccountDropdown />
            </CartIconWrapper>
            {/* <Toggle onClick={ toggleNavbar }/> */}
            <Collapsable isOpen={!collapsed} navbar>
              <NavSubMenu navbar className="me-auto">
                <NavSubMenuItem>
                  <NavLink to="/shop">{getTranslationKey('shop.menu.shopItem')}</NavLink>
                </NavSubMenuItem>
                <NavSubMenuItem>
                  <NavLink to="/download">{getTranslationKey('shop.menu.download')}</NavLink>
                </NavSubMenuItem>
                <NavSubMenuItem>
                  <NavLink to="/share">{getTranslationKey('shop.menu.share')}</NavLink>
                </NavSubMenuItem>
                <NavSubMenuItem>
                  <NavLink to="/login">{getTranslationKey('shop.menu.login')}</NavLink>
                </NavSubMenuItem>
              </NavSubMenu>
            </Collapsable>
          </>
        ) : null}
      </Navigation>
      <Exist when={isChildBottom}>
        <Exist when={isShowBanner}>
          <Container>
            <PromoBanner type="fullWidth" section="shop" />
          </Container>
        </Exist>
        {children}
      </Exist>
    </>
  );
};
