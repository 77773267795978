import { useRef, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'modules/ScrShop/components/Forms';
import { Col, Form, Modal, Row } from 'reactstrap';
import {
  checkEndCustomerEmail,
  createAccountWithPasswordAction,
  setCreateAccountWithPasswordFlowStatus,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShop/store/actions';
import { signupWithPasswordFlowStatusSelector } from 'modules/ScrShop/store/selectors';
import { Button } from 'modules/ScrShop/components/Buttons';
import EnterPasswordModal from '../LoginWithPasswordAccountModal/EnterPasswordModal';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { LinkBtn } from 'components/IconButton/LinkButton';
import { getTranslationKey } from 'helpers/texting';
import { useToggle } from 'hooks/useToggle';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function CreateAccountEmailModal() {
  const dispatch = useDispatch();
  const ref = useRef<RefObject<HTMLInputElement>>(null);
  const { toggle: isValidated, openToggle: enableValidation } = useToggle();
  const { showEmailModal, showPasswordModal, email, password, emailIsAlreadyTaken } = useSelector(
    signupWithPasswordFlowStatusSelector
  );

  const setEmail = (email: string) => dispatch(setCreateAccountWithPasswordFlowStatus({ email }));

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(getTranslationKey('checkEmailFormat'))
        .required(getTranslationKey('mandatoryField'))
    }),
    onSubmit: async (values, { resetForm }) => {
      setEmail(values.email);
      dispatch(checkEndCustomerEmail(values.email.toLowerCase()));
      resetForm();
    }
  });

  const setPassword = (password: string) =>
    dispatch(setCreateAccountWithPasswordFlowStatus({ password }));
  const toggleModal = () =>
    dispatch(
      setCreateAccountWithPasswordFlowStatus({
        showEmailModal: !showEmailModal
      })
    );
  const togglePasswordModal = () =>
    dispatch(
      setCreateAccountWithPasswordFlowStatus({
        showPasswordModal: !showPasswordModal
      })
    );

  const openLoginModal = () => {
    if (showEmailModal) toggleModal();
    if (showPasswordModal) togglePasswordModal();

    dispatch(toggleLoginWithPasswordModalAction());
  };

  const onPasswordSubmit = (password: string) => {
    dispatch(
      // @ts-ignore
      createAccountWithPasswordAction({
        password,
        email: email.toLowerCase(),
        collectionId: window.SITE_ID,
        firstName: '',
        lastName: ''
      })
    );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  return (
    <>
      <Modal
        data-cy="component-create-profile"
        centered
        isOpen={showEmailModal}
        toggle={toggleModal}
        size="md"
        // @ts-ignore
        zIndex={999999}
      >
        <ModalHeader toggle={toggleModal}>
          {getTranslationKey('createAccountModal.title')}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col xs="12">
                <Input
                  ref={ref}
                  data-cy="component-input-email"
                  name="email"
                  type="email"
                  label={getTranslationKey('shop.forms.emailTitle')}
                  placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={isValidated ? errors.email : ''}
                />
              </Col>
              <Col xs="12">
                {emailIsAlreadyTaken && (
                  <p className="text-center">
                    {getTranslationKey('signupWithPassword.emailIsAlreadyTaken')}
                  </p>
                )}
                <Button
                  className="sb-btn sb-btn-icon w-100-p text-center m-b-10"
                  disabled={false}
                  type="submit"
                  data-cy="component-submit-button"
                >
                  {getTranslationKey('loginAccountModal.loginFromShopContinue')}
                </Button>
                <LinkBtn onClick={openLoginModal} onKeyDown={openLoginModal}>
                  {getTranslationKey('createAccountModal.alreadyAccount')}
                </LinkBtn>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <EnterPasswordModal
        title={getTranslationKey('createAccountModal.title')}
        text={getTranslationKey('signupWithPassword.enterPasswordText')}
        submitButtonLabel={getTranslationKey('signupWithPassword.createAccount')}
        showEnterPasswordModal={showPasswordModal}
        passwordIsInvalid={false}
        password={password}
        footerLinkText={getTranslationKey('signupWithPassword.returnToLogin')}
        onSubmit={onPasswordSubmit}
        toggleModal={togglePasswordModal}
        setPassword={setPassword}
        onFooterLinkClick={openLoginModal}
      />
    </>
  );
}
