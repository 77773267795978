import { useRef, RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'modules/ScrShop/components/Forms';
import { Col, Form, Modal, Row } from 'reactstrap';
import {
  checkEndCustomerPassword,
  loginCustomerWithPasswordAction,
  setCreateAccountWithPasswordFlowStatus,
  setEmailForLoginWithPassword,
  setForgotPasswordFlowStatus,
  setPasswordForLoginWithPassword,
  toggleEnterPasswordModalAction,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShop/store/actions';
import { loginFlowStatusSelector } from 'modules/ScrShop/store/selectors';
import { Button } from 'modules/ScrShop/components/Buttons';
import EnterPasswordModal from './EnterPasswordModal';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { LinkBtn } from 'components/IconButton/LinkButton';
import { getTranslationKey } from 'helpers/texting';
import { useToggle } from '../../../../hooks/useToggle';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function LoginWithPasswordAccountModal() {
  const dispatch = useDispatch();
  const {
    showLoginWithPasswordModal,
    showEnterPasswordModal,
    endCustomerHasPassword,
    emailDoesNotExists,
    passwordIsInvalid,
    password,
    email
  } = useSelector(loginFlowStatusSelector);
  const ref = useRef<RefObject<HTMLInputElement>>(null);

  const { toggle: isValidated, openToggle: enableValidation } = useToggle();
  const setEmail = (email: string) => dispatch(setEmailForLoginWithPassword(email.toLowerCase()));
  const setPassword = (password: string) => dispatch(setPasswordForLoginWithPassword(password));
  const toggleModal = () => dispatch(toggleLoginWithPasswordModalAction());
  const togglePasswordModal = () => dispatch(toggleEnterPasswordModalAction());

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(getTranslationKey('checkEmailFormat'))
        .required(getTranslationKey('mandatoryField'))
    }),
    onSubmit: async (values, { resetForm }) => {
      setEmail(values.email);
      dispatch(checkEndCustomerPassword(values.email.toLowerCase()));
      resetForm();
    }
  });

  useEffect(() => {
    document.body.style.overflowY = 'unset';
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  const onPasswordSubmit = (password: string) => {
    dispatch(
      loginCustomerWithPasswordAction({
        password,
        email: email.toLowerCase()
      })
    );
  };

  const openSignupModal = () => {
    if (showLoginWithPasswordModal) toggleModal();
    if (showEnterPasswordModal) togglePasswordModal();

    dispatch(
      setCreateAccountWithPasswordFlowStatus({
        showEmailModal: true
      })
    );
  };

  const openForgotPasswordModal = () => {
    toggleModal();
    dispatch(
      setForgotPasswordFlowStatus({
        showForgotPasswordModal: true,
        redirectToLoginModal: true,
        email: email.toLowerCase()
      })
    );
  };

  return (
    <>
      <Modal
        data-cy="component-login-modal"
        centered
        isOpen={showLoginWithPasswordModal}
        toggle={toggleModal}
        size="md"
        zIndex={999999}
      >
        <ModalHeader toggle={toggleModal} data-cy="component-login-close">
          {getTranslationKey('loginAccountModal.title')}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col xs="12">
                <Input
                  ref={ref}
                  data-cy="component-input-login"
                  id="email-input"
                  name="email"
                  type="email"
                  label={getTranslationKey('shop.forms.emailTitle')}
                  placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={isValidated ? errors.email : ''}
                />
              </Col>
              <Col xs="12">
                {emailDoesNotExists && (
                  <p className="text-center" data-cy="text-email-not-found">
                    {getTranslationKey('loginAccountModal.emailNotFound')}
                  </p>
                )}
                <Button
                  data-cy="component-button-continue"
                  className="sb-btn sb-btn-icon w-100-p text-center m-b-10"
                  type="submit"
                >
                  {getTranslationKey('loginAccountModal.loginFromShopContinue')}
                </Button>
                <LinkBtn
                  onClick={openSignupModal}
                  onKeyDown={openSignupModal}
                  data-cy="component-open-sign-up-modal"
                >
                  {getTranslationKey('loginAccountModal.noAccount')}
                </LinkBtn>
                <LinkBtn onClick={openForgotPasswordModal} onKeyDown={openForgotPasswordModal}>
                  {getTranslationKey('shop.forms.forgotPassword')}
                </LinkBtn>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <EnterPasswordModal
        title={
          endCustomerHasPassword
            ? getTranslationKey('loginAccountModal.title')
            : getTranslationKey('enterPasswordModal.titlePasswordWasGenerated')
        }
        text={
          endCustomerHasPassword
            ? getTranslationKey('enterPasswordModal.textHasPassword', { email })
            : getTranslationKey('enterPasswordModal.textPasswordWasGenerated')
        }
        submitButtonLabel={getTranslationKey('loginAccountModal.submit')}
        showEnterPasswordModal={showEnterPasswordModal}
        password={password}
        passwordIsInvalid={passwordIsInvalid}
        toggleModal={togglePasswordModal}
        onSubmit={onPasswordSubmit}
        setPassword={setPassword}
      />
    </>
  );
}

export default LoginWithPasswordAccountModal;
