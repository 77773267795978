import classNames from 'classnames';
import styled from 'styled-components';
import { useState } from 'react';
import { Alert } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';

import { checkPassword } from 'old-store/actions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getTranslationKey } from 'helpers/texting';
import { Input } from 'modules/ScrShop/components/Forms';
import { Button } from 'modules/ScrShop/components/Buttons';
import { useTheme } from 'hooks/useTheme';

const Form = styled.form<{ background: string }>`
  overflow: hidden;
  height: 100%;
  background: ${({ background }) => background};
`;

const PageTitle = styled.h1<{ color: string }>`
  color: ${({ color }) => color};
`;

const InputWrapper = styled.div<{ labelColor: string }>`
  label {
    color: ${({ labelColor }) => labelColor};
  }
`;

const PasswordForm = styled.div<{ color: string; secondaryColor: string }>`
  h1,
  label {
    color: ${({ color }) => color} !important;
  }
  button {
    color: ${({ secondaryColor }) => secondaryColor} !important;
  }
`;

export const PasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState('');
  const [theme] = useTheme();
  const { title, templateName } = useAppSelector((state) => state.collection);

  const { pw, locked_until: lockedUntil } = queryString.parse(window.location.search);

  const handlePasswordCheck = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    event.preventDefault();

    dispatch(checkPassword(window.SITE_ID, password));
  };

  const color = templateName === 'Rose' ? theme.colors.primaryColor : '';
  const secondaryColor = templateName === 'Rose' ? theme.colors.primaryColor : '';

  return (
    <Form background={theme.colors.pageBg} onSubmit={handlePasswordCheck}>
      <PasswordForm
        className="password-form form-group max-w-400 mx-auto p-t-100 p-20"
        color={color}
        secondaryColor={secondaryColor}
      >
        <PageTitle color={theme.colors.fontColor} className="text-center mb-5">
          {title}
        </PageTitle>

        {!window.navigator.cookieEnabled ? (
          <p className="text-center">{getTranslationKey('allowCookies')}</p>
        ) : (
          <InputWrapper labelColor={theme.colors.fontColor}>
            <Input
              type="password"
              id="pin-password-input"
              className="text-center"
              label={getTranslationKey('shop.forms.galleryPassword')}
              placeholder={getTranslationKey('password')}
              value={password}
              onChange={(event) => setPassword(event.target.value.trimStart())}
              onBlur={(event) => setPassword(event.target.value.trim())}
              spellCheck="false"
              withEye
            />
            <Button
              theme={theme}
              onClick={handlePasswordCheck}
              className={classNames('m-t-10', {
                'sb-btn': !theme.isSecondaryColor,
                'sb-btn-secondary': theme.isSecondaryColor
              })}
              style={{
                borderColor: theme.colors.inputBorderColor
              }}
            >
              {getTranslationKey('shop.forms.viewGallery')}
            </Button>
          </InputWrapper>
        )}

        <div className="mt-4">
          {pw === 'wrong' && <Alert color="danger">{getTranslationKey('passwordWrong')}</Alert>}

          {lockedUntil && (
            <Alert color="danger">
              {getTranslationKey('singInLockedUntil').replace(
                '{{until}}',
                moment(lockedUntil).add(1, 'm').format('DD.MM.YY - HH:mm')
              )}
            </Alert>
          )}
        </div>
      </PasswordForm>
    </Form>
  );
};
