import { useEffect, useRef } from 'react';

export function useOnOrientationChange(callback: () => void) {
  const savedCallback = useRef<(() => void) | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let timeoutId: number;

    const onOrientationchange = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    // slows down resize as to not hurt performance
    window.addEventListener('orientationchange', onOrientationchange);

    // call immediately on load so that we have an initial value
    // eslint-disable-next-line
    timeoutId = window.setTimeout(onOrientationchange);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('orientationchange', onOrientationchange);
    };
  }, []);
}
