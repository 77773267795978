import { useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import styled from 'styled-components';

const DragLayerContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const DragPreview = styled.div<{
  offsetX: number;
  offsetY: number;
  width?: number;
  height?: number;
}>`
  position: absolute;
  transform: ${({ offsetX, offsetY }) => `translate(${offsetX}px, ${offsetY}px)`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  z-index: 999;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const DesktopDragLayer = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset()
  }));

  const containerRef = useRef<HTMLDivElement>(null);

  if (!isDragging || !initialOffset || !currentOffset) return null;

  const offsetWidth = item?.offsetWidth || 0;
  const offsetHeight = item?.offsetHeight || 0;

  const offsetX = currentOffset.x;
  const offsetY = currentOffset.y;

  return (
    <DragLayerContainer id="drag-layer" ref={containerRef}>
      <DragPreview
        offsetX={offsetX}
        offsetY={offsetY}
        width={offsetWidth}
        height={offsetHeight}
        id="drag-preview"
        style={{ cursor: isDragging ? 'grabbing' : 'pointer' }}
      >
        <img src={item.image.url} alt="drag-preview" />
      </DragPreview>
    </DragLayerContainer>
  );
};

export default DesktopDragLayer;
