import { createSelector } from '@reduxjs/toolkit';

import type { IStore } from 'old-store/types';

export const selectImages = (state: IStore) => state.images.images;
const selectEndCustomer = (state: IStore) => state.endCustomer;

export const selectAllImagesExceptGifs = createSelector(selectImages, (images) =>
  images.filter((image) => image.extension.toLowerCase() !== 'gif')
);

export const selectHasHiddenImages = createSelector(
  selectImages,
  selectEndCustomer,
  (images, endCustomer) => {
    const hiddenImages = images.filter((image) => image.hiddenBy.includes(endCustomer._id));

    return hiddenImages.length > 0;
  }
);
