import { appReducer } from 'store/slices/app';
import { bannersReducer } from 'store/slices/banners';
import { collectionReducer } from 'store/slices/collection';
import { endCustomerReducer } from 'store/slices/endCustomer';
import { imageReducer } from 'store/slices/image';
import { imagesReducer } from 'store/slices/images';
import { modalsReducer } from 'store/slices/modals';
import { selectionReducer } from 'store/slices/selection';
import { sharingReducer } from 'store/slices/sharing';
import { userBrandReducer } from 'store/slices/userBrand';
import { messagesReducer } from './slices/messages';
import { portfolioReducer } from './slices/portfolios';
import { photobookReducer } from 'modules/ScrPhotobook/store/slices/photobook';

export const reducers = {
  collection: collectionReducer,
  modals: modalsReducer,
  images: imagesReducer,
  userBrand: userBrandReducer,
  sharing: sharingReducer,
  selection: selectionReducer,
  endCustomer: endCustomerReducer,
  banners: bannersReducer,
  image: imageReducer,
  app: appReducer,
  messages: messagesReducer,
  portfolio: portfolioReducer,
  photobook: photobookReducer
};
