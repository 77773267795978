import {
  IShopCoupon,
  IShopCouponDiscount,
  ShopCouponTypesEnum,
  ShopCouponValueTypeEnum
} from 'types/coupons';

export const DEFAULT_DISCOUNT: IShopCouponDiscount = {
  type: ShopCouponTypesEnum.Total,
  valueType: ShopCouponValueTypeEnum.Percent,
  productGroupId: null,
  quantityMax: null,
  valueFixed: 0,
  valuePercent: 20
};

export const DEFAULT_COUPON: IShopCoupon = {
  id: '',
  user: '',
  name: '',
  description: '',
  active: true,
  code: '',
  codeLowerCase: '',
  validUntilDate: '',
  discounts: [DEFAULT_DISCOUNT]
};
