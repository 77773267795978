import styled from 'styled-components';
import media from 'assets/css/media';

interface IHideSectionQuery {
  mediaSection: 'mobile' | 'desktop';
}

export const HideSectionQuery = styled.div<IHideSectionQuery>`
  @media (${(props) => (props.mediaSection === 'mobile' ? 'max-width' : 'min-width')}: ${(props) =>
      media[props.mediaSection]}) {
    display: none;
  }
`;
