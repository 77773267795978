import { get, isNull, mapValues } from 'lodash';

import type { IDictionary } from 'modules/ScrShop/store/types';
import Texts from 'texts.json';

export const getTranslationKey = (key: string, replacedValues?: IDictionary<string | number>) => {
  const text = get(Texts[window.LANGUAGE], key, null);
  let defaultString = isNull(text) ? `key - ${key} not found` : text;
  mapValues(replacedValues, (value, replaceKey) => {
    defaultString = defaultString.replace(`{${replaceKey}}`, value as string);
  });
  return !defaultString ? `key - ${key} not translated` : defaultString;
};
