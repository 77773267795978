import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from 'old-store/utils';
import { setMessage, toggleMessage } from '.';
import { getCookie } from 'helpers/cookie';
import { IStore } from 'old-store/types';

function getMessageViews(messageId: string): number {
  const messagesViews = JSON.parse(localStorage.getItem('messagesViews') ?? '{}');
  const messageViews =
    messagesViews[window.SITE_ID]?.currentMessage === messageId
      ? messagesViews[window.SITE_ID]?.views || 0
      : 0;

  return messageViews;
}

export const fetchMessage = createAsyncThunk(
  'messages/fetchMessageForEndCustomer',
  async (_: void, { dispatch, getState }) => {
    try {
      const { endCustomer } = getState() as IStore;
      const mainCustomerId = getCookie('mainCustomerId');
      const endCustomerId = endCustomer?._id || mainCustomerId;

      const { result } = await Api.Messages.getForEndCustomer();
      const message = result?.[0] ?? null;

      if (endCustomerId) {
        dispatch(setMessage(message));
        dispatch(toggleMessage(true));

        return;
      }

      const collectionsViews = JSON.parse(localStorage.getItem('collectionsViews') ?? '{}');
      const messageViews = getMessageViews(message?.id ?? '');

      if (
        collectionsViews[window.SITE_ID] &&
        message &&
        message.minCollectionViews !== collectionsViews[window.SITE_ID].minCollectionViews
      ) {
        collectionsViews[window.SITE_ID] = {
          minCollectionViews: message.minCollectionViews,
          views: 0
        };
        localStorage.setItem('collectionsViews', JSON.stringify(collectionsViews));
      }
      const collectionViews = collectionsViews[window.SITE_ID]?.views || 0;

      if (
        message &&
        messageViews < message.maxMessageViews &&
        collectionViews >= message.minCollectionViews
      ) {
        dispatch(setMessage(message));
        dispatch(toggleMessage(true));
      } else if (!message) {
        const messagesViews = JSON.parse(localStorage.getItem('messagesViews') ?? '{}');
        messagesViews[window.SITE_ID] = {};
        localStorage.setItem('messagesViews', JSON.stringify(messagesViews));
      }
    } catch (e) {
      console.log('e', e);
    }
  }
);

export const trackMessageView = createAsyncThunk(
  'messages/trackMessageView',
  async (messageId: string, { getState }) => {
    try {
      const { endCustomer } = getState() as IStore;
      const mainCustomerId = getCookie('mainCustomerId');
      const endCustomerId = endCustomer?._id || mainCustomerId;

      if (endCustomerId) {
        await Api.Messages.trackMessageView(messageId);

        return;
      }

      const messagesViews = JSON.parse(localStorage.getItem('messagesViews') ?? '{}');
      messagesViews[window.SITE_ID] = {
        currentMessage: messageId,
        views:
          messagesViews[window.SITE_ID]?.currentMessage === messageId
            ? messagesViews[window.SITE_ID].views + 1
            : 1
      };
      localStorage.setItem('messagesViews', JSON.stringify(messagesViews));
    } catch (e) {
      console.log('e', e);
    }
  }
);

export const trackCollectionView = () => {
  try {
    const collectionViews = JSON.parse(localStorage.getItem('collectionsViews') ?? '{}');
    collectionViews[window.SITE_ID] = {
      ...collectionViews[window.SITE_ID],
      views: (collectionViews[window.SITE_ID]?.views || 0) + 1
    };
    localStorage.setItem('collectionsViews', JSON.stringify(collectionViews));
  } catch (e) {
    console.log('e', e);
  }
};
