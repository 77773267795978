interface IPreviewImage {
  image: string;
  maskId?: string;
  mask?: any;
  x?: number;
  y?: number;
  viewBox?: string;
  styles?: Record<string, string>;
}

export const PreviewImage = ({
  image,
  mask,
  maskId,
  x,
  y,
  viewBox = '0 0 1500 1500',
  styles = {}
}: IPreviewImage) => (
  <svg viewBox={viewBox} style={{ height: '100%', ...styles }}>
    {mask && mask}
    <image
      width="100%"
      height="100%"
      xlinkHref={image}
      mask={`url(#${maskId})`}
      preserveAspectRatio="xMidYMid slice"
      x={x}
      y={y}
    />
  </svg>
);
