import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';
import { v4 as uuidv4 } from 'uuid';

export const PrintsPreview = ({ backgroundImage, galleryId }) => {
  const horizontalImage1 = useGalleryImage('horizontal', 0, galleryId);
  const horizontalImage2 = useGalleryImage('horizontal', 1, galleryId);
  const horizontalImage3 = useGalleryImage('horizontal', 2, galleryId);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = horizontalImage1._id ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 622 805 L 597 1018 C 568 1042 587 1073 586 1076 L -91 1218 L -11 734 M -91 1218 L 540 1294 C 589 1283 562 1088 586 1076 M 477 1109 C 561 1193 578 1088 586 1076 L 472 1119" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={48.6}
        left={-0.4}
        width={42.5}
        height={33.1}
        transform="rotate(7deg)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-100} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-18.2}
        left={31.4}
        width={65}
        height={43.5}
        transform="rotate(19deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 900" x={-150} />
      </PreviewImageContainer>
      <PreviewImageContainer
        top={68.8}
        left={73.4}
        width={30.8}
        height={19.5}
        transform="rotate(20deg)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 850" x={-150} />
      </PreviewImageContainer>
    </>
  );
};
