import { useMemo } from 'react';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { v4 as uuidv4 } from 'uuid';

export const LeporelloDoubleImage_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);
  const maskId = fallbackImages.length ? 'leporello-double-image_0' : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 499 881 L 907 904 L 1371 788 L 1465 1236 L 994 1351 L 574 1347" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={49.5}
        left={30.79}
        width={30}
        height={44.7}
        transform="matrix3d(1, 0.14, 0, 0, 0.15, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage1.url_M} x={-120} y={-80} viewBox="0 0 1300 850" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={55.8}
        left={61}
        width={33}
        height={36}
        transform="matrix3d(1, -0.165, 0, 0, 0.15, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage1.url_M} x={-690} y={-35} viewBox="0 0 1300 700" />
      </PreviewImageContainer>
    </>
  );
};

export const LeporelloDoubleImage_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 1, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 2, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -37 888 L 129 867 L 395 731 L 726 834 L 1046 716 L 1346 814 L 1551 813 L 1542 1176 L 1380 1178 L 1068 1089 L 737 1198 L 392 1104 L 124 1230 L -36 1239" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={58.3}
        left={-9.9}
        width={20}
        height={36}
        transform="matrix3d(1,-0.05,0,0,0,1,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage1.url_M} x={200} y={-50} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={56}
        left={8}
        width={26}
        height={36}
        transform="matrix3d(0.95,-0.38,0,0.0006,0.007,1.03,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage1.url_M} x={-600} viewBox="0 0 1500 1100" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47}
        left={25.9}
        width={25}
        height={44.7}
        transform="matrix3d(0.95,0.3,0,0.00035,0.023,1.02,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage2.url_M} x={-220} y={-80} viewBox="0 0 1300 850" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={55.4}
        left={48.2}
        width={22}
        height={36}
        transform="matrix3d(1,-0.365,0,0,0.03,1,1,0,0,0,1,0,0,0,1,1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage2.url_M} x={-690} y={-35} viewBox="0 0 1300 700" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.2}
        left={69.5}
        width={21}
        height={36}
        transform="matrix3d(1,0.3,0,0.0001,0.06,1.03,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage3.url_M} x={-200} y={10} viewBox="0 0 1500 800" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={53.4}
        left={90.1}
        width={22}
        height={46}
        transform="matrix3d(1,0,0,0,0.06,1,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={horizontalImage3.url_M} x={-620} y={-135} />
      </PreviewImageContainer>
    </>
  );
};
