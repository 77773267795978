import { createSelector } from '@reduxjs/toolkit';

import type { IStore } from 'old-store/types';
import { cloneDeep } from 'lodash';

// this is exported because saga code needs it
export const selectCollection = (state: IStore) => state.collection;
export const selectGalleries = (state: IStore) => state.collection.galleries;

export const selectSortedGalleries = createSelector(selectGalleries, (galleries) =>
  // if we don't copyt the array sorting fails
  // this occurs because .sort tries to mutate the state which isn't allowed on Redux properties
  cloneDeep(galleries).sort((a, b) => a.order - b.order)
);
