import { ILayout } from '../store/slices/photobook';

export const findBestMatchingLayout = (
  currentImageOrientations: string[],
  layoutGroup,
  nextGroupFrameOrientations,
  nextGroupFrames
): { bestMatchingLayout: ILayout | null; bestMatchIndex: number } => {
  const bestMatchIndexes: number[] = [];
  let bestMatchCount = -1;

  nextGroupFrameOrientations.forEach((frameOrientations, frameOrientationIndex) => {
    let matchCount = 0;

    const remainingFrameOrientations = [...frameOrientations];

    currentImageOrientations.forEach((imageOrientation) => {
      const matchIndex = remainingFrameOrientations.indexOf(imageOrientation);
      if (matchIndex !== -1) {
        matchCount += 1;
        remainingFrameOrientations.splice(matchIndex, 1); // Remove the matched orientation
      }
    });

    if (matchCount > bestMatchCount) {
      bestMatchCount = matchCount;
      bestMatchIndexes.length = 0;
      bestMatchIndexes.push(frameOrientationIndex);
    } else if (matchCount === bestMatchCount) {
      bestMatchIndexes.push(frameOrientationIndex);
    }
  });

  // Now find the layout with the lowest accuracy sum among the best matches
  let bestAccuracyLayout: ILayout | null = null;
  let bestAccuracyIndex: number = -1;
  let bestAccuracySum = 0;

  bestMatchIndexes.forEach((index) => {
    const accuracySum = nextGroupFrames[index].reduce((sum, { frameSizesSum }) => {
      const total = sum + frameSizesSum;

      return total;
    }, 0);

    if (accuracySum > bestAccuracySum) {
      bestAccuracySum = accuracySum;
      bestAccuracyLayout = layoutGroup[index];
      bestAccuracyIndex = index;
    }
  });

  return { bestMatchingLayout: bestAccuracyLayout, bestMatchIndex: bestAccuracyIndex };
};
