import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

const Overlay = styled.div<{
  width: number;
  height: number;
  top: number;
  left: number;
  transform: string;
  boxShadow?: string;
  disableGradient?: boolean;
  gradient: string;
  zIndex?: number;
  opacity?: number;
  filter?: string;
}>`
  position: absolute;
  z-index: ${({ zIndex }) => `${zIndex || 5}`};
  width: ${({ width }) => `${width}%`};
  height: ${({ height }) => `${height}%`};
  top: ${({ top }) => `${top}%`};
  left: ${({ left }) => `${left}%`};
  transform: ${({ transform }) => transform};
  box-shadow: ${({ boxShadow }) => boxShadow};
  background: ${({ disableGradient, gradient }) => !disableGradient && gradient};
  opacity: ${({ opacity }) => `${opacity}`};
  filter: ${({ filter }) => filter};
`;

export const AcrylicBlock_10x10_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={10}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 259 331 L 184 315 L 1304 285 L 1335 297 L 1342 1159 L 258 1295 L 190 1248 L 184 315" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <Overlay
        top={38.5}
        left={-30}
        width={87}
        height={8}
        transform="rotate(-90deg)"
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.6993391106442577) 0%, rgba(0, 0, 0, 0.08869485294117652) 98%, rgba(0, 0, 0, 0.04947916666666663) 100%)"
        zIndex={6}
      />
      <Overlay
        top={17.9}
        left={17.4}
        width={80}
        height={3}
        transform="rotate(-1.8deg)"
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.6993391106442577) 0%, rgba(0, 0, 0, 0.08869485294117652) 98%, rgba(0, 0, 0, 0.04947916666666663) 100%)"
        zIndex={6}
      />
      <Overlay
        top={13}
        left={-10.2}
        width={80}
        height={30}
        transform="rotate(128deg)"
        gradient="linear-gradient(180deg, rgba(255,255,255,0.6503195028011204) 0%, rgba(255,255,255,0.10970325630252098) 100%);"
        zIndex={4}
        opacity={0.4}
        filter="blur(1px)"
      />
      <PreviewImageContainer
        top={19.9}
        left={9.4}
        width={7.8}
        height={68}
        transform="matrix3d(0.94, -0.15, 0, -0.002, 0, 0.94, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        boxShadow="rgba(0, 0, 0, 0.75) -3px 1px 12px -2px"
        zIndex={4}
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer top={19} left={10} width={85} height={68}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>
    </>
  );
};

export const AcrylicBlock_10x10_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={5}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -289 566 L -239 -637 L 1738 -21 L 864 1023 M -289 566 L 864 1023 L 1738 -21 L 1832 30 L 857 1229 L -328 758" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47}
        left={-6}
        width={50}
        height={24}
        transform="matrix3d(0.94, 0.4, 0, -0.003, 0.02, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        boxShadow="rgb(0, 0, 0) -1px 0 50px -1px"
        zIndex={4}
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 700" y={-1000} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={73.6}
        left={57}
        width={50}
        height={24}
        transform="matrix3d(0.94, -1.2, 0, -0.003, 0.02, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        boxShadow="rgb(0, 0, 0) -1px 0px 50px -1px"
        zIndex={4}
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 700" y={-1000} />
      </PreviewImageContainer>

      <PreviewImageContainer top={-47} left={16} width={110} height={102} transform="rotate(28deg)">
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const AcrylicBlock_10x10_3 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={10}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 532 511 L 478 496 L 1344 467 L 1369 483 L 1365 1141 L 532 1252 L 478 1228 L 478 496" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <Overlay
        top={38.5}
        left={-12}
        width={87}
        height={8}
        transform="rotate(-90deg)"
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.6993391106442577) 0%, rgba(0, 0, 0, 0.08869485294117652) 98%, rgba(0, 0, 0, 0.04947916666666663) 100%)"
        zIndex={6}
      />
      <Overlay
        top={30.1}
        left={35.5}
        width={60}
        height={3}
        transform="rotate(-2deg)"
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.6993391106442577) 0%, rgba(0, 0, 0, 0.08869485294117652) 98%, rgba(0, 0, 0, 0.04947916666666663) 100%)"
      />
      <Overlay
        top={35}
        left={-10.2}
        width={80}
        height={30}
        transform="rotate(128deg)"
        gradient="linear-gradient(180deg, rgba(255,255,255,0.6503195028011204) 0%, rgba(255,255,255,0.10970325630252098) 100%);"
        zIndex={4}
        opacity={0.4}
        filter="blur(1px)"
      />

      <PreviewImageContainer
        top={30.5}
        left={30.3}
        width={5}
        height={54}
        transform="matrix3d(0.94, -0.15, 0, -0.002, 0, 0.94, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        boxShadow="rgba(0, 0, 0, 0.75) -3px 1px 12px -2px"
        zIndex={5}
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>

      <PreviewImageContainer top={30} left={29} width={65} height={54} transform="rotate(-4deg)">
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1200" />
      </PreviewImageContainer>
    </>
  );
};
