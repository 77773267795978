import { Modal, Form, Row, Col } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toggleSaveSelectionModal } from 'store/slices/modals';
import { getTranslationKey } from 'helpers/texting';
import { useToggle } from 'hooks/useToggle';
import { Input } from 'modules/ScrShop/components/Forms';
import { saveSelection } from 'store/slices/selection/actions';
import { Button } from 'modules/ScrShop/components/Buttons';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { enableUpdateSelectionState } from 'store/slices/selection';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { SaveSelectionIcon } from 'components/icons/SaveSelectionIcon';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { useTheme } from 'hooks/useTheme';

export const SaveSelectionModal = () => {
  const dispatch = useAppDispatch();
  const [theme] = useTheme();

  const endCustomer = useAppSelector((state) => state.endCustomer);
  const { currentSelection } = useAppSelector((state) => state.selection);
  const { saveSelectionModal } = useAppSelector((state) => state.modals);

  const { toggle: isValidated, openToggle: enableValidation } = useToggle();

  const validationWithouthAuth = {
    firstName: Yup.string().required(getTranslationKey('mandatoryField')),
    lastName: Yup.string().required(getTranslationKey('mandatoryField')),
    email: Yup.string()
      .email(getTranslationKey('checkEmailFormat'))
      .required(getTranslationKey('mandatoryField')),
    selectionName: Yup.string().required(getTranslationKey('mandatoryField'))
  };

  const validationWithAuth = {
    selectionName: Yup.string().required(getTranslationKey('mandatoryField'))
  };

  const validationObj = endCustomer.authed ? validationWithAuth : validationWithouthAuth;

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      firstName: endCustomer.firstName ?? '',
      lastName: endCustomer.lastName ?? '',
      email: endCustomer.email ?? '',
      selectionName: currentSelection.name ?? ''
    },
    // this re-initializes the form with the latest initial values if state changes
    enableReinitialize: true,
    validationSchema: Yup.object(validationObj),
    onSubmit: async (values, { resetForm }) => {
      dispatch(enableUpdateSelectionState());

      if (endCustomer.authed) {
        dispatch(
          saveSelection({
            firstName: endCustomer.firstName || '',
            lastName: endCustomer.lastName || '',
            email: endCustomer.email?.toLowerCase() || '',
            selectionName: values.selectionName
          })
        );
      } else {
        dispatch(saveEndCustomer(values));

        dispatch(saveSelection({ ...values, email: values.email.toLowerCase() }));
      }

      resetForm();
    }
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  const toggleEditSaveSelectionModal = () => dispatch(toggleSaveSelectionModal());

  return (
    <Modal
      centered
      isOpen={saveSelectionModal.isOpen}
      toggle={toggleEditSaveSelectionModal}
      size="md"
      zIndex={999999}
    >
      <ModalHeader toggle={toggleEditSaveSelectionModal}>
        {getTranslationKey('selections.saveSelection')}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <Row>
            {!endCustomer.authed && (
              <>
                <Col xs="6">
                  <Input
                    id="firstNameID"
                    name="firstName"
                    type="text"
                    label={getTranslationKey('shop.forms.firstName')}
                    placeholder={getTranslationKey('shop.forms.firstName')}
                    value={values.firstName}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={isValidated ? errors.firstName : ''}
                  />
                </Col>
                <Col xs="6">
                  <Input
                    id="lastNameID"
                    name="lastName"
                    type="text"
                    label={getTranslationKey('shop.forms.lastName')}
                    placeholder={getTranslationKey('shop.forms.lastName')}
                    value={values.lastName}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={isValidated ? errors.lastName : ''}
                  />
                </Col>
                <Col xs="12">
                  <Input
                    id="emailID"
                    name="email"
                    type="email"
                    label={getTranslationKey('shop.forms.emailTitle')}
                    placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                    value={values.email}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={isValidated ? errors.email : ''}
                  />
                </Col>
              </>
            )}
            <Col xs="12">
              <Input
                id="selectionNameID"
                name="selectionName"
                type="text"
                label={getTranslationKey('selections.saveSelectionModal.selectionName')}
                placeholder={getTranslationKey(
                  'selections.saveSelectionModal.selectionNamePlaceholder'
                )}
                value={values.selectionName}
                onChange={handleChange}
                handleBlur={handleBlur}
                errorText={isValidated ? errors.selectionName : ''}
              />
            </Col>
            <Col className="mt-2" xs="12">
              <Button type="submit" onClick={onSubmit} className="sb-btn sb-btn-icon">
                <SaveSelectionIcon size={16} color={theme.colors.menuIconsColor} />
                {getTranslationKey('selections.saveSelection')}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
