import { IPackage } from 'modules/ScrShop/store/types';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { usePrevious } from 'hooks/usePrevious';
import { useState, useRef, useEffect } from 'react';

interface ISliderIndex {
  packages: IPackage[];
  slickGoTo: (index: number) => void;
  matchedPackage?: IPackage;
}

export const useSliderIndex = ({ packages, slickGoTo, matchedPackage }: ISliderIndex) => {
  const [sliderIndex, setSliderIndex] = useState<number>(0);

  const isDesktop = useBreakpoint('desktopXl');
  const previousDesktopValue = usePrevious(isDesktop);
  const orientationWasChanged = isDesktop !== previousDesktopValue;

  const initialPackageWasSet = useRef(false);

  const setInitialSliderIndex = () => {
    const initialSlideIdx = !isDesktop
      ? packages.findIndex((pckg) => pckg.id === matchedPackage?.id)
      : 0;

    setSliderIndex(initialSlideIdx);
    slickGoTo(initialSlideIdx);
  };

  useEffect(() => {
    if (initialPackageWasSet.current || !packages.length) return;
    setInitialSliderIndex();

    initialPackageWasSet.current = true;
  }, [packages.length, matchedPackage]);

  useEffect(() => {
    if (orientationWasChanged) {
      setInitialSliderIndex();
    }
  }, [orientationWasChanged]);

  return { sliderIndex, setSliderIndex };
};
