import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { usePrevious } from 'modules/ScrShop/store/hooks';

export function TagManagerPageTracking() {
  const { pathname } = useLocation();
  const lastPath = usePrevious(pathname);
  if (pathname !== lastPath) {
    TagManager.dataLayer({
      dataLayer: { event: 'page_view', pathname },
      dataLayerName: window.SHOP_DATA_LAYER_NAME
    });
  }

  return null;
}
