import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { DEFAULT_COUPON } from 'constants/coupons';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import {
  fetchProductsActionSuccess,
  fetchProductSuggestionSuccess
} from 'modules/ScrShop/store/actions';
import {
  IFetchProductsAction,
  IGetProductSuggestionAction,
  ISagaAction
} from 'modules/ScrShop/store/types';
import { selectBanners } from 'store/slices/banners/selectors';
import { setGlobalShopStatus } from 'store/slices/collection';

export const fetchProductsSaga = function* ({
  payload
}: ISagaAction<IFetchProductsAction>): SagaIterator {
  try {
    const { couponCodeAdvertising = { coupon: DEFAULT_COUPON } } = yield select(selectBanners);
    const { id, active } = couponCodeAdvertising.coupon;
    if (id && active) {
      payload.couponId = id; // eslint-disable-line
    }
    const result = yield call(Api.Products.get, payload);
    ApiErrors.checkOnApiError(result);

    const sortedResult = [...result].sort((a, b) => a.order - b.order);
    yield put(fetchProductsActionSuccess(sortedResult));
  } catch (err) {
    yield put(setGlobalShopStatus(false));
    console.log(err);
  }
};

export const fetchProductSuggestionSaga = function* ({
  payload
}: ISagaAction<IGetProductSuggestionAction>): SagaIterator {
  try {
    const { couponCodeAdvertising = { coupon: DEFAULT_COUPON } } = yield select(selectBanners);
    const { id, active } = couponCodeAdvertising.coupon;
    if (id && active) {
      payload.couponId = id; // eslint-disable-line
    }

    const response = yield call(Api.Products.getSuggestion, payload);
    ApiErrors.checkOnApiError(response);
    yield put(fetchProductSuggestionSuccess(response.result));
  } catch (e) {
    yield put(setGlobalShopStatus(false));
    console.log(e);
  }
};
