import { IImage, ISelection } from 'old-store/types/gallery';
import naturalCompare from 'natural-compare-lite';
import { dateOrderResolver } from 'helper';
import { orderBy, partial } from 'lodash';

type ImgFilter = (image: IImage) => boolean;

interface IImgSort {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

export const getSortedAndfilteredImgs = (
  images: IImage[],
  imgFilter: ImgFilter,
  imgSorter?: IImgSort,
  selection?: ISelection
): IImage[] => {
  let filteredImgs = images || [];

  if (imgFilter) {
    filteredImgs = filteredImgs.filter(imgFilter);
  }

  if (selection) {
    const selectionIds = selection.favorites.map(({ _image }) => _image);

    return filteredImgs.sort((a, b) => selectionIds.indexOf(a._id) - selectionIds.indexOf(b._id));
  }

  // Only continue with sorting when there is a sorter
  if (!imgSorter) return filteredImgs;

  if (imgSorter.sortBy === 'originalImageName') {
    filteredImgs = filteredImgs.sort((i1, i2) =>
      naturalCompare(i1.originalImageName, i2.originalImageName)
    );

    if (imgSorter.sortOrder === 'desc') filteredImgs = filteredImgs.reverse();

    return filteredImgs;
  }

  return orderBy(filteredImgs, partial(dateOrderResolver, imgSorter.sortBy), [
    imgSorter.sortBy === 'manualOrder' ? 'asc' : imgSorter.sortOrder
  ]);
};
