import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMessagesState {
  message: IMessage | null;
  isOpened: boolean;
}

export interface IMessage {
  id: string;
  user: string;
  title: string;
  message: string | null;
  image: string | null;
  publicImageKey: string | null;
  previewUrl: string | null;
  recipient: typeof MessageRecipient[keyof typeof MessageRecipient];
  cta: {
    type: string;
    text: string | null;
    url: string | null;
  };
  expirationDate: string | null;
  active: boolean;
  minCollectionViews: number;
  maxMessageViews: number;
  updatedAt: string;
  createdAt: string;
}

export const MessageRecipient = {
  All: 'all',
  MainCustomer: 'main_customer'
} as const;

const initialState: IMessagesState = {
  message: null,
  isOpened: false
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessage(state, { payload }: PayloadAction<IMessage | null>) {
      Object.assign(state, { message: payload });
    },
    toggleMessage(state, { payload }: PayloadAction<boolean>) {
      Object.assign(state, { isOpened: payload });
    }
  }
});

export const { setMessage, toggleMessage } = messagesSlice.actions;

export const messagesReducer = messagesSlice.reducer;
