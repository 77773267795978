import { SagaIterator } from 'redux-saga';
import {
  IAddImageAction,
  IAddImagesAction,
  ICreateAccountWithPasswordParams,
  IImage,
  ILoginWithPasswordParams,
  ISagaAction,
  ISetShopEditMode
} from 'modules/ScrShop/store/types';
import { call, put, select } from 'redux-saga/effects';
import {
  claimCheckoutAction,
  deleteCroppedImageSuccessAction,
  fetchDigitalPricingForUpsellingSuccess,
  fetchGlobalShopSettingsForCollectionActionSuccess,
  fetchLaboratoryActionSuccess,
  fetchPackagesForUpsellingSuccess,
  resetLoginWithPasswordFlow,
  resetSignupWithPasswordFlow,
  setCreateAccountWithPasswordFlowStatus,
  setCropImagesAction,
  setEndCustomerEmailWasNotFound,
  setEndCustomerHasPassword,
  setForgotPasswordFlowStatus,
  setLoginFlowStatus,
  setSelectedImagedSuccessAction,
  startFetchingPackagesForUpselling,
  stopFetchingPackagesForUpselling,
  toggleEnterPasswordModalAction,
  toggleLoginWithPasswordModalAction,
  updateShopDataAction
} from 'modules/ScrShop/store/actions';
import {
  getSelectedImagesIDsSelector,
  getShopCroppedImagesSelector,
  getShopOptionSelector,
  getVerticalImages
} from 'modules/ScrShop/store/selectors/shop';
import {
  getProductByID,
  getProductBySlug,
  getHorizontalImages
} from 'modules/ScrShop/store/selectors';
import { omit, get, find, cloneDeep, keyBy } from 'lodash';
import { getCheckoutProductByID } from 'modules/ScrShop/store/selectors/checkout';
import { globalError, showGlobalError } from 'modules/ScrShop/store/actions/errors';
import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';
import TagManager from 'react-gtm-module';
import db from 'database';
import { trackEventAction } from 'old-store/actions';
import { fetchSelectionImages, setImages } from 'store/slices/images';
import { setCollection } from 'store/slices/collection';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { saveSelection } from 'store/slices/selection';
import { getTranslationKey } from 'helpers/texting';
import {
  fetchProductBestsellerList,
  fetchProductBestsellerListSuccess,
  fetchProductRecList,
  fetchProductRecListSuccess,
  setGroupedByGalleriesImages,
  setGroupedImages
} from 'modules/ScrShop/store/actions/shop';
import { getImagesByGalleries, getShuffledImages } from 'modules/ScrShop/helpers/images';
import { getGalleries } from 'modules/ScrShop/store/selectors/galleries';
import { fetchMessage } from 'store/slices/messages/actions';
import { HttpStatus } from 'constants/statusCodes';
import { saveAndSetNewToken } from 'helpers/tokens';
import { fetchPhotobooks } from 'modules/ScrPhotobook/store/slices/photobook/actions';

const getIsAdding = (max: number, selected: string[], imageID: string) =>
  max === 1 && selected.indexOf(imageID) === -1;
const getIsRemoveImage = (max: number, selected: string[], imageID: string) =>
  selected.indexOf(imageID) > -1 && max !== 1;

export const updateSelectionSaga = function* (): SagaIterator {
  try {
    const { selections, allUserSelections } = yield call(Api.Auth.updateEndCustomer);

    yield put(saveSelection({ selections, allUserSelections }));
    yield put(fetchSelectionImages(selections));
    yield put({
      type: 'fetch_selections',
      payload: selections
    });
    yield put({
      type: 'set_selection_fetch_status',
      payload: 'success'
    });

    const res = yield call(Api.Site.get);
    yield put(setCollection(res.result));
  } catch (e) {
    console.log(e);
  }
};

// tslint:disable-next-line:cyclomatic-complexity
export const setSelectedImageSaga = function* ({
  payload
}: ISagaAction<IAddImageAction>): SagaIterator {
  const selected = cloneDeep(yield select(getSelectedImagesIDsSelector));
  const croppedImages = yield select(getShopCroppedImagesSelector);
  const { maxImageSelection } = yield select((store) =>
    payload.groupSlug === 'digital'
      ? getProductByID(store)(payload.productSlug)
      : getProductBySlug(store)(payload.productSlug)
  );
  const isRemove = getIsRemoveImage(maxImageSelection, selected, payload.imageID);
  if (!isRemove && maxImageSelection === selected.length && maxImageSelection !== 1) {
    yield put(
      showGlobalError({
        title: getTranslationKey('shop.imagesSelectionModal.title'),
        text: getTranslationKey('shop.imagesSelectionModal.toManyImagesText'),
        confirmText: getTranslationKey('shop.imagesSelectionModal.button')
      })
    );

    return undefined;
  }
  let IDs = selected;
  if (getIsRemoveImage(maxImageSelection, selected, payload.imageID)) {
    IDs = selected.filter((id: string) => id !== payload.imageID);
    yield put(setCropImagesAction(omit(croppedImages, payload.imageID)));
  } else if (getIsAdding(maxImageSelection, selected, payload.imageID)) {
    IDs = [payload.imageID];
  } else if (!maxImageSelection || maxImageSelection > 1) {
    IDs.push(payload.imageID);
  }
  yield put(setSelectedImagedSuccessAction([...IDs]));
  yield put(
    trackEventAction({
      name: 'images-selected',
      payload: {
        images_ids: [payload.imageID],
        images_count: 1,
        product_group_slug: payload.groupSlug,
        product_slug: payload.productSlug
      }
    })
  );
};

export const setSelectedImagesSaga = function* ({
  payload
}: ISagaAction<IAddImagesAction>): SagaIterator {
  const selectedItem = yield select(getSelectedImagesIDsSelector);
  const selected = JSON.parse(JSON.stringify(selectedItem));
  const { maxImageSelection } = yield select((store) =>
    payload.groupSlug === 'digital'
      ? getProductByID(store)(payload.productSlug)
      : getProductBySlug(store)(payload.productSlug)
  );
  const imagesThatNotSelected = payload.imageIDs.filter((imageId) => !selected.includes(imageId));
  if (
    imagesThatNotSelected.length > 0 &&
    maxImageSelection === selected.length &&
    maxImageSelection !== 1
  ) {
    yield put(
      showGlobalError({
        title: getTranslationKey('shop.imagesSelectionModal.title'),
        text: getTranslationKey('shop.imagesSelectionModal.toManyImagesText'),
        confirmText: getTranslationKey('shop.imagesSelectionModal.button')
      })
    );

    return undefined;
  }

  const imagesToAdd =
    typeof maxImageSelection === 'number'
      ? imagesThatNotSelected.slice(0, maxImageSelection - selected.length)
      : imagesThatNotSelected;

  selected.push(...imagesToAdd);
  yield put(setSelectedImagedSuccessAction([...selected]));
  yield put(
    trackEventAction({
      name: 'images-selected',
      payload: {
        images_ids: payload.imageIDs,
        images_count: payload.imageIDs.length,
        product_group_slug: payload.groupSlug,
        product_slug: payload.productSlug
      }
    })
  );
};

export const deleteCroppedImageSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  const selected = yield select(getSelectedImagesIDsSelector);
  const cropped = yield select(getShopCroppedImagesSelector);
  const filterSelected = selected.filter((id: string) => id !== payload);
  const filterCropped = omit(cropped, payload);
  yield put(setSelectedImagedSuccessAction(filterSelected));
  yield put(deleteCroppedImageSuccessAction(filterCropped));
};

export const setShopEditModeSaga = function* ({
  payload
}: ISagaAction<ISetShopEditMode>): SagaIterator {
  if (!payload.cartSelectedProductID) return;

  const checkoutProduct = yield select((store) =>
    getCheckoutProductByID(store)(payload.cartSelectedProductID)
  );
  const { options } = yield select((store) => getProductByID(store)(checkoutProduct._product));
  const shopOptions = cloneDeep(yield select(getShopOptionSelector));
  const images = [...get(checkoutProduct, 'images', [])].sort((a, b) => a.order - b.order);
  const convertImages = images.map((image: any) => ({
    ...image.crop,
    quantity: image.quantity,
    _id: image._id,
    _image: `${checkoutProduct.slug}-${image._image}`
  }));
  checkoutProduct?.selectedOptions?.forEach((option: string) => {
    const productOption = find(options, { _id: option });
    if (productOption) {
      shopOptions[productOption.type] = option;
    }
  });
  if (images.length) {
    yield put(setSelectedImagedSuccessAction(images.map((image: IImage) => image._image)));
    yield put(
      updateShopDataAction({ options: shopOptions, croppedImages: keyBy(convertImages, '_image') })
    );
  }
};

// eslint-disable-next-line require-yield
export const clearShopSaga = function* (): SagaIterator {
  localStorage.removeItem(`shop_${window.SITE_ID}`);
};

export const fetchGlobalShopSettingsForCollectionSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getGlobalShopSettingsForCollection, window.SITE_ID);
    ApiErrors.checkOnApiError(response);
    yield put(fetchGlobalShopSettingsForCollectionActionSuccess(response.result));
  } catch (err: any) {
    if (err.code !== HttpStatus.Forbidden) {
      console.log(err);
    }
  }
};

export const checkEndCustomerPasswordSaga = function* ({
  payload
}: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Auth.checkPassword, payload, window.SITE_ID);
    ApiErrors.checkOnApiError(response);
    yield put(setEndCustomerHasPassword(response.result.endCustomerHasPassword));
    yield put(toggleLoginWithPasswordModalAction());
    yield put(toggleEnterPasswordModalAction());
  } catch (err: any) {
    if (err.code !== HttpStatus.NotFound) {
      console.log(err);
    } else {
      yield put(setEndCustomerEmailWasNotFound(true));
    }
  }
};

export const checkEndCustomerEmailSaga = function* ({
  payload
}: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Auth.checkEmail, payload, window.SITE_ID);
    ApiErrors.checkOnApiError(response);

    if (response.result.emailIsAlreadyTaken && !response.result.endCustomerHasPassword) {
      yield put(
        setCreateAccountWithPasswordFlowStatus({
          showEmailModal: false
        })
      );
      yield put(
        setLoginFlowStatus({
          email: payload,
          showEnterPasswordModal: true,
          endCustomerHasPassword: false
        })
      );
    } else {
      yield put(
        setCreateAccountWithPasswordFlowStatus({
          showEmailModal:
            response.result.emailIsAlreadyTaken && response.result.endCustomerHasPassword,
          showPasswordModal: !response.result.emailIsAlreadyTaken,
          emailIsAlreadyTaken:
            response.result.emailIsAlreadyTaken && response.result.endCustomerHasPassword
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
};

export const resetPasswordSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Auth.resetPassword, payload, window.SITE_ID);
    ApiErrors.checkOnApiError(response);
    yield put(
      setForgotPasswordFlowStatus({
        showSuccessMessage: true
      })
    );
  } catch (err: any) {
    if (err.code === HttpStatus.NotFound) {
      yield put(
        setForgotPasswordFlowStatus({
          emailDoesNotExists: true
        })
      );
    } else {
      console.log(err);
    }
  }
};

export const loginCustomerWithPasswordSaga = function* ({
  payload
}: ISagaAction<ILoginWithPasswordParams>): SagaIterator {
  const formValues: any = {
    collectionId: window.SITE_ID,
    ...payload
  };

  try {
    // @ts-ignore
    yield db.sites.delete(window.SITE_ID).catch((e) => {
      if (e && e.inner) console.log('DataBaseError helper deleteCache inner:', e.inner, e);
      else console.log('DataBaseError helper deleteCache:', e);
    });

    yield put(setLoginFlowStatus({ passwordIsInvalid: false }));
    const response = yield call(Api.Auth.login, formValues);
    ApiErrors.checkOnApiError(response);

    const {
      result: { endCustomerToken, endCustomer }
    } = response;
    const { _user, signedWildCardUrl } = yield select((store) => store.collection);

    saveAndSetNewToken(_user, endCustomerToken);
    yield put({ type: 'fetch_endCustomer', payload: endCustomer });
    yield put(
      trackEventAction({
        name: 'user-signed-in',
        payload: { endCustomer, flyUserId: window.localStorage.getItem('flyUserId') }
      })
    );
    yield call(updateSelectionSaga);
    yield put(resetLoginWithPasswordFlow());
    yield put(fetchProductBestsellerList());
    yield put(fetchProductRecList());
    yield put(saveEndCustomer({ ...endCustomer, _id: endCustomer.id, authed: true }));

    // @ts-ignore
    yield put(fetchMessage());

    window.localStorage.removeItem('flyUserId');

    TagManager.dataLayer({
      dataLayer: { event: 'login' },
      dataLayerName: window.SHOP_DATA_LAYER_NAME
    });

    // we have to refetch images to synchronize user id
    const images = yield call(Api.Images.getImages);
    yield put(setImages({ images, signedWildCardUrl }));
    yield put(fetchPhotobooks({}));
  } catch (e: any) {
    if (e.code === HttpStatus.Forbidden) {
      yield put(setLoginFlowStatus({ passwordIsInvalid: true }));
    } else {
      yield put(globalError(e));
    }
  }
};

const updateEndCustomerSaga = function* (): SagaIterator {
  try {
    const res = yield call(Api.Auth.updateEndCustomer);

    yield put({ type: 'fetch_endCustomer', payload: res });
  } catch (e) {
    console.log(e);
  }
};

export const createAccountWithPasswordSaga = function* ({
  payload
}: ISagaAction<ICreateAccountWithPasswordParams>): SagaIterator {
  try {
    const { _user } = yield select((store) => store.collection);
    // @ts-ignore
    const response = yield call(Api.Auth.signup, payload);
    ApiErrors.checkOnApiError(response);
    const { endCustomerToken } = response.result;
    saveAndSetNewToken(_user, endCustomerToken);

    yield call(updateEndCustomerSaga);
    yield put(claimCheckoutAction());
    yield put(resetSignupWithPasswordFlow());
    yield put(
      trackEventAction({
        name: 'user-signed-up',
        payload: {
          endCustomer: response.result.endCustomer,
          flyUserId: localStorage.getItem('flyUserId')
        }
      })
    );
    yield put(fetchProductBestsellerList());
    yield put(fetchProductRecList());
    yield put(
      saveEndCustomer({
        ...response.result.endCustomer,
        _id: response.result.endCustomer.id,
        authed: true
      })
    );

    localStorage.removeItem('flyUserId');
    const { signedWildCardUrl } = yield select((store) => store.collection);

    // we have to refetch images to synchronize user id
    const images = yield call(Api.Images.getImages);
    yield put(setImages({ images, signedWildCardUrl }));
  } catch (e) {
    console.log(e);
  }
};

export const fetchLaboratorySaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Shop.getLaboratory, payload);

    ApiErrors.checkOnApiError(response);

    yield put(fetchLaboratoryActionSuccess(response.result));
  } catch (e) {
    console.log(e);
  }
};

export const fetchPackagesForUpsellingSaga = function* ({
  payload
}: ISagaAction<string>): SagaIterator {
  try {
    yield put(startFetchingPackagesForUpselling());
    const response = yield call(Api.Shop.getDigitalPricingListPackages, payload);

    ApiErrors.checkOnApiError(response);

    yield put(fetchPackagesForUpsellingSuccess(response.result));
    yield put(stopFetchingPackagesForUpselling());
  } catch (e) {
    console.log(e);
  }
};

export const fetchDigitalPricingForUpsellingSaga = function* ({
  payload
}: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Shop.getDigitalPricingList, payload);

    ApiErrors.checkOnApiError(response);

    yield put(fetchDigitalPricingForUpsellingSuccess(response.result));
  } catch (e) {
    console.log(e);
  }
};

export const setGroupedImagesSaga = function* (): SagaIterator {
  try {
    const galleries = yield select(getGalleries);
    const horizontal = yield select(getHorizontalImages);
    const vertical = yield select(getVerticalImages);

    const horizontalImages = getShuffledImages(horizontal);
    const verticalImages = getShuffledImages(vertical);
    const fallbackImages = getShuffledImages([...horizontal, ...vertical]);

    yield put(
      setGroupedImages({
        horizontal: horizontalImages,
        vertical: verticalImages,
        fallback: fallbackImages
      })
    );
    yield put(
      setGroupedByGalleriesImages({
        horizontal: getImagesByGalleries(horizontalImages, galleries),
        vertical: getImagesByGalleries(verticalImages, galleries),
        fallback: getImagesByGalleries(fallbackImages, galleries)
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const fetchProductRecListSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getRecommendations);
    ApiErrors.checkOnApiError(response);

    yield put(fetchProductRecListSuccess(response.result));
  } catch (e) {
    console.log(e);
  }
};

export const fetchProductBestsellerListSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getBestsellers);
    ApiErrors.checkOnApiError(response);

    yield put(fetchProductBestsellerListSuccess(response.result));
  } catch (e) {
    console.log(e);
  }
};
