import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { FaCheck } from 'react-icons/fa';
import { FormPart } from 'modules/ScrShop/components/Forms/FormPart';

interface ICheckbox extends React.AllHTMLAttributes<HTMLInputElement> {
  // readonly onChange: (e: React.SyntheticEvent<EventTarget>) => void;
  readonly children: React.ReactNode;
  readonly name: string;
  readonly id?: string;
  readonly tick_position?: string;
  readonly type?: string;
  readonly as?: any;
  readonly is_radio?: boolean;
  readonly width?: string;
  readonly errorText?: string;
}

const StyledCheckbox = styled.div`
  //overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

interface ICheckboxStyled {
  readonly tick_position?: string;
}

const CheckboxStyled = styled.input`
  position: absolute;
  visibility: hidden;
  ${({ tick_position }: ICheckboxStyled) => tick_position}: 0;
  z-index: 0;
`;
const Radio = styled.div`
  border: 1px solid #f1f1f1;
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  border-radius: 4px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  pointer-events: none;
`;

interface ILabel extends ICheckboxStyled {
  readonly is_radio?: boolean;
  readonly width?: string;
}

const Label = styled.label`
  display: flex;
  transition: 0.4s;
  position: relative;
  text-align: left;
  max-width: ${({ width }: ILabel) => width || '100%'};
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  align-items: flex-start;
  line-height: 25px;
  font-size: 12px;
  svg path {
    fill: transparent;
  }

  ${CheckboxStyled}:disabled + & {
    cursor: default;
  }
  ${CheckboxStyled}:checked + & {
    svg path {
      fill: #000;
    }
  }
`;

export const Checkbox = ({
  onChange,
  children,
  tick_position = 'left',
  type = 'checkbox',
  id = uniqueId('checkbox'),
  width,
  errorText,
  ...rest
}: ICheckbox) => (
  <FormPart errorText={errorText}>
    <StyledCheckbox>
      <CheckboxStyled
        onChange={onChange}
        id={id}
        type={type}
        tick_position={tick_position}
        {...rest}
      />
      <Label
        data-cy="component-checkbox"
        htmlFor={id}
        is_radio={type === 'radio'}
        tick_position={tick_position}
        width={width}
      >
        <Radio>
          <FaCheck />
        </Radio>
        {children}
      </Label>
    </StyledCheckbox>
  </FormPart>
);
