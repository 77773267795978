import { Triplex15x15_0 } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Triplex/TriplexMini';
import { Triplex20x20_0 } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Triplex/TriplexClassic';
import { Triplex15x23_0 } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Triplex/TriplexHigh';
import { Triplex20x30_0 } from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Triplex/TriplexHighXL';

export const triplex = {
  'triplex-15x15': Triplex15x15_0,
  'triplex-15x15_0': Triplex15x15_0,
  'triplex-20x20': Triplex20x20_0,
  'triplex-20x20_0': Triplex20x20_0,
  'triplex-15x23': Triplex15x23_0,
  'triplex-15x23_0': Triplex15x23_0,
  'triplex-20x30': Triplex20x30_0,
  'triplex-20x30_0': Triplex20x30_0
};
