import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';
import { useMemo } from 'react';

const WhiteBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 87%;
  border: 20px solid #fff;
`;

export const ClassicPrint9x13 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 0, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={30.2}
        left={9.6}
        width={83.5}
        height={50.6}
        transform="rotate(-8deg)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 950" x={-100} />
      </PreviewImageContainer>
    </>
  );
};

export const ClassicPrint10x15_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 0, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 422 362 V 1213 H 1081 L 1078 360 L 422 362 M 977 286 L 1078 360 L 422 362 L 541 283" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={18.5} left={9.6} width={62.5} height={62.5} zIndex={1}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1600 1500" x={-100} />
      </PreviewImageContainer>
    </>
  );
};

export const ClassicPrint15x23_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 1, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 411 361 V 1177 H 1089 L 1089 347 L 411 361 M 1009 274 L 1089 347 L 411 361 L 410 276 M 1089 1231 L 471 1231 L 411 1177 L 1089 1177" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={18} left={10.5} width={62.5} height={65} zIndex={1}>
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1600 1500" x={-100} />
      </PreviewImageContainer>
    </>
  );
};

export const ClassicPrint20x30_0 = ({ backgroundImage, withBorder }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 1, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 46 315 L 589 316 M 910 315 L 1465 312 V 1295 L 45 1287 L 45 315 M 591 315 L 597 337 L 914 337 L 907 315" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={20.5} left={3} width={95} height={76} zIndex={1}>
        {withBorder && <WhiteBorder />}
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1200" x={0} y={-130} />
      </PreviewImageContainer>
    </>
  );
};

export const ClassicPrint30x45_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage = getImageByIdx(verticalImages, 2, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 137 1004 L 613 406 L 1149 436 L 885 1160" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={25}
        left={41}
        width={100}
        height={100}
        zIndex={1}
        transform="matrix3d(1,0,0,0,-0.5,0.6,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={verticalImage.url_M} viewBox="0 0 1500 1950" x={-300} y={-150} />
      </PreviewImageContainer>
    </>
  );
};
