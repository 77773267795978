const lightOrDarkColor = (colorString: string): 'light' | 'dark' => {
  let r: number = 0;
  let g: number = 0;
  let b: number = 0;

  if (colorString.match(/^rgb/)) {
    const colors = colorString.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (colors) {
      r = parseInt(colors[1]);
      g = parseInt(colors[2]);
      b = parseInt(colors[3]);
    }
  } else {
    // string is in hex
    const rgb = +('0x' + colorString.slice(1));

    r = rgb >> 16;
    g = (rgb >> 8) & 255;
    b = rgb & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

export { lightOrDarkColor };
