import { toggleLoginWithPasswordModalAction } from 'modules/ScrShop/store/actions';
import { fetchSelectionImages } from 'store/slices/images';
import { saveSelection } from 'store/slices/selection';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { setCookie, getCookie } from 'helpers/cookie';
import axios from '../utils/axios';
import { setGalleries } from 'store/slices/collection';

export const getEndCustomer = (props) => async (dispatch, getState) => {
  try {
    const { includeAllUserSelections = false } = props || {};
    const { collection } = getState();

    const persistData = localStorage.getItem('endCustomerToken');
    const endCustomerTokenMap = persistData ? JSON.parse(persistData) : {};
    const currentToken = endCustomerTokenMap[collection._user];
    if (currentToken) setCookie('endCustomerToken', currentToken, 30);

    const res = await axios.get('/api/collection/endCustomer', {
      params: { collection_id: window.SITE_ID, includeAllUserSelections }
    });

    if (!currentToken) {
      // no token in local storage yet we need to add it so that subsequent request and check for temp end customer do not fail
      // since the get end customer request (above) fails if the user from the current collection do not fit with the end customer from the cookie
      // that is used we know that the current cookie is from the current collection user

      localStorage.setItem(
        'endCustomerToken',
        JSON.stringify({ [collection._user]: getCookie('endCustomerToken') })
      );
    }

    const isUserAuthed = res.data.type && res.data.type === 'permanent';
    const isUserPreAuthed = res.data.type && res.data.type === 'fly';

    dispatch(saveEndCustomer({ ...res.data, authed: isUserAuthed, preAuthed: isUserPreAuthed }));

    dispatch(fetchSelectionImages(res.data.selections));
    dispatch(
      saveSelection({
        selections: res.data.selections,
        allUserSelections: res.data.allUserSelections
      })
    );
    if (res.data.collectionGalleries) {
      dispatch(setGalleries(res.data.collectionGalleries));
    }

    dispatch({
      type: 'set_selection_fetch_status',
      payload: 'success'
    });

    return res.data;
  } catch (e) {
    dispatch({
      type: 'set_selection_fetch_status',
      payload: 'error'
    });
    dispatch({ type: 'set_endCustomer_auth', payload: false });
    dispatch({ type: 'set_endCustomer_pre_auth', payload: false });

    return null;
  }
};

export const createEndCustomer = (data) => async () => {
  try {
    const res = await axios.put('/api/collection/endCustomer', {
      ...data,
      collection_id: window.SITE_ID
    });

    return { statusCode: 200, data: res.data };
  } catch (e) {
    console.log(e);

    return { statusCode: e.response ? e.response.status : 500 };
  }
};

export const loginEndCustomer = (data) => async () => {
  try {
    const res = await axios.post('/api/collection/endCustomer/login', {
      ...data,
      collection_id: window.SITE_ID
    });

    return { statusCode: 200, data: res.data };
  } catch (e) {
    return { statusCode: e.response ? e.response.status : 500 };
  }
};

export const toggleCreateEndCustomerModal =
  (payload = {}) =>
  (dispatch) =>
    dispatch({ type: 'toggle_create_end_customer_modal', payload });

export const toggleLoginEndCustomerModal =
  (payload = {}) =>
  (dispatch) =>
    dispatch(toggleLoginWithPasswordModalAction(payload));
