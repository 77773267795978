import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useGalleryImage } from 'hooks/useGalleryImage';

export const GreetingCardsPreview = ({ backgroundImage, galleryId }) => {
  const horizontalImage1 = useGalleryImage('horizontal', 6, galleryId);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={10.3} left={39.9} width={60} height={41.4}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 900" />
      </PreviewImageContainer>
    </>
  );
};
