export const appendOrAddQueryParam = ({ url, params }: { url: string; params: any }): string => {
  try {
    const keys = Object.keys(params);

    keys.forEach((key) => {
      if (params[key] !== undefined) {
        if (url.includes('?')) {
          url += `&${key}=${params[key]}`;
        } else {
          url += `?${key}=${params[key]}`;
        }
      }
    });

    return url;
  } catch (e) {
    console.log(e);

    return url;
  }
};
