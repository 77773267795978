import { createReducer } from 'redux-act';
import { IGlobalReducer } from 'modules/ScrShop/store/types/global';
import {
  setBackButtonRouteAction,
  setBreadcrumbs,
  setModalStateAction,
  setSectionTitleAction,
  setSiteGlobalClass,
  toggleGlobalLoadingAction,
  toggleCheckoutLoadingAction
} from 'modules/ScrShop/store/actions';

const initialState: IGlobalReducer = {
  sectionTitle: '',
  siteGlobalClass: '',
  isGlobalLoading: false,
  isCheckoutLoading: false,
  backButtonRoute: '',
  isShowTermsModal: false,
  isShowCouponModal: false,
  customModalData: {},
  breadcrumbs: []
};

export const global = createReducer<IGlobalReducer>({}, initialState);
global.on(setSectionTitleAction, (state, payload) => ({
  ...state,
  sectionTitle: payload
}));
global.on(setSiteGlobalClass, (state, payload) => ({
  ...state,
  siteGlobalClass: payload
}));
global.on(toggleGlobalLoadingAction, (state, payload) => ({
  ...state,
  isGlobalLoading: payload
}));
global.on(setBackButtonRouteAction, (state, payload) => ({
  ...state,
  backButtonRoute: payload
}));
global.on(setModalStateAction, (state, payload) => ({
  ...state,
  [payload.modal]: payload.state,
  customModalData: payload.customData
}));
global.on(setBreadcrumbs, (state, payload) => ({
  ...state,
  breadcrumbs: payload
}));
global.on(toggleCheckoutLoadingAction, (state, payload) => ({
  ...state,
  isCheckoutLoading: payload
}));
