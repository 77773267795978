import { useMemo } from 'react';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { v4 as uuidv4 } from 'uuid';

export const Mousepad_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 338 547 C 347 514 348 510 387 512 L 1113 510 C 1158 510 1158 502 1174 565 L 1285 948 C 1290 986 1275 996 1209 996 L 258 999 C 210 999 213 978 212 956" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={34} left={14} width={75} height={34}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 710" />
      </PreviewImageContainer>
    </>
  );
};

export const Mousepad_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -26 -47 L 2870 -113 L 1064 1089 C 1002 1119 953 1128 876 1097 L -188 438" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-37}
        left={33}
        width={130}
        height={70}
        transform="matrix3d(1, 0.46, 0, 0, -0.9, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};

export const Mousepad_3 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  const horizontalImage1 = getImageByIdx(horizontalImages, 1, fallbackImages);

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 288 352 C 288 351 292 324 320 328 L 1115 322 C 1128 321 1167 318 1165 355 L 1166 556 C 1091 579 1061 938 1178 981 L 1178 1038 C 1176 1071 1156 1082 1130 1082 L 325 1079 C 296 1074 296 1065 295 1041" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={21} left={10} width={75} height={52}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" />
      </PreviewImageContainer>
    </>
  );
};
