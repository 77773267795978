import { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import { getCheckoutIDSelector } from 'modules/ScrShop/store/selectors/checkout';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import {
  capturePaymentFailure,
  capturePaymentSuccess,
  convertToOrderSuccessAction,
  setRedirectToPaymentAction,
  toggleGlobalLoadingAction,
  updateCheckoutStoreAction
} from 'modules/ScrShop/store/actions';
import { IApplyCouponAction, ICapturePayment, ISagaAction } from 'modules/ScrShop/store/types';
import { showGlobalError } from 'modules/ScrShop/store/actions/errors';
import { getLastPaymentSelector, getOrdersStore } from 'modules/ScrShop/store/selectors/orders';
import { get } from 'lodash';
import { ORDER_DEFAULTS } from 'modules/ScrShop/store/constants/orders';
import { getTranslationKey } from 'helpers/texting';
import { getCollectionCreator } from 'old-store/actions';
import { fetchPhotobooks } from 'modules/ScrPhotobook/store/slices/photobook/actions';

export const fetchOrderSaga = function* (): SagaIterator {
  // @ts-ignore
  const payment = yield select(getLastPaymentSelector);
  const { order } = yield select(getOrdersStore);
  const payment_id = get(payment, '_id', false);
  if (!payment_id) {
    return;
  }
  try {
    const response = yield call(Api.Orders.reFetchPayment, {
      payment_id
    });
    ApiErrors.checkOnApiError(response);
    yield put(
      convertToOrderSuccessAction({
        ...order,
        payments: [response, ...order.payments]
      })
    );
  } catch (e) {
    localStorage.removeItem(`orders_${window.SITE_ID}`);
    console.log(e);
  }
};

export const convertToOrderSaga = function* (): SagaIterator {
  const shoppingCart_id = yield select(getCheckoutIDSelector);
  const collection_id = window.SITE_ID;
  yield put(toggleGlobalLoadingAction(true));
  try {
    const convertResponse = yield call(Api.Orders.convertToOrder, {
      collection_id,
      shoppingCart_id,
      paymentMethod: 'paypal'
    });
    ApiErrors.checkOnApiError(convertResponse);
    yield put(convertToOrderSuccessAction(convertResponse));
    yield put(setRedirectToPaymentAction(true));
    yield put(fetchPhotobooks({}));
  } catch (e) {
    console.log(e);
  }
  yield put(toggleGlobalLoadingAction(false));
};

export const applyCouponSaga = function* ({
  payload
}: ISagaAction<IApplyCouponAction>): SagaIterator {
  const shoppingCart_id = yield select(getCheckoutIDSelector);
  const collectionCreator = yield select(getCollectionCreator);
  const collection_id = window.SITE_ID;
  yield put(toggleGlobalLoadingAction(true));
  try {
    const response = yield call(Api.Orders.applyCoupon, {
      collection_id,
      shoppingCart_id,
      code: payload.code,
      isFromMarketingAutomation: payload.isFromMarketingAutomation
    });

    if (response.response?.data?.error === 'ORDER.VALUE.TOO.LOW') {
      if (!payload.isFromMarketingAutomation) {
        yield put(
          showGlobalError({
            title: getTranslationKey('shop.coupon.notFoundModal.title'),
            text: getTranslationKey('shop.coupon.order-value-too-low', {
              minimumOrderValue: response.response.data.couponMinimumOrderValueCents / 100,
              currency: collectionCreator.currency || 'EUR'
            })
          })
        );
      }
    } else {
      ApiErrors.checkOnApiError(response);
    }
    yield put(updateCheckoutStoreAction(response));
  } catch (e) {
    yield put(
      showGlobalError({
        title: getTranslationKey('shop.coupon.notFoundModal.title'),
        text: getTranslationKey('shop.coupon.notFoundModal.text')
      })
    );
  }
  yield put(toggleGlobalLoadingAction(false));
};

export const cleanUpOrdersSaga = function* (): SagaIterator {
  localStorage.removeItem(`orders_${window.SITE_ID}`);
  yield put(convertToOrderSuccessAction(ORDER_DEFAULTS));
};

export const capturePaymentSaga = function* ({
  payload
}: ISagaAction<ICapturePayment>): SagaIterator {
  try {
    yield put(toggleGlobalLoadingAction(true));
    const response = yield call(Api.Orders.capturePayment, payload);
    ApiErrors.checkOnApiError(response);

    yield put(capturePaymentSuccess());
    yield put(toggleGlobalLoadingAction(false));
  } catch (e) {
    yield put(
      showGlobalError({
        text: getTranslationKey('shop.checkoutPaymentPage.paymentError')
      })
    );
    yield put(capturePaymentFailure());
  }
};
