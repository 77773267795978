import { findNextLayoutGroup } from './findNextLayoutGroup';
import { getImageOrientations } from './getImageOrientations';
import { filterAndSortImagesBySpread } from './sortImagesBySpreadOrder';

export const getInitialFrameLayout = ({ layouts, images, newImage }) => {
  const nextLayoutGroup = findNextLayoutGroup(layouts, 0);
  const layoutWidths = nextLayoutGroup.flatMap((layout) =>
    layout.frames.map((frame) => frame.boundsRelative.widthPercent)
  );

  const currentImages = filterAndSortImagesBySpread(images.images, [newImage]);
  const [currentImageOrientation] = getImageOrientations(currentImages);

  const selectBestLayout = (conditionFn) =>
    nextLayoutGroup.find((layout) => layout.frames.some(conditionFn));

  const bestMatchingLayout =
    currentImageOrientation === 'horizontal'
      ? selectBestLayout(
          ({ boundsRelative }) => boundsRelative.widthPercent === Math.max(...layoutWidths)
        )
      : selectBestLayout(
          ({ boundsRelative }) => boundsRelative.widthPercent === Math.min(...layoutWidths)
        );

  return bestMatchingLayout || null;
};
