import { useEffect } from 'react';
import { getTranslationKey } from 'helpers/texting';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Button, Modal } from 'reactstrap';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { handleTour } from 'store/slices/app';
import { closeSingleImageDownload } from 'store/slices/modals';
import { getCookie } from 'helpers/cookie';
import { useToggle } from 'hooks/useToggle';
import styled from 'styled-components';
import { useBreakpoint } from 'hooks/useBreakpoint';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding-top: 16px;
  position: relative;
  button {
    margin-left: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #dee2e6;
    width: calc(100% + 32px);
    transform: translateX(-16px);
  }
`;

export const DownloadTourModal = () => {
  const dispatch = useAppDispatch();
  const { toggle: isOpened, openToggle: openModal, closeToggle: closeModal } = useToggle();
  const { imagesDownloaded } = useAppSelector((state) => state.app);
  const isDownloadTourNotStarted = getCookie('isDownloadTourFinished') !== 'true';
  const isTourEnabled = localStorage.getItem('downloadTourWasEnabled') === 'true';
  const isDesktop = useBreakpoint('mobile');

  const { isShareSite } = useAppSelector((state) => state.collection);

  const isTourShouldBeShown = imagesDownloaded >= 5 || (isTourEnabled && imagesDownloaded >= 1);

  useEffect(() => {
    if (isDownloadTourNotStarted && isTourShouldBeShown && !isShareSite) {
      openModal();
    }
  }, [isTourShouldBeShown, isShareSite]);

  const startTourDesktop = () => {
    closeModal();

    setTimeout(() => {
      document.querySelector('[data-scroll="image-effect-scroll"]')?.scrollIntoView();
    }, 400);

    // Timeout to provide some more time for tour to mount after scrolling
    setTimeout(() => {
      dispatch(handleTour('downloadTour'));
      dispatch(closeSingleImageDownload());
    }, 450);
  };

  const startTourMobile = () => {
    closeModal();
    dispatch(handleTour('downloadTour'));
    dispatch(closeSingleImageDownload());
  };

  return (
    <Modal
      centered
      isOpen={isOpened}
      toggle={closeModal}
      wrapClassName="modal-primary modal-site download-selection-modal"
      size="medium"
      zIndex={999999}
      backdrop="static"
    >
      <ModalHeader toggle={closeModal}>{getTranslationKey('tourGuide.tip')}</ModalHeader>
      <ModalBody>
        <p>{getTranslationKey('tourGuide.downloadMoreText')}</p>

        <ButtonWrapper>
          <Button className="sb-btn" onClick={closeModal}>
            {getTranslationKey('tourGuide.okay')}
          </Button>
          <Button className="sb-btn" onClick={isDesktop ? startTourDesktop : startTourMobile}>
            {getTranslationKey('tourGuide.startTour')}
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
