import styled from 'styled-components';

import { Contact } from 'components/Footer/Contact';
import { Socials } from 'components/Footer/Socials';
import { getCleanUrl } from 'helpers/url';
import { useAppSelector } from 'hooks/redux';

interface ILogoProps {
  url: string;
  logoUrl?: string;
}

const Logo: React.FC<ILogoProps> = ({ logoUrl, url }) => {
  if (!logoUrl) return null;

  return (
    <a href={getCleanUrl(url)} target="_blank" rel="noreferrer">
      <div id="footer-logo-photographer" className="footer-logo mx-auto text-center">
        <img alt="logo" src={logoUrl} />
      </div>
    </a>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-around;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Footer: React.FC = () => {
  const userBrands = useAppSelector((state) => state.userBrand);
  const { logo } = useAppSelector((state) => state.userBrand);

  const logoUrl = logo?.url;

  const isSocialsPresent = (): boolean => {
    const { socials } = userBrands;

    const keys = Object.keys(socials);

    return keys.some((key) => socials[key] !== '');
  };

  return (
    <Wrapper>
      <Logo logoUrl={logoUrl} url={userBrands.website} />
      <Contact />
      {isSocialsPresent() && <Socials />}
    </Wrapper>
  );
};
