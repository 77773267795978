import { createAction } from 'redux-act';
import { IApplyCouponAction, IOrder, IPayment } from 'modules/ScrShop/store/types';

export const convertToOrderAction = createAction();
export const convertToOrderSuccessAction = createAction<IOrder>();
export const createPaymentSuccessAction = createAction<IPayment>();
export const setRedirectToPaymentAction = createAction<boolean>();
export const applyCouponAction = createAction<IApplyCouponAction>();

export const capturePayment = createAction<{ paymentId: string }>();
export const capturePaymentSuccess = createAction();
export const capturePaymentFailure = createAction();
export const resetCapturePaymentStatus = createAction();

export const cleanUpOrders = createAction();
