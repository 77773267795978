import { ICheckoutFlowReducer } from 'modules/ScrShop/store/types';

export const DEFAULT_CHECKOUT_FLOW_STORE: ICheckoutFlowReducer = {
  isShowLoginForm: false,
  isShowShippingForm: true,
  isShowShippingEditForm: false,
  isShowOverview: false,
  isPricingUpdating: false,
  loginForm: {
    email: {
      value: '',
      error: ''
    },
    password: {
      value: '',
      error: ''
    }
  }
};
