import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { logout } from 'old-store/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getEndCustomerStore } from 'modules/ScrShop/store/selectors';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { getTranslationKey } from 'helpers/texting';
import {
  setBackButtonRouteAction,
  setBreadcrumbs,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShop/store/actions';
import styled from 'styled-components';
import media from 'assets/css/media';

const Icon = styled.i`
  font-size: 25px;
  @media all and (max-width: ${media.mobile}) {
    font-size: 20px;
  }
`;

export function AccountDropdown() {
  const { authed } = useSelector(getEndCustomerStore);
  const { isShareSite } = useAppSelector((state) => state.collection);
  const dispatch = useDispatch();
  const history = useHistory();
  const openLoginModal = () => dispatch(toggleLoginWithPasswordModalAction());
  const openAccountPage = () => {
    dispatch(setBackButtonRouteAction(window.location.pathname.replace(`/${window.SITE_ID}`, '')));
    dispatch(setBreadcrumbs([]));
    history.push('/shop/account');
  };

  if (isShareSite) return null;

  return authed ? (
    <UncontrolledDropdown>
      <DropdownToggle
        className="p-0 m-0"
        color="rgba(0, 0, 0, 0)"
        data-cy="component-dropdown-toggle"
      >
        <Icon className="fa fa-user profile-dropdown-toggle" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem data-cy="component-my-account" disabled={false} onClick={openAccountPage}>
          {getTranslationKey('shop.accountDropdown.myAccount')}
        </DropdownItem>
        <DropdownItem data-cy="component-logout-item" disabled={false} onClick={() => logout()}>
          {getTranslationKey('shop.accountDropdown.logout')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button
      data-cy="component-login-button"
      className="p-0 m-0"
      color="rgba(0, 0, 0, 0)"
      onClick={openLoginModal}
    >
      <Icon className="fa fa-user profile-dropdown-toggle" />
    </Button>
  );
}
