import { useState, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import { ColumnProps } from 'reactstrap/lib/Col';
import { HideSectionQuery } from 'modules/ScrShop/components/Layouts';
import { PersonalizedPreview } from 'modules/ScrShop/components/PersonalizedPreview';
import { IProductImage } from 'modules/ScrShop/store/types';
import { preloadImage } from 'helpers/preloader';
import media from 'assets/css/media';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Wrapper = styled(Col)``;

const SwiperContainer = styled.div`
  height: fit-content;

  img {
    width: 100%;
    height: 100%;
  }

  .slick-slide {
    height: auto;
  }

  .slick-dots {
    li {
      margin: 0;
    }

    @media all and (max-width: ${media.desktop}) {
      bottom: 5px;
    }
  }
`;

const ThumbContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 40px;
  height: 100px;

  .slick-slide {
    cursor: pointer;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    overflow: hidden;
    width: 80px !important;
    height: 80px;
    margin-right: 15px;

    svg {
      border-radius: 5px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageSlideWrapperExtended = styled.div`
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  img {
    object-fit: cover;
  }
`;

const SlideOverlay = styled.div`
  opacity: 0.5;
  position: absolute;
  background: #fff;
  inset: 0;
  z-index: 9;
`;

interface ISwiperSlider {
  slideComponents: IProductImage[];
  productSlug: string;
  colSizes?: {
    xs?: ColumnProps;
    sm?: ColumnProps;
    md?: ColumnProps;
    lg?: ColumnProps;
    xl?: ColumnProps;
  };
}

export const SwiperSlider: React.FC<ISwiperSlider> = ({
  slideComponents,
  colSizes,
  productSlug
}) => {
  const sliderRef = useRef<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  useLayoutEffect(() => {
    // preloading images before they are rendered
    slideComponents.forEach(({ src }) => preloadImage(src));
  }, []);

  const SLIDE_SETTINGS = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    useTransform: false,
    beforeChange: (_: number, newIndex: number) => setCurrentSlide(newIndex)
  };

  const THUMBS_SLIDE_SETTINGS = {
    ...SLIDE_SETTINGS,
    swipe: false,
    dots: false
  };

  const goToIndex = (index: number) => sliderRef?.current?.slickGoTo(index);

  return (
    <Wrapper {...colSizes}>
      <SwiperContainer>
        <Slider ref={sliderRef} {...SLIDE_SETTINGS}>
          {slideComponents.map((item, index) => (
            <div key={index} style={{ overflow: 'hidden' }}>
              <ImageSlideWrapperExtended>
                <PersonalizedPreview image={item.src} groupSlug={`${productSlug}_${index}`} />
              </ImageSlideWrapperExtended>
            </div>
          ))}
        </Slider>
      </SwiperContainer>

      <HideSectionQuery mediaSection="mobile">
        <ThumbContainer>
          <Slider {...THUMBS_SLIDE_SETTINGS}>
            {slideComponents.map((item, index) => (
              <div
                key={index}
                style={{ overflow: 'hidden' }}
                onClick={() => goToIndex(index)}
                onKeyDown={() => goToIndex(index)}
                role="listitem"
              >
                <ImageSlideWrapperExtended>
                  {currentSlide !== index && <SlideOverlay />}
                  <PersonalizedPreview image={item.src} groupSlug={`${productSlug}_${index}`} />
                </ImageSlideWrapperExtended>
              </div>
            ))}
          </Slider>
        </ThumbContainer>
      </HideSectionQuery>
    </Wrapper>
  );
};
