export default {
  VIEW: 'view',
  DOWNLOAD: 'download',
  CLICK: 'click',
  APP_INSTALL: 'appInstall',
  SHARE: 'share',
  CREATE: 'create',
  COMPLETE: 'complete',
  SAVE: 'save',
  SECURITY: 'security'
};
