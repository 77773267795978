import { fabric } from 'fabric';

export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);

    img.onerror = img.onabort = () => reject(src);

    img.src = src;
  });
};

export const loadFabricImage = (url) =>
  new Promise((resolve, reject) => {
    fabric.Image.fromURL(url, (img) => {
      if (img) {
        resolve(img);
      } else {
        reject(new Error('Image failed to load'));
      }
    });
  });
