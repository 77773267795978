export const BREADCRUMBS_PRODUCT_FULL = [
  {
    link: '/shop',
    text: 'Shop'
  },
  {
    link: '/shop/products/{groupSlug}',
    text: '{groupTitle}'
  },
  {
    link: '/shop/products/{groupSlug}/{productSlug}',
    text: '{productTitle}'
  }
];
