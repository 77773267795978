import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';

export const useGalleryImage = (imageType: string, imageIdx: number, galleryId: string = '') => {
  const { groupedImages, groupedByGalleriesImages } = useAppSelector((state) => state.shop);

  const groupedGalleriesImages = groupedByGalleriesImages[imageType][galleryId];
  const groupedFallbackImages = groupedByGalleriesImages.fallback[galleryId];

  if (groupedFallbackImages?.length) {
    return getImageByIdx(groupedGalleriesImages, imageIdx, groupedFallbackImages);
  }

  const defaultImages = groupedImages[imageType];
  const defaultFallbackImages = groupedImages.fallback;

  return getImageByIdx(defaultImages, imageIdx, defaultFallbackImages);
};
