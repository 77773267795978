export default {
  TEL: 'tel',
  WA: 'whatsApp',
  FB: 'facebook',
  EM: 'email',
  LINK: 'link',
  PT: 'pinterest',
  YT: 'youtube',
  IG: 'instagram',
  TW: 'twitter',
  WWW: 'website',
  PAGE: 'page'
};
