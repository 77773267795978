import { useTheme } from 'hooks/useTheme';
import { theme as defaultTheme } from 'assets/css/theme';
import { ModalFooter as ModalFooterReactsrap, ModalFooterProps } from 'reactstrap';
import styled from 'styled-components';
import { intersection } from 'lodash';
import { useLocation } from 'react-router-dom';

const Footer = styled(ModalFooterReactsrap)`
  .close {
    color: ${(props) => props.theme.colors.modalFontColor} !important;
  }
  background-color: ${(props) => props.theme.colors.modalBg} !important;
  ${(props) => props.color && `color: ${props.color};`}
`;

const ModalFooter = (props: ModalFooterProps) => {
  const [theme] = useTheme();
  const { pathname } = useLocation();
  const isShop = intersection(pathname.split('/'), ['shop']).length;

  return (
    <Footer
      color={!isShop && theme.colors.modalFontColor}
      theme={isShop ? defaultTheme : theme}
      {...props}
    />
  );
};

export { ModalFooter };
