import React from 'react';
import { IIconProps } from 'types/icons';

interface IBaseIconProps extends IIconProps {
  icon: React.FunctionComponent<any>;
}

const BaseIcon = (props: IBaseIconProps) => {
  const { icon: Icon, size, color = 'black', className, ...restProps } = props;

  return (
    <Icon
      fill={color}
      stroke={color}
      width={size}
      height={size}
      className={className}
      {...restProps}
    />
  );
};

export { BaseIcon };
