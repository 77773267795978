import { createReducer } from 'redux-act';
import { IProductsReducer } from 'modules/ScrShop/store/types';
import {
  fetchProductsAction,
  fetchProductsActionSuccess,
  fetchProductSuggestionSuccess,
  setMadeCroppingChanges
} from 'modules/ScrShop/store/actions';

const initialState: IProductsReducer = {
  groups: [],
  isLoading: true,
  suggestions: [],
  isMadeCroppingChanges: false
};

export const products = createReducer<IProductsReducer>({}, initialState);
products.on(fetchProductsAction, (state) => ({
  ...state,
  isLoading: true
}));
products.on(fetchProductsActionSuccess, (state, payload) => ({
  ...state,
  groups: payload,
  isLoading: false
}));
products.on(fetchProductSuggestionSuccess, (state, payload) => ({
  ...state,
  suggestions: payload
}));
products.on(setMadeCroppingChanges, (state, payload) => ({
  ...state,
  isMadeCroppingChanges: payload
}));
