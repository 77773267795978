import {
  PfePostkarte10x15,
  PfePostkarte10x21,
  PfePostkarte12x18,
  PfePostkarte12x23
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/GreeingsCards/Postcards';

export const greeingCards = {
  'pfe-postkarte-10x15': PfePostkarte10x15,
  'pfe-postkarte-10x15_0': PfePostkarte10x15,
  'pfe-postkarte-10x21': PfePostkarte10x21,
  'pfe-postkarte-10x21_0': PfePostkarte10x21,
  'pfe-postkarte-12x18': PfePostkarte12x18,
  'pfe-postkarte-12x18_0': PfePostkarte12x18,
  'pfe-postkarte-12x23': PfePostkarte12x23,
  'pfe-postkarte-12x23_0': PfePostkarte12x23
};
