import { Currency } from 'dinero.js';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { RiAlarmFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import media from 'assets/css/media';
import { CountDown } from 'components/CountDown';
import { Exist } from 'components/Exist';
import { GalleryDivider } from 'components/Gallery/GalleryDivider';
import { useAppSelector } from 'hooks/redux';
import { trackEventAction } from 'old-store/actions';
import { BANNERS_TEXT_KEYS } from 'old-store/constants/banners';
import { Modal } from 'modules/ScrShop/components/Modal';
import { useMediaQuery } from 'modules/ScrShop/store/hooks';
import {
  getProductByID,
  getProductGroupByID,
  isShopActiveSelector
} from 'modules/ScrShop/store/selectors';
import { displayProductPrice } from 'modules/ScrShop/store/utils';
import { getTranslationKey } from 'helpers/texting';
import { useCurrency } from 'hooks/useCurrency';

export type TBannerType = 'fullWidth' | 'gallery' | 'galleryMasonry';

const discountValueFontSize = css<{ width: number }>`
  font-size: ${({ width }) => {
    if (width < 100) return '20px';
    if (width < 200) return '30px';
    if (width < 280) return '42px';
    if (width < 380) return '60px';

    return '80px';
  }};
`;

const BannerModalWrapper = styled.div<{ width: number }>`
  .banner-modal-discount {
    ${discountValueFontSize}
  }
`;

const ModalStyled = styled(Modal)`
  container-type: inline-size;
  container-name: banner-container;
`;

const ComponentWrapper = styled.div<{ stylesType: TBannerType; section: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.stylesType !== 'fullWidth' ? '100%' : 'auto')};
  padding: ${(props) =>
    props.stylesType === 'fullWidth' && props.section === 'gallery' ? '0 .8rem' : '0'};

  .banner-modal {
    background: #f0e9df;
    padding: 30px;
    max-width: 45%;
    text-align: center;
    @media (max-width: ${media.desktop}) {
      max-width: 100%;
    }

    .banner-modal-discount {
      margin-top: 2rem;
    }

    .close-button {
      @media (min-width: ${media.desktop}) {
        display: none;
      }
    }
  }
`;

const LeftSection = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: 20%;
  color: #000;
  align-self: stretch;
  border-right: 3px dashed #ffffff;
`;
const LeftSectionValue = styled.div`
  font-size: 4.5em;
  font-style: italic;
  line-height: 1;
  text-shadow: -7px 1px 2px white;
`;
const LeftSectionLabel = styled.div``;
const MainSection = styled.div`
  flex: 1;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Row = styled.div`
  color: #b3a289;
  font-size: 20px;

  &:last-child {
    margin-bottom: 5px;
  }
`;
const Asterisk = styled.div`
  color: #000;
  font-size: 12px;
`;
const CTA = styled.div`
  color: #000;
  border-bottom: 2px solid #000000;
  margin-top: 20px;
  cursor: pointer;
  @media (max-width: ${media.desktop}) {
    font-size: 16px;
  }
`;

const CountDownWrapper = styled.div`
  color: #b3a289;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;

  svg {
    margin-right: 5px;
    margin-bottom: 4px;
  }
`;

const inGalleryStyles = css<{
  width: number;
}>`
  text-align: center;
  @media (max-width: ${media.desktop}) {
    font-size: 12px;
  }

  ${MainSection} {
    align-items: center;
    align-self: stretch;
    container-type: inline-size;
    container-name: banner-container;
  }

  ${Row} {
    &:nth-child(1) {
      font-size: 1.5em;
      margin-bottom: 1em;
    }
  }
  .banner-row-2 {
    color: #000000;
    ${discountValueFontSize}
    font-style: italic;
    text-shadow: -7px 1px 2px white;
    line-height: 0.7;
    margin-top: auto;
  }

  ${CTA} {
    margin-top: auto;
  }
`;

const bannerStyles: any = {
  gallery: css`
    ${inGalleryStyles};
    margin: 0;
    width: 100%;
    @media (max-width: ${media.desktop}) {
      margin: 0;
      width: 100%;
      font-size: 14px;
      height: 100%;
    }
  `,
  galleryMasonry: css`
    ${inGalleryStyles};
    height: 400px;
    margin: 0;
    @media (max-width: ${media.desktop}) {
      height: auto;
    }
  `
};
const BannerWrapper = styled.div<{
  stylesType: TBannerType;
  notClickable?: boolean;
  section?: 'gallery' | 'shop';
  templateName: string;
  isMobileDevice: boolean;
  width?: number;
}>`
  background: #f0e9df;
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  font-size: 20px;
  margin: ${(props) => {
    if (props.templateName === 'VickyBaumannFineart') return '20px 0';
    if (props.isMobileDevice) return '10px 0';

    return '10px 0 0';
  }};
  cursor: ${(props) => (props.notClickable ? 'normal' : 'pointer')};
  width: 100%;
  border-radius: ${(props) => (props.section === 'shop' ? '10px' : 0)};
  @media (max-width: ${media.desktop}) {
    min-height: 100px;
  }
  ${(props) => bannerStyles[props.stylesType] || null};
`;

const getIsGallery = (type: string, section?: 'gallery' | 'shop') =>
  type === 'fullWidth' && section === 'gallery';
const getIsShop = (type: string, section?: 'gallery' | 'shop') =>
  type === 'fullWidth' && section === 'shop';
const getIsBetweenImages = (type: string, index: number, section?: 'gallery' | 'shop') =>
  type !== 'fullWidth' && !section && index;

const getTextKeys = (
  type: string,
  isMobile: boolean,
  index: number,
  section?: 'gallery' | 'shop'
) => {
  let bannerTextKeysType: keyof typeof BANNERS_TEXT_KEYS = '';
  if (getIsGallery(type, section)) {
    bannerTextKeysType = isMobile ? 'smallGallery' : 'largeGallery';
  }
  if (getIsShop(type, section)) {
    bannerTextKeysType = isMobile ? 'smallShop' : 'largeShop';
  }
  if (getIsBetweenImages(type, index, section)) {
    bannerTextKeysType = `betweenImages${index}`;
  }

  if (bannerTextKeysType) {
    return {
      bannerTextKeysType,
      textKeys: BANNERS_TEXT_KEYS[bannerTextKeysType]
    };
  }

  return {
    bannerTextKeysType,
    textKeys: {
      row1: '',
      row2: '',
      row2MinimumValue: '',
      asterisk: '',
      discountValue: '',
      discountLabel: '',
      cta: '',
      freeShippingForAllOrders: '',
      freeShippingAvailableForCart: '',
      cartNeedsMoreProductsForFreeShipping: ''
    }
  };
};

const timeFormat = 'MM DD YYYY, h:mm a';

interface IProps {
  type: TBannerType;
  section?: 'gallery' | 'shop';
  grandProductGrossPrice?: number;
  notClickable?: boolean;
  trackingContext?: { [key: string]: any };
}

// tslint:disable-next-line:cyclomatic-complexity max-func-body-length
export const PromoBanner: React.FC<IProps> = ({
  type,
  section,
  grandProductGrossPrice,
  notClickable,
  trackingContext
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bannerWrapperRef = useRef<any>(null);
  const bannerModalWrapperRef = useRef<any>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [bannerIndex, setIndex] = useState(0);
  const currency = useCurrency();
  const { collectionCreator, templateName } = useAppSelector((state) => state.collection);
  const environment = useSelector((item: any) => item.environment);
  const isShopActive = useSelector(isShopActiveSelector);
  const [modalState, changeModalState] = useState(false);
  const { couponCodeAdvertising, freeShipping } = useAppSelector((state) => state.banners);
  const isGrandProductGrossPricePresented = typeof grandProductGrossPrice === 'number';
  const isShowFreeShipping = isShopActive && freeShipping && isGrandProductGrossPricePresented;
  const isShowPromo = isShopActive && (couponCodeAdvertising || isShowFreeShipping);
  const { textKeys, bannerTextKeysType } = getTextKeys(type, isMobile, bannerIndex, section);
  const discount = get(couponCodeAdvertising, 'coupon.discounts[0]', {}) as any;
  const minimumOrderValue = get(couponCodeAdvertising, 'coupon.minimumOrderValue', null);
  const endsAt = get(couponCodeAdvertising, 'endsAt', '');
  const today = moment();
  const validDateMoment = moment(endsAt);
  const isDateValid = validDateMoment.isValid();
  const daysLeft = validDateMoment.diff(today, 'days');

  const isFreeShippingForAllOrders = !!freeShipping && freeShipping.startsFrom === 0;
  const isFreeShippingAvailableForCart =
    !isFreeShippingForAllOrders &&
    !!freeShipping &&
    isGrandProductGrossPricePresented &&
    freeShipping.startsFrom <= grandProductGrossPrice!;

  const amountCartNeededForFreeShipping =
    !isFreeShippingForAllOrders &&
    !isFreeShippingAvailableForCart &&
    !!freeShipping &&
    isGrandProductGrossPricePresented &&
    (freeShipping.startsFrom - grandProductGrossPrice!) / 100;

  const isShowLeftSide =
    !isShowFreeShipping && !isMobile && ['gallery', 'galleryMasonry'].indexOf(type) === -1;
  const productGroup = useSelector(getProductGroupByID)(discount.productGroupId);
  const product = useSelector(getProductByID)(discount.productId);
  const productTitle = get(product, 'title', '');
  const productDimensions = get(product, 'dimensions', null);
  const productCouponTitle = `${productTitle}${
    productDimensions ? ` ${productDimensions.width}x${productDimensions.height}` : ''
  }`;
  const productGroupTitle = get(productGroup, 'title', '');
  const groupTitle = get(productGroup, 'couponTitle', productGroupTitle);

  useEffect(() => {
    // tslint:disable-next-line:insecure-random
    const randIndex = Math.floor(Math.random() * 3) + 1;
    setIndex(randIndex);
  }, [dispatch]);

  let productsTitle;
  switch (discount.type) {
    case 'productGroup':
      productsTitle = groupTitle;
      break;
    case 'product':
      productsTitle = productCouponTitle;
      break;
    default:
      productsTitle = getTranslationKey('banners.products');
  }

  const replacedValues = {
    'user.first_name': collectionCreator.firstName,
    all: discount.type === 'product' ? '' : ` ${getTranslationKey('banners.all')}`,
    'valuePercent/valueFixed':
      discount.valueType === 'percent'
        ? discount.valuePercent
        : displayProductPrice(discount.valueFixed || 0, currency),
    'currency/%': discount.valueType === 'percent' ? '%' : '',
    'products/productGroupName': productsTitle,
    quantityMax: discount.quantityMax,
    productGroupName: discount.type === 'productGroup' ? groupTitle : '',
    amountCartNeededForFreeShipping:
      typeof amountCartNeededForFreeShipping === 'number' && freeShipping
        ? `${displayProductPrice(
            Math.round(amountCartNeededForFreeShipping * 100),
            currency as Currency
          )}`
        : '',
    startsFrom: `${
      freeShipping && displayProductPrice(freeShipping.startsFrom, currency as Currency)
    }`,
    minimumValue:
      typeof minimumOrderValue === 'number'
        ? displayProductPrice(minimumOrderValue * 100, currency)
        : ''
  };
  const navigateToShop = () => history.push('/shop');
  const clickAction = () => {
    if (section === 'gallery') {
      dispatch(
        trackEventAction({
          name: 'clicked-action',
          payload: {
            button_id: 'promo-banner',
            is_coupon_code_advertising: couponCodeAdvertising,
            is_free_shipping: isShowFreeShipping,
            ...trackingContext
          }
        })
      );
      history.push('/shop');

      return;
    }
    if (section === 'shop' && discount.type === 'productGroup' && discount.productGroupId) {
      dispatch(
        trackEventAction({
          name: 'clicked-action',
          payload: {
            button_id: 'promo-banner',
            is_coupon_code_advertising: couponCodeAdvertising,
            is_free_shipping: isShowFreeShipping,
            ...trackingContext
          }
        })
      );
      history.push(`/shop/products/${discount.productGroupId}`);

      return;
    }

    if (section === 'shop' && discount.type === 'product' && discount.productId) {
      dispatch(
        trackEventAction({
          name: 'clicked-action',
          payload: {
            button_id: 'promo-banner',
            is_coupon_code_advertising: couponCodeAdvertising,
            is_free_shipping: isShowFreeShipping,
            ...trackingContext
          }
        })
      );
      history.push(`/shop/products/${product?._productGroup}/${product?.slug}?grouped`);

      return;
    }

    if (!section) {
      changeModalState(true);
    }
  };

  const closeModal = () => {
    changeModalState(false);
  };

  const countDownComponent = (
    <Exist when={isDateValid}>
      <CountDownWrapper>
        <RiAlarmFill />
        {daysLeft ? (
          `${daysLeft} ${
            daysLeft === 1
              ? getTranslationKey('banners.dayLeft')
              : getTranslationKey('banners.daysLeft')
          }`
        ) : (
          <CountDown
            timeTillDate={validDateMoment.format(timeFormat)}
            timeFormat={timeFormat}
            displayValues={['h', 'm', 's']}
            interval={1000}
          />
        )}
      </CountDownWrapper>
    </Exist>
  );

  const row2 = bannerTextKeysType
    ? getTranslationKey(BANNERS_TEXT_KEYS[bannerTextKeysType].row2, replacedValues)
    : '';
  const getRow2 = (row2: string) =>
    typeof minimumOrderValue === 'number'
      ? `${row2} ${getTranslationKey(
          BANNERS_TEXT_KEYS.largeGallery.row2MinimumValue,
          replacedValues
        )}`
      : row2;
  const largeGalleryRow2 = getRow2(row2);

  return isShowPromo ? (
    <>
      <ComponentWrapper stylesType={type} section={section || ''}>
        <BannerWrapper
          onClick={clickAction}
          stylesType={type}
          width={bannerWrapperRef.current?.offsetWidth}
          section={section}
          notClickable={notClickable}
          templateName={templateName}
          isMobileDevice={environment.isMobileDevice}
          ref={bannerWrapperRef}
        >
          <Exist when={isShowLeftSide}>
            <LeftSection>
              <LeftSectionValue>
                {getTranslationKey(textKeys.discountValue, replacedValues)}
              </LeftSectionValue>
              <LeftSectionLabel>
                {getTranslationKey(textKeys.discountLabel, replacedValues)}
              </LeftSectionLabel>
              {countDownComponent}
            </LeftSection>
          </Exist>
          <MainSection>
            <Exist when={!isShowFreeShipping}>
              <Row>{getTranslationKey(textKeys.row1, replacedValues)}</Row>
              <Row className="banner-row-2">
                {['largeGallery', 'largeShop', 'smallGallery', 'smallShop'].includes(
                  bannerTextKeysType
                )
                  ? largeGalleryRow2
                  : getTranslationKey(textKeys.row2, replacedValues)}
              </Row>
              <Exist when={discount.quantityMax}>
                <Asterisk>{getTranslationKey(textKeys.asterisk, replacedValues)}</Asterisk>
              </Exist>
              <Exist when={Boolean(!section || isMobile)}>{countDownComponent}</Exist>
              <CTA>{getTranslationKey(textKeys.cta, replacedValues)}</CTA>
            </Exist>
            <Exist when={!!isShowFreeShipping}>
              <Exist when={isFreeShippingForAllOrders}>
                <Row>{getTranslationKey(textKeys.freeShippingForAllOrders, replacedValues)}</Row>
              </Exist>
              <Exist when={isFreeShippingAvailableForCart}>
                <Row>
                  {getTranslationKey(textKeys.freeShippingAvailableForCart, replacedValues)}
                </Row>
              </Exist>
              <Exist when={amountCartNeededForFreeShipping !== false}>
                <Row>
                  {getTranslationKey(textKeys.cartNeedsMoreProductsForFreeShipping, replacedValues)}
                </Row>
              </Exist>
            </Exist>
          </MainSection>
        </BannerWrapper>
        <Exist when={Boolean(modalState && (!section || isMobile))}>
          <BannerModalWrapper
            ref={bannerModalWrapperRef}
            width={bannerModalWrapperRef.current?.offsetWidth}
          >
            <ModalStyled onClose={closeModal} className="banner-modal" isClosableOutSide>
              <Row className="banner-modal-row1">
                {getTranslationKey(BANNERS_TEXT_KEYS.largeGallery.row1, replacedValues)}
              </Row>
              <Row className="banner-modal-row2">
                {getRow2(getTranslationKey(BANNERS_TEXT_KEYS.largeGallery.row2, replacedValues))}
              </Row>
              <LeftSectionValue className="banner-modal-discount">
                {getTranslationKey(textKeys.row2, replacedValues)}
              </LeftSectionValue>
              <Exist when={discount.quantityMax}>
                <Asterisk>
                  {getTranslationKey(BANNERS_TEXT_KEYS.largeGallery.asterisk, replacedValues)}
                </Asterisk>
              </Exist>
              {countDownComponent}
              <CTA onClick={navigateToShop}>
                {getTranslationKey(BANNERS_TEXT_KEYS.largeGallery.cta, replacedValues)}
              </CTA>
            </ModalStyled>
          </BannerModalWrapper>
        </Exist>
      </ComponentWrapper>
      {section === 'gallery' && type === 'fullWidth' && <GalleryDivider />}
    </>
  ) : null;
};
