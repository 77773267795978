import styled from 'styled-components';

interface IPreviewContainer {
  top: number;
  left: number;
  right?: number;
  bottom?: number;
  width?: number;
  height?: number;
  transform?: string;
  borderRadius?: number;
  boxShadow?: string;
  zIndex?: number;
  media?: string;
}

export const PreviewImageContainer = styled.div<IPreviewContainer>`
  position: absolute;
  overflow: hidden;
  top: ${({ top }) => `${top}%`};
  left: ${({ left }) => `${left}%`};
  right: ${({ right }) => `${right}%`};
  bottom: ${({ bottom }) => `${bottom}%`};
  width: ${({ width }) => `${width}%`};
  height: ${({ height }) => `${height}%`};
  transform: ${({ transform }) => transform};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: ${({ borderRadius }) => borderRadius + '%'};
  z-index: ${({ zIndex }) => zIndex || 2};
  transform-origin: 0px 0px;
  ${({ media }) => media};
`;
