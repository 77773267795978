import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

export const Aludibond_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 19, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 87 49 L 1746 367 L 993 1435 L -679 860" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-27}
        left={25}
        width={130}
        height={108}
        zIndex={1}
        transform="matrix3d(1,0.5,0.4,0.0003,-0.8,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} y={80} />
      </PreviewImageContainer>
    </>
  );
};

export const Aludibond_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 19, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={4}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -36 169 L 953 211 L 407 618 L -346 544" />
              <path d="M 1053 190 L 1628 214 L 1529 727 L 504 623" />
              <path d="M 73 614 L 1417 765 L 704 1521 L -594 1192" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-7}
        left={14}
        width={53}
        height={46}
        zIndex={1}
        transform="matrix3d(1,0.38,0.5,0.0001,-0.9,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} y={40} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-4}
        left={83.3}
        width={54}
        height={46}
        zIndex={2}
        transform="matrix3d(1,0.38,0.5,0.0001,-1.1,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} y={100} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={40}
        left={5}
        width={105}
        height={80}
        zIndex={3}
        transform="matrix3d(1,0.12,0.5,0.0001,-1.1,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} y={-100} />
      </PreviewImageContainer>
    </>
  );
};

export const Aludibond_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 19, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -165 -84 L 3195 -95 L 397 1335 L -639 649" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={-41}
        left={53}
        width={126}
        height={105}
        zIndex={1}
        transform="matrix3d(1,0.5,0.4,0.0003,-1.29,1,0,0,0,0,1,0,0,0,0,1)"
      >
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-200} />
      </PreviewImageContainer>
    </>
  );
};
