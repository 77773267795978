import {
  Mousepad_1,
  Mousepad_2,
  Mousepad_3
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Decor/Mousepad';
import {
  Cushion_1,
  Cushion_2,
  Cushion_3
} from 'modules/ScrShop/components/PersonalizedPreview/ProductsPreview/Decor/Cushion';

export const decor = {
  'pic-cushion-basic-40x40': Cushion_1,
  'pic-cushion-basic-40x40_0': Cushion_1,
  'pic-cushion-basic-40x40_1': Cushion_2,
  'pic-cushion-basic-40x40_2': Cushion_3,
  'pic-mousepad-22x18': Mousepad_1,
  'pic-mousepad-22x18_0': Mousepad_1,
  'pic-mousepad-22x18_1': Mousepad_2,
  'pic-mousepad-22x18_2': Mousepad_3,

  'pic-cushion-basic-16x16': Cushion_1,
  'pic-cushion-basic-16x16_0': Cushion_1,
  'pic-cushion-basic-16x16_1': Cushion_2,
  'pic-cushion-basic-16x16_2': Cushion_3
};
