import { useRef, RefObject, useState } from 'react';
import { Input } from 'modules/ScrShop/components/Forms';
import { Col, Form, Modal, Row } from 'reactstrap';
import { Button } from 'modules/ScrShop/components/Buttons';
import { setForgotPasswordFlowStatus } from 'modules/ScrShop/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { loginFlowStatusSelector } from 'modules/ScrShop/store/selectors';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { SendToPhotographerIcon } from 'components/icons/SendToPhotographerIcon';
import { LinkBtn } from 'components/IconButton/LinkButton';
import { useIsShop } from 'hooks/useIsShop';
import { getTranslationKey } from 'helpers/texting';
import { useTheme } from 'hooks/useTheme';

type Props = {
  title: string;
  showEnterPasswordModal: boolean;
  password: string;
  text: string;
  passwordIsInvalid: boolean;
  submitButtonLabel: string;
  footerLinkText?: string;
  onSubmit: (password: string) => void;
  toggleModal: () => void;
  setPassword: (password: string) => void;
  onFooterLinkClick?: () => void;
};

function EnterPasswordModal({
  title,
  showEnterPasswordModal,
  text,
  password,
  passwordIsInvalid,
  footerLinkText,
  submitButtonLabel,
  onSubmit,
  toggleModal,
  setPassword,
  onFooterLinkClick
}: Props) {
  const [theme] = useTheme();
  const dispatch = useDispatch();
  const [isTooShortPass, setIsTooShortPass] = useState(false);
  const { email, endCustomerHasPassword } = useSelector(loginFlowStatusSelector);
  const isShop = useIsShop();
  const ref = useRef<RefObject<HTMLInputElement>>(null);

  const openForgotPasswordModal = () => {
    toggleModal();
    dispatch(
      setForgotPasswordFlowStatus({
        showForgotPasswordModal: true,
        redirectToLoginModal: true,
        email: email.toLowerCase()
      })
    );
  };

  return (
    <Modal
      data-cy="component-password-modal"
      centered
      isOpen={showEnterPasswordModal}
      toggle={toggleModal}
      size="md"
      // @ts-ignore
      zIndex={999999}
    >
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            if (password.length >= 6) onSubmit(password);
            else setIsTooShortPass(true);
          }}
        >
          <Row>
            <Col xs="12">
              <p data-cy="component-show-email">{text}</p>
              <Input
                ref={ref}
                data-cy="component-password-input"
                name="password"
                type="password"
                label={getTranslationKey('shop.forms.passwordPlaceHolder')}
                placeholder={getTranslationKey('shop.forms.passwordPlaceHolder')}
                value={password}
                onChange={(e: React.BaseSyntheticEvent) => setPassword(e.currentTarget.value)}
                errorText={
                  isTooShortPass ? getTranslationKey('enterPasswordModal.passwordIsTooShort') : ''
                }
                withEye
              />
            </Col>
            <Col xs="12">
              {passwordIsInvalid && (
                <p className="text-center" data-cy="component-modal-message">
                  {getTranslationKey('enterPasswordModal.passwordIsInvalid')}
                </p>
              )}
              <Button
                data-cy="component-submit-button"
                className="sb-btn sb-btn-icon w-100-p text-center"
                disabled={false}
                type="submit"
              >
                <SendToPhotographerIcon
                  size={16}
                  color={isShop ? 'white' : theme.colors.menuIconsColor}
                />
                {submitButtonLabel}
              </Button>
              {endCustomerHasPassword && (
                <>
                  {onFooterLinkClick && footerLinkText && (
                    <LinkBtn onClick={onFooterLinkClick} onKeyDown={onFooterLinkClick}>
                      {footerLinkText}
                    </LinkBtn>
                  )}
                  <LinkBtn onClick={openForgotPasswordModal} onKeyDown={openForgotPasswordModal}>
                    {getTranslationKey('shop.forms.forgotPassword')}
                  </LinkBtn>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default EnterPasswordModal;
