import { AnyAction, createAsyncThunk, Dispatch, ThunkAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { HttpStatus } from 'constants/statusCodes';

import { getCookie, setCookie } from 'helpers/cookie';
import { getTranslationKey } from 'helpers/texting';
import { getEndCustomer } from 'old-store/actions';
import { RootState } from 'old-store/store';
import { ISelection } from 'old-store/types/gallery';
import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';
import { toast } from 'react-toastify';
import { addSelection, handleLikes, ISelectionState } from 'store/slices/selection';
import { ICollection } from 'types/collection';

interface IValues {
  selectionName: string | null;
  message?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  withCongratsModal?: boolean;
  withPasswordModal?: boolean;
}

export function checkIfAuthorizedAndLogoutIfNot(code: number, user: string = '') {
  if (code === HttpStatus.Forbidden || code === HttpStatus.NotFound) {
    if (user !== '') {
      const currentLocalStorageValue = localStorage.getItem('endCustomerToken');
      const currentLocalStorageTokenMap = currentLocalStorageValue
        ? JSON.parse(currentLocalStorageValue)
        : {};

      localStorage.setItem(
        'endCustomerToken',
        JSON.stringify({
          ...currentLocalStorageTokenMap,
          [user]: undefined
        })
      );

      setCookie('endCustomerToken', '');
    }
    window.location.reload();
  }
}

export const fetchCustomerToken = async (user: string) => {
  try {
    const actualEndCustomerCookie = getCookie('endCustomerToken');

    if (actualEndCustomerCookie) {
      const { SITE_ID: collectionId } = window;

      const endCustomerTokenIsValid = await axios
        .get('/api/end-customers/validate-token', {
          headers: {
            authorizationendcustomer: actualEndCustomerCookie
          },
          params: {
            collectionId
          },
          validateStatus: (status) => status === 200 || status === 403
        })
        .then((response) => response.status === 200);

      if (endCustomerTokenIsValid) {
        const currentLocalStorageValue = localStorage.getItem('endCustomerToken');
        const currentLocalStorageTokenMap = currentLocalStorageValue
          ? JSON.parse(currentLocalStorageValue)
          : {};

        localStorage.setItem(
          'endCustomerToken',
          JSON.stringify({
            ...currentLocalStorageTokenMap,
            [user]: actualEndCustomerCookie
          })
        );
      }
    }

    const persistData = localStorage.getItem('endCustomerToken');
    const endCustomerTokenMap = persistData ? JSON.parse(persistData) : {};
    const currentToken = endCustomerTokenMap[user];

    if (currentToken) {
      setCookie('endCustomerToken', currentToken, 30);

      return currentToken;
    }

    const {
      result: { token, flyUserId }
    } = await Api.TempCustomer.createTempCustomer();

    localStorage.setItem('flyUserId', flyUserId);
    localStorage.setItem(
      'endCustomerToken',
      JSON.stringify({ ...endCustomerTokenMap, [user]: token })
    );

    setCookie('endCustomerToken', token, 30);

    return token;
  } catch (e) {
    console.log(e);
  }
};

export const getCustomerSelectionId = async (
  selection: ISelectionState,
  dispatch: Dispatch<any>,
  user: string = ''
) => {
  try {
    const currentSelection = selection.all.find((item) => item.current);

    if (currentSelection) return currentSelection._id;

    const newSelection = await Api.TempCustomer.createSelection();
    ApiErrors.checkOnApiError(newSelection);

    // covers edge case when like fails due to corrupted cookie
    // if (!newSelection._id) {
    //   localStorage.removeItem('endCustomerToken');

    //   return;
    // }

    dispatch(addSelection(newSelection));

    await Api.TempCustomer.setSelection({
      collection_id: window.SITE_ID,
      selection_id: newSelection._id
    });

    return newSelection._id;
  } catch (e: any) {
    checkIfAuthorizedAndLogoutIfNot(e.code, user);

    console.log('e', e);
  }
};

let isLikeRequestProcessing = false;
const likeRequestQueue: string[] = [];

export const likeImage = createAsyncThunk<void, any, { state: RootState }>(
  'selection/likeImage',
  async (
    { imageId, applyUIChanges = true }: { imageId: string; applyUIChanges?: boolean },
    { getState, dispatch }
  ) => {
    try {
      const { selection, collection } = getState();
      const numberOfLikeIncludingCurrentImage = selection.likes.length + 1;
      const { maxLikes } = selection.currentSelection;

      if (maxLikes && maxLikes > 0) {
        if (maxLikes < numberOfLikeIncludingCurrentImage && !selection.likes.includes(imageId)) {
          toast.error(getTranslationKey('selections.maxImagesSelected', { limit: maxLikes }), {
            theme: 'colored',
            autoClose: 2000,
            hideProgressBar: true
          });

          return;
        }
      }

      // we do optimistic update in order to immediately get feedback in the UI
      // with timeout we avoid blocking animation thread
      if (applyUIChanges) setTimeout(() => dispatch(handleLikes(imageId)));

      // we need this to prevent the issue when there are 2 or even more initial requests succeeded
      // as a result there are different selections created for the same user in the same time
      if (isLikeRequestProcessing) {
        likeRequestQueue.push(imageId);

        return;
      }
      isLikeRequestProcessing = true;

      await fetchCustomerToken(collection._user);

      const customerSelectionId = await getCustomerSelectionId(
        selection,
        dispatch,
        collection._user
      );

      const bodyData = {
        collection_id: window.SITE_ID,
        selection_id: customerSelectionId,
        image_id: imageId
      };

      try {
        const response = await Api.TempCustomer.like(bodyData);

        ApiErrors.checkOnApiError(response);
      } catch (e: any) {
        checkIfAuthorizedAndLogoutIfNot(e.code);
        // if the request fails we dislike the image again
        if (applyUIChanges) dispatch(handleLikes(imageId));
      }
    } catch (e) {
      console.log('e', e);
    }

    isLikeRequestProcessing = false;

    if (likeRequestQueue.length) {
      dispatch(likeImage({ imageId: likeRequestQueue[0], applyUIChanges: false }));

      likeRequestQueue.shift();
    }
  }
);

export const cancelSelectionAndDeleteIfUnsaved = createAsyncThunk<void, void, { state: RootState }>(
  'selection/cancelSelection',
  async (_, { getState, dispatch }) => {
    try {
      const { selection, collection } = getState();
      const currentSelection = selection.all.find((item: ISelection) => item.current);

      const selectionId = currentSelection?._id;

      if (!selectionId) throw new Error('No selection active');

      const unsetData = {
        collection_id: window.SITE_ID,
        selection_id: selectionId
      };

      await fetchCustomerToken(collection._user);
      const response = await Api.TempCustomer.unsetSelection(unsetData);
      ApiErrors.checkOnApiError(response);

      // only delete the selection if it doesn't have a name yet and thus isn't saved
      if (!currentSelection.name) {
        await Api.TempCustomer.deleteSelection(selectionId);
      }

      dispatch(getEndCustomer());
    } catch (e: any) {
      checkIfAuthorizedAndLogoutIfNot(e.code);

      console.log('e', e);
    }
  }
);

export const saveSelection = createAsyncThunk<any, IValues, { state: RootState }>(
  'selection/saveSelection',
  async (
    {
      selectionName,
      message,
      firstName,
      lastName,
      email,
      withCongratsModal,
      withPasswordModal
    }: IValues,
    { getState, dispatch }
  ) => {
    try {
      const { selection, modals, collection } = getState();
      const predefinedSelectionId = modals.sendSelectionModal.selectionId;

      const selectionId = selection.all.find((item: ISelection) => item.current)?._id;

      const selectionData: any = {
        collection_id: window.SITE_ID,
        selection_id: predefinedSelectionId || selectionId,
        name: selectionName,
        firstName,
        lastName,
        email,
        message
      };

      let currentSelection = null;

      await fetchCustomerToken(collection._user);

      if (selection.updateSelectionState) {
        currentSelection = await Api.Selection.updateSelection(selectionData);
      } else {
        currentSelection = await Api.Selection.saveSelection(selectionData);
      }

      const unsetData = {
        collection_id: window.SITE_ID,
        selection_id: predefinedSelectionId || selectionId
      };

      await Api.TempCustomer.unsetSelection(unsetData);

      dispatch(getEndCustomer());

      return { currentSelection, withCongratsModal, withPasswordModal };
    } catch (e) {
      console.log('e', e);
    }
  }
);

export const setActiveSelection =
  (
    selection_id: string,
    collection: ICollection
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      await fetchCustomerToken(collection._user);
      await Api.TempCustomer.setSelection({ selection_id, collection_id: collection._id });

      dispatch(getEndCustomer());
    } catch (error) {
      console.log(error);
    }
  };
