import { calculateFrameAspectAccuracy } from './calculateFrameAspectAccuracy';

export const getFrameOrientations = (layoutGroup, dimensions) =>
  layoutGroup.map((layout) =>
    layout.frames.map((frame) => {
      const frameAspectAccuracy = calculateFrameAspectAccuracy(
        frame,
        dimensions.width,
        dimensions.height
      );

      return frameAspectAccuracy;
    })
  );
