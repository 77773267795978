export function calculateFrameAspectRatio(frame, dimensionWidth: number, dimensionHeight: number) {
  const { widthPercent, heightPercent } = frame.boundsRelative;

  const frameWidth = (widthPercent * (dimensionWidth * 2)) / 100;
  const frameHeight = (heightPercent * dimensionHeight) / 100;

  const orientation = frameWidth / frameHeight > 1 ? 'horizontal' : 'vertical';

  return { orientation, frameWidth, frameHeight };
}
