import { IProductImage } from 'modules/ScrShop/store/types';
import { find, get } from 'lodash';
import Dinero, { Currency } from 'dinero.js';

export const getOverviewImageSrc = (images: IProductImage[]) => {
  const image = find(images, { type: 'overview' });

  return get(image, 'src', '');
};

export const displayProductPriceNoCurrency = (price: number) =>
  Dinero({ amount: price }).setLocale('de').toUnit();

type PostFormatFunction = (formattedAmount: string) => string;

const localeCurrencyMap: Record<
  string,
  { locale: string; format: string; postFormat: PostFormatFunction }
> = {
  EUR: {
    locale: 'de-DE',
    format: '0.00',
    postFormat: (formattedAmount) =>
      formattedAmount.replace('.', ',').replace('€', '').trim() + '\u00A0€'
  },
  CHF: {
    locale: 'de-CH',
    format: '0.00',
    postFormat: (formattedAmount) => formattedAmount.replace('CHF', 'Fr.')
  },
  USD: {
    locale: 'en-US',
    format: '0.00',
    postFormat: (formattedAmount) => formattedAmount
  }
};

export function displayProductPrice(amountInCents: number, currency?: Currency): string {
  if (currency && !localeCurrencyMap[currency]) {
    return Dinero({ amount: amountInCents, currency }).toFormat();
  }

  if (!currency) return Dinero({ amount: amountInCents }).setLocale('en').toFormat();

  const amount = amountInCents / 100;
  const { locale, format, postFormat } = localeCurrencyMap[currency];

  if (!locale || !format || !postFormat) {
    throw new Error(`Unsupported currency: ${currency}`);
  }

  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);

  return postFormat(formattedAmount);
}
