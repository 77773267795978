import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getTranslationKey } from 'helpers/texting';
import { trackEventAction } from 'old-store/actions';
import { Api } from 'old-store/utils/API';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { setImages } from 'store/slices/images';
import { saveSelection } from 'store/slices/selection/actions';
import { HttpStatus } from 'constants/statusCodes';
import { saveAndSetNewToken } from 'helpers/tokens';
import { fetchPhotobooks } from 'modules/ScrPhotobook/store/slices/photobook/actions';

interface ISignUp {
  email: string | null;
  password: string | null;
  firstName: string | null;
  lastName: string | null;
}

interface ISignIn {
  email: string;
  firstName: string;
  lastName: string;
  password: string | null;
  selectionName: string | null;
  message?: string | null;
}

export const checkIfUserExists = async (email: string) => {
  const { result } = await Api.Auth.checkEmail(email, window.SITE_ID);

  return result.emailIsAlreadyTaken;
};

export const signUpFlyCustomer = createAsyncThunk(
  'endCustomer/signUpFlyCustomer',
  async ({ email, firstName, lastName, password }: ISignUp, { dispatch, getState }) => {
    try {
      const {
        // @ts-ignore
        collection
      } = getState();
      const signUpData = {
        collectionId: window.SITE_ID,
        email,
        password,
        firstName,
        lastName
      };

      const { result } = await Api.Auth.signup(signUpData);
      dispatch(
        saveEndCustomer({ ...result.endCustomer, _id: result.endCustomer.id, authed: true })
      );

      saveAndSetNewToken(collection._user, result.endCustomerToken);

      dispatch(
        trackEventAction({
          name: 'user-signed-up',
          payload: { endCustomer: result.endCustomer, flyUserId: localStorage.getItem('flyUserId') }
        })
      );
      localStorage.removeItem('flyUserId');

      // we have to refetch images to synchronize user id
      const images = await Api.Images.getImages();
      dispatch(setImages({ images, signedWildCardUrl: collection.signedWildCardUrl }));
      dispatch(fetchPhotobooks({}));
    } catch (e) {
      console.log('e', e);
    }
  }
);

export const signInFlyCustomer = createAsyncThunk(
  'endCustomer/signInFlyCustomer',
  async (
    { email, selectionName, message, firstName, lastName, password }: ISignIn,
    { dispatch, getState }
  ) => {
    try {
      const {
        // @ts-ignore
        selection,
        // @ts-ignore
        collection,
        // @ts-ignore
        endCustomer: { type, _id }
      } = getState();
      const signInData = { collectionId: window.SITE_ID, email: email.toLowerCase(), password };

      const res = await Api.Auth.signin(signInData);

      if (res?.code >= 400) throw res;

      dispatch(
        saveEndCustomer({ ...res.result.endCustomer, _id: res.result.endCustomer.id, authed: true })
      );

      saveAndSetNewToken(collection._user, res.result.endCustomerToken);

      dispatch(
        trackEventAction({
          name: 'user-signed-in',
          payload: { endCustomer: res.result.endCustomer, flyUserId: type === 'fly' && _id }
        })
      );

      dispatch(
        // @ts-ignore
        saveSelection({
          selectionName,
          firstName,
          lastName,
          email: email.toLowerCase(),
          message,
          withCongratsModal: !selection.updateSelectionState
        })
      );

      // we have to refetch images to synchronize user id
      const images = await Api.Images.getImages();
      dispatch(setImages({ images, signedWildCardUrl: collection.signedWildCardUrl }));
      dispatch(fetchPhotobooks({}));
    } catch (e) {
      // @ts-ignore
      if (e.code === HttpStatus.Forbidden) {
        toast.error(getTranslationKey('enterPasswordModal.passwordIsInvalid'), {
          theme: 'colored'
        });
      }
      console.log('e', e);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'endCustomer/resetPassword',
  async (email: string | null, { dispatch }) => {
    try {
      await Api.Auth.resetPassword(email, window.SITE_ID);

      dispatch(saveEndCustomer({ showPasswordChangeSuccess: true }));
    } catch (err: any) {
      if (err.code === HttpStatus.NotFound) {
        dispatch(saveEndCustomer({ showPasswordChangeFailure: true }));
      } else {
        console.log(err);
      }
    }
  }
);
