import { ReactComponent as SelectionIconDark } from 'assets/images/icons/dark - selection.svg';
import { ReactComponent as SelectionIconSimpleFiligree } from 'assets/images/icons/simple filigree - selection.svg';
import { ReactComponent as SelectionIconSpringVibes } from 'assets/images/icons/spring - selection.svg';
import { ReactComponent as SelectionIconVickyBaumannBoho } from 'assets/images/icons/vicky boho - selection.svg';
import { ReactComponent as SelectionIconVickyBaumannFineart } from 'assets/images/icons/vicky fineart - selection.svg';
import { IIconProps } from 'types/icons';
import { BaseIcon } from './BaseIcon';
import { useTemplateVariant } from 'hooks/useTemplateVariant';

export const SelectionIcon = (props: IIconProps) =>
  useTemplateVariant({
    classic: () => <BaseIcon icon={SelectionIconSimpleFiligree} {...props} />,
    dark: () => <BaseIcon icon={SelectionIconDark} {...props} />,
    simpleFiligree: () => <BaseIcon icon={SelectionIconSimpleFiligree} {...props} />,
    springVibes: () => <BaseIcon icon={SelectionIconSpringVibes} {...props} />,
    vickyBaumannBoho: () => <BaseIcon icon={SelectionIconVickyBaumannBoho} {...props} />,
    vickyBaumannFineart: () => <BaseIcon icon={SelectionIconVickyBaumannFineart} {...props} />,
    breeze: () => <BaseIcon icon={SelectionIconSimpleFiligree} {...props} />,
    capri: () => <BaseIcon icon={SelectionIconSimpleFiligree} {...props} />,
    rose: () => <BaseIcon icon={SelectionIconSimpleFiligree} {...props} />,
    calm: () => <BaseIcon icon={SelectionIconSimpleFiligree} {...props} />
  })();
