import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { useAppSelector } from 'hooks/redux';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { v4 as uuidv4 } from 'uuid';

export const LeporelloSingleImage_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 0, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 1, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={3}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 499 881 L 907 904 L 1371 788 L 1465 1236 L 994 1351 L 574 1347" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={58.7}
        left={32.6}
        width={50}
        height={36.9}
        transform="matrix3d(1,0.05,0,0.0002,0.19,1.01,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1180 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={60.2}
        left={60.2}
        width={50}
        height={41.8}
        transform="matrix3d(1,-0.25,0,-0.0002,0.2,1,1,0,0,0,1,0,0,0,1,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1100 1500" />
      </PreviewImageContainer>
    </>
  );
};

export const LeporelloSingleImage_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const verticalImages = shop.groupedImages.vertical;
  const fallbackImages = shop.groupedImages.fallback;

  const verticalImage1 = getImageByIdx(verticalImages, 0, fallbackImages);
  const verticalImage2 = getImageByIdx(verticalImages, 1, fallbackImages);
  const verticalImage3 = getImageByIdx(verticalImages, 2, fallbackImages);
  const verticalImage4 = getImageByIdx(verticalImages, 3, fallbackImages);
  const verticalImage5 = getImageByIdx(verticalImages, 4, fallbackImages);
  const verticalImage6 = getImageByIdx(verticalImages, 5, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -37 888 L 129 867 L 395 731 L 726 837 L 1047 714 L 1344 810 L 1551 813 L 1542 1176 L 1380 1178 L 1068 1089 L 737 1198 L 392 1104 L 124 1230 L -36 1239" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={58.3}
        left={-8.8}
        width={20}
        height={25}
        transform="matrix3d(1,-0.05,0,0,0,1,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage1.url_M} viewBox="0 0 1180 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={55}
        left={8}
        width={20.8}
        height={31.2}
        transform="matrix3d(0.95,-0.38,0,0.0006,0.007,1.03,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage2.url_M} viewBox="0 0 1000 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={48.2}
        left={25.9}
        width={26}
        height={34.4}
        transform="matrix3d(0.95,0.31,0,0.00035,0.023,1.02,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage3.url_M} viewBox="0 0 1100 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={55.5}
        left={48}
        width={24}
        height={29.5}
        transform="matrix3d(1,-0.365,0,0,0.03,1,1,-0.0002,0,0,1,0,0,0,1,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage4.url_M} viewBox="0 0 1100 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={47.2}
        left={69.5}
        width={21}
        height={28.6}
        transform="matrix3d(1,0.3,0,0.0001,0.06,1.03,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage5.url_M} viewBox="0 0 1100 1500" />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={53.9}
        left={90.1}
        width={20}
        height={24.9}
        transform="matrix3d(1,-0.04,0,0,0.06,1,0,0,1,0,1,0,0,0,0,1)"
        zIndex={1}
      >
        <PreviewImage image={verticalImage6.url_M} viewBox="0 0 1100 1500" />
      </PreviewImageContainer>
    </>
  );
};
