import { useMemo } from 'react';
import { PreviewImageContainer } from 'modules/ScrShop/components/PersonalizedPreview/styles';
import { PreviewImage } from 'modules/ScrShop/components/PersonalizedPreview/PreviewImage';
import { getImageByIdx } from 'modules/ScrShop/helpers/images';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';

export const SquarePrint15x15_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 0, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 1, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 2, fallbackImages);
  const horizontalImage4 = getImageByIdx(horizontalImages, 3, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 250 252 L 454 253 A 1 1 0 0 0 494 253 M 494 253 L 693 253 L 696 689 L 249 687 L 250 252" />
              <path d="M 807 252 L 1009 253 A 1 1 0 0 0 1049 253 M 1049 253 L 1250 253 L 1251 696 L 806 691 L 807 252" />
              <path d="M 252 810 L 454 813 A 1 1 0 0 0 493 814 M 493 814 L 695 813 L 695 1258 L 252 1254 L 252 810" />
              <path d="M 807 810 L 1010 813 A 1 1 0 0 0 1049 814 M 1049 814 L 1250 813 L 1250 1258 L 807 1254 L 807 810" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={16.7} left={17} width={30} height={30} zIndex={1}>
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>

      <PreviewImageContainer top={16.7} left={53} width={31} height={31} zIndex={1}>
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>

      <PreviewImageContainer top={54} left={17} width={31} height={30} zIndex={1}>
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>

      <PreviewImageContainer top={54} left={53} width={31} height={30} zIndex={1}>
        <PreviewImage image={horizontalImage4.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>
    </>
  );
};

export const SquarePrint15x15_1 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 4, fallbackImages);

  return (
    <>
      <PreviewImageContainer className="bg-preview" top={0} left={0} right={0} bottom={0}>
        <PreviewImage image={backgroundImage} />
      </PreviewImageContainer>

      <PreviewImageContainer top={19.7} left={16.2} width={67.9} height={68}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>
    </>
  );
};

export const SquarePrint15x15_2 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage = getImageByIdx(horizontalImages, 5, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M 190 245 L 564 241 M 930 239 L 1321 239 V 1364 L 184 1360 L 190 243 M 564 241 L 568 269 L 926 269 L 930 239" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer top={15.5} left={12} width={77} height={76} zIndex={1}>
        <PreviewImage image={horizontalImage.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>
    </>
  );
};

export const SquarePrint20x20_0 = ({ backgroundImage }) => {
  const shop = useAppSelector((state) => state.shop);
  const horizontalImages = shop.groupedImages.horizontal;
  const fallbackImages = shop.groupedImages.fallback;

  const horizontalImage1 = getImageByIdx(horizontalImages, 6, fallbackImages);
  const horizontalImage2 = getImageByIdx(horizontalImages, 7, fallbackImages);
  const horizontalImage3 = getImageByIdx(horizontalImages, 8, fallbackImages);

  const UNIQUE_MASK_ID = useMemo(() => uuidv4(), []);

  const maskId = fallbackImages.length ? UNIQUE_MASK_ID : '';

  return (
    <>
      <PreviewImageContainer
        className="bg-preview"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={2}
      >
        <PreviewImage
          image={backgroundImage}
          maskId={maskId}
          mask={
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="white" />
              <path d="M -69 547 L 91 558 L 91 614 L 140 616 L 142 560 M 142 560 L 384 577 L 340 1136 L -120 1099 L -69 547" />
              <path d="M 469 523 L 752 551 L 755 603 L 801 600 L 801 557 M 801 557 L 1042 578 L 982 1138 L 420 1076 L 469 523" />
              <path d="M 1094 490 L 1388 473 L 1383 535 L 1436 534 L 1440 469 M 1440 469 L 1670.0387 449.2168 L 1723 1020 L 1143 1058 L 1094 490" />
            </mask>
          }
        />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={36}
        left={-3}
        width={29}
        height={38}
        zIndex={1}
        transform="rotate(5deg)"
      >
        <PreviewImage image={horizontalImage1.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={34}
        left={32}
        width={40}
        height={38}
        zIndex={1}
        transform="rotate(7deg)"
      >
        <PreviewImage image={horizontalImage2.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>

      <PreviewImageContainer
        top={32}
        left={71}
        width={44}
        height={39}
        zIndex={1}
        transform="rotate(-4deg)"
      >
        <PreviewImage image={horizontalImage3.url_M} viewBox="0 0 1500 1000" x={-250} />
      </PreviewImageContainer>
    </>
  );
};
