import { createAction } from 'redux-act';
import {
  IAddImageAction,
  IAddImagesAction,
  IChangeImageCountAction,
  ICreateAccountWithPasswordParams,
  ICropData,
  IGroupedImages,
  IDictionary,
  IDigitalPricingList,
  IGalleryToggleState,
  IGlobalShopSettings,
  ILaboratory,
  ILoginWithPasswordParams,
  IPackage,
  IRecalculatePricingAction,
  ISetCropAction,
  ISetCropBoxImageData,
  ISetOptionAction,
  ISetShopEditMode,
  IShopReducer,
  IGroupedByGalleriesImages,
  IProductRecList
} from 'modules/ScrShop/store/types';

export const setSelectedProductIDAction = createAction<string>();

export const setSelectedImagedAction = createAction<IAddImageAction>();
export const setSelectedImagedSuccessAction = createAction<string[]>();
export const setSelectedImagesAction = createAction<IAddImagesAction>();
export const clearShopAction = createAction();
export const setOptionAction = createAction<ISetOptionAction>();
export const setCropImageAction = createAction<ISetCropAction>();
export const setCropImagesAction = createAction<IDictionary<ICropData>>();
export const deleteCroppedImageAction = createAction<string>();
export const deleteCroppedImageSuccessAction = createAction<IDictionary<ICropData>>();
export const setCropBoxImageData = createAction<ISetCropBoxImageData>();
export const addCroppedImageCountAction = createAction<IChangeImageCountAction>();
export const setShopEditModeAction = createAction<ISetShopEditMode>();
export const updateShopDataAction = createAction<Partial<IShopReducer>>('UPDATE_SHOP_DATA_ACTION');
export const changeGalleryToggleStateAction = createAction<IGalleryToggleState>();
export const fetchGlobalShopSettingsForCollectionAction = createAction();
export const fetchGlobalShopSettingsForCollectionActionSuccess =
  createAction<IGlobalShopSettings>();

export const checkEndCustomerPassword = createAction<string, string>();
export const setEndCustomerHasPassword = createAction<boolean | null>();
export const setEndCustomerEmailWasNotFound = createAction<boolean | null>();
export const toggleLoginWithPasswordModalAction = createAction(
  'TOGGLE_LOGIN_WITH_PASSWORD_MODAL_ACTION'
);
export const toggleEnterPasswordModalAction = createAction();
export const loginCustomerWithPasswordAction = createAction<ILoginWithPasswordParams>();
export const setPasswordForLoginWithPassword = createAction<string>();
export const setEmailForLoginWithPassword = createAction<string>();
export const resetLoginWithPasswordFlow = createAction();
export const setLoginFlowStatus = createAction<Partial<IShopReducer['loginFlowStatus']>>();
export const setCreateAccountWithPasswordFlowStatus =
  createAction<Partial<IShopReducer['createAccountWithPasswordFlowStatus']>>();
export const setForgotPasswordFlowStatus =
  createAction<Partial<IShopReducer['forgotPasswordFlowStatus']>>();
export const resetPassword = createAction<string>();
export const checkEndCustomerEmail = createAction<string>();
export const createAccountWithPasswordAction = createAction<ICreateAccountWithPasswordParams>();
export const resetSignupWithPasswordFlow = createAction();

export const fetchLaboratoryAction = createAction<string>();
export const fetchLaboratoryActionSuccess = createAction<ILaboratory>();

export const recalculatePricingAction = createAction<IRecalculatePricingAction>();

export const fetchPackagesForUpselling = createAction<string>();
export const startFetchingPackagesForUpselling = createAction();
export const stopFetchingPackagesForUpselling = createAction();
export const fetchPackagesForUpsellingSuccess = createAction<IPackage[]>();

export const fetchDigitalPricingForUpselling = createAction<string>();
export const fetchDigitalPricingForUpsellingSuccess = createAction<IDigitalPricingList>();

export const groupImagesBySizeType = createAction();
export const setGroupedImages = createAction<IGroupedImages>();
export const setGroupedByGalleriesImages = createAction<IGroupedByGalleriesImages>();

export const fetchProductRecList = createAction();
export const fetchProductRecListSuccess = createAction<IProductRecList>();
export const fetchProductBestsellerList = createAction();
export const fetchProductBestsellerListSuccess = createAction<IProductRecList>();
