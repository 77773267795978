const digits = '0987654321';

const getCase = (letter: string, idx: number) =>
  letter.toUpperCase() === letter && !digits.includes(letter)
    ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
    : letter;

const camelCaseToKebabCase = (str: string) => str.split('').map(getCase).join('');

export default camelCaseToKebabCase;
